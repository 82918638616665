import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { LanguageSelectPopupComponent } from './language-select.component'

@NgModule({
  declarations: [LanguageSelectPopupComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatCardModule, MatDialogModule],
})
export class LanguageSelectPopupModule {}
