import { Action } from '@ngrx/store'
import { ITokenPair, IUser, IUserLogin } from 'src/app/models/User.model'
import { HttpRequest } from '@angular/common/http'

export enum EAuthActions {
  RefreshToken = '[Auth] Refresh Auth',
  RefreshTokenSuccess = '[Auth] Refresh Auth Success',
  RefreshTokenFailure = '[Auth] Refresh Auth Failure',
  SignIn = '[Auth] Sign In',
  SignInGoogle = '[Auth] Sign In Google',
  SignInSuccess = '[Auth] Sign In Success',
  SignInFailure = '[Auth] Sign In Failure',
  SignUp = '[Auth] Sign Up',
  SignUpGoogle = '[Auth] Sign Up Google',
  SignUpSuccess = '[Auth] Sign Up Success',
  SignUpFailure = '[Auth] Sign Up Failure',
}

export class RefreshToken implements Action {
  public readonly type = EAuthActions.RefreshToken
  constructor(public payload: HttpRequest<any>) {}
}

export class RefreshTokenSuccess implements Action {
  public readonly type = EAuthActions.RefreshTokenSuccess
}

export class RefreshTokenFailure implements Action {
  public readonly type = EAuthActions.RefreshTokenFailure
}

export class SignIn implements Action {
  public readonly type = EAuthActions.SignIn
  constructor(public payload: IUserLogin) {}
}

export class SignInSuccess implements Action {
  public readonly type = EAuthActions.SignInSuccess
  constructor(public payload: ITokenPair) {}
}

export class SignInFailure implements Action {
  public readonly type = EAuthActions.SignInFailure
  constructor(public payload: string) {}
}

export class SignInGoogle implements Action {
  public readonly type = EAuthActions.SignInGoogle
  constructor(public payload: any) {}
}

export class SignUp implements Action {
  public readonly type = EAuthActions.SignUp
  constructor(public payload: IUser) {}
}

export class SignUpSuccess implements Action {
  public readonly type = EAuthActions.SignUpSuccess
  constructor(public payload: ITokenPair) {}
}

export class SignUpFailure implements Action {
  public readonly type = EAuthActions.SignUpFailure
  constructor(public payload: any) {}
}

export class SignUpGoogle implements Action {
  public readonly type = EAuthActions.SignUpGoogle
  constructor(public payload: any) {}
}

export type AuthActions = RefreshToken | RefreshTokenSuccess | RefreshTokenFailure | SignIn | SignInGoogle | SignInSuccess | SignInFailure | SignUp | SignUpGoogle | SignUpSuccess | SignUpFailure
