import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BulkCreateComponent } from './bulk-create.component'
import { TranslateModule } from '@ngx-translate/core'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [BulkCreateComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, MatInputModule, MatFormFieldModule, MatIconModule, RouterModule, MatButtonModule, MatSelectModule],
})
export class BulkCreateModule {}
