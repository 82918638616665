import { Action } from '@ngrx/store'
import { IResponseError } from 'src/app/models/Error.model'
import { IBook, IBookCompleteRequest, IBooksRequest, IBooksResponse, IBookCompleteResponse } from '../../models/Book.model'

export enum EBooksActions {
  CompleteBook = '[Books] Complete Book',
  CompleteBookSuccess = '[Books] Complete Book Success',
  CompleteBookFailure = '[Books] Complete Book Failure',
  GetBooks = '[Books] Get Books',
  GetBooksSuccess = '[Books] Get Books Success',
  GetBook = '[Books] Get Book',
  GetBookSuccess = '[Books] Get Book Success',
}

export class CompleteBook implements Action {
  public readonly type = EBooksActions.CompleteBook
  constructor(public payload: IBookCompleteRequest) {}
}

export class CompleteBookSuccess implements Action {
  public readonly type = EBooksActions.CompleteBookSuccess
  constructor(public payload: IBookCompleteResponse) {}
}

export class CompleteBookFailure implements Action {
  public readonly type = EBooksActions.CompleteBookFailure
  constructor(public payload: IResponseError) {}
}

export class GetBooks implements Action {
  public readonly type = EBooksActions.GetBooks
  constructor(public payload: IBooksRequest) {}
}

export class GetBooksSuccess implements Action {
  public readonly type = EBooksActions.GetBooksSuccess
  constructor(public payload: IBooksResponse) {}
}

export class GetBook implements Action {
  public readonly type = EBooksActions.GetBook
  constructor(public payload: number | string) {}
}

export class GetBookSuccess implements Action {
  public readonly type = EBooksActions.GetBookSuccess
  constructor(public payload: IBook) {}
}

export type BooksActions = CompleteBook | CompleteBookSuccess | CompleteBookFailure | GetBooks | GetBooksSuccess | GetBook | GetBookSuccess
