import { Component, OnDestroy } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { EUserActions, UpdateUserPassword, UpdateUserPasswordSuccess } from 'src/app/store/actions/user.actions'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntil } from 'rxjs/operators'
import { Subject, Observable } from 'rxjs'
import { IUser } from 'src/app/models/User.model'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { NotificationService } from 'src/app/services/notification.service'
import { TranslateService } from '@ngx-translate/core'
import { PasswordValidator } from 'src/app/helpers/validators/password.validator'

@Component({
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.scss'],
})
export class ChangePasswordDialogComponent implements OnDestroy {
  private destroyed$ = new Subject<boolean>()

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private _store: Store<IAppState>,
    private updates$: Actions,
    private _notificationService: NotificationService,
    public translate: TranslateService,
    private passwordValidator: PasswordValidator
  ) {
    this.updates$.pipe(ofType<UpdateUserPasswordSuccess>(EUserActions.UpdateUserPasswordSuccess), takeUntil(this.destroyed$)).subscribe((res: UpdateUserPasswordSuccess) => {
      this.translate.get('TOASTER_NOTIFICATIONS.PASSWORD_UPDATED').subscribe((translatedText: string) => {
        this._notificationService.showNotification(translatedText, 2)
      })
      this.dialogRef.close()
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  private unsubscribe$ = new Subject()

  parent$: Observable<IUser> = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser))

  changePasswordForm = new UntypedFormGroup({
    newPassword: new UntypedFormControl('', [Validators.minLength(8), Validators.maxLength(20), this.passwordValidator.passwordValidator, Validators.required]),
    confirmPassword: new UntypedFormControl('', [Validators.minLength(8), Validators.maxLength(20), this.passwordValidator.passwordValidator, Validators.required]),
  })

  onNoClick(): void {
    this.dialogRef.close()
  }

  saveNewPassword() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      const request = {
        id: user.user.id,
        newPassword: this.changePasswordForm.value.newPassword,
        refreshToken: localStorage.getItem('refreshToken')
      }
      
      if (this.changePasswordForm.value.newPassword === this.changePasswordForm.value.confirmPassword) {
        this._store.dispatch(new UpdateUserPassword(request))
      } else {
        this.translate.get('TOASTER_NOTIFICATIONS.CONFIRM_PASSWORD_INCORRECT').subscribe((translatedText: string) => {
          this._notificationService.showNotification(translatedText, 2)
        })
      }
    })
  }
}
