export const langForms = {
  en: {
    language: {
      form1: 'language',
    },
    english: {
      form1: 'English'
    },
    russian: {
      form1: 'Russian'
    },
    albanian: {
      form1: 'Albanian'
    },
    arabic: {
      form1: 'Arabic'
    },
    armenian: {
      form1: 'Armenian'
    },
    bulgarian: {
      form1: 'Bulgarian'
    },
    cantonese: {
      form1: 'Cantonese'
    },
    chinese: {
      form1: 'Chinese'
    },
    creole: {
      form1: 'Creole'
    },
    croatian: {
      form1: 'Croatian'
    },
    czech: {
      form1: 'Czech'
    },
    danish: {
      form1: 'Danish'
    },
    dari: {
      form1: 'Dari'
    },
    dutch: {
      form1: 'Dutch'
    },
    euportuguese: {
      form1: 'European Portuguese'
    },
    finnish: {
      form1: 'Finnish'
    },
    french: {
      form1: 'French'
    },
    german: {
      form1: 'German'
    },
    greek: {
      form1: 'Greek'
    },
    gujarati: {
      form1: 'Gujarati'
    },
    hawaiian: {
      form1: 'Hawaiian'
    },
    hebrew: {
      form1: 'Hebrew'
    },
    hindi: {
      form1: 'Hindi'
    },
    hungarian: {
      form1: 'Hungarian'
    },
    indonesian: {
      form1: 'Indonesian'
    },
    irish: {
      form1: 'Irish'
    },
    italian: {
      form1: 'Italian'
    },
    japanese: {
      form1: 'Japanese'
    },
    kazakh: {
      form1: 'Kazakh',
    },
    korean: {
      form1: 'Korean'
    },
    latin: {
      form1: 'Latin'
    },
    malay: {
      form1: 'Malay'
    },
    norwegian: {
      form1: 'Norwegian'
    },
    persian: {
      form1: 'Farsi - Persian'
    },
    polish: {
      form1: 'Polish'
    },
    portuguese: {
      form1: 'Portuguese'
    },
    punjabi: {
      form1: 'Punjabi'
    },
    romanian: {
      form1: 'Romanian'
    },
    serbian: {
      form1: 'Serbian'
    },
    slovak: {
      form1: 'Slovak'
    },
    slovenian: {
      form1: 'Slovenian'
    },
    spanish: {
      form1: 'Spanish'
    },
    swahili: {
      form1: 'Swahili'
    },
    swedish: {
      form1: 'Swedish'
    },
    tagalog: {
      form1: 'Tagalog'
    },
    thai: {
      form1: 'Thai'
    },
    turkish: {
      form1: 'Turkish'
    },
    ukrainian: {
      form1: 'Ukrainian'
    },
    urdu: {
      form1: 'Urdu'
    },
    vietnamese: {
      form1: 'Vietnamese'
    },
    welsh: {
      form1: 'Welsh'
    }
  },
  ru: {
    language: {
      form1: 'язык',
      form2: 'языков',
      form3: 'язык',
      form4: 'язык',
      form5: 'язык',
    },
    english: {
      form1: 'английский',
      form2: 'английского',
      form3: 'английскому',
      form4: 'английском',
      form5: 'английским',
    },
    russian: {
      form1: 'русский',
      form2: 'русского',
      form3: 'русскому',
      form4: 'русском',
      form5: 'русским',
    },
    albanian: {
      form1: 'албанский',
      form2: 'албанского',
      form3: 'албанскому',
      form4: 'албанском',
      form5: 'албанским',
    },
    arabic: {
      form1: 'арабский',
      form2: 'арабского',
      form3: 'арабскому',
      form4: 'арабском',
      form5: 'арабским',
    },
    armenian: {
      form1: 'армянский',
      form2: 'армянского',
      form3: 'армянскому',
      form4: 'армянском',
      form5: 'армянским',
    },
    bulgarian: {
      form1: 'болгарский',
      form2: 'болгарского',
      form3: 'болгаркому',
      form4: 'болгарском',
      form5: 'болгарским',
    },
    cantonese: {
      form1: 'кантонский',
      form2: 'кантонского',
      form3: 'кантонскому',
      form4: 'кантонском',
      form5: 'кантонским',
    },
    chinese: {
      form1: 'китайский',
      form2: 'китайского',
      form3: 'китайскому',
      form4: 'китайском',
      form5: 'китайским',
    },
    creole: {
      form1: 'креольский',
      form2: 'креольского',
      form3: 'креольскому',
      form4: 'креольском',
      form5: 'креольским',
    },
    croatian: {
      form1: 'хорватский',
      form2: 'хорватского',
      form3: 'хорватскому',
      form4: 'хорватском',
      form5: 'хорватским',
    },
    czech: {
      form1: 'чешский',
      form2: 'чешского',
      form3: 'ческому',
      form4: 'чешском',
      form5: 'ческим',
    },
    danish: {
      form1: 'датский',
      form2: 'датского',
      form3: 'датскому',
      form4: 'датском',
      form5: 'датским',
    },
    dari: {
      form1: 'дари',
      form2: 'дари',
      form3: 'дари',
      form4: 'дари',
      form5: 'дари',
    },
    dutch: {
      form1: 'голландский',
      form2: 'голландского',
      form3: 'голландскому',
      form4: 'голландском',
      form5: 'голландским',
    },
    euportuguese: {
      form1: 'португальский',
      form2: 'португальского',
      form3: 'португальскому',
      form4: 'португальском',
      form5: 'португальским',
    },
    finnish: {
      form1: 'финский',
      form2: 'финского',
      form3: 'финскому',
      form4: 'финском',
      form5: 'финским',
    },
    french: {
      form1: 'французский',
      form2: 'французского',
      form3: 'французскому',
      form4: 'французском',
      form5: 'французским',
    },
    german: {
      form1: 'немецкий',
      form2: 'немецкого',
      form3: 'немецкому',
      form4: 'немецком',
      form5: 'немецким',
    },
    greek: {
      form1: 'греческий',
      form2: 'греческого',
      form3: 'греческому',
      form4: 'греческом',
      form5: 'греческим',
    },
    gujarati: {
      form1: 'гуджарати',
      form2: 'гуджарати',
      form3: 'гуджарати',
      form4: 'гуджарати',
      form5: 'гуджарати',
    },
    hawaiian: {
      form1: 'гавайский',
      form2: 'гавайского',
      form3: 'гавайскому',
      form4: 'гавайском',
      form5: 'гавайским',
    },
    hebrew: {
      form1: 'иврит',
      form2: 'иврита',
      form3: 'ивриту',
      form4: 'иврите',
      form5: 'ивритом',
    },
    hindi: {
      form1: 'хинди',
      form2: 'хинди',
      form3: 'хинди',
      form4: 'хинди',
      form5: 'хинди',
    },
    hungarian: {
      form1: 'венгерский',
      form2: 'венгерского',
      form3: 'венгерскому',
      form4: 'венгерском',
      form5: 'венгерским',
    },
    indonesian: {
      form1: 'индонезийский',
      form2: 'индонезийского',
      form3: 'индонезийскому',
      form4: 'индонезийском',
      form5: 'индонезийским',
    },
    irish: {
      form1: 'ирландский',
      form2: 'ирландского',
      form3: 'ирландскому',
      form4: 'ирландском',
      form5: 'ирландским',
    },
    italian: {
      form1: 'итальянский',
      form2: 'итальянского',
      form3: 'итальянскому',
      form4: 'итальянском',
      form5: 'итальянским',
    },
    japanese: {
      form1: 'японский',
      form2: 'японского',
      form3: 'японскому',
      form4: 'японском',
      form5: 'японским',
    },
    kazakh: {
      form1: 'казахский',
      form2: 'казахского',
      form3: 'казахскому',
      form4: 'казахском',
      form5: 'казахским',
    },
    korean: {
      form1: 'корейский',
      form2: 'корейского',
      form3: 'корейскому',
      form4: 'корейском',
      form5: 'корейским',
    },
    latin: {
      form1: 'латынь',
      form2: 'латыни',
      form3: 'латыни',
      form4: 'латыни',
      form5: 'латинским',
    },
    malay: {
      form1: 'малайский',
      form2: 'малайского',
      form3: 'малайскому',
      form4: 'малайском',
      form5: 'малайским',
    },
    norwegian: {
      form1: 'норвежский',
      form2: 'норвежского',
      form3: 'норвежскому',
      form4: 'норвежском',
      form5: 'норвежским',
    },
    persian: {
      form1: 'персидский',
      form2: 'персидского',
      form3: 'персидскому',
      form4: 'персидском',
      form5: 'персидским',
    },
    polish: {
      form1: 'польский',
      form2: 'польского',
      form3: 'польскому',
      form4: 'польском',
      form5: 'польским',
    },
    portuguese: {
      form1: 'португальский',
      form2: 'португальского',
      form3: 'португальскому',
      form4: 'португальском',
      form5: 'португальским',
    },
    punjabi: {
      form1: 'панджаби',
      form2: 'панджаби',
      form3: 'панджаби',
      form4: 'панджаби',
      form5: 'панджаби',
    },
    romanian: {
      form1: 'румынский',
      form2: 'румынского',
      form3: 'румынскому',
      form4: 'румынском',
      form5: 'румынским',
    },
    serbian: {
      form1: 'сербский',
      form2: 'сербского',
      form3: 'сербскому',
      form4: 'сербском',
      form5: 'сербским',
    },
    slovak: {
      form1: 'словацкий',
      form2: 'словацкого',
      form3: 'словацкому',
      form4: 'словацком',
      form5: 'словацким',
    },
    slovenian: {
      form1: 'словенский',
      form2: 'словенского',
      form3: 'словенскому',
      form4: 'словенском',
      form5: 'словенским',
    },
    spanish: {
      form1: 'испанский',
      form2: 'испанского',
      form3: 'испанскому',
      form4: 'испанском',
      form5: 'испанским',
    },
    swahili: {
      form1: 'суахили',
      form2: 'суахили',
      form3: 'суахили',
      form4: 'суахили',
      form5: 'суахили',
    },
    swedish: {
      form1: 'шведский',
      form2: 'шведского',
      form3: 'шведскому',
      form4: 'шведском',
      form5: 'шведским',
    },
    tagalog: {
      form1: 'тагальский',
      form2: 'тагальского',
      form3: 'тагальскому',
      form4: 'тагальском',
      form5: 'тагальским',
    },
    thai: {
      form1: 'тайский',
      form2: 'тайского',
      form3: 'тайскому',
      form4: 'тайском',
      form5: 'тайским',
    },
    turkish: {
      form1: 'турецкий',
      form2: 'турецкого',
      form3: 'турецкому',
      form4: 'турецком',
      form5: 'турецким',
    },
    ukrainian: {
      form1: 'украинский',
      form2: 'украинского',
      form3: 'украинскому',
      form4: 'украинском',
      form5: 'украинским',
    },
    urdu: {
      form1: 'урду',
      form2: 'урду',
      form3: 'урду',
      form4: 'урду',
      form5: 'урду',
    },
    vietnamese: {
      form1: 'вьетнамский',
      form2: 'вьентнамского',
      form3: 'вьетнамскому',
      form4: 'вьетнамском',
      form5: 'вьетнамским',
    },
    welsh: {
      form1: 'уэльский',
      form2: 'уэльского',
      form3: 'уэльскому',
      form4: 'уэльском',
      form5: 'уэльским',
    },
  },
ua: {
    language: {
      form1: 'іноземна мова',
      form2: 'іноземну мову',
      form3: 'цільовою мовою',
      form4: 'іноземної мови',
    },
    english: {
      form1: 'англійська',
      form2: 'англійську',
      form3: 'англійською',
      form4: 'англійської',
    },
    albanian: {
      form1: 'албанська',
      form2: 'албанську',
      form3: 'албанською',
      form4: 'албанської',
    },
    arabic: {
      form1: 'арабська',
      form2: 'арабську',
      form3: 'арабською',
      form4: 'арабської',
    },
    armenian: {
      form1: 'вірменська',
      form2: 'вірменську',
      form3: 'вірменською',
      form4: 'вірменської',
    },
    bulgarian: {
      form1: 'болгарська',
      form2: 'болгарську',
      form3: 'болгарською',
      form4: 'болгарської',
    },
    cantonese: {
      form1: 'кантонська',
      form2: 'кантонську',
      form3: 'кантонською',
      form4: 'кантонської',
    },
    chinese: {
      form1: 'китайська',
      form2: 'китайську',
      form3: 'китайською',
      form4: 'китайської',
    },
    creole: {
      form1: 'креольська',
      form2: 'креольську',
      form3: 'креольською',
      form4: 'креольської',
    },
    croatian: {
      form1: 'хорватська',
      form2: 'хорватську',
      form3: 'хорватською',
      form4: 'хорватської',
    },
    czech: {
      form1: 'чешська',
      form2: 'чешську',
      form3: 'чешською',
      form4: 'чешської',
    },
    danish: {
      form1: 'данська',
      form2: 'данську',
      form3: 'данською',
      form4: 'данської',
    },
    dari: {
      form1: 'дарійська',
      form2: 'дарійську',
      form3: 'дарійською',
      form4: 'дарійської',
    },
    dutch: {
      form1: 'голандська',
      form2: 'голандську',
      form3: 'голандською',
      form4: 'голандської',
    },
    euportuguese: {
      form1: 'португальська',
      form2: 'португальську',
      form3: 'португальською',
      form4: 'португальської',
    },
    finnish: {
      form1: 'фінська',
      form2: 'фінську',
      form3: 'фінською',
      form4: 'фінської',
    },
    french: {
      form1: 'французька',
      form2: 'французьку',
      form3: 'французькою',
      form4: 'французької',
    },
    german: {
      form1: 'німецька',
      form2: 'німецьку',
      form3: 'німецькою',
      form4: 'німецької',
    },
    greek: {
      form1: 'грецька',
      form2: 'грецьку',
      form3: 'грецькою',
      form4: 'грецької',
    },
    gujarati: {
      form1: 'гуджараті',
      form2: 'гуджараті',
      form3: 'гуджараті',
      form4: 'гуджараті',
    },
    hawaiian: {
      form1: 'гавайська',
      form2: 'гавайську',
      form3: 'гавайською',
      form4: 'гавайської',
    },
    hebrew: {
      form1: 'іврит',
      form2: 'іврит',
      form3: 'івритом',
      form4: 'івриту',
    },
    hindi: {
      form1: 'хінді',
      form2: 'хінді',
      form3: 'хінді',
      form4: 'хінді',
    },
    hungarian: {
      form1: 'угорська',
      form2: 'угорську',
      form3: 'угорською',
      form4: 'угорської',
    },
    indonesian: {
      form1: 'індонезійська',
      form2: 'індонезійську',
      form3: 'індонезійською',
      form4: 'індонезійської',
    },
    irish: {
      form1: 'ірландська',
      form2: 'ірландську',
      form3: 'ірландською',
      form4: 'ірландської',
    },
    italian: {
      form1: 'італійська',
      form2: 'італійську',
      form3: 'італійською',
      form4: 'італійської',
    },
    japanese: {
      form1: 'японська',
      form2: 'японську',
      form3: 'японською',
      form4: 'японської',
    },
    kazakh: {
      form1: 'казахська',
      form2: 'казахську',
      form3: 'казахською',
      form4: 'казахської',
    },
    korean: {
      form1: 'корейська',
      form2: 'корейську',
      form3: 'корейською',
      form4: 'корейської',
    },
    latin: {
      form1: 'латинська',
      form2: 'латинську',
      form3: 'латинською',
      form4: 'латинської',
    },
    malay: {
      form1: 'малайська',
      form2: 'малайську',
      form3: 'малайською',
      form4: 'малайської',
    },
    norwegian: {
      form1: 'норвезька',
      form2: 'норвезьку',
      form3: 'норвезькою',
      form4: 'норвезької',
    },
    persian: {
      form1: 'перська',
      form2: 'перську',
      form3: 'перською',
      form4: 'перської',
    },
    polish: {
      form1: 'польська',
      form2: 'польську',
      form3: 'польською',
      form4: 'польської',
    },
    portuguese: {
      form1: 'португальська',
      form2: 'португальську',
      form3: 'португальською',
      form4: 'португальської',
    },
    punjabi: {
      form1: 'пенджабі',
      form2: 'пенджабі',
      form3: 'пенджабі',
      form4: 'пенджабі',
    },
    romanian: {
      form1: 'румунська',
      form2: 'румунську',
      form3: 'румунською',
      form4: 'румунської',
    },
    russian: {
      form1: 'російська',
      form2: 'російську',
      form3: 'російською',
      form4: 'російської',
    },
    serbian: {
      form1: 'сербська',
      form2: 'сербську',
      form3: 'сербською',
      form4: 'сербської',
    },
    slovak: {
      form1: 'словацька',
      form2: 'словацьку',
      form3: 'словацькою',
      form4: 'словацької',
    },
    slovenian: {
      form1: 'словенська',
      form2: 'словенську',
      form3: 'словенською',
      form4: 'словенської',
    },
    spanish: {
      form1: 'іспанська',
      form2: 'іспанську',
      form3: 'іспанською',
      form4: 'іспанської',
    },
    swahili: {
      form1: 'cуахілі',
      form2: 'cуахілі',
      form3: 'cуахілі',
      form4: 'cуахілі',
    },
    swedish: {
      form1: 'шведська',
      form2: 'шведську',
      form3: 'шведською',
      form4: 'шведської',
    },
    tagalog: {
      form1: 'тагальська',
      form2: 'тагальську',
      form3: 'тагальською',
      form4: 'тагальської',
    },
    thai: {
      form1: 'тайська',
      form2: 'тайську',
      form3: 'тайською',
      form4: 'тайської',
    },
    turkish: {
      form1: 'турецька',
      form2: 'турецьку',
      form3: 'турецькою',
      form4: 'турецької',
    },
    ukrainian: {
      form1: 'українська',
      form2: 'українську',
      form3: 'українською',
      form4: 'української',
    },
    urdu: {
      form1: 'урду',
      form2: 'урду',
      form3: 'урду',
      form4: 'урду',
    },
    vietnamese: {
      form1: 'в\'єтнамська',
      form2: 'в\'єтнамську',
      form3: 'в\'єтнамською',
      form4: 'в\'єтнамської',
    },
    welsh: {
      form1: 'уельська',
      form2: 'уельську',
      form3: 'уельською',
      form4: 'уельської',
    },
  },
  de: {
    language: {
      form1: "language",
      form2: "language",
      form3: "language",
      form4: "language",
      form5: "language",
      },
      english: {
      form1: "Englisch",
      form2: "Englisch",
      form3: "Englisch",
      form4: "Englisch",
      form5: "Englischen",
      },
      russian: {
      form1: "Russisch",
      form2: "Russisch",
      form3: "Russisch",
      form4: "Russisch",
      form5: "Russischen",
      },
      albanian: {
      form1:"Albanisch",
      form2: "Albanisch",
      form3: "Albanisch",
      form4: "Albanisch",
      form5: "Albanischen",
      },
      arabic: {
      form1: "Arabisch",
      form2: "Arabisch",
      form3: "Arabisch",
      form4: "Arabisch",
      form5: "Arabischen",
      },
      armenian: {
      form1: "Armenisch",
      form2: "Armenisch",
      form3: "Armenisch",
      form4: "Armenisch",
      form5: "Armenischen",
      },
      bulgarian: {
      form1: "Bulgarisch",
      form2: "Bulgarisch",
      form3: "Bulgarisch",
      form4: "Bulgarisch",
      form5: "Bulgarischen",
      },
      cantonese: {
      form1: "Kantonesisch",
      form2: "Katonesisch",
      form3: "Kantonesisch",
      form4: "Kantonesisch",
      form5: "Kantonesischen",
      },
      chinese: {
      form1: "Chinesisch",
      form2: "Chinesisch",
      form3: "Chinesisch",
      form4: "Chinesisch",
      form5: "Chinesischen",
      },
      creole: {
      form1: "Kreolisch",
      form2: "Kreolisch",
      form3: "Kreolisch",
      form4: "Kreolisch",
      form5: "Kreolischen",
      },
      croatian: {
      form1: "Kroatisch",
      form2: "Kroatisch",
      form3: "Kroatisch",
      form4: "Kroatisch",
      form5: "Kroatischen",
      },
      czech: {
      form1: "Tschechisch",
      form2: "Tschechisch",
      form3: "Tschechisch",
      form4: "Tschechisch",
      form5: "Tschechischen",
      },
      danish: {
      form1: "Dänisch",
      form2: "Dänisch",
      form3: "Dänisch",
      form4: "Dänisch",
      form5: "Dänischen",
      },
      dari: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      dutch: {
      form1: "Niederländisch",
      form2: "Niederländisch",
      form3: "Niederländisch",
      form4: "Niederländisch",
      form5: "Niederländischen",
      },
      euportuguese: {
      form1: "Europäisches Portugiesisch",
      form2: "Europäisches Portugiesisch",
      form3: "Europäisches Portugiesisch",
      form4: "Europäischem Portugiesisch",
      form5: "Europäisch Portugiesischen",
      },
      finnish: {
      form1: "Finnisch",
      form2: "Finnisch",
      form3: "Finnisch",
      form4: "Finnisch",
      form5: "Finnischen",
      },
      french: {
      form1: "Französisch",
      form2: "Französisch",
      form3: "Französisch",
      form4: "Französisch",
      form5: "Französischen",
      },
      german: {
      form1: "Deutsch",
      form2: "Deutsch",
      form3: "Deutsch",
      form4: "Deutsch",
      form5: "Deutschen",
      },
      greek: {
      form1: "Griechisch",
      form2: "Griechisch",
      form3: "Griechisch",
      form4: "Griechisch",
      form5: "Griechischen",
      },
      gujarati: {
      form1: "Gujarati",
      form2: "Gujarati",
      form3: "Gujarati",
      form4: "Gujarati",
      form5: "Gujaratischen",
      },
      hawaiian: {
      form1: "Hawaiianisch",
      form2: "Hawaiianisch",
      form3: "Hawaiianisch",
      form4: "Hawaiianisch",
      form5: "Hawaiianischen",
      },
      hebrew: {
      form1: "Hebräisch",
      form2: "Hebräisch",
      form3: "Hebräisch",
      form4: "Hebräisch",
      form5: "Hebräischen",
      },
      hindi: {
      form1: "Hindi",
      form2: "Hindi",
      form3: "Hindi",
      form4: "Hindi",
      form5: "Hindi",
      },
      hungarian: {
      form1: "Ungarisch",
      form2: "Ungarisch",
      form3: "Ungarisch",
      form4: "Ungarisch",
      form5: "Ungarischen",
      },
      indonesian: {
      form1: "Indonesisch",
      form2: "Indonesisch",
      form3: "Indonesisch",
      form4: "Indonesisch",
      form5: "Indonesischen",
      },
      irish: {
      form1: "Irisch",
      form2: "Irisch",
      form3: "Irisch",
      form4: "Irisch",
      form5: "Irischen",
      },
      italian: {
      form1: "Italienisch",
      form2: "Italienisch",
      form3: "Italienisch",
      form4: "Italienisch",
      form5: "Italienischen",
      },
      japanese: {
      form1: "Japanisch",
      form2: "Japanisch",
      form3: "Japanisch",
      form4: "Japanisch",
      form5: "Japanischen",
      },
      kazakh: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      korean: {
      form1: "Koreanisch",
      form2: "Koreanisch",
      form3: "Koreanisch",
      form4: "Koreanisch",
      form5: "Koreanischen",
      },
      latin: {
      form1: "Latein",
      form2: "Latein",
      form3: "Latein",
      form4: "Latein",
      form5: "Lateinischen",
      },
      malay: {
      form1: "Malaiisch",
      form2: "Malaiisch",
      form3: "Malaiisch",
      form4: "Malaiisch",
      form5: "Malaiischen",
      },
      norwegian: {
      form1: "Norwegisch",
      form2: "Norwegisch",
      form3: "Norwegisch",
      form4: "Norwegisch",
      form5: "Norwegischen",
      },
      persian: {
      form1: "Persisch",
      form2: "Persisch",
      form3: "Persisch",
      form4: "Persisch",
      form5: "Persischen",
      },
      polish: {
      form1: "Polnisch",
      form2: "Polnisch",
      form3: "Polnisch",
      form4: "Polnisch",
      form5: "Polnischen",
      },
      portuguese: {
      form1: "Portugiesisch",
      form2: "Portugiesisch",
      form3: "Portugiesisch",
      form4: "Portugiesisch",
      form5: "Portugiesischen",
      },
      punjabi: {
      form1: "Punjabi",
      form2: "Punjabi",
      form3: "Punjabi",
      form4: "Punjabi",
      form5: "Punjabischen",
      },
      romanian: {
      form1: "Rumänisch",
      form2: "Rumänisch",
      form3: "Rumänisch",
      form4: "Rumänisch",
      form5: "Rumänischen",
      },
      serbian: {
      form1: "Serbisch",
      form2: "Serbisch",
      form3: "Serbisch",
      form4: "Serbisch",
      form5: "Serbischen",
      },
      slovak: {
      form1: "Slowakisch",
      form2: "Slowakisch",
      form3: "Slowakisch",
      form4: "Slowakisch",
      form5: "Slowakisch",
      },
      slovenian: {
      form1: "Slowenisch",
      form2: "Slowenisch",
      form3: "Slowenisch",
      form4: "Slowenisch",
      form5: "Slowenischen",
      },
      spanish: {
      form1: "Spanisch",
      form2: "Spanisch",
      form3: "Spanisch",
      form4: "Spanisch",
      form5: "Spanischen",
      },
      swahili: {
      form1: "Swahili",
      form2: "Swahili",
      form3: "Swahili",
      form4: "Swahili",
      form5: "Swahili",
      },
      swedish: {
      form1: "Schwedisch",
      form2: "Schwedisch",
      form3: "Schwedisch",
      form4: "Schwedisch",
      form5: "Schwedischen",
      },
      tagalog: {
      form1: "Tagalog",
      form2: "Tagalog",
      form3: "Tagalog",
      form4: "Tagalog",
      form5: "Tagalog",
      },
      thai: {
      form1: "Thailändisch",
      form2: "Thailändisch",
      form3: "Thailändisch",
      form4: "Thailändisch",
      form5: "Thailänischen",
      },
      turkish: {
      form1: "Türkisch",
      form2: "Türkisch",
      form3: "Türkisch",
      form4: "Türkisch",
      form5: "Türkischen",
      },
      ukrainian: {
      form1: "Ukrainisch",
      form2: "Ukrainisch",
      form3: "Ukrainisch",
      form4: "Ukrainisch",
      form5: "Ukrainischen",
      },
      urdu: {
      form1: "Urdu",
      form2: "Urdu",
      form3: "Urdu",
      form4: "Urdu",
      form5: "Urdu",
      },
      vietnamese: {
      form1: "Vietnamesisch",
      form2: "Vietnamesisch",
      form3: "Vietnamesisch",
      form4: "Vietnamesisch",
      form5: "Vietnamesischen",
      },
      welsh: {
      form1: "Walisisch",
      form2: "Walisisch",
      form3: "Walisisch",
      form4: "Walisisch",
      form5: "Walisischen",
      },
  },
  fr: {
    language: {
      form1: "language",
      form2: "language",
      form3: "language",
      form4: "language",
      form5: "language",
      },
      english: {
      form1: "l'Anglais",
      form2: "d'Anglais",
      form3: "anglaise",
      form4: "anglais",
      form5: "en Anglais",
      },
      russian: {
      form1: "le Russe",
      form2: "de Russe",
      form3: "russe",
      form4: "russe",
      form5: "en Russe",
      },
      albanian: {
      form1:"l'Albanais",
      form2: "d'Albanais",
      form3: "albanaise",
      form4: "albanais ",
      form5: "en Albanais",
      },
      arabic: {
      form1: "l'Arabe",
      form2: "d'Arabe",
      form3: "arabe",
      form4: "arabe",
      form5: "en Arabe",
      },
      armenian: {
      form1: "l'Arménien",
      form2: "d'Arménien",
      form3: "arménienne",
      form4: "arménien ",
      form5: "en Arménien",
      },
      bulgarian: {
      form1: "le Bulgare",
      form2: "de Bulgare",
      form3: "bulgare",
      form4: "bulgare",
      form5: "en Bulgare",
      },
      cantonese: {
      form1: "le Cantonais",
      form2: "de Cantonais",
      form3: "cantonaise",
      form4: "cantonais",
      form5: "en Cantonais",
      },
      chinese: {
      form1: "le Chinois",
      form2: "de Chinois",
      form3: "chinoise",
      form4: "chinois ",
      form5: "en Chinois",
      },
      creole: {
      form1: "le Créole",
      form2: "de Créole",
      form3: "créole",
      form4: "créole",
      form5: "en Créole",
      },
      croatian: {
      form1: "le Croate",
      form2: "de Croate",
      form3: "croate",
      form4: "croate",
      form5: "en Croate",
      },
      czech: {
      form1: "le Tchèque",
      form2: "de Tchèque",
      form3: "tchèque",
      form4: "tchèque",
      form5: "en Thèque",
      },
      danish: {
      form1: "le Danois",
      form2: "de Danois",
      form3: "danoise",
      form4: "danois ",
      form5: "en Danois",
      },
      dari: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      dutch: {
      form1: "le Néérlandais",
      form2: "de Néérlandais",
      form3: "néérlandaise",
      form4: "néérlandais ",
      form5: "en Néérlandais",
      },
      euportuguese: {
      form1: "le Portugais",
      form2: "de Portugais",
      form3: "portugaise",
      form4: "portugais ",
      form5: "en Portugais",
      },
      finnish: {
      form1: "le Finnois",
      form2: "de Finnois",
      form3: "finnoise",
      form4: "finnois ",
      form5: "en Finnois",
      },
      french: {
      form1: "le Français",
      form2: "de Français",
      form3: "française",
      form4: "français ",
      form5: "en Français",
      },
      german: {
      form1: "l'Allemand",
      form2: "d'Allemand",
      form3: "allemande",
      form4: "allemand ",
      form5: "en Allemand",
      },
      greek: {
      form1: "le Grec",
      form2: "de Grec",
      form3: "grecque",
      form4: "grec ",
      form5: "en Grec",
      },
      gujarati: {
      form1: "le Gujarati",
      form2: "de Gujarati",
      form3: "gujarati",
      form4: "gujarati",
      form5: "en Gujarati",
      },
      hawaiian: {
      form1: "le Hawaïen",
      form2: "d'Hawaïen",
      form3: "hawaïenne",
      form4: "hawaïen ",
      form5: "en Hawaïen",
      },
      hebrew: {
      form1: "le Hébreu",
      form2: "d'Hébreu",
      form3: "hébreuse",
      form4: "hébreu ",
      form5: "en Hébreu",
      },
      hindi: {
      form1: "l'Hindi",
      form2: "d'Hindi",
      form3: "hindi",
      form4: "hindi",
      form5: "en Hindi",
      },
      hungarian: {
      form1: "le Hongrois",
      form2: "de Hongrois",
      form3: "hongroise",
      form4: "hongrois ",
      form5: "en Hongrois",
      },
      indonesian: {
      form1: "l'Indonésien",
      form2: "d'Indonésien",
      form3: "indonésienne",
      form4: "indonésien ",
      form5: "en Indonésien",
      },
      irish: {
      form1: "l'Irlandais",
      form2: "d'Irlandais",
      form3: "irlandaise",
      form4: "irlandais ",
      form5: "en Irlandais",
      },
      italian: {
      form1: "l'Italien",
      form2: "d'italien",
      form3: "italienne",
      form4: "italien ",
      form5: "en Italien",
      },
      japanese: {
      form1: "le Japonais",
      form2: "de Japonais",
      form3: "le Japonais",
      form4: "japonaise",
      form5: "japonais ",
      },
      kazakh: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      korean: {
      form1: "le Koréen",
      form2: "de Koréen",
      form3: "koréenne",
      form4: "koréen ",
      form5: "en Coréen",
      },
      latin: {
      form1: "le Latin",
      form2: "de Latin",
      form3: "latine",
      form4: "latin",
      form5: "en Latin",
      },
      malay: {
      form1: "le Malais",
      form2: "de Malais",
      form3: "malaise",
      form4: "malais ",
      form5: "en Malais",
      },
      norwegian: {
      form1: "le Norvégien",
      form2: "de Norvégien",
      form3: "norvégienne",
      form4: "norvégien ",
      form5: "en Norvégien",
      },
      persian: {
      form1: "le Perse",
      form2: "de Perse",
      form3: "perse",
      form4: "perse",
      form5: "en Perse",
      },
      polish: {
      form1: "Le Polonais",
      form2: "de Polonais",
      form3: "polonaise",
      form4: "polonais",
      form5: "en Polonais",
      },
      portuguese: {
      form1: "Le Portugais",
      form2: "de Portugais",
      form3: "portugaise",
      form4: "portugais ",
      form5: "en Portugais",
      },
      punjabi: {
      form1: "le Punjabi",
      form2: "de Punjabi",
      form3: "punjabi",
      form4: "punjabi",
      form5: "en Punjabi",
      },
      romanian: {
      form1: "Le Roumain",
      form2: "de Roumain",
      form3: "roumaine",
      form4: "roumain ",
      form5: "en Roumain",
      },
      serbian: {
      form1: "le Serbe",
      form2: "de Serbe",
      form3: "serbe",
      form4: "serbe",
      form5: "en Serbe",
      },
      slovak: {
      form1: "le Slovaque",
      form2: "de Slovaque",
      form3: "slovaque",
      form4: "slovaque",
      form5: "en Slovaque",
      },
      slovenian: {
      form1: "le Slovène",
      form2: "de Slovène",
      form3: "slovène",
      form4: "slovène",
      form5: "en Slovène",
      },
      spanish: {
      form1: "l'Espagnol",
      form2: "d'Espagnol",
      form3: "espagnole",
      form4: "espagnol ",
      form5: "en Espagnol",
      },
      swahili: {
      form1: "le Swahili",
      form2: "de Swahili",
      form3: "swahili",
      form4: "swahili",
      form5: "en Swahili",
      },
      swedish: {
      form1: "le Suédois",
      form2: "de Suédois",
      form3: "suédoise",
      form4: "suédois ",
      form5: "en Suédois",
      },
      tagalog: {
      form1: "le Tagalog",
      form2: "de Tagalog",
      form3: "tagalog",
      form4: "tagalog",
      form5: "en Tagalog",
      },
      thai: {
      form1: "le Thaïlandais",
      form2: "de Thaïlandais",
      form3: "thaïlandaise",
      form4: "thaîlandais",
      form5: "en Thaïlandais",
      },
      turkish: {
      form1: "le Turc",
      form2: "de Turc",
      form3: "turque",
      form4: "turque",
      form5: "en Turque",
      },
      ukrainian: {
      form1: "l'Ukrainien",
      form2: "d'Ukrainien",
      form3: "ukrainienne",
      form4: "ukrainien ",
      form5: "en Ukrainien",
      },
      urdu: {
      form1: "le Ourdou",
      form2: "d'Ourdou",
      form3: "ourdoue",
      form4: "ourdou ",
      form5: "en Ourdou",
      },
      vietnamese: {
      form1: "le Vietnamien",
      form2: "de Vietnamien",
      form3: "vietnamienne",
      form4: "vietnamien ",
      form5: "en Vietnamien",
      },
      welsh: {
      form1: "le Gallois",
      form2: "de Gallois",
      form3: "galloise",
      form4: "gallois ",
      form5: "en Gallois",
      },
  },
  ar: {
    language: {
      form1: "language",
      form2: "language",
      form3: "language",
      form4: "language",
      form5: "language",
      },
      english: {
      form1: "الإنجليزية",
      form2: "الإنجليزية",
      form3: "الإنجليزية",
      form4: "الإنجليزية",
      form5: "الإنجليزية",
      },
      russian: {
      form1: "الروسية",
      form2: "الروسية",
      form3: "الروسية",
      form4: "الروسية",
      form5: "الروسية",
      },
      albanian: {
      form1:"الألبانية",
      form2: "الألبانية",
      form3: "الألبانية",
      form4: "الألبانية",
      form5: "الألبانية",
      },
      arabic: {
      form1: "العربية",
      form2: "العربية",
      form3: "العربية",
      form4: "العربية",
      form5: "العربية",
      },
      armenian: {
      form1: "الأرمنية",
      form2: "الأرمنية",
      form3: "الأرمنية",
      form4: "الأرمنية",
      form5: "الأرمنية",
      },
      bulgarian: {
      form1: "البلغارية",
      form2: "البلغارية",
      form3: "البلغارية",
      form4: "البلغارية",
      form5: "البلغارية",
      },
      cantonese: {
      form1: "الكانتونية",
      form2: "الكانتونية",
      form3: "الكانتونية",
      form4: "الكانتونية",
      form5: "الكانتونية",
      },
      chinese: {
      form1: "الصينية",
      form2: "الصينية",
      form3: "الصينية",
      form4: "الصينية",
      form5: "الصينية",
      },
      creole: {
      form1: "الكريولية",
      form2: "الكريولية",
      form3: "الكريولية",
      form4: "الكريولية",
      form5: "الكريولية",
      },
      croatian: {
      form1: "الكرواتية",
      form2: "الكرواتية",
      form3: "الكرواتية",
      form4: "الكرواتية",
      form5: "الكرواتية",
      },
      czech: {
      form1: "التشيكية",
      form2: "التشيكية",
      form3: "التشيكية",
      form4: "التشيكية",
      form5: "التشيكية",
      },
      danish: {
      form1: "الدنماركية",
      form2: "الدنماركية",
      form3: "الدنماركية",
      form4: "الدنماركية",
      form5: "الدنماركية",
      },
      dari: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      dutch: {
      form1: "الهولندية",
      form2: "الهولندية",
      form3: "الهولندية",
      form4: "الهولندية",
      form5: "الهولندية",
      },
      euportuguese: {
      form1: "البرتغالية الأوروبية",
      form2: "البرتغالية الأوروبية",
      form3: "البرتغالية الأوروبية",
      form4: "البرتغالية الأوروبية",
      form5: "البرتغالية الأوروبية",
      },
      finnish: {
      form1: "الفنلندية",
      form2: "الفنلندية",
      form3: "الفنلندية",
      form4: "الفنلندية",
      form5: "الفنلندية",
      },
      french: {
      form1: "الفرنسية",
      form2: "الفرنسية",
      form3: "الفرنسية",
      form4: "الفرنسية",
      form5: "الفرنسية",
      },
      german: {
      form1: "الألمانية",
      form2: "الألمانية",
      form3: "الألمانية",
      form4: "الألمانية",
      form5: "الألمانية",
      },
      greek: {
      form1: "اليونانية",
      form2: "اليونانية",
      form3: "اليونانية",
      form4: "اليونانية",
      form5: "اليونانية",
      },
      gujarati: {
      form1: "الغوجاراتية",
      form2: "الغوجاراتية",
      form3: "الغوجاراتية",
      form4: "الغوجاراتية",
      form5: "الغوجاراتية",
      },
      hawaiian: {
      form1: "الهاوائية",
      form2: "الهاوائية",
      form3: "الهاوائية",
      form4: "الهاوائية",
      form5: "الهاوائية",
      },
      hebrew: {
      form1: "العبرية",
      form2: "العبرية",
      form3: "العبرية",
      form4: "العبرية",
      form5: "العبرية",
      },
      hindi: {
      form1: "الهندية",
      form2: "الهندية",
      form3: "الهندية",
      form4: "الهندية",
      form5: "الهندية",
      },
      hungarian: {
      form1: "المجرية",
      form2: "المجرية",
      form3: "المجرية",
      form4: "المجرية",
      form5: "المجرية",
      },
      indonesian: {
      form1: "الإندونيسية",
      form2: "الإندونيسية",
      form3: "الإندونيسية",
      form4: "الإندونيسية",
      form5: "الإندونيسية",
      },
      irish: {
      form1: "الأيرلندية",
      form2: "الأيرلندية",
      form3: "الأيرلندية",
      form4: "الأيرلندية",
      form5: "الأيرلندية",
      },
      italian: {
      form1: "الإيطالية",
      form2: "الإيطالية",
      form3: "الإيطالية",
      form4: "الإيطالية",
      form5: "الإيطالية",
      },
      japanese: {
      form1: "اليابانية",
      form2: "اليابانية",
      form3: "اليابانية",
      form4: "اليابانية",
      form5: "اليابانية",
      },
      kazakh: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      korean: {
      form1: "الكورية",
      form2: "الكورية",
      form3: "الكورية",
      form4: "الكورية",
      form5: "الكورية",
      },
      latin: {
      form1: "اللاتينية",
      form2: "اللاتينية",
      form3: "اللاتينية",
      form4: "اللاتينية",
      form5: "اللاتينية",
      },
      malay: {
      form1: "الملايوية",
      form2: "الملايوية",
      form3: "الملايوية",
      form4: "الملايوية",
      form5: "الملايوية",
      },
      norwegian: {
      form1: "النرويجية",
      form2: "النرويجية",
      form3: "النرويجية",
      form4: "النرويجية",
      form5: "النرويجية",
      },
      persian: {
      form1: "الفارسية",
      form2: "الفارسية",
      form3: "الفارسية",
      form4: "الفارسية",
      form5: "الفارسية",
      },
      polish: {
      form1: "البولندية",
      form2: "البولندية",
      form3: "البولندية",
      form4: "البولندية",
      form5: "البولندية",
      },
      portuguese: {
      form1: "البرتغالية",
      form2: "البرتغالية",
      form3: "البرتغالية",
      form4: "البرتغالية",
      form5: "البرتغالية",
      },
      punjabi: {
      form1: "البنجابية",
      form2: "البنجابية",
      form3: "البنجابية",
      form4: "البنجابية",
      form5: "البنجابية",
      },
      romanian: {
      form1: "الرومانية",
      form2: "الرومانية",
      form3: "الرومانية",
      form4: "الرومانية",
      form5: "الرومانية",
      },
      serbian: {
      form1: "الصربية",
      form2: "الصربية",
      form3: "الصربية",
      form4: "الصربية",
      form5: "الصربية",
      },
      slovak: {
      form1: "السلوفاكية",
      form2: "السلوفاكية",
      form3: "السلوفاكية",
      form4: "السلوفاكية",
      form5: "السلوفاكية",
      },
      slovenian: {
      form1: "السلوفينية",
      form2: "السلوفينية",
      form3: "السلوفينية",
      form4: "السلوفينية",
      form5: "السلوفينية",
      },
      spanish: {
      form1: "الإسبانية",
      form2: "الإسبانية",
      form3: "الإسبانية",
      form4: "الإسبانية",
      form5: "الإسبانية",
      },
      swahili: {
      form1: "السواحلية",
      form2: "السواحلية",
      form3: "السواحلية",
      form4: "السواحلية",
      form5: "السواحلية",
      },
      swedish: {
      form1: "السويدية",
      form2: "السويدية",
      form3: "السويدية",
      form4: "السويدية",
      form5: "السويدية",
      },
      tagalog: {
      form1: "التاغالوغية",
      form2: "التاغالوغية",
      form3: "التاغالوغية",
      form4: "التاغالوغية",
      form5: "التاغالوغية",
      },
      thai: {
      form1: "التايلاندية",
      form2: "التايلاندية",
      form3: "التايلاندية",
      form4: "التايلاندية",
      form5: "التايلاندية",
      },
      turkish: {
      form1: "التركية",
      form2: "التركية",
      form3: "التركية",
      form4: "التركية",
      form5: "التركية",
      },
      ukrainian: {
      form1: "الأوكرانية",
      form2: "الأوكرانية",
      form3: "الأوكرانية",
      form4: "الأوكرانية",
      form5: "الأوكرانية",
      },
      urdu: {
      form1: "الأردية",
      form2: "الأردية",
      form3: "الأردية",
      form4: "الأردية",
      form5: "الأردية",
      },
      vietnamese: {
      form1: "الفيتنامية",
      form2: "الفيتنامية",
      form3: "الفيتنامية",
      form4: "الفيتنامية",
      form5: "الفيتنامية",
      },
      welsh: {
      form1: "الويلزية",
      form2: "الويلزية",
      form3: "الويلزية",
      form4: "الويلزية",
      form5: "الويلزية",
      },
  },
  pt:{
    language: {
      form1: "idiomas",
      form2: "idiomas",
      form3: "idiomas",
      form4: "idiomas",
      form5: "idiomas",
      },
      english: {
      form1: "Inglês",
      form2: "Inglês",
      form3: "Inglês",
      form4: "Inglês",
      form5: "Inglês",
      },
      russian: {
      form1: "Russo",
      form2: "Russo",
      form3: "Russo",
      form4: "Russo",
      form5: "Russo",
      },
      albanian: {
      form1:"Albanês",
      form2: "Albanês",
      form3: "Albanês",
      form4: "Albanês",
      form5: "Albanês",
      },
      arabic: {
      form1: "Árabe",
      form2: "Árabe",
      form3: "Árabe",
      form4: "Árabe",
      form5: "Árabe",
      },
      armenian: {
      form1: "Armênio",
      form2: "Armênio",
      form3: "Armênio",
      form4: "Armênio",
      form5: "Armênio",
      },
      bulgarian: {
      form1: "Búlgaro",
      form2: "Búlgaro",
      form3: "Búlgaro",
      form4: "Búlgaro",
      form5: "Búlgaro",
      },
      cantonese: {
      form1: "Cantonês",
      form2: "Cantonês",
      form3: "Cantonês",
      form4: "Cantonês",
      form5: "Cantonês",
      },
      chinese: {
      form1: "Chinês",
      form2: "Chinês",
      form3: "Chinês",
      form4: "Chinês",
      form5: "Chinês",
      },
      creole: {
      form1: "Crioulo Haitiano",
      form2: "Crioulo Haitiano",
      form3: "Crioulo Haitiano",
      form4: "Crioulo Haitiano",
      form5: "Crioulo Haitiano",
      },
      croatian: {
      form1: "Croata",
      form2: "Croata",
      form3: "Croata",
      form4: "Croata",
      form5: "Croata",
      },
      czech: {
      form1: "Tcheco",
      form2: "Tcheco",
      form3: "Tcheco",
      form4: "Tcheco",
      form5: "Tcheco",
      },
      danish: {
      form1: "Dinamarquês",
      form2: "Dinamarquês",
      form3: "Dinamarquês",
      form4: "Dinamarquês",
      form5: "Dinamarquês",
      },
      dari: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      dutch: {
      form1: "Holandês",
      form2: "Holandês",
      form3: "Holandês",
      form4: "Holandês",
      form5: "Holandês",
      },
      euportuguese: {
      form1: "Português Europeu",
      form2: "Português Europeu",
      form3: "Português Europeu",
      form4: "Português Europeu",
      form5: "Português Europeu",
      },
      finnish: {
      form1: "Finlandês",
      form2: "Finlandês",
      form3: "Finlandês",
      form4: "Finlandês",
      form5: "Finlandês",
      },
      french: {
      form1: "Francês",
      form2: "Francês",
      form3: "Francês",
      form4: "Francês",
      form5: "Francês",
      },
      german: {
      form1: "Alemão",
      form2: "Alemão",
      form3: "Alemão",
      form4: "Alemão",
      form5: "Alemão",
      },
      greek: {
      form1: "Grego",
      form2: "Grego",
      form3: "Grego",
      form4: "Grego",
      form5: "Grego",
      },
      gujarati: {
      form1: "Gujarati",
      form2: "Gujarati",
      form3: "Gujarati",
      form4: "Gujarati",
      form5: "Gujarati",
      },
      hawaiian: {
      form1: "Havaiano",
      form2: "Havaiano",
      form3: "Havaiano",
      form4: "Havaiano",
      form5: "Havaiano",
      },
      hebrew: {
      form1: "Hebraico",
      form2: "Hebraico",
      form3: "Hebraico",
      form4: "Hebraico",
      form5: "Hebraico",
      },
      hindi: {
      form1: "Hindi",
      form2: "Hindi",
      form3: "Hindi",
      form4: "Hindi",
      form5: "Hindi",
      },
      hungarian: {
      form1: "Húngaro",
      form2: "Húngaro",
      form3: "Húngaro",
      form4: "Húngaro",
      form5: "Húngaro",
      },
      indonesian: {
      form1: "Indonésio",
      form2: "Indonésio",
      form3: "Indonésio",
      form4: "Indonésio",
      form5: "Indonésio",
      },
      irish: {
      form1: "Irlandês",
      form2: "Irlandês",
      form3: "Irlandês",
      form4: "Irlandês",
      form5: "Irlandês",
      },
      italian: {
      form1: "Italiano",
      form2: "Italiano",
      form3: "Italiano",
      form4: "Italiano",
      form5: "Italiano",
      },
      japanese: {
      form1: "Japonês",
      form2: "Japonês",
      form3: "Japonês",
      form4: "Japonês",
      form5: "Japonês",
      },
      kazakh: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      korean: {
      form1: "Coreano",
      form2: "Coreano",
      form3: "Coreano",
      form4: "Coreano",
      form5: "Coreano",
      },
      latin: {
      form1: "Latim",
      form2: "Latim",
      form3: "Latim",
      form4: "Latim",
      form5: "Latim",
      },
      malay: {
      form1: "Malaio",
      form2: "Malaio",
      form3: "Malaio",
      form4: "Malaio",
      form5: "Malaio",
      },
      norwegian: {
      form1: "Norueguês",
      form2: "Norueguês",
      form3: "Norueguês",
      form4: "Norueguês",
      form5: "Norueguês",
      },
      persian: {
      form1: "Persa",
      form2: "Persa",
      form3: "Persa",
      form4: "Persa",
      form5: "Persa",
      },
      polish: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      portuguese: {
      form1: "Português",
      form2: "Português",
      form3: "Português",
      form4: "Português",
      form5: "Português",
      },
      punjabi: {
      form1: "Punjabi",
      form2: "Punjabi",
      form3: "Punjabi",
      form4: "Punjabi",
      form5: "Punjabi",
      },
      romanian: {
      form1: "Romeno",
      form2: "Romeno",
      form3: "Romeno",
      form4: "Romeno",
      form5: "Romeno",
      },
      serbian: {
      form1: "Sérvio",
      form2: "Sérvio",
      form3: "Sérvio",
      form4: "Sérvio",
      form5: "Sérvio",
      },
      slovak: {
      form1: "Eslovaco",
      form2: "Eslovaco",
      form3: "Eslovaco",
      form4: "Eslovaco",
      form5: "Eslovaco",
      },
      slovenian: {
      form1: "Esloveno",
      form2: "Esloveno",
      form3: "Esloveno",
      form4: "Esloveno",
      form5: "Esloveno",
      },
      spanish: {
      form1: "Espanhol",
      form2: "Espanhol",
      form3: "Espanhol",
      form4: "Espanhol",
      form5: "Espanhol",
      },
      swahili: {
      form1: "Suaíli",
      form2: "Suaíli",
      form3: "Suaíli",
      form4: "Suaíli",
      form5: "Suaíli",
      },
      swedish: {
      form1: "Sueco",
      form2: "Sueco",
      form3: "Sueco",
      form4: "Sueco",
      form5: "Sueco",
      },
      tagalog: {
      form1: "Tagalo",
      form2: "Tagalo",
      form3: "Tagalo",
      form4: "Tagalo",
      form5: "Tagalo",
      },
      thai: {
      form1: "Tailandês",
      form2: "Tailandês",
      form3: "Tailandês",
      form4: "Tailandês",
      form5: "Tailandês",
      },
      turkish: {
      form1: "Turco",
      form2: "Turco",
      form3: "Turco",
      form4: "Turco",
      form5: "Turco",
      },
      ukrainian: {
      form1: "Ucraniano",
      form2: "Ucraniano",
      form3: "Ucraniano",
      form4: "Ucraniano",
      form5: "Ucraniano",
      },
      urdu: {
      form1: "Urdu",
      form2: "Urdu",
      form3: "Urdu",
      form4: "Urdu",
      form5: "Urdu",
      },
      vietnamese: {
      form1: "Vietnamita",
      form2: "Vietnamita",
      form3: "Vietnamita",
      form4: "Vietnamita",
      form5: "Vietnamita",
      },
      welsh: {
      form1: "Galês",
      form2: "Galês",
      form3: "Galês",
      form4: "Galês",
      form5: "Galês",
      },
  },
  hi:{
    language: {
        form1: "language",
        form2: "language",
        form3: "language",
        form4: "language",
        form5: "language",
        },
        english: {
        form1: "अंग्रेजी ",
        form2: "अंग्रेजी ",
        form3: "अंग्रेजी ",
        form4: "अंग्रेजी ",
        form5: "अंग्रेजी ",
        },
        russian: {
        form1: "रूसी ",
        form2: "रूसी ",
        form3: "रूसी ",
        form4: "रूसी ",
        form5: "रूसी ",
        },
        albanian: {
        form1:"अल्बानियन",
        form2: "अल्बानियन",
        form3: "अल्बानियन",
        form4: "अल्बानियन",
        form5: "अल्बानियन",
        },
        arabic: {
        form1: "अरबी",
        form2: "अरबी",
        form3: "अरबी",
        form4: "अरबी",
        form5: "अरबी",
        },
        armenian: {
        form1: "अर्मेनियाई ",
        form2: "अर्मेनियाई ",
        form3: "अर्मेनियाई ",
        form4: "अर्मेनियाई ",
        form5: "अर्मेनियाई ",
        },
        bulgarian: {
        form1: "बुल्गारियाई",
        form2: "बुल्गारियाई",
        form3: "बुल्गारियाई",
        form4: "बुल्गारियाई",
        form5: "बुल्गारियाई",
        },
        cantonese: {
        form1: "कैंटोनीज़",
        form2: "कैंटोनीज़",
        form3: "कैंटोनीज़",
        form4: "कैंटोनीज़",
        form5: "कैंटोनीज़",
        },
        chinese: {
        form1: "चीनी",
        form2: "चीनी",
        form3: "चीनी",
        form4: "चीनी",
        form5: "चीनी",
        },
        creole: {
        form1: "क्रियोल ",
        form2: "क्रियोल ",
        form3: "क्रियोल ",
        form4: "क्रियोल ",
        form5: "क्रियोल ",
        },
        croatian: {
        form1: "क्रोएशियाई ",
        form2: "क्रोएशियाई ",
        form3: "क्रोएशियाई ",
        form4: "क्रोएशियाई ",
        form5: "क्रोएशियाई ",
        },
        czech: {
        form1: "चेक ",
        form2: "चेक ",
        form3: "चेक ",
        form4: "चेक ",
        form5: "चेक ",
        },
        danish: {
        form1: "डैनिश",
        form2: "डैनिश",
        form3: "डैनिश",
        form4: "डैनिश",
        form5: "डैनिश",
        },
        dari: {
        form1: "",
        form2: "",
        form3: "",
        form4: "",
        form5: "",
        },
        dutch: {
        form1: "डच ",
        form2: "डच ",
        form3: "डच ",
        form4: "डच ",
        form5: "डच ",
        },
        euportuguese: {
        form1: "ई-पुर्तगाली ",
        form2: "ई-पुर्तगाली ",
        form3: "ई-पुर्तगाली ",
        form4: "ई-पुर्तगाली ",
        form5: "ई-पुर्तगाली ",
        },
        finnish: {
        form1: "फिनिश ",
        form2: "फिनिश ",
        form3: "फिनिश ",
        form4: "फिनिश ",
        form5: "फिनिश ",
        },
        french: {
        form1: "फ्रेंच",
        form2: "फ्रेंच",
        form3: "फ्रेंच",
        form4: "फ्रेंच",
        form5: "फ्रेंच",
        },
        german: {
        form1: "जर्मन",
        form2: "जर्मन",
        form3: "जर्मन",
        form4: "जर्मन",
        form5: "जर्मन",
        },
        greek: {
        form1: "ग्रीक ",
        form2: "ग्रीक ",
        form3: "ग्रीक ",
        form4: "ग्रीक ",
        form5: "ग्रीक ",
        },
        gujarati: {
        form1: "गुजराती ",
        form2: "गुजराती ",
        form3: "गुजराती ",
        form4: "गुजराती ",
        form5: "गुजराती ",
        },
        hawaiian: {
        form1: "हवाईयन ",
        form2: "हवाईयन ",
        form3: "हवाईयन ",
        form4: "हवाईयन ",
        form5: "हवाईयन ",
        },
        hebrew: {
        form1: "हिब्रू",
        form2: "हिब्रू",
        form3: "हिब्रू",
        form4: "हिब्रू",
        form5: "हिब्रू",
        },
        hindi: {
        form1: "हिंदी ",
        form2: "हिंदी ",
        form3: "हिंदी ",
        form4: "हिंदी ",
        form5: "हिंदी ",
        },
        hungarian: {
        form1: "हंगेरियन ",
        form2: "हंगेरियन ",
        form3: "हंगेरियन ",
        form4: "हंगेरियन ",
        form5: "हंगेरियन ",
        },
        indonesian: {
        form1: "इन्डोनेशियाई ",
        form2: "इन्डोनेशियाई ",
        form3: "इन्डोनेशियाई ",
        form4: "इन्डोनेशियाई ",
        form5: "इन्डोनेशियाई ",
        },
        irish: {
        form1: "आयरिश",
        form2: "आयरिश",
        form3: "आयरिश",
        form4: "आयरिश",
        form5: "आयरिश",
        },
        italian: {
        form1: " इतालवी ",
        form2: " इतालवी ",
        form3: " इतालवी ",
        form4: " इतालवी ",
        form5: " इतालवी ",
        },
        japanese: {
        form1: "जापानी ",
        form2: "जापानी ",
        form3: "जापानी ",
        form4: "जापानी ",
        form5: "जापानी ",
        },
        kazakh: {
        form1: "",
        form2: "",
        form3: "",
        form4: "",
        form5: "",
        },
        korean: {
        form1: "कोरियाई",
        form2: "कोरियाई",
        form3: "कोरियाई",
        form4: "कोरियाई",
        form5: "कोरियाई",
        },
        latin: {
        form1: "लैटिन ",
        form2: "लैटिन ",
        form3: "लैटिन ",
        form4: "लैटिन ",
        form5: "लैटिन ",
        },
        malay: {
        form1: "मलय ",
        form2: "मलय ",
        form3: "मलय ",
        form4: "मलय ",
        form5: "मलय ",
        },
        norwegian: {
        form1: "नॉर्वेजियन ",
        form2: "नॉर्वेजियन ",
        form3: "नॉर्वेजियन ",
        form4: "नॉर्वेजियन ",
        form5: "नॉर्वेजियन ",
        },
        persian: {
        form1: "फ़ारसी ",
        form2: "फ़ारसी ",
        form3: "फ़ारसी ",
        form4: "फ़ारसी ",
        form5: "फ़ारसी ",
        },
        polish: {
        form1: "पोलिश ",
        form2: "पोलिश ",
        form3: "पोलिश ",
        form4: "पोलिश ",
        form5: "पोलिश ",
        },
        portuguese: {
        form1: "पुर्तगाली",
        form2: "पुर्तगाली",
        form3: "पुर्तगाली",
        form4: "पुर्तगाली",
        form5: "पुर्तगाली",
        },
        punjabi: {
        form1: "पंजाबी ",
        form2: "पंजाबी ",
        form3: "पंजाबी ",
        form4: "पंजाबी ",
        form5: "पंजाबी ",
        },
        romanian: {
        form1: "रोमानियाई ",
        form2: "रोमानियाई ",
        form3: "रोमानियाई ",
        form4: "रोमानियाई ",
        form5: "रोमानियाई ",
        },
        serbian: {
        form1: "सर्बियाई ",
        form2: "सर्बियाई ",
        form3: "सर्बियाई ",
        form4: "सर्बियाई ",
        form5: "सर्बियाई ",
        },
        slovak: {
        form1: "स्लोवाक ",
        form2: "स्लोवाक ",
        form3: "स्लोवाक ",
        form4: "स्लोवाक ",
        form5: "स्लोवाक ",
        },
        slovenian: {
        form1: "स्लोवेनियाई",
        form2: "स्लोवेनियाई",
        form3: "स्लोवेनियाई",
        form4: "स्लोवेनियाई",
        form5: "स्लोवेनियाई",
        },
        spanish: {
        form1: "स्पैनिश ",
        form2: "स्पैनिश ",
        form3: "स्पैनिश ",
        form4: "स्पैनिश ",
        form5: "स्पैनिश ",
        },
        swahili: {
        form1: "स्वाहिली ",
        form2: "स्वाहिली ",
        form3: "स्वाहिली ",
        form4: "स्वाहिली ",
        form5: "स्वाहिली ",
        },
        swedish: {
        form1: "स्वीडिश ",
        form2: "स्वीडिश ",
        form3: "स्वीडिश ",
        form4: "स्वीडिश ",
        form5: "स्वीडिश ",
        },
        tagalog: {
        form1: "तागालोग ",
        form2: "तागालोग ",
        form3: "तागालोग ",
        form4: "तागालोग ",
        form5: "तागालोग ",
        },
        thai: {
        form1: "थाई",
        form2: "थाई",
        form3: "थाई",
        form4: "थाई",
        form5: "थाई",
        },
        turkish: {
        form1: "तुर्की ",
        form2: "तुर्की ",
        form3: "तुर्की ",
        form4: "तुर्की ",
        form5: "तुर्की ",
        },
        ukrainian: {
        form1: "यूक्रेनी ",
        form2: "यूक्रेनी ",
        form3: "यूक्रेनी ",
        form4: "यूक्रेनी ",
        form5: "यूक्रेनी ",
        },
        urdu: {
        form1: "उर्दू ",
        form2: "उर्दू ",
        form3: "उर्दू ",
        form4: "उर्दू ",
        form5: "उर्दू ",
        },
        vietnamese: {
        form1: "वियतनामी ",
        form2: "वियतनामी ",
        form3: "वियतनामी ",
        form4: "वियतनामी ",
        form5: "वियतनामी ",
        },
        welsh: {
        form1: "वेल्श",
        form2: "वेल्श",
        form3: "वेल्श",
        form4: "वेल्श",
        form5: "वेल्श",
        },
},
  pl:{
    language: {
      form1: "language",
      form2: "language",
      form3: "language",
      form4: "language",
      form5: "language",
      },
      english: {
      form1: "angielskiego",
      form2: "angielskiego",
      form3: "angielskiego",
      form4: "angielskim",
      form5: "angielskiego",
      },
      russian: {
      form1: "rosyjskiego",
      form2: "rosyjskiego",
      form3: "rosyjskiego",
      form4: "rosyjskim",
      form5: "rosyjskiego",
      },
      albanian: {
      form1:"albańskiego",
      form2: "albańskiego",
      form3: "albańskiego",
      form4: "albańskim",
      form5: "albańskiego",
      },
      arabic: {
      form1: "arabskiego",
      form2: "arabskiego",
      form3: "arabskiego",
      form4: "arabskim",
      form5: "arabskiego",
      },
      armenian: {
      form1: "armeńskiego",
      form2: "armeńskiego",
      form3: "armeńskiego",
      form4: "armeńskim",
      form5: "armeńskiego",
      },
      bulgarian: {
      form1: "bułgarskiego",
      form2: "bułgarskiego",
      form3: "bułgarskiego",
      form4: "bułgarskim",
      form5: "bułgarskiego",
      },
      cantonese: {
      form1: "kantońskiego",
      form2: "kantońskiego",
      form3: "kantońskiego",
      form4: "kantońskim",
      form5: "kantońskiego",
      },
      chinese: {
      form1: "chińskiego",
      form2: "chińskiego",
      form3: "chińskiego",
      form4: "chińskim",
      form5: "chińskiego",
      },
      creole: {
      form1: "kreolskiego",
      form2: "kreolskiego",
      form3: "kreolskiego",
      form4: "kreolskim",
      form5: "kreolskiego",
      },
      croatian: {
      form1: "chorwackiego",
      form2: "chorwackiego",
      form3: "chorwackiego",
      form4: "chorwackim",
      form5: "chorwackiego",
      },
      czech: {
      form1: "czeskiego",
      form2: "czeskiego",
      form3: "czeskiego",
      form4: "czeskim",
      form5: "czeskiego",
      },
      danish: {
      form1: "duńskiego",
      form2: "duńskiego",
      form3: "duńskiego",
      form4: "duńskim",
      form5: "duńskiego",
      },
      dari: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      dutch: {
      form1: "niderlandzkiego",
      form2: "niderlandzkiego",
      form3: "niderlandzkiego",
      form4: "niderlandzkim",
      form5: "niderlandzkiego",
      },
      euportuguese: {
      form1: "portugalskiego",
      form2: "portugalskiego",
      form3: "portugalskiego",
      form4: "portugalskim",
      form5: "portugalskiego",
      },
      finnish: {
      form1: "fińskiego",
      form2: "fińskiego",
      form3: "fińskiego",
      form4: "fińskim",
      form5: "fińskiego",
      },
      french: {
      form1: "francuskiego",
      form2: "francuskiego",
      form3: "francuskiego",
      form4: "francuskim",
      form5: "francuskiego",
      },
      german: {
      form1: "niemieckiego",
      form2: "niemieckiego",
      form3: "niemieckiego",
      form4: "niemieckim",
      form5: "niemieckiego",
      },
      greek: {
      form1: "greckiego",
      form2: "greckiego",
      form3: "greckiego",
      form4: "greckim",
      form5: "greckiego",
      },
      gujarati: {
      form1: "gudźarati",
      form2: "gudźarati",
      form3: "gudźarati",
      form4: "gudźarati",
      form5: "gudźarati",
      },
      hawaiian: {
      form1: "hawajskiego",
      form2: "hawajskiego",
      form3: "hawajskiego",
      form4: "hawajskim",
      form5: "hawajskiego",
      },
      hebrew: {
      form1: "hebrajskiego",
      form2: "hebrajskiego",
      form3: "hebrajskiego",
      form4: "hebrajskim",
      form5: "hebrajskiego",
      },
      hindi: {
      form1: "hindi",
      form2: "hindi",
      form3: "hindi",
      form4: "hindi",
      form5: "hindi",
      },
      hungarian: {
      form1: "węgierskiego",
      form2: "węgierskiego",
      form3: "węgierskiego",
      form4: "węgierskim",
      form5: "węgierskiego",
      },
      indonesian: {
      form1: "indonezyjskiego",
      form2: "indonezyjskiego",
      form3: "indonezyjskiego",
      form4: "indonezyjskim",
      form5: "indonezyjskiego",
      },
      irish: {
      form1: "irlandzkiego",
      form2: "irlandzkiego",
      form3: "irlandzkiego",
      form4: "irlandzkim",
      form5: "irlandzkiego",
      },
      italian: {
      form1: "włoskiego",
      form2: "włoskiego",
      form3: "włoskiego",
      form4: "włoskim",
      form5: "włoskiego",
      },
      japanese: {
      form1: "japońskiego",
      form2: "japońskiego",
      form3: "japońskiego",
      form4: "japońskim",
      form5: "japońskiego",
      },
      kazakh: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      korean: {
      form1: "koreańskiego",
      form2: "koreańskiego",
      form3: "koreańskiego",
      form4: "koreańskim",
      form5: "koreańskiego",
      },
      latin: {
      form1: "łaciny",
      form2: "łaciny",
      form3: "łaciny",
      form4: "łacińskim",
      form5: "łacińskiego",
      },
      malay: {
      form1: "malajskiego",
      form2: "malajskiego",
      form3: "malajskiego",
      form4: "malajskim",
      form5: "malajskiego",
      },
      norwegian: {
      form1: "norweskiego",
      form2: "norweskiego",
      form3: "norweskiego",
      form4: "norweskim",
      form5: "norweskiego",
      },
      persian: {
      form1: "perskiego",
      form2: "perskiego",
      form3: "perskiego",
      form4: "perskim",
      form5: "perskiego",
      },
      polish: {
      form1: "polskiego",
      form2: "polskiego",
      form3: "polskiego",
      form4: "polskim",
      form5: "polskiego",
      },
      portuguese: {
      form1: "portugalskiego",
      form2: "portugalskiego",
      form3: "portugalskiego",
      form4: "portugalskim",
      form5: "portugalskiego",
      },
      punjabi: {
      form1: "pendżabskiego",
      form2: "pendżabskiego",
      form3: "pendżabskiego",
      form4: "pendżabskim",
      form5: "pendżabskiego",
      },
      romanian: {
      form1: "rumuńskiego",
      form2: "rumuńskiego",
      form3: "rumuńskiego",
      form4: "rumuńskim",
      form5: "rumuńskiego",
      },
      serbian: {
      form1: "serbskiego",
      form2: "serbskiego",
      form3: "serbskiego",
      form4: "serbskim",
      form5: "serbskiego",
      },
      slovak: {
      form1: "słowackiego",
      form2: "słowackiego",
      form3: "słowackiego",
      form4: "słowackim",
      form5: "słowackiego",
      },
      slovenian: {
      form1: "słoweńskiego",
      form2: "słoweńskiego",
      form3: "słoweńskiego",
      form4: "słoweńskim",
      form5: "słoweńskiego",
      },
      spanish: {
      form1: "hiszpańskiego",
      form2: "hiszpańskiego",
      form3: "hiszpańskiego",
      form4: "hiszpańskim",
      form5: "hiszpańskiego",
      },
      swahili: {
      form1: "suahili",
      form2: "suahili",
      form3: "suahili",
      form4: "suahili",
      form5: "suahili",
      },
      swedish: {
      form1: "szwedzkiego",
      form2: "szwedzkiego",
      form3: "szwedzkiego",
      form4: "szwedzkim",
      form5: "szwedzkiego",
      },
      tagalog: {
      form1: "tagalog",
      form2: "tagalog",
      form3: "tagalog",
      form4: "tagalog",
      form5: "tagalog",
      },
      thai: {
      form1: "tajskiego",
      form2: "tajskiego",
      form3: "tajskiego",
      form4: "tajskim",
      form5: "tajskiego",
      },
      turkish: {
      form1: "tureckiego",
      form2: "tureckiego",
      form3: "tureckiego",
      form4: "tureckim",
      form5: "tureckiego",
      },
      ukrainian: {
      form1: "ukraińskiego",
      form2: "ukraińskiego",
      form3: "ukraińskiego",
      form4: "ukraińskim",
      form5: "ukraińskiego",
      },
      urdu: {
      form1: "urdu",
      form2: "urdu",
      form3: "urdu",
      form4: "urdu",
      form5: "urdu",
      },
      vietnamese: {
      form1: "wietnamskiego",
      form2: "wietnamskiego",
      form3: "wietnamskiego",
      form4: "wietnamskim",
      form5: "wietnamskiego",
      },
      welsh: {
      form1: "walijskiego",
      form2: "walijskiego",
      form3: "walijskiego",
      form4: "walijskim",
      form5: "walijskiego",
      },
  },
  es:
  {language: {
    form1: "language",
    form2: "language",
    form3: "language",
    form4: "language",
    form5: "language",
    },
    english: {
    form1: "Inglés ",
    form2: "Inglés ",
    form3: "Inglés ",
    form4: "Inglés ",
    form5: "Inglés ",
    },
    russian: {
    form1: "Ruso",
    form2: "Ruso",
    form3: "Ruso",
    form4: "Ruso",
    form5: "Ruso",
    },
    albanian: {
    form1:"Albano",
    form2: "Albano",
    form3: "Albano",
    form4: "Albano",
    form5: "Albano",
    },
    arabic: {
    form1: "Árabe",
    form2: "Árabe",
    form3: "Árabe",
    form4: "Árabe",
    form5: "Árabe",
    },
    armenian: {
    form1: "Armenio",
    form2: "Armenio",
    form3: "Armenio",
    form4: "Armenio",
    form5: "Armenio",
    },
    bulgarian: {
    form1: "Búlgaro",
    form2: "Búlgaro",
    form3: "Búlgaro",
    form4: "Búlgaro",
    form5: "Búlgaro",
    },
    cantonese: {
    form1: "Cantonés",
    form2: "Cantonés",
    form3: "Cantonés",
    form4: "Cantonés",
    form5: "Cantonés",
    },
    chinese: {
    form1: "Chino",
    form2: "Chino",
    form3: "Chino",
    form4: "Chino",
    form5: "Chino",
    },
    creole: {
    form1: "Criollo",
    form2: "Criollo",
    form3: "Criollo",
    form4: "Criollo",
    form5: "Criollo",
    },
    croatian: {
    form1: "Croata",
    form2: "Croata",
    form3: "Croata",
    form4: "Croata",
    form5: "Croata",
    },
    czech: {
    form1: "Checo",
    form2: "Checo",
    form3: "Checo",
    form4: "Checo",
    form5: "Checo",
    },
    danish: {
    form1: "Danés",
    form2: "Danés",
    form3: "Danés",
    form4: "Danés",
    form5: "Danés",
    },
    dari: {
    form1: "",
    form2: "",
    form3: "",
    form4: "",
    form5: "",
    },
    dutch: {
    form1: "Holandés",
    form2: "Holandés",
    form3: "Holandés",
    form4: "Holandés",
    form5: "Holandés",
    },
    euportuguese: {
    form1: "Portugués",
    form2: "Portugués",
    form3: "Portugués",
    form4: "Portugués",
    form5: "Portugués",
    },
    finnish: {
    form1: "Finlandés",
    form2: "Finlandés",
    form3: "Finlandés",
    form4: "Finlandés",
    form5: "Finlandés",
    },
    french: {
    form1: "Francés",
    form2: "Francés",
    form3: "Francés",
    form4: "Francés",
    form5: "Francés",
    },
    german: {
    form1: "Alemán",
    form2: "Alemán",
    form3: "Alemán",
    form4: "Alemán",
    form5: "Alemán",
    },
    greek: {
    form1: "Griego",
    form2: "Griego",
    form3: "Griego",
    form4: "Griego",
    form5: "Griego",
    },
    gujarati: {
    form1: "Guyaratí",
    form2: "Guyaratí",
    form3: "Guyaratí",
    form4: "Guyaratí",
    form5: "Guyaratí",
    },
    hawaiian: {
    form1: "Hawaiano",
    form2: "Hawaiano",
    form3: "Hawaiano",
    form4: "Hawaiano",
    form5: "Hawaiano",
    },
    hebrew: {
    form1: "Hebreo",
    form2: "Hebreo",
    form3: "Hebreo",
    form4: "Hebreo",
    form5: "Hebreo",
    },
    hindi: {
    form1: "Hindi",
    form2: "Hindi",
    form3: "Hindi",
    form4: "Hindi",
    form5: "Hindi",
    },
    hungarian: {
    form1: "Húngaro",
    form2: "Húngaro",
    form3: "Húngaro",
    form4: "Húngaro",
    form5: "Húngaro",
    },
    indonesian: {
    form1: "Indonesio",
    form2: "Indonesio",
    form3: "Indonesio",
    form4: "Indonesio",
    form5: "Indonesio",
    },
    irish: {
    form1: "Irlandés",
    form2: "Irlandés",
    form3: "Irlandés",
    form4: "Irlandés",
    form5: "Irlandés",
    },
    italian: {
    form1: "Italiano",
    form2: "Italiano",
    form3: "Italiano",
    form4: "Italiano",
    form5: "Italiano",
    },
    japanese: {
    form1: "Japonés",
    form2: "Japonés",
    form3: "Japonés",
    form4: "Japonés",
    form5: "Japonés",
    },
    kazakh: {
    form1: "",
    form2: "",
    form3: "",
    form4: "",
    form5: "",
    },
    korean: {
    form1: "Coreano",
    form2: "Coreano",
    form3: "Coreano",
    form4: "Coreano",
    form5: "Coreano",
    },
    latin: {
    form1: "Latín",
    form2: "Latín",
    form3: "Latín",
    form4: "Latín",
    form5: "Latín",
    },
    malay: {
    form1: "Malayo",
    form2: "Malayo",
    form3: "Malayo",
    form4: "Malayo",
    form5: "Malayo",
    },
    norwegian: {
    form1: "Noruego",
    form2: "Noruego",
    form3: "Noruego",
    form4: "Noruego",
    form5: "Noruego",
    },
    persian: {
    form1: "Persa",
    form2: "Persa",
    form3: "Persa",
    form4: "Persa",
    form5: "Persa",
    },
    polish: {
    form1: "Polaco",
    form2: "Polaco",
    form3: "Polaco",
    form4: "Polaco",
    form5: "Polaco",
    },
    portuguese: {
    form1: "Portugués",
    form2: "Portugués",
    form3: "Portugués",
    form4: "Portugués",
    form5: "Portugués",
    },
    punjabi: {
    form1: "Punjabí",
    form2: "Punjabí",
    form3: "Punjabí",
    form4: "Punjabí",
    form5: "Punjabí",
    },
    romanian: {
    form1: "Romano",
    form2: "Romano",
    form3: "Romano",
    form4: "Romano",
    form5: "Romano",
    },
    serbian: {
    form1: "Serbio",
    form2: "Serbio",
    form3: "Serbio",
    form4: "Serbio",
    form5: "Serbio",
    },
    slovak: {
    form1: "Eslovaco",
    form2: "Eslovaco",
    form3: "Eslovaco",
    form4: "Eslovaco",
    form5: "Eslovaco",
    },
    slovenian: {
    form1: "Esloveno",
    form2: "Esloveno",
    form3: "Esloveno",
    form4: "Esloveno",
    form5: "Esloveno",
    },
    spanish: {
    form1: "Español",
    form2: "Español",
    form3: "Español",
    form4: "Español",
    form5: "Español",
    },
    swahili: {
    form1: "Swahili",
    form2: "Swahili",
    form3: "Swahili",
    form4: "Swahili",
    form5: "Swahili",
    },
    swedish: {
    form1: "Sueco",
    form2: "Sueco",
    form3: "Sueco",
    form4: "Sueco",
    form5: "Sueco",
    },
    tagalog: {
    form1: "Tagalo",
    form2: "Tagalo",
    form3: "Tagalo",
    form4: "Tagalo",
    form5: "Tagalo",
    },
    thai: {
    form1: "Tailandés",
    form2: "Tailandés",
    form3: "Tailandés",
    form4: "Tailandés",
    form5: "Tailandés",
    },
    turkish: {
    form1: "Turco",
    form2: "Turco",
    form3: "Turco",
    form4: "Turco",
    form5: "Turco",
    },
    ukrainian: {
    form1: "Ucraniano",
    form2: "Ucraniano",
    form3: "Ucraniano",
    form4: "Ucraniano",
    form5: "Ucraniano",
    },
    urdu: {
    form1: "Urdu",
    form2: "Urdu",
    form3: "Urdu",
    form4: "Urdu",
    form5: "Urdu",
    },
    vietnamese: {
    form1: "Vietnamita",
    form2: "Vietnamita",
    form3: "Vietnamita",
    form4: "Vietnamita",
    form5: "Vietnamita",
    },
    welsh: {
    form1: "Galés",
    form2: "Galés",
    form3: "Galés",
    form4: "Galés",
    form5: "Galés",
    },
  },
  tr: {
    language: {
      form1: "language",
      form2: "language",
      form3: "language",
      form4: "language",
      form5: "language",
      },
      english: {
      form1: "İngilizce",
      form2: "İngilizce",
      form3: "İngilizce",
      form4: "İngilizce",
      form5: "İngiliz",
      },
      russian: {
      form1: "Rusça",
      form2: "Rusça",
      form3: "Rusça",
      form4: "Rusça",
      form5: "Rus",
      },
      albanian: {
      form1:"Arnavutça",
      form2: "Arnavutça",
      form3: "Arnavutça",
      form4: "Arnavutça",
      form5: "Arnavut",
      },
      arabic: {
      form1: "Arapça",
      form2: "Arapça",
      form3: "Arapça",
      form4: "Arapça",
      form5: "Arap",
      },
      armenian: {
      form1: "Ermenice",
      form2: "Ermenice",
      form3: "Ermenice",
      form4: "Ermenice",
      form5: "Ermeni",
      },
      bulgarian: {
      form1: "Bulgarca",
      form2: "Bulgarca",
      form3: "Bulgarca",
      form4: "Bulgarca",
      form5: "Bulgar",
      },
      cantonese: {
      form1: "Kantonca",
      form2: "Kantonca",
      form3: "Kantonca",
      form4: "Kantonca",
      form5: "Kanton",
      },
      chinese: {
      form1: "Çince",
      form2: "Çince",
      form3: "Çince",
      form4: "Çince",
      form5: "Çin",
      },
      creole: {
      form1: "Kreol",
      form2: "Kreol",
      form3: "Kreol",
      form4: "Kreol",
      form5: "Kreol",
      },
      croatian: {
      form1: "Hırvatça",
      form2: "Hırvatça",
      form3: "Hırvatça",
      form4: "Hırvatça",
      form5: "Hırvat",
      },
      czech: {
      form1: "Çekçe",
      form2: "Çekçe",
      form3: "Çekçe",
      form4: "Çekçe",
      form5: "Çek",
      },
      danish: {
      form1: "Danca",
      form2: "Danca",
      form3: "Danca",
      form4: "Danca",
      form5: "Dan",
      },
      dari: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      dutch: {
      form1: "Hollandaca",
      form2: "Hollandaca",
      form3: "Hollandaca",
      form4: "Hollandaca",
      form5: "Hollanda",
      },
      euportuguese: {
      form1: "Portekizce",
      form2: "Portekizce",
      form3: "Portekizce",
      form4: "Portekizce",
      form5: "Portekiz",
      },
      finnish: {
      form1: "Fince",
      form2: "Fince",
      form3: "Fince",
      form4: "Fince",
      form5: "Fin",
      },
      french: {
      form1: "Fransızca",
      form2: "Fransızca",
      form3: "Fransızca",
      form4: "Fransızca",
      form5: "Fransız",
      },
      german: {
      form1: "Almanca",
      form2: "Almanca",
      form3: "Almanca",
      form4: "Almanca",
      form5: "Alman",
      },
      greek: {
      form1: "Yunanca",
      form2: "Yunanca",
      form3: "Yunanca",
      form4: "Yunanca",
      form5: "Yunan",
      },
      gujarati: {
      form1: "Gujarati",
      form2: "Gujarati",
      form3: "Gujarati",
      form4: "Gujarati",
      form5: "Gujarati",
      },
      hawaiian: {
      form1: "Hawaiice",
      form2: "Hawaiice",
      form3: "Hawaiice",
      form4: "Hawaiice",
      form5: "Hawaii",
      },
      hebrew: {
      form1: "İbranice",
      form2: "İbranice",
      form3: "İbranice",
      form4: "İbranice",
      form5: "İbrani",
      },
      hindi: {
      form1: "Hintçe",
      form2: "Hintçe",
      form3: "Hintçe",
      form4: "Hintçe",
      form5: "Hint",
      },
      hungarian: {
      form1: "Macarca",
      form2: "Macarca",
      form3: "Macarca",
      form4: "Macarca",
      form5: "Macar",
      },
      indonesian: {
      form1: "Endonezce",
      form2: "Endonezce",
      form3: "Endonezce",
      form4: "Endonezce",
      form5: "Endonez",
      },
      irish: {
      form1: "İrlandaca",
      form2: "İrlandaca",
      form3: "İrlandaca",
      form4: "İrlandaca",
      form5: "İrlanda",
      },
      italian: {
      form1: "İtalyanca",
      form2: "İtalyanca",
      form3: "İtalyanca",
      form4: "İtalyanca",
      form5: "İtalyan",
      },
      japanese: {
      form1: "Japonca",
      form2: "Japonca",
      form3: "Japonca",
      form4: "Japonca",
      form5: "Japon",
      },
      kazakh: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      korean: {
      form1: "Korece",
      form2: "Korece",
      form3: "Korece",
      form4: "Korece",
      form5: "Kore",
      },
      latin: {
      form1: "Latince",
      form2: "Latince",
      form3: "Latince",
      form4: "Latince",
      form5: "Latin",
      },
      malay: {
      form1: "Malayca",
      form2: "Malayca",
      form3: "Malayca",
      form4: "Malayca",
      form5: "Malay",
      },
      norwegian: {
      form1: "Norveççe",
      form2: "Norveççe",
      form3: "Norveççe",
      form4: "Norveççe",
      form5: "Norveç",
      },
      persian: {
      form1: "Farsça",
      form2: "Farsça",
      form3: "Farsça",
      form4: "Farsça",
      form5: "Fars",
      },
      polish: {
      form1: "Lehçe",
      form2: "Lehçe",
      form3: "Lehçe",
      form4: "Lehçe",
      form5: "Leh",
      },
      portuguese: {
      form1: "Portekizce",
      form2: "Portekizce",
      form3: "Portekizce",
      form4: "Portekizce",
      form5: "Portekiz",
      },
      punjabi: {
      form1: "Pencapça",
      form2: "Pencapça",
      form3: "Pencapça",
      form4: "Pencapça",
      form5: "Pencap",
      },
      romanian: {
      form1: "Romence",
      form2: "Romence",
      form3: "Romence",
      form4: "Romence",
      form5: "Romen",
      },
      serbian: {
      form1: "Sırpça",
      form2: "Sırpça",
      form3: "Sırpça",
      form4: "Sırpça",
      form5: "Sırp",
      },
      slovak: {
      form1: "Slovakça",
      form2: "Slovakça",
      form3: "Slovakça",
      form4: "Slovakça",
      form5: "Slovak",
      },
      slovenian: {
      form1: "Slovence",
      form2: "Slovence",
      form3: "Slovence",
      form4: "Slovence",
      form5: "Sloven",
      },
      spanish: {
      form1: "İspanyolca",
      form2: "İspanyolca",
      form3: "İspanyolca",
      form4: "İspanyolca",
      form5: "İspanyol",
      },
      swahili: {
      form1: "Svahili",
      form2: "Svahili",
      form3: "Svahili",
      form4: "Svahili",
      form5: "Svahili",
      },
      swedish: {
      form1: "İsveççe",
      form2: "İsveççe",
      form3: "İsveççe",
      form4: "İsveççe",
      form5: "İsveç",
      },
      tagalog: {
      form1: "Tagalog",
      form2: "Tagalog",
      form3: "Tagalog",
      form4: "Tagalog",
      form5: "Tagalog",
      },
      thai: {
      form1: "Tayca",
      form2: "Tayca",
      form3: "Tayca",
      form4: "Tayca",
      form5: "Tay",
      },
      turkish: {
      form1: "Türkçe",
      form2: "Türkçe",
      form3: "Türkçe",
      form4: "Türkçe",
      form5: "Türk",
      },
      ukrainian: {
      form1: "Ukraynaca",
      form2: "Ukraynaca",
      form3: "Ukraynaca",
      form4: "Ukraynaca",
      form5: "Ukrayna",
      },
      urdu: {
      form1: "Urduca",
      form2: "Urduca",
      form3: "Urduca",
      form4: "Urduca",
      form5: "Urdu",
      },
      vietnamese: {
      form1: "Vietnamca",
      form2: "Vietnamca",
      form3: "Vietnamca",
      form4: "Vietnamca",
      form5: "Vietnam",
      },
      welsh: {
      form1: "Galce",
      form2: "Galce",
      form3: "Galce",
      form4: "Galce",
      form5: "Gal",
      },
  },
  zh: {
    language: {
      form1: "language",
      form2: "language",
      form3: "language",
      form4: "language",
      form5: "language",
      },
      english: {
      form1: "英语",
      form2: "英语",
      form3: "英语",
      form4: "英语",
      form5: "英语",
      },
      russian: {
      form1: "俄语",
      form2: "俄语",
      form3: "俄语",
      form4: "俄语",
      form5: "俄语",
      },
      albanian: {
      form1:"阿尔巴尼亚语",
      form2: "阿尔巴尼亚语",
      form3: "阿尔巴尼亚语",
      form4: "阿尔巴尼亚语",
      form5: "阿尔巴尼亚语",
      },
      arabic: {
      form1: "阿拉伯语",
      form2: "阿拉伯语",
      form3: "阿拉伯语",
      form4: "阿拉伯语",
      form5: "阿拉伯语",
      },
      armenian: {
      form1: "亚美尼亚语",
      form2: "亚美尼亚语",
      form3: "亚美尼亚语",
      form4: "亚美尼亚语",
      form5: "亚美尼亚语",
      },
      bulgarian: {
      form1: "保加利亚语",
      form2: "保加利亚语",
      form3: "保加利亚语",
      form4: "保加利亚语",
      form5: "保加利亚语",
      },
      cantonese: {
      form1: "广东话",
      form2: "广东话",
      form3: "广东话",
      form4: "广东话",
      form5: "广东话",
      },
      chinese: {
      form1: "中文",
      form2: "中文",
      form3: "中文",
      form4: "中文",
      form5: "中文",
      },
      creole: {
      form1: "克里奥尔语",
      form2: "克里奥尔语",
      form3: "克里奥尔语",
      form4: "克里奥尔语",
      form5: "克里奥尔语",
      },
      croatian: {
      form1: "克罗地亚语",
      form2: "克罗地亚语",
      form3: "克罗地亚语",
      form4: "克罗地亚语",
      form5: "克罗地亚语",
      },
      czech: {
      form1: "捷克语",
      form2: "捷克语",
      form3: "捷克语",
      form4: "捷克语",
      form5: "捷克语",
      },
      danish: {
      form1: "丹麦语",
      form2: "丹麦语",
      form3: "丹麦语",
      form4: "丹麦语",
      form5: "丹麦语",
      },
      dari: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      dutch: {
      form1: "荷兰语",
      form2: "荷兰语",
      form3: "荷兰语",
      form4: "荷兰语",
      form5: "荷兰语",
      },
      euportuguese: {
      form1: "葡萄牙语",
      form2: "葡萄牙语",
      form3: "葡萄牙语",
      form4: "葡萄牙语",
      form5: "葡萄牙语",
      },
      finnish: {
      form1: "芬兰语",
      form2: "芬兰语",
      form3: "芬兰语",
      form4: "芬兰语",
      form5: "芬兰语",
      },
      french: {
      form1: "法语",
      form2: "法语",
      form3: "法语",
      form4: "法语",
      form5: "法语",
      },
      german: {
      form1: "德语",
      form2: "德语",
      form3: "德语",
      form4: "德语",
      form5: "德语",
      },
      greek: {
      form1: "希腊语",
      form2: "希腊语",
      form3: "希腊语",
      form4: "希腊语",
      form5: "希腊语",
      },
      gujarati: {
      form1: "古吉拉特语",
      form2: "古吉拉特语",
      form3: "古吉拉特语",
      form4: "古吉拉特语",
      form5: "古吉拉特语",
      },
      hawaiian: {
      form1: "夏威夷语",
      form2: "夏威夷语",
      form3: "夏威夷语",
      form4: "夏威夷语",
      form5: "夏威夷语",
      },
      hebrew: {
      form1: "希伯来语",
      form2: "希伯来语",
      form3: "希伯来语",
      form4: "希伯来语",
      form5: "希伯来语",
      },
      hindi: {
      form1: "印地语",
      form2: "印地语",
      form3: "印地语",
      form4: "印地语",
      form5: "印地语",
      },
      hungarian: {
      form1: "匈牙利语",
      form2: "匈牙利语",
      form3: "匈牙利语",
      form4: "匈牙利语",
      form5: "匈牙利语",
      },
      indonesian: {
      form1: "印尼语",
      form2: "印尼语",
      form3: "印尼语",
      form4: "印尼语",
      form5: "印尼语",
      },
      irish: {
      form1: "爱尔兰语",
      form2: "爱尔兰语",
      form3: "爱尔兰语",
      form4: "爱尔兰语",
      form5: "爱尔兰语",
      },
      italian: {
      form1: "意大利语",
      form2: "意大利语",
      form3: "意大利语",
      form4: "意大利语",
      form5: "意大利语",
      },
      japanese: {
      form1: "日语",
      form2: "日语",
      form3: "日语",
      form4: "日语",
      form5: "日语",
      },
      kazakh: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      korean: {
      form1: "韩语",
      form2: "韩语",
      form3: "韩语",
      form4: "韩语",
      form5: "韩语",
      },
      latin: {
      form1: "拉丁语",
      form2: "拉丁语",
      form3: "拉丁语",
      form4: "拉丁语",
      form5: "拉丁语",
      },
      malay: {
      form1: "马来语",
      form2: "马来语",
      form3: "马来语",
      form4: "马来语",
      form5: "马来语",
      },
      norwegian: {
      form1: "挪威语",
      form2: "挪威语",
      form3: "挪威语",
      form4: "挪威语",
      form5: "挪威语",
      },
      persian: {
      form1: "波斯语",
      form2: "波斯语",
      form3: "波斯语",
      form4: "波斯语",
      form5: "波斯语",
      },
      polish: {
      form1: "波兰语",
      form2: "波兰语",
      form3: "波兰语",
      form4: "波兰语",
      form5: "波兰语",
      },
      portuguese: {
      form1: "葡萄牙语",
      form2: "葡萄牙语",
      form3: "葡萄牙语",
      form4: "葡萄牙语",
      form5: "葡萄牙语",
      },
      punjabi: {
      form1: "旁遮普语",
      form2: "旁遮普语",
      form3: "旁遮普语",
      form4: "旁遮普语",
      form5: "旁遮普语",
      },
      romanian: {
      form1: "罗马尼亚语",
      form2: "罗马尼亚语",
      form3: "罗马尼亚语",
      form4: "罗马尼亚语",
      form5: "罗马尼亚语",
      },
      serbian: {
      form1: "塞尔维亚语",
      form2: "塞尔维亚语",
      form3: "塞尔维亚语",
      form4: "塞尔维亚语",
      form5: "塞尔维亚语",
      },
      slovak: {
      form1: "斯洛伐克",
      form2: "斯洛伐克",
      form3: "斯洛伐克",
      form4: "斯洛伐克",
      form5: "斯洛伐克",
      },
      slovenian: {
      form1: "斯洛文尼亚语",
      form2: "斯洛文尼亚语",
      form3: "斯洛文尼亚语",
      form4: "斯洛文尼亚语",
      form5: "斯洛文尼亚语",
      },
      spanish: {
      form1: "西班牙语",
      form2: "西班牙语",
      form3: "西班牙语",
      form4: "西班牙语",
      form5: "西班牙语",
      },
      swahili: {
      form1: "斯瓦希里语",
      form2: "斯瓦希里语",
      form3: "斯瓦希里语",
      form4: "斯瓦希里语",
      form5: "斯瓦希里语",
      },
      swedish: {
      form1: "瑞典语",
      form2: "瑞典语",
      form3: "瑞典语",
      form4: "瑞典语",
      form5: "瑞典语",
      },
      tagalog: {
      form1: "他加禄语",
      form2: "他加禄语",
      form3: "他加禄语",
      form4: "他加禄语",
      form5: "他加禄语",
      },
      thai: {
      form1: "泰语",
      form2: "泰语",
      form3: "泰语",
      form4: "泰语",
      form5: "泰语",
      },
      turkish: {
      form1: "土耳其语",
      form2: "土耳其语",
      form3: "土耳其语",
      form4: "土耳其语",
      form5: "土耳其语",
      },
      ukrainian: {
      form1: "乌克兰语",
      form2: "乌克兰语",
      form3: "乌克兰语",
      form4: "乌克兰语",
      form5: "乌克兰语",
      },
      urdu: {
      form1: "乌尔都语",
      form2: "乌尔都语",
      form3: "乌尔都语",
      form4: "乌尔都语",
      form5: "乌尔都语",
      },
      vietnamese: {
      form1: "越南语",
      form2: "越南语",
      form3: "越南语",
      form4: "越南语",
      form5: "越南语",
      },
      welsh: {
      form1: "威尔士语",
      form2: "威尔士语",
      form3: "威尔士语",
      form4: "威尔士语",
      form5: "威尔士语",
      },
  },
  it: {
    language: {
      form1: "lingue",
      form2: "lingue",
      form3: "lingue",
      form4: "lingue",
      form5: "lingue",
      },
      english: {
      form1: "inglese",
      form2: "inglese",
      form3: "inglese",
      form4: "inglese",
      form5: "inglese",
      },
      russian: {
      form1: "russo",
      form2: "russo",
      form3: "russo",
      form4: "russa",
      form5: "russo",
      },
      albanian: {
      form1:"albanese",
      form2: "albanese",
      form3: "albanese",
      form4: "albanese",
      form5: "albanese",
      },
      arabic: {
      form1: "arabo",
      form2: "arabo",
      form3: "arabo",
      form4: "araba",
      form5: "arabo",
      },
      armenian: {
      form1: "armeno",
      form2: "armeno",
      form3: "armeno",
      form4: "armena",
      form5: "armeno",
      },
      bulgarian: {
      form1: "bulgaro",
      form2: "bulgaro",
      form3: "bulgaro",
      form4: "bulgara",
      form5: "bulgaro",
      },
      cantonese: {
      form1: "cantonese",
      form2: "cantonese",
      form3: "cantonese",
      form4: "cantonese",
      form5: "cantonese",
      },
      chinese: {
      form1: "cinese",
      form2: "cinese",
      form3: "cinese",
      form4: "cinese",
      form5: "cinese",
      },
      creole: {
      form1: "creolo",
      form2: "creolo",
      form3: "creolo",
      form4: "creola",
      form5: "creolo",
      },
      croatian: {
      form1: "croato",
      form2: "croato",
      form3: "croato",
      form4: "croata",
      form5: "croato",
      },
      czech: {
      form1: "ceco",
      form2: "ceco",
      form3: "ceco",
      form4: "ceca",
      form5: "ceco",
      },
      danish: {
      form1: "danese",
      form2: "danese",
      form3: "danese",
      form4: "danese",
      form5: "danese",
      },
      dari: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      dutch: {
      form1: "olandese",
      form2: "olandese",
      form3: "olandese",
      form4: "olandese",
      form5: "olandese",
      },
      euportuguese: {
      form1: "portoghese eu",
      form2: "portoghese eu",
      form3: "portoghese eu",
      form4: "portoghese eu",
      form5: "portoghese eu",
      },
      finnish: {
      form1: "finlandese",
      form2: "finlandese",
      form3: "finlandese",
      form4: "finlandese",
      form5: "finlandese",
      },
      french: {
      form1: "francese",
      form2: "francese",
      form3: "francese",
      form4: "francese",
      form5: "francese",
      },
      german: {
      form1: "tedesco",
      form2: "tedesco",
      form3: "tedesco",
      form4: "tedesca",
      form5: "tedesco",
      },
      greek: {
      form1: "greco",
      form2: "greco",
      form3: "greco",
      form4: "greca",
      form5: "greco",
      },
      gujarati: {
      form1: "gujarati",
      form2: "gujarati",
      form3: "gujarati",
      form4: "gujarati",
      form5: "gujarati",
      },
      hawaiian: {
      form1: "hawaiano",
      form2: "hawaiano",
      form3: "hawaiano",
      form4: "hawaiana",
      form5: "hawaiano",
      },
      hebrew: {
      form1: "ebraico",
      form2: "ebraico",
      form3: "ebraico",
      form4: "ebraica",
      form5: "ebraico",
      },
      hindi: {
      form1: "hindi",
      form2: "hindi",
      form3: "hindi",
      form4: "hindi",
      form5: "hindi",
      },
      hungarian: {
      form1: "ungherese",
      form2: "ungherese",
      form3: "ungherese",
      form4: "ungherese",
      form5: "ungherese",
      },
      indonesian: {
      form1: "indonesiano",
      form2: "indonesiano",
      form3: "indonesiano",
      form4: "indonesiana",
      form5: "indonesiano",
      },
      irish: {
      form1: "irlandese",
      form2: "irlandese",
      form3: "irlandese",
      form4: "irlandese",
      form5: "irlandese",
      },
      italian: {
      form1: "italiano",
      form2: "italiano",
      form3: "italiano",
      form4: "italiana",
      form5: "italiano",
      },
      japanese: {
      form1: "giapponese",
      form2: "giapponese",
      form3: "giapponese",
      form4: "giapponese",
      form5: "giapponese",
      },
      kazakh: {
      form1: "",
      form2: "",
      form3: "",
      form4: "",
      form5: "",
      },
      korean: {
      form1: "coreano",
      form2: "coreano",
      form3: "coreano",
      form4: "coreana",
      form5: "coreano",
      },
      latin: {
      form1: "latino",
      form2: "latino",
      form3: "latino",
      form4: "latina",
      form5: "latino",
      },
      malay: {
      form1: "malese",
      form2: "malese",
      form3: "malese",
      form4: "malese",
      form5: "malese",
      },
      norwegian: {
      form1: "norvegese",
      form2: "norvegese",
      form3: "norvegese",
      form4: "norvegese",
      form5: "norvegese",
      },
      persian: {
      form1: "persiano",
      form2: "persiano",
      form3: "persiano",
      form4: "persiana",
      form5: "persiano",
      },
      polish: {
      form1: "polacco",
      form2: "polacco",
      form3: "polacco",
      form4: "polacca",
      form5: "polacco",
      },
      portuguese: {
      form1: "portoghese",
      form2: "portoghese",
      form3: "portoghese",
      form4: "portoghese",
      form5: "portoghese",
      },
      punjabi: {
      form1: "punjabi",
      form2: "punjabi",
      form3: "punjabi",
      form4: "punjabi",
      form5: "punjabi",
      },
      romanian: {
      form1: "rumeno",
      form2: "rumeno",
      form3: "rumeno",
      form4: "rumena",
      form5: "rumeno",
      },
      serbian: {
      form1: "serbo",
      form2: "serbo",
      form3: "serbo",
      form4: "serba",
      form5: "serbo",
      },
      slovak: {
      form1: "slovacco",
      form2: "slovacco",
      form3: "slovacco",
      form4: "slovacca",
      form5: "slovacco",
      },
      slovenian: {
      form1: "sloveno",
      form2: "sloveno",
      form3: "sloveno",
      form4: "slovena",
      form5: "sloveno",
      },
      spanish: {
      form1: "spagnolo",
      form2: "spagnolo",
      form3: "spagnolo",
      form4: "spagnola",
      form5: "spagnolo",
      },
      swahili: {
      form1: "swahili",
      form2: "swahili",
      form3: "swahili",
      form4: "swahili",
      form5: "swahili",
      },
      swedish: {
      form1: "svedese",
      form2: "svedese",
      form3: "svedese",
      form4: "svedese",
      form5: "svedese",
      },
      tagalog: {
      form1: "tagalog",
      form2: "tagalog",
      form3: "tagalog",
      form4: "tagalog",
      form5: "tagalog",
      },
      thai: {
      form1: "tailandese",
      form2: "tailandese",
      form3: "tailandese",
      form4: "tailandese",
      form5: "tailandese",
      },
      turkish: {
      form1: "turco",
      form2: "turco",
      form3: "turco",
      form4: "turca",
      form5: "turco",
      },
      ukrainian: {
      form1: "ucraino",
      form2: "ucraino",
      form3: "ucraino",
      form4: "ucraina",
      form5: "ucraino",
      },
      urdu: {
      form1: "urdu",
      form2: "urdu",
      form3: "urdu",
      form4: "urdu",
      form5: "urdu",
      },
      vietnamese: {
      form1: "vietnamita",
      form2: "vietnamita",
      form3: "vietnamita",
      form4: "vietnamita",
      form5: "vietnamita",
      },
      welsh: {
      form1: "gallese",
      form2: "gallese",
      form3: "gallese",
      form4: "gallese",
      form5: "gallese",
      },
    },
    ko: {
      language: {
        form1: "language",
        form2: "language",
        form3: "language",
        form4: "language",
        form5: "language",
        },
        english: {
        form1: "영어",
        form2: "영어",
        form3: "영어",
        form4: "영어",
        form5: "영어",
        },
        russian: {
        form1: "러시아인",
        form2: "러시아인",
        form3: "러시아인",
        form4: "러시아인",
        form5: "러시아인",
        },
        albanian: {
        form1:"알바니아어",
        form2: "알바니아어",
        form3: "알바니아어",
        form4: "알바니아어",
        form5: "알바니아어",
        },
        arabic: {
        form1: "아랍어",
        form2: "아랍어",
        form3: "아랍어",
        form4: "아랍어",
        form5: "아랍어",
        },
        armenian: {
        form1: "아르메니아어",
        form2: "아르메니아어",
        form3: "아르메니아어",
        form4: "아르메니아어",
        form5: "아르메니아어",
        },
        bulgarian: {
        form1: "불가리아어",
        form2: "불가리아어",
        form3: "불가리아어",
        form4: "불가리아어",
        form5: "불가리아어",
        },
        cantonese: {
        form1: "광동어",
        form2: "광동어",
        form3: "광동어",
        form4: "광동어",
        form5: "광동어",
        },
        chinese: {
        form1: "중국어",
        form2: "중국어",
        form3: "중국어",
        form4: "중국어",
        form5: "중국어",
        },
        creole: {
        form1: "크리올",
        form2: "크리올",
        form3: "크리올",
        form4: "크리올",
        form5: "크리올",
        },
        croatian: {
        form1: "크로아티아어",
        form2: "크로아티아어",
        form3: "크로아티아어",
        form4: "크로아티아어",
        form5: "크로아티아어",
        },
        czech: {
        form1: "체코",
        form2: "체코",
        form3: "체코",
        form4: "체코",
        form5: "체코",
        },
        danish: {
        form1: "덴마크",
        form2: "덴마크",
        form3: "덴마크",
        form4: "덴마크",
        form5: "덴마크",
        },
        dari: {
        form1: "",
        form2: "",
        form3: "",
        form4: "",
        form5: "",
        },
        dutch: {
        form1: "네덜란드어",
        form2: "네덜란드어",
        form3: "네덜란드어",
        form4: "네덜란드어",
        form5: "네덜란드어",
        },
        euportuguese: {
        form1: "[유럽] 포르투갈어",
        form2: "[유럽] 포르투갈어",
        form3: "[유럽] 포르투갈어",
        form4: "[유럽] 포르투갈어",
        form5: "[유럽] 포르투갈어",
        },
        finnish: {
        form1: "핀란드어",
        form2: "핀란드어",
        form3: "핀란드어",
        form4: "핀란드어",
        form5: "핀란드어",
        },
        french: {
        form1: "프랑스어",
        form2: "프랑스어",
        form3: "프랑스어",
        form4: "프랑스어",
        form5: "프랑스어",
        },
        german: {
        form1: "독일",
        form2: "독일",
        form3: "독일",
        form4: "독일",
        form5: "독일",
        },
        greek: {
        form1: "그리스어",
        form2: "그리스어",
        form3: "그리스어",
        form4: "그리스어",
        form5: "그리스어",
        },
        gujarati: {
        form1: "구자라트어",
        form2: "구자라트어",
        form3: "구자라트어",
        form4: "구자라트어",
        form5: "구자라트어",
        },
        hawaiian: {
        form1: "하와이",
        form2: "하와이",
        form3: "하와이",
        form4: "하와이",
        form5: "하와이",
        },
        hebrew: {
        form1: "히브리어",
        form2: "히브리어",
        form3: "히브리어",
        form4: "히브리어",
        form5: "히브리어",
        },
        hindi: {
        form1: "힌디어",
        form2: "힌디어",
        form3: "힌디어",
        form4: "힌디어",
        form5: "힌디어",
        },
        hungarian: {
        form1: "헝가리어",
        form2: "헝가리어",
        form3: "헝가리어",
        form4: "헝가리어",
        form5: "헝가리어",
        },
        indonesian: {
        form1: "인도네시아어",
        form2: "인도네시아어",
        form3: "인도네시아어",
        form4: "인도네시아어",
        form5: "인도네시아어",
        },
        irish: {
        form1: "아일랜드",
        form2: "아일랜드",
        form3: "아일랜드",
        form4: "아일랜드",
        form5: "아일랜드",
        },
        italian: {
        form1: "이탈리아어",
        form2: "이탈리아어",
        form3: "이탈리아어",
        form4: "이탈리아어",
        form5: "이탈리아어",
        },
        japanese: {
        form1: "일본인",
        form2: "일본인",
        form3: "일본인",
        form4: "일본인",
        form5: "일본인",
        },
        kazakh: {
        form1: "",
        form2: "",
        form3: "",
        form4: "",
        form5: "",
        },
        korean: {
        form1: "한국인",
        form2: "한국인",
        form3: "한국인",
        form4: "한국인",
        form5: "한국인",
        },
        latin: {
        form1: "라틴어",
        form2: "라틴어",
        form3: "라틴어",
        form4: "라틴어",
        form5: "라틴어",
        },
        malay: {
        form1: "말레이어",
        form2: "말레이어",
        form3: "말레이어",
        form4: "말레이어",
        form5: "말레이어",
        },
        norwegian: {
        form1: "노르웨이",
        form2: "노르웨이",
        form3: "노르웨이",
        form4: "노르웨이",
        form5: "노르웨이",
        },
        persian: {
        form1: "페르시아어",
        form2: "페르시아어",
        form3: "페르시아어",
        form4: "페르시아어",
        form5: "페르시아어",
        },
        polish: {
        form1: "폴란드어",
        form2: "폴란드어",
        form3: "폴란드어",
        form4: "폴란드어",
        form5: "폴란드어",
        },
        portuguese: {
        form1: "포르투갈어",
        form2: "포르투갈어",
        form3: "포르투갈어",
        form4: "포르투갈어",
        form5: "포르투갈어",
        },
        punjabi: {
        form1: "펀자브어",
        form2: "펀자브어",
        form3: "펀자브어",
        form4: "펀자브어",
        form5: "펀자브어",
        },
        romanian: {
        form1: "루마니아어",
        form2: "루마니아어",
        form3: "루마니아어",
        form4: "루마니아어",
        form5: "루마니아어",
        },
        serbian: {
        form1: "세르비아어",
        form2: "세르비아어",
        form3: "세르비아어",
        form4: "세르비아어",
        form5: "세르비아어",
        },
        slovak: {
        form1: "슬로바키아어",
        form2: "슬로바키아어",
        form3: "슬로바키아어",
        form4: "슬로바키아어",
        form5: "슬로바키아어",
        },
        slovenian: {
        form1: "슬로베니아어",
        form2: "슬로베니아어",
        form3: "슬로베니아어",
        form4: "슬로베니아어",
        form5: "슬로베니아어",
        },
        spanish: {
        form1: "스페인어",
        form2: "스페인어",
        form3: "스페인어",
        form4: "스페인어",
        form5: "스페인어",
        },
        swahili: {
        form1: "스와힐리어",
        form2: "스와힐리어",
        form3: "스와힐리어",
        form4: "스와힐리어",
        form5: "스와힐리어",
        },
        swedish: {
        form1: "스웨덴어",
        form2: "스웨덴어",
        form3: "스웨덴어",
        form4: "스웨덴어",
        form5: "스웨덴어",
        },
        tagalog: {
        form1: "타갈로그",
        form2: "타갈로그",
        form3: "타갈로그",
        form4: "타갈로그",
        form5: "타갈로그",
        },
        thai: {
        form1: "타이",
        form2: "타이",
        form3: "타이",
        form4: "타이",
        form5: "타이",
        },
        turkish: {
        form1: "터키어",
        form2: "터키어",
        form3: "터키어",
        form4: "터키어",
        form5: "터키어",
        },
        ukrainian: {
        form1: "우크라이나",
        form2: "우크라이나",
        form3: "우크라이나",
        form4: "우크라이나",
        form5: "우크라이나",
        },
        urdu: {
        form1: "우르두",
        form2: "우르두",
        form3: "우르두",
        form4: "우르두",
        form5: "우르두",
        },
        vietnamese: {
        form1: "베트남어",
        form2: "베트남어",
        form3: "베트남어",
        form4: "베트남어",
        form5: "베트남어",
        },
        welsh: {
        form1: "웨일스어",
        form2: "웨일스어",
        form3: "웨일스어",
        form4: "웨일스어",
        form5: "웨일스어",
        },
    },
    jp:{
      language: {
        form1: "language",
        form2: "language",
        form3: "language",
        form4: "language",
        form5: "language",
        },
        english: {
        form1: "英語",
        form2: "英語",
        form3: "英語を",
        form4: "英語で",
        form5: "英語の",
        },
        russian: {
        form1: "ロシア語",
        form2: "ロシア語",
        form3: "ロシア語を",
        form4: "ロシア語で",
        form5: "ロシア語の",
        },
        albanian: {
        form1:"アルバニア語",
        form2: "アルバニア語",
        form3: "アルバニア語を",
        form4: "アルバニア語で",
        form5: "アルバニア語の",
        },
        arabic: {
        form1: "アラビア語",
        form2: "アラビア語",
        form3: "アラビア語を",
        form4: "アラビア語で",
        form5: "アラビア語の",
        },
        armenian: {
        form1: "アルメニア語",
        form2: "アルメニア語",
        form3: "アルメニア語を",
        form4: "アルメニア語で",
        form5: "アルメニア語の",
        },
        bulgarian: {
        form1: "ブルガリア語",
        form2: "ブルガリア語",
        form3: "ブルガリア語を",
        form4: "ブルガリア語で",
        form5: "ブルガリア語の",
        },
        cantonese: {
        form1: "広東語",
        form2: "広東語",
        form3: "広東語を",
        form4: "広東語で",
        form5: "広東語の",
        },
        chinese: {
        form1: "中国語",
        form2: "中国語",
        form3: "中国語を",
        form4: "中国語で",
        form5: "中国語の",
        },
        creole: {
        form1: "クレオール語",
        form2: "クレオール語",
        form3: "クレオール語を",
        form4: "クレオール語で",
        form5: "クレオール語の",
        },
        croatian: {
        form1: "クロアチア語",
        form2: "クロアチア語",
        form3: "クロアチア語を",
        form4: "クロアチア語で",
        form5: "クロアチア語の",
        },
        czech: {
        form1: "チェコ語",
        form2: "チェコ語",
        form3: "チェコ語を",
        form4: "チェコ語で",
        form5: "チェコ語の",
        },
        danish: {
        form1: "デンマーク語",
        form2: "デンマーク語",
        form3: "デンマーク語を",
        form4: "デンマーク語で",
        form5: "デンマーク語の",
        },
        dari: {
        form1: "",
        form2: "",
        form3: "",
        form4: "",
        form5: "",
        },
        dutch: {
        form1: "オランダ語",
        form2: "オランダ語",
        form3: "オランダ語を",
        form4: "オランダ語で",
        form5: "オランダ語の",
        },
        euportuguese: {
        form1: "ポルトガル語 (EU)",
        form2: "ポルトガル語 (EU)",
        form3: "ポルトガル語 (EU)を",
        form4: "ポルトガル語 (EU)で",
        form5: "ポルトガル語 (EU)の",
        },
        finnish: {
        form1: "フィンランド語",
        form2: "フィンランド語",
        form3: "フィンランド語を",
        form4: "フィンランド語で",
        form5: "フィンランド語の",
        },
        french: {
        form1: "フランス語",
        form2: "フランス語",
        form3: "フランス語を",
        form4: "フランス語で",
        form5: "フランス語の",
        },
        german: {
        form1: "ドイツ語",
        form2: "ドイツ語",
        form3: "ドイツ語を",
        form4: "ドイツ語で",
        form5: "ドイツ語の",
        },
        greek: {
        form1: "ギリシャ語",
        form2: "ギリシャ語",
        form3: "ギリシャ語を",
        form4: "ギリシャ語で",
        form5: "ギリシャ語の",
        },
        gujarati: {
        form1: "グジャラート語",
        form2: "グジャラート語",
        form3: "グジャラート語を",
        form4: "グジャラート語で",
        form5: "グジャラート語の",
        },
        hawaiian: {
        form1: "ハワイ語",
        form2: "ハワイ語",
        form3: "ハワイ語を",
        form4: "ハワイ語で",
        form5: "ハワイ語の",
        },
        hebrew: {
        form1: "ヘブライ語",
        form2: "ヘブライ語",
        form3: "ヘブライ語を",
        form4: "ヘブライ語で",
        form5: "ヘブライ語の",
        },
        hindi: {
        form1: "ヒンディー語",
        form2: "ヒンディー語",
        form3: "ヒンディー語を",
        form4: "ヒンディー語で",
        form5: "ヒンディー語の",
        },
        hungarian: {
        form1: "ハンガリー語",
        form2: "ハンガリー語",
        form3: "ハンガリー語を",
        form4: "ハンガリー語で",
        form5: "ハンガリー語の",
        },
        indonesian: {
        form1: "インドネシア語",
        form2: "インドネシア語",
        form3: "インドネシア語を",
        form4: "インドネシア語で",
        form5: "インドネシア語の",
        },
        irish: {
        form1: "アイルランド語",
        form2: "アイルランド語",
        form3: "アイルランド語を",
        form4: "アイルランド語で",
        form5: "アイルランド語の",
        },
        italian: {
        form1: "イタリア語",
        form2: "イタリア語",
        form3: "イタリア語を",
        form4: "イタリア語で",
        form5: "イタリア語の",
        },
        japanese: {
        form1: "日本語",
        form2: "日本語",
        form3: "日本語を",
        form4: "日本語で",
        form5: "日本語の",
        },
        kazakh: {
        form1: "",
        form2: "",
        form3: "",
        form4: "",
        form5: "",
        },
        korean: {
        form1: "韓国語",
        form2: "韓国語",
        form3: "韓国語を",
        form4: "韓国語で",
        form5: "韓国語の",
        },
        latin: {
        form1: "ラテン語",
        form2: "ラテン語",
        form3: "ラテン語を",
        form4: "ラテン語で",
        form5: "ラテン語の",
        },
        malay: {
        form1: "マレー語",
        form2: "マレー語",
        form3: "マレー語を",
        form4: "マレー語で",
        form5: "マレー語の",
        },
        norwegian: {
        form1: "ノルウェー語",
        form2: "ノルウェー語",
        form3: "ノルウェー語を",
        form4: "ノルウェー語で",
        form5: "ノルウェー語の",
        },
        persian: {
        form1: "ペルシャ語",
        form2: "ペルシャ語",
        form3: "ペルシャ語を",
        form4: "ペルシャ語で",
        form5: "ペルシャ語の",
        },
        polish: {
        form1: "ポーランド語",
        form2: "ポーランド語",
        form3: "ポーランド語を",
        form4: "ポーランド語で",
        form5: "ポーランド語の",
        },
        portuguese: {
        form1: "ポルトガル語 (BR)",
        form2: "ポルトガル語 (BR)",
        form3: "ポルトガル語 (BR)を",
        form4: "ポルトガル語 (BR)で",
        form5: "ポルトガル語 (BR)の",
        },
        punjabi: {
        form1: "パンジャブ語",
        form2: "パンジャブ語",
        form3: "パンジャブ語を",
        form4: "パンジャブ語で",
        form5: "パンジャブ語の",
        },
        romanian: {
        form1: "ルーマニア語",
        form2: "ルーマニア語",
        form3: "ルーマニア語を",
        form4: "ルーマニア語で",
        form5: "ルーマニア語の",
        },
        serbian: {
        form1: "セルビア語",
        form2: "セルビア語",
        form3: "セルビア語を",
        form4: "セルビア語で",
        form5: "セルビア語の",
        },
        slovak: {
        form1: "スロバキア語",
        form2: "スロバキア語",
        form3: "スロバキア語を",
        form4: "スロバキア語で",
        form5: "スロバキア語の",
        },
        slovenian: {
        form1: "スロベニア語",
        form2: "スロベニア語",
        form3: "スロベニア語を",
        form4: "スロベニア語で",
        form5: "スロベニア語の",
        },
        spanish: {
        form1: "スペイン語",
        form2: "スペイン語",
        form3: "スペイン語を",
        form4: "スペイン語で",
        form5: "スペイン語の",
        },
        swahili: {
        form1: "スワヒリ語",
        form2: "スワヒリ語",
        form3: "スワヒリ語を",
        form4: "スワヒリ語で",
        form5: "スワヒリ語の",
        },
        swedish: {
        form1: "スエーデン語",
        form2: "スエーデン語",
        form3: "スエーデン語を",
        form4: "スエーデン語で",
        form5: "スエーデン語の",
        },
        tagalog: {
        form1: "タガログ語",
        form2: "タガログ語",
        form3: "タガログ語を",
        form4: "タガログ語で",
        form5: "タガログ語の",
        },
        thai: {
        form1: "タイ語",
        form2: "タイ語",
        form3: "タイ語を",
        form4: "タイ語で",
        form5: "タイ語の",
        },
        turkish: {
        form1: "トルコ語",
        form2: "トルコ語",
        form3: "トルコ語を",
        form4: "トルコ語で",
        form5: "トルコ語の",
        },
        ukrainian: {
        form1: "ウクライナ語",
        form2: "ウクライナ語",
        form3: "ウクライナ語を",
        form4: "ウクライナ語で",
        form5: "ウクライナ語の",
        },
        urdu: {
        form1: "ウルドゥー語",
        form2: "ウルドゥー語",
        form3: "ウルドゥー語を",
        form4: "ウルドゥー語で",
        form5: "ウルドゥー語の",
        },
        vietnamese: {
        form1: "ベトナム語",
        form2: "ベトナム語",
        form3: "ベトナム語を",
        form4: "ベトナム語で",
        form5: "ベトナム語の",
        },
        welsh: {
        form1: "ウェールズ語",
        form2: "ウェールズ語",
        form3: "ウェールズ語を",
        form4: "ウェールズ語で",
        form5: "ウェールズ語の",
        },	
    }
};
