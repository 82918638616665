import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AudioToAudioGameComponent } from './audio-to-audio.component'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatRippleModule } from '@angular/material/core'
import { TranslateModule } from '@ngx-translate/core'
import { GameLocalizationPipeModule } from 'src/app/helpers/pipes/game-localization/game-localization-pipe.module'
import { LocalizationPipe } from 'src/app/helpers/pipes/game-localization/game-localization.pipe'

@NgModule({
    declarations: [AudioToAudioGameComponent],
    exports: [AudioToAudioGameComponent],
    providers: [LocalizationPipe],
    imports: [CommonModule, FormsModule, RouterModule, MatButtonModule, MatIconModule, MatRippleModule, TranslateModule, GameLocalizationPipeModule]
})
export class AudioToAudioGameModule {}
