<section class="confirmation">
  <article>
    <div class="text" [innerHTML]="question | translate"></div>
    <div class="answers">
      <button *ngIf="extraConfirmation; else elseBlock" appSounds mat-raised-button color="warn" (click)="selectAnswer(true)" [disabled]="secondsLeft > 0">
        <ng-template [ngIf]="secondsLeft > 0">({{ secondsLeft }})</ng-template>
        <ng-template [ngIf]="secondsLeft === 0">{{ 'MODAL_CONFIRM.YES' | translate }}</ng-template>
      </button>
      <ng-template #elseBlock>
        <button *ngIf="!customYes" appSounds mat-raised-button color="accent" (click)="selectAnswer(true)">{{ 'MODAL_CONFIRM.YES' | translate }}</button>
        <button *ngIf="customYes" appSounds mat-raised-button color="accent" (click)="selectAnswer(true)">{{ customYes | translate }}</button>
      </ng-template>
      <button *ngIf="!customNo" appSounds mat-raised-button color="primary" (click)="selectAnswer(false)">{{ 'MODAL_CONFIRM.NO' | translate }}</button>
      <button *ngIf="customNo" appSounds mat-raised-button color="primary" (click)="selectAnswer(false)">{{ customNo | translate }}</button>
    </div>
  </article>
</section>
