import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { HomeComponent } from './home.component'
import { TranslateModule } from '@ngx-translate/core'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { TestimonialsModule } from 'src/app/views/landing/common/testimonials/testimonials.module'
import { GoogleReviewsModule } from 'src/app/views/landing/common/google-reviews/google-reviews.module'
import { ReviewStarsModule } from 'src/app/views/landing/common/review-stars/review-stars.module'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, TranslateModule, AngularSvgIconModule, TestimonialsModule, GoogleReviewsModule, ReviewStarsModule],
  declarations: [HomeComponent],
})
export class HomeModule {}
