import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { GetNotificationRequest } from 'src/app/models/Notification.model'
import { NotificService } from 'src/app/services/notific.service'
import { NotificationService } from 'src/app/services/notification.service'
import { ENotificationActions, GetNotification, GetNotificationSuccess } from '../actions/notification.actions'

@Injectable()
export class NotificationEffects {
  getNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetNotification>(ENotificationActions.GetNotification),
      map((action: GetNotification) => action.payload),
      mergeMap((request: GetNotificationRequest) => this._notificService.GetNotification(request)),
      mergeMap((notific: any) => of(new GetNotificationSuccess(notific))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  constructor(private _actions$: Actions, private _notificService: NotificService, private _notificationService: NotificationService) {}
}
