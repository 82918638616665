<section *ngIf="data; else elseBlock" class="detailedReport">
  <div class="close-container"><span class="close" (click)="dialogRef.close()">+</span></div>
  <div class="name">{{ dialogData.report.subuser.name }}</div>

  <div class="title">{{ 'LINKS.REPORTS' | translate }}</div>
  <article class="contentWrapper">
    <mat-form-field>
      <mat-label>{{ 'PLACEHOLDERS.SELECT_DATE' | translate }} </mat-label>
      <mat-select [(value)]="selected">
        <mat-option *ngFor="let date of dateArray | keyvalue" (click)="getSubuserMonthReport(date.key)" [value]="date.key | date : 'MMMM y'">
          {{ date.key | date : 'MMMM y' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="infoBlock">
      <div class="info" *ngIf="detailedSubuserReport">
        <div class="shortInfo">
          <p class="main">
            {{ 'REPORT.WORDS' | translate }} :
            <span>{{ detailedSubuserReport?.totalWordsCount }}</span>
          </p>
        </div>
      </div>

      <div class="infoTable" *ngFor="let item of detailedSubuserReport?.tableData.response | keyvalue">
        <h4>{{ item.key | date : 'MMMM d' }}</h4>
        <div class="lessonDetailed" *ngFor="let x of item?.value; let lessonIndex = index">
          <p>
            <span>{{ lessonIndex + 1 }}.</span>{{ 'DETAILED_REPORT.NAME_LESSON' | translate }} :
            <span>{{ x.lessonName }}</span>
          </p>

          <ng-container *ngIf="x.words.length > 0">
            <p>
              {{ 'DETAILED_REPORT.COUNT_WORDS' | translate }} :
              <span>{{ x.wordsCount }}</span>
            </p>
            <span>{{ 'DETAILED_REPORT.COUNT_WORDS_TEXT' | translate }} :</span>
            <div class="words">
              <div *ngFor="let word of x.words">
                <span>{{ word }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="emptyObj === true">
        <div>
          <h3>{{ 'DETAILED_REPORT.NOT_FINISHED_YET' | translate }}</h3>
        </div>
      </ng-container>
    </div>
  </article>
</section>

<ng-template #elseBlock>
  <div class="loader-back">
    <div class="loaderBlock">
      <div class="{{ loaderClass }}"></div>
    </div>
  </div>
</ng-template>
