import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { CurrentUserPopupComponent } from './current-user.component'
import { RouterModule } from '@angular/router'
import { CheckParentPinDialogModule } from '../checkParentPin/checkParentPin.module'
import { TranslateModule } from '@ngx-translate/core'
import { ConfirmationPopupModule } from '../../Control-Dialogs/confirmation/confirmation.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  declarations: [CurrentUserPopupComponent],
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule, MatCardModule, ConfirmationPopupModule, CheckParentPinDialogModule, TranslateModule, SoundsModule, LottieModule],
})
export class CurrentUserPopupModule {}
