<section class="gradebook" [ngClass]="{ loader: !loaded }">
  <article class="contentWrapper">
    <div class="title">{{ 'LINKS.GRADEBOOK' | translate }}</div>

    <div *ngIf="classrooms.length > 0; else noClassroomsBlock" class="gradebook-wrapper">
      <div class="classroom-select-export-gradebook">
        <div class="classroom-select">
          <mat-form-field>
            <mat-label>{{ 'GRADEBOOK.CHOOSE_CLASSROOM' | translate }}</mat-label>
            <mat-select [(value)]="selectedClassroom">
              <mat-option *ngFor="let classroom of classrooms" (click)="selectClassroom(classroom)" [value]="classroom">{{ classroom.classroomName }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="export-students">
          <button type="button" class="btn-export-gradebook" (click)="openExportGradebookDialog()"><img src="assets/images/icons/download.svg" alt="export gradebook" />{{ 'GRADEBOOK.EXPORT.TITLE' | translate }}</button>
        </div>
      </div>

      <div class="assignments" [ngClass]="{ hide: !loaded || !students.length || !assignments.lessons?.length }">
        <div class="grade-breakdown">Student Grading Breakdown</div>
        <div class="grade-breakdown">- Tests: 50% <span class="normal-text"> of Grade</span></div>
        <div class="grade-breakdown">- Videos & Games: 35% <span class="normal-text">of Grade</span></div>
        <div class="grade-breakdown">- Print Activties: 15% <span class="normal-text"> of Grade</span></div>
        <div class="grade-disclaimer">
          Note: <span class="normal-text">{{ 'GRADEBOOK.GRADE.DISCLAIMER' | translate }}</span>
        </div>
        <div class="table-scroll">
          <table class="table-classroom">
            <thead>
              <tr>
                <th class="cell-identifier">&nbsp;</th>
                <th class="cell-identifier" *ngFor="let student of students">
                  <div class="student-name"><span class="download" (click)="openExportGradebookDialog(student)"></span> {{ student.subuser.name }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="cell-identifier">{{ 'GRADEBOOK.GRADE.OVERALL' | translate }}</th>
                <td *ngFor="let student of students">{{ getOverallGrade(student) }}%</td>
              </tr>
              <tr *ngFor="let data of tableData">
                <th class="cell-identifier">{{ data.lesson.name }}</th>
                <td *ngFor="let result of data.results" [ngClass]="result.cssClass" (click)="openAdjustGradeDialog(result, result.student)">
                  <ng-container *ngIf="result.complete; else elseBlock">
                    <span class="checkmark">&#x2714;</span>
                    <span *ngIf="result.lessonType === 3">- {{ result.progress }}% </span>
                    <span>- {{ result.createdAt | date : 'MM/dd/yy' }}</span>
                  </ng-container>
                  <ng-template #elseBlock><span class="x">&#215;</span></ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="below-table">
          <div class="legend"><span class="checkmark">&#x2714;</span> {{ 'GRADEBOOK.LEGEND_COMPLETE' | translate }} <span class="x">&#215;</span> {{ 'GRADEBOOK.LEGEND_INCOMPLETE' | translate }}</div>
        </div>
      </div>
      <div class="data-missing" [ngClass]="{ hide: !loaded || (students.length && assignments.lessons?.length) }">
        <span *ngIf="!students.length; else missingElseBlock">{{ 'GRADEBOOK.MISSING_STUDENTS' | translate }}</span>
        <ng-template #missingElseBlock
          ><span>{{ 'GRADEBOOK.MISSING_ASSIGNMENTS' | translate }}</span></ng-template
        >
      </div>
    </div>
    <ng-template #noClassroomsBlock
      ><span>{{ 'GRADEBOOK.NO_CLASSROOMS' | translate }}</span></ng-template
    >
  </article>
</section>
