export const environment = {
  name: 'prod',
  production: true,
  appVersion: require('../../package.json').version,
  stripeKey: 'pk_live_zv6XsYKVYCMWzdbUJTflLAyq',
  googleApiKey:
    '725259782118-6fe2ncldvsn9in8nmraprcomta9u4ll0.apps.googleusercontent.com',
  facebookApiKey: '1138619883150721',
  gatewayUrl: 'https://gw.dinolearning.com/',
  stripeCustomerPortal: 'https://billing.stripe.com/p/login/aEUbMgc4W4UL4py5kk',
  monthlyPlanId: 'price_1OoZLy4V1uixoOB2kL3qJ3fP',
  annualPlanId: 'price_1OoZLy4V1uixoOB2sdwXRbL9',
  ppMonthlyPlanId: 'P-1X474204F03242247M2NJ36I',
  ppAnnualPlanId: 'P-55939698F5082123MM2NJ6QY',
  logoutRedirectUrl: 'https://dinolingo.com',
}
