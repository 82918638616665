import { NgModule } from '@angular/core'
import { GoogleReviewsComponent } from './google-reviews.component'
import { CommonModule } from '@angular/common'
import { AngularSvgIconModule } from 'angular-svg-icon'

@NgModule({
  imports: [CommonModule, AngularSvgIconModule],
  declarations: [GoogleReviewsComponent],
  exports: [GoogleReviewsComponent],
})
export class GoogleReviewsModule {}
