import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy, ElementRef, ViewChild } from '@angular/core'
import { IQuestion } from 'src/app/models/Question.model'
import { AudioService } from 'src/app/services/audio.service'
import { of } from 'rxjs'
import { delay, take } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { RandomizeService } from 'src/app/services/randomize.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import * as Sentry from "@sentry/angular"

@Component({
  selector: 'correct-or-incorrect',
  templateUrl: './correct-or-incorrect.component.html',
  styleUrls: ['./correct-or-incorrect.component.scss', '../games.scss'],
})
export class CorrectOrIncorrectGameComponent implements OnChanges, OnInit, OnDestroy {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Input() comparison: boolean
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  constructor(public translate: TranslateService, private _RandomizeService: RandomizeService, private localizeHelperService: LocalizeHelperService, private audioService: AudioService) {}

  public checked = false
  public selectedAnswer: boolean = null
  public altImage = []
  public imgType
  public cardColors = []
  public questionId: number
  public timerId
  public audiohelpIsPlaying = false

  /* Clear selected answer and checked answer when inputs are changed */
  ngOnChanges() {
    this.selectedAnswer = null
    this.checked = false
    of(true)
      .pipe(delay(500), take(1))
      .subscribe(() => this.audioService.playAudio(this.question.answers[0].audioSrc, this.question.answers[0].audioSrc))

    if (this.altImage.length > 0 && this.question.id != this.questionId) {
      this.altImage.unshift(this._RandomizeService.getRandomQuestionImage(this.question))
      this.imgType = this.altImage[0].includes('.json') ? 'json' : ''
      this.cardColors = this._RandomizeService.getRandom12Colors()
    }
  }

  ngOnInit() {
    this.currentlang = this.localizeHelperService.getLangForced()

    this.questionId = this.question.id

    this.altImage.unshift(this._RandomizeService.getRandomQuestionImage(this.question))
    this.imgType = this.altImage[0].includes('.json') ? 'json' : ''

    this.audioService.preloadAudio(this.question.answers[0].audioSrc, this.question.answers[0].audioSrc)

    of(true)
      .pipe(delay(500), take(1))
      .subscribe(() => this.audioService.playAudio(this.question.answers[0].audioSrc, this.question.answers[0].audioSrc))

    this.cardColors = this._RandomizeService.getRandom12Colors()
  }

  ngOnDestroy() {
    clearTimeout(this.timerId)
    this.audioService.stopAllAudio()
  }

  public audiohelpPlay() {
    this.audiohelpIsPlaying = true
    const callback = () => {
      this.audiohelpIsPlaying = false
      this.audiohelp.nativeElement.removeEventListener('ended', callback)
    }
    
    this.audiohelp.nativeElement.addEventListener('ended', callback)

    this.audiohelp.nativeElement.play().catch((error) => {
      Sentry.captureException(error, {
        level: "error",
        extra: {
          location: 'audiohelpPlay CorrectOrIncorrectGameComponent',
          questionId: this.question.id,
          currentlang: this.currentlang,
        }
      })
    })
  }

  public selectAnswer(answer) {
    if (this.checked === true) return
    this.selectedAnswer = answer
  }

  public playAudio(src, $event: Event) {
    const cardElement = $event.currentTarget as HTMLElement;

    this.audioService.playAudio(src, src, true, true, null, () => {
      cardElement.classList.remove('active')
    })
    
    cardElement.classList.add('active')
  }

  public checkAnswer() {
    this.checked = true
    if (this.question.answers[0].correct == this.selectedAnswer) {
      this.audioService.playSoundCorrect()
    } else {
      this.audioService.playSoundIncorrect()
    }
  }

  public get checkRightAnswer() {
    return this.selectedAnswer === this.question.answers[0].correct
  }

  public setAnswer(answer) {
    if (this.checkRightAnswer)
      this.success.emit({
        question: this.question,
        answer: this.question.answers[0],
      })
    else this.failed.emit({ question: this.question, answer: false })
  }

  public setAnswerTest(answer) {
    if (this.checkRightAnswer)
      this.success.emit({
        question: this.question,
        answer: this.question.answers[0],
      })
    else this.failed.emit({ question: this.question, answer: false })
  }
}
