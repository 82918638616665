<section class="detailed-reports">
  <article class="contentWrapper" *ngIf="loaded; else elseBlock">
    <div class="form-fields-container full-width" *ngIf="type === 'school' && assignments?.length">
      <div class="form-field-container">
        <label for="selectedAssignment">{{ 'PROGRESS_REPORTS.CHOOSE_ASSIGNMENT' | translate }}</label>
        <mat-select [(value)]="selectedAssignment" placeholder="{{ 'PROGRESS_REPORTS.CHOOSE_ASSIGNMENT' | translate }}">
          <mat-option (click)="selectAssignment(null)" [value]="null">None</mat-option>
          <mat-option *ngFor="let assignment of assignments" (click)="selectAssignment(assignment)" [value]="assignment">{{ assignment.lesson.name }}</mat-option>
        </mat-select>
      </div>
    </div>

    <div class="column-labels">
      <div class="column-label column-label--last-activity">{{ 'PROGRESS_REPORTS.LABELS.LAST_ACTIVITY' | translate }}</div>
      <div class="column-label column-label--total-words">{{ 'PROGRESS_REPORTS.LABELS.WORD_COUNT' | translate }}</div>
    </div>
    <div class="level">
      <div class="students" *ngFor="let report of detailedReports">
        <div class="student">
          <div class="student-avatar">
            <ng-lottie class="avatar" [options]="{ path: report.subuser.avatar.src }"> </ng-lottie>
          </div>
          <div class="student-name">{{ report.subuser.name }}</div>
        </div>

        <div class="time-logs">
          <ng-container *ngIf="report.detailedReports.lastActivity">
            <div class="log-row">
              <div class="left">
                <span>{{ formatCourseName(report.detailedReports.lastActivity) }}</span>
              </div>
              <div class="center">
                <span class="daily-time">{{ report.detailedReports.wordsCount }}</span>
              </div>
              <div class="right">
                <div (click)="openAllDetailedReportsPopup(report); $event.stopPropagation()">
                  <mat-icon color="primary">remove_red_eye</mat-icon>
                  <span>Details</span>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="log-row" *ngIf="!report.detailedReports.lastActivity">
            <div class="center2">
              <div class="no-results">
                <span class="result-title ftsize">{{ 'LEARNING_REPORT.NO_RESULTS' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
  <ng-template #elseBlock>
    <div class="loader-back">
      <div class="loaderBlock">
        <div class="{{ loaderClass }}"></div>
      </div>
    </div>
  </ng-template>
</section>
