<div class="export-gradebook">
  <div class="actions">
    <span class="title">{{ 'GRADEBOOK.EXPORT.TITLE' | translate }} </span>
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>
  <div class="content">
    <div class="format-select" *ngIf="selectedStudent">
      <mat-form-field>
        <mat-label>{{ 'GRADEBOOK.EXPORT.CHOOSE_FORMAT' | translate }}</mat-label>
        <mat-select [(value)]="selectedFormat">
          <mat-option *ngFor="let format of formats" [value]="format">{{ format }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="save">
    <button mat-raised-button color="accent" [disabled]="!selectedFormat" (click)="export()">
      {{ 'GRADEBOOK.EXPORT.DOWNLOAD' | translate }}
    </button>
  </div>
</div>
