import { EProgressReportsActions, ProgressReportsActions } from '../actions/progress-reports.actions'
import { InitialProgressReportsState, IProgressReportsState } from '../state/progress-reports.state'

export const progressReportsReducers = (state = InitialProgressReportsState, action: ProgressReportsActions): IProgressReportsState => {
  switch (action.type) {
    case EProgressReportsActions.GetSubuserTestReportsSuccess:
    case EProgressReportsActions.GetStudentsTestReportsSuccess: {
      return {
        ...state,
        testReports: action.payload,
      }
    }

    case EProgressReportsActions.GetSubuserTestReportSuccess:
    case EProgressReportsActions.GetStudentTestReportsSuccess: {
      return {
        ...state,
        currentTestReports: action.payload,
      }
    }

    case EProgressReportsActions.GetSubuserTimeLogsSuccess:
    case EProgressReportsActions.GetStudentsTimeLogsSuccess: {
      return {
        ...state,
        timeLogs: action.payload,
      }
    }

    case EProgressReportsActions.GetSubuserTimeLogSuccess:
    case EProgressReportsActions.GetStudentTimeLogSuccess: {
      return {
        ...state,
        currentTimeLogs: action.payload,
      }
    }

    case EProgressReportsActions.GetSubuserDetailedReportsSuccess:
    case EProgressReportsActions.GetStudentsDetailedReportsSuccess: {
      return {
        ...state,
        detailedReports: action.payload,
      }
    }

    case EProgressReportsActions.ResetReports: {
      return {
        ...InitialProgressReportsState,
      }
    }

    default:
      return state
  }
}
