import { HttpClient } from '@angular/common/http'
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { environment } from 'src/environments/environment'

export interface IRewiwe {
  countReviews: number
  max: number
  min: number
  starsAverage: string
  userReview: number
}

@Injectable({
  providedIn: 'root',
})
export class GetIpUserService {
  private gatewayUrl: string
  private _renderer2: Renderer2

  constructor(private http: HttpClient, rendererFactory: RendererFactory2) {
    this.gatewayUrl = `${environment.gatewayUrl}review/`
    this._renderer2 = rendererFactory.createRenderer(null, null)
  }

  getUserIp() {
    return this.http.get<{ ip: string }>('https://jsonip.com' /* , {headers: headers} */)
  }

  setReviewSnippet(request, title, document) {
    const el = document.getElementById('review-script')
    if (el) {
      this._renderer2.removeChild(document.head, el)
    }

    const script = this._renderer2.createElement('script')
    script.type = `application/ld+json`
    script.id = 'review-script'

    this.getUserReview(request).subscribe((res: IRewiwe) => {
      if (res) {
        const pageStars = res.countReviews ? res.countReviews : 0
        const best = res.max ? res.max : 0
        const worst = res.min ? res.min : 0
        const val = res.starsAverage ? res.starsAverage : 0
        script.text = `{
                "@context": "https://schema.org",
                "@type": "CreativeWorkSeries",
                "name": "${title}",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "${val}",
                  "ratingCount": "${pageStars.toString()}",
                  "bestRating": "${best.toString()}"
                }
            }`
        if (val) {
          this._renderer2.appendChild(document.head, script)
        }
      }
    })
  }

  sendUserReviw(request) {
    return this.http.post(`${this.gatewayUrl}create`, request)
  }

  getUserReview(request) {
    return this.http.get(`${this.gatewayUrl}get`, {
      params: { url: request.url },
    })
  }

  getUserCountry() {
    return this.http.get('https://ipinfo.io/?token=5764f63b46010c')
  }
}
