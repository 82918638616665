export const courseNames = {
    en: {
        "Lesson": "Lesson",
        "Game": "Game",
        "Test": "Test",
        "Flashcards": "Flashcards",
        "Worksheet": "Worksheet",
        "Song": "Song",
        "Book": "Book",
        "Poster": "Poster",
        "Vocabulary": "Vocabulary",
        "Animals": "Animals",
        "Numbers": "Numbers",
        "Colors": "Colors",
        "Food": "Food",
        "Fruit and Vegetables": "Fruit and Vegetables",
        "In the House": "In the House",
        "Nature": "Nature",
        "Actions": "Actions",
        "Family": "Family",
        "Vehicles": "Vehicles",
        "Body": "Body",
        "Clothes": "Clothes",
        "50 Words": "50 Words",
        "Alphabet": "Alphabet",
        "Phonics": "Phonics",
        "Baby Animals": "Baby Animals",
        "Numbers 1-20": "Numbers 1-20",
        "Shapes": "Shapes",
        "Veggies": "Veggies",
        "Fruit": "Fruit",
        "Nuts": "Nuts",
        "Weather": "Weather",
        "Adjectives": "Adjectives",
        "Comparisons": "Comparisons",
        "Kitchen": "Kitchen",
        "Groceries": "Groceries",
        "Bathroom": "Bathroom",
        "In the house": "In the house",
        "Tools": "Tools",
        "Shopping": "Shopping",
        "School Subjects": "School Subjects",
        "Traveling": "Traveling",
        "Places": "Places",
        "Musical Instruments": "Musical Instruments",
        "Body Parts": "Body Parts",
        "Health": "Health",
        "Pronouns": "Pronouns",
        "Possessions": "Possessions",
        "Professions": "Professions",
        "Emotions": "Emotions",
        "Verbs": "Verbs",
        "Daily Activities": "Daily Activities",
        "House Chores": "House Chores",
        "Sports": "Sports",
        "Hobbies": "Hobbies",
        "Months": "Months",
        "Seasons": "Seasons",
        "Days": "Days",
        "Time": "Time",
        "House Items": "House Items",
        "English Phonics": "English Phonics",
        "Time Flashcards": "Time Flashcards "
    },
    ru: {
        "Activity": "Игры",
        "Video": "Видео",
        "Lesson": "Урок",
        "Game": "Игра",
        "Test": "Тест",
        "Flashcards": "Карточки",
        "Worksheet": "Рабочая тетрадь",
        "Song": "Песня",
        "Book": "Книга",
        "Poster": "Постер",
        "poster": "Постер",
        "Vocabulary": "Словарь",
        "Animals": "Животные",
        "Numbers": "Числа",
        "Colors": "Цвета",
        "Food": "Еда",
        "Fruit and Vegetables": "Фрукты и Овощи",
        "In the House": "В доме",
        "Nature": "Природа",
        "Actions": "Действия",
        "Family": "Семья",
        "Vehicles": "Транспорт",
        "Body": "Тело",
        "Clothes": "Одежда",
        "Words": "слов",
        "Alphabet": "Алфавит",
        "Phonics": "Произношение",
        "Baby Animals": "Детеныши животных",
        "Shapes": "Геометрические фигуры",
        "Veggies": "Овощи",
        "Fruit": "Фрукты",
        "Nuts": "Орехи",
        "Weather": "Погода",
        "Adjectives": "Прилагательные",
        "Comparisons": "Сравнения",
        "Kitchen": "Кухня",
        "Groceries": "Бакалея",
        "Bathroom": "Ванная",
        "In the house": "В доме",
        "Tools": "Инструменты",
        "Shopping": "Покупки",
        "School Subjects": "Школьные предметы",
        "Traveling": "Путешествия",
        "Places": "Места",
        "Musical Instruments": "Музыкальные инструменты",
        "Body Parts": "Части тела",
        "Health": "Здоровье",
        "Pronouns": "Местоимения",
        "Possessions": "Притяжательные местоимения",
        "Professions": "Профессии",
        "Emotions": "Эмоции",
        "Verbs": "Глаголы",
        "Daily Activities": "Ежедневные занятия",
        "House Chores": "Домашние дела",
        "Sports": "Спорт",
        "Hobbies": "Хобби",
        "Months": "Месяца",
        "Seasons": "Времена года",
        "Days": "Дни",
        "Time": "Время",
        "House Items": "Предметы в доме",
        "words Song": "слов Песни",
        "English": "Английский",
        "English Alphabet": "Английский алфавит",
        "Months Days": "Месяца Дни",
        "Days Time": "Дни Время",
        "Alphabet Video": "Алфавит Видео",
        "Alphabet song": "Алфавит Песни",
        "Alphabet Worksheet": "Алфавит Рабочая тетрадь",
        "Alphabet Book": "Алфавит Книга",
        "Shapes Flashcards": "Геометрические Фигуры Карточки",
        "Weather Flashcards": "Погода Карточки",
        "Possessions Flashcards": "Притяжательные местоимения Карточки",
        "Months Days Flashcards": "Месяца Дни Карточки",
        "English Phonics": "Английское Произношение",
        "Time Flashcards": "Время Карточки"
         },
    ua: {
        "Lesson":"Урок",
        "Game":"Гра",
        "Test":"Тест",
        "Flashcards":"Картки",
        "Worksheet":"Робочий зошит",
        "Song":"Пісня",
        "Book":"Книга",
        "Poster":"Плакат",
        "Vocabulary":"Словник",
        "Activity":"Розваги",
        "Video":"Відео",
        "poster":"плакат",
        "Animals":"Тварини",
        "Numbers":"Числа",
        "Colors":"Кольори",
        "Food":"Їжа",
        "Fruit and Vegetables":"Фрукти та овочі",
        "In the House":"В будинку",
        "Nature":"Природа",
        "Actions":"Дії",
        "Family":"Сім'я",
        "Vehicles":"Транспортні засоби",
        "Body":"Тіло",
        "Clothes":"Одяг",
        "Words":"Слів",
        "Alphabet":"Алфавіт",
        "Phonics":"Вимова",
        "Baby Animals":"Звірята",
        "Shapes":"Фігури",
        "Veggies":"Овочі",
        "Fruit":"Фрукти",
        "Nuts":"Горіхи",
        "Weather":"Погода",
        "Adjectives":"Прикметники",
        "Comparisons":"Порівняння",
        "Kitchen":"Кухня",
        "Groceries":"Продукти",
        "Bathroom":"Ванна",
        "In the house":"В будинку",
        "Tools":"Інструменти",
        "Shopping":"Покупки",
        "School Subjects":"Шкільні предмети",
        "Traveling":"Подорож",
        "Places":"Місця",
        "Musical Instruments":"Музичні інструменти",
        "Body Parts":"Частини тіла",
        "Health":"Здоров'я",
        "Pronouns":"Займенники",
        "Possessions":"Присвійні займенники",
        "Professions":"Професії",
        "Emotions":"Емоції",
        "Verbs":"Дієслова",
        "Daily Activities":"Щоденні справи",
        "House Chores":"Домашні обов'язки",
        "Sports":"Спорт",
        "Hobbies":"Хобі",
        "Months":"Місяці",
        "Seasons":"Пори року",
        "Days":"Дні",
        "Time":"Час",
        "House Items":"Предмети у будинку",
        "words Song":"слів Пісні",
        "English":"Англійська",
        "English Alphabet":"Англійський алфавіт",
        "Months Days":"Місяці Дні",
        "Days Time":"Час Дні",
        "Alphabet Video":"Алфавіт відео",
        "Alphabet song":"Алфавіт пісня",
        "Alphabet Worksheet":"Алфавіт зошит",
        "Alphabet Book":"Алфавіт Книга ",
        "Shapes Flashcards":"Фыгури Картки",
        "Weather Flashcards":"Погода Картки",
        "Possessions Flashcards":"Присвійні займенники картки",
        "Months Days Flashcards":"Місяці, Дні Картки",
        "English Phonics": "Англійська Вимова",
        "Time Flashcards": "Час Картки"
    },
    de: {
        "Lesson": "",
        "Game": "",
        "Test": "",
        "Flashcards": "",
        "Worksheet": "",
        "Song": "",
        "Book": "",
        "Poster": "",
        "Vocabulary": "",
        "Activity": "",
        "Video":"",
        "poster":"",
        "Animals":"Tiere",
        "Numbers":"Zahlen",
        "Colors":"Farben",
        "Food":"Essen",
        "Fruit and Vegetables":"Obst und Gemüse",
        "In the House":"Im Haus ",
        "Nature":"Natur",
        "Actions":"Aktivitäten",
        "Family":"Familie",
        "Vehicles":"Fahrzeuge",
        "Body":"Körper",
        "Clothes":"Kleidungsstücke",
        "Words":"Wörter",
        "Alphabet":"Alphabet",
        "Phonics":"Phonetik",
        "Baby Animals":"Babytiere",
        "Shapes":"Formen",
        "Veggies":"Gemüse",
        "Fruit":"Obst",
        "Nuts":"Nüsse",
        "Weather":"Wetter",
        "Adjectives":"Adjektive",
        "Comparisons":"Vergleiche",
        "Kitchen":"Küche",
        "Groceries":"Lebensmittel",
        "Bathroom":"Badezimmer",
        "In the house":"Im Haus",
        "Tools":"Werkzeuge",
        "Shopping":"Einkaufen",
        "School Subjects":"Schulfächer",
        "Traveling":"Reisen",
        "Places":"Orte",
        "Musical Instruments":"Musikinstrumente",
        "Body Parts":"Körperteile",
        "Health":"Gesundheit",
        "Pronouns":"Pronomen",
        "Possessions":"Besitztümer",
        "Professions":"Berufe",
        "Emotions":"Emotionen",
        "Verbs":"Verben",
        "Daily Activities":"Alltägliche Aktivitäten",
        "House Chores":"Hausarbeit",
        "Sports":"Sportarten",
        "Hobbies":"Hobbies",
        "Months":"Monate",
        "Seasons":"Jahreszeiten",
        "Days":"Tage",
        "Time":"Zeit",
        "House Items":"",
        "words Song":"",
        "English":"Englisch",
        "English Alphabet":"",
        "Months Days":"",
        "Days Time":"",
        "Alphabet Video":"",
        "Alphabet song":"",
        "Alphabet Worksheet":"",
        "Alphabet Book":"",
        "Shapes Flashcards":"",
        "Weather Flashcards":"",
        "Possessions Flashcards":"",
        "Months Days Flashcards":"",
        "English Phonics": "",
        "Time Flashcards": "",
        "Hello!":"Hallo!",
        "What Is Your Name?":"Wie ist dein Name?",
        "How Old Are You?":"Wie alt bist du?",
        "Where Are You From?":"Wo kommst du her?",
        "What Is That?":"Was ist das?",
        "Where Is Dobby?":"Wo ist Dobby?",
        "My Day":"Mein Tag",
        "Family Members":"Familienmitglieder",
        "What Are You Doing?":"Was machst du gerade?",
        "Who Are You?":"Wer bist du?",
        "I Want Strawberry Ice Cream!":"Ich möchte Erdbeereis!",
        "What Can You Do?":"Was kannst du tun?",
        "Terry and Rexy - Numbers":"Terry und Rexy - Zahlen",
        "Terry and Rexy - Animals":"Terry und Rexy - Tiere",
        "Terry and Rexy - Colors":"Terry und Rexy - Farben",
        "Terry and Rexy - Food":"Terry und Rexy - Essen",
        "Terry and Rexy - Verbs":"Terry und Rexy - Verben",
        "Terry and Rexy - Body Parts":"Terry und Rexy - Körperteile",
        "Terry and Rexy - Clothes":"Terry und Rexy - Kleidungsstücke",
        "Terry and Rexy - Vehicles":"Terry und Rexy - Fahrzeuge",
        "Terry and Rexy - Shapes":"Terry und Rexy - Formen",
        "Terry and Rexy - General Phrases":"Terry und Rexy - Allgemeine Redewendungen",
        "The Lion and Mouse":"Der Löwe und die Maus",
        "The Ant and Grasshopper":"Die Ameise und der Grashüpfer",
        "The Tortoise and Hare":"Die Schildkröte und der Hase",
        "The Dog and His Reflection":"Der Hund und sein Spiegelbild",
        "The Peacock and Crane":"Der Pfau und der Kranich",
        "The Goose with Golden Eggs":"Die Gans mit den goldenen Eiern",
        "The Bear Teaches the Fox":"Der Bär unterrichtet den Fuchs",
        "The Crow and Fox":"Die Krähe und der Fuchs",
        "The Stork and Fox":"Der Storch und der Fuchs",
        "The Lion, Bear and Fox":"Der Löwe, der Bär und der Fuchs",
        "The Fox and the Grapes":"Der Fuchs und die Weintrauben",
        "The Fox and Hedgehog":"Der Fuchs und der Igel",
        "The Boy Who Cried Wolf":"Der Junge, der den Wolf rief",
        "The Bear and Two Travelers":"Der Bär und die zwei Reisenden",
        "The Three Bears":"Die drei Bären",
        "The Hare and Lion":"Der Hase und der Löwe",
        "The Sheep, Lamb, Wolf and Rabbit":"Das Schaf, das Lamm, der Wolf und der Hase",
        "Beauty and the Beast":"Die Schöne und das Biest",
        "The Impatient Farmer":"Der ungeduldige Bauer",
        "The Bear Prince":"Der Bärenprinz",
        "The Monkeys and Dragonflies":"Die Affen und die Libellen",
        "The Nightingale":"Die Nachtigall",
        "The Scared Rabbit":"Der Angsthase",
        "The Giant Turnip":"Die riesige Rübe",
        "The Story of Saci":"Die Geschichte von Saci",
        "The Man Who Spoke to Animals":"Der Mann, der mit Tieren sprach",
        "Red Riding Hood":"Rotkäppchen",
        "Jack and the Beanstalk":"Hans und die Bohnenranke",
        "Hansel and Gretel":"Hänsel und Gretel",
        "Cinderella":"Cinderella",
        "Ali Baba and the 40 Thieves":"Ali Baba und die 40 Räuber",
        "Snow White":"Schneewittchen",
        "Pinocchio":"Pinocchio",
        "Aladdin":"Aladdin",
        "Peter Pan":"Peter Pan",
        "Rapunzel":"Rapunzel",
        "Gulliver's Adventures":"Gullivers Reisen",
        "Around the World":"Rund um die Welt",
        "Funny Questions":"Lustige Fragen",
        "Crazy Animals":"Verrückte Tiere",
        "The Babylonian Tree":"Der babylonische Baum",
        "The Princess and the Pea":"Die Prinzessin auf der Erbse",
        "The King's New Clothes":"Des Königs neue Kleider",
        "The Muscians of Bremen":"Die Bremer Stadtmusikanten",
        "The Runaway Pancake":"Der entlaufene Pfannkuchen",
        "Cattarinetta":"Cattarinetta",
        "The Fastest Toy":"Das schnellste Spielzeug",
    },
    fr: {
        "Lesson": "Leçon",
        "Game": "Jeux",
        "Test": "Test",
        "Flashcards": "Flashcards",
        "Worksheet": "Cahiers d'exercices",
        "Song": "Chanson",
        "Book": "Livre",
        "Poster": "Affiche",
        "Vocabulary": "Vocabulaire",
        "Activity":"Activité",
        "Video":"Vidéo",
        "poster":"Affiche",
        "Animals":"Animaux",
        "Numbers":"Nombres",
        "Colors":"Couleurs",
        "Food":"Aliments",
        "Fruit and Vegetables":"Fruits et légumes",
        "In the House":"Dans la maison",
        "Nature":"Nature",
        "Actions":"Actions",
        "Family":"Famille",
        "Vehicles":"Véhicules",
        "Body":"Corps",
        "Clothes":"Vêtements",
        "Words":"mots",
        "Alphabet":"Alphabet",
        "Phonics":"Phonétique",
        "Baby Animals":"Bébés animaux",
        "Shapes":"Formes",
        "Veggies":"Légumes",
        "Fruit":"Fruits",
        "Nuts":"Fruits à coque",
        "Weather":"Temps",
        "Adjectives":"Adjectifs",
        "Comparisons":"Comparaisons",
        "Kitchen":"Cuisine",
        "Groceries":"Épiceries",
        "Bathroom":"Salle de bain",
        "In the house":"Dans la maison",
        "Tools":"Outils",
        "Shopping":"Shopping",
        "School Subjects":"Personnages de l'école",
        "Traveling":"Voyage",
        "Places":"Endroits",
        "Musical Instruments":"Instruments musicaux",
        "Body Parts":"Parties du corps",
        "Health":"Santé",
        "Pronouns":"Pronoms",
        "Possessions":"Possessions",
        "Professions":"Professions",
        "Emotions":"Émotions",
        "Verbs":"Verbes",
        "Daily Activities":"Activités quotidiennes",
        "House Chores":"Tâches ménagères",
        "Sports":"Sports",
        "Hobbies":"Loisirs",
        "Months":"Mois",
        "Seasons":"Saisons",
        "Days":"Jours",
        "Time":"Heure",
        "House Items":"Choses de la maison",
        "words Song":"Chanson avec mots",
        "English":"l'Anglais",
        "English Alphabet":"Alphabet anglais",
        "Months Days":"Jour Mois",
        "Days Time":"Jours heures",
        "Alphabet Video":"Vidéo sur l'alphabet",
        "Alphabet song":"Chanson sur l'alphabet",
        "Alphabet Worksheet":"Cahier d'exercices sur l'alphabet",
        "Alphabet Book":"Livre sur l'alphabet",
        "Shapes Flashcards":"Flashcards de formes",
        "Weather Flashcards":"Flascards du temps",
        "Possessions Flashcards":"Flashcards des possessions",
        "Months Days Flashcards":  "Flashcards  Mois Jours", 
        "English Phonics": "Phonétique anglaise",
        "Time Flashcards": "Flashcards des heures",
        "Hello!":"Bonjour !",
        "What Is Your Name?":"Comment t'appelles-tu ?",
        "How Old Are You?":"Quel âge as tu ?",
        "Where Are You From?":"D'où es tu ?",
        "What is that?":"Qu'est-ce que c'est que ça?",
        "Where Is Dobby?":"Où est Dobby ?",
        "My Day":"Mon jour",
        "Family Members":"Membres de la famille",
        "What Are You Doing?":"Que faites-vous ?",
        "Who Are You?":"Qui es tu ?",
        "I Want Strawberry Ice Cream!":"Je veux une glace à la fraise !",
        "What Can You Do?":"Que puis-je faire ?",
        "Terry and Rexy - Numbers":"Terry et Rexy - Nombres",
        "Terry and Rexy - Animals":"Terry et Rexy - Animaux",
        "Terry and Rexy - Colors":"Terry et Rexy - Couleurs",
        "Terry and Rexy - Food":"Terry et Rexy - Aliments",
        "Terry and Rexy - Verbs":"Terry et Rexy - Verbes",
        "Terry and Rexy - Body Parts":"Terry et Rexy - Parties du corps",
        "Terry and Rexy - Clothes":"Terry et Rexy - Vêtements",
        "Terry and Rexy - Vehicles":"Terry et Rexy - Véhicules",
        "Terry and Rexy - Shapes":"Terry et Rexy - Formes",
        "Terry and Rexy - General Phrases":"Terry et Rexy - Phrases générales",
        "The Lion and Mouse":"Le lion et la souris",
        "The Ant and Grasshopper":"La fourmi et la sauterelle",
        "The Tortoise and Hare":"La tortue et le lièvre",
        "The Dog and His Reflection":"Le chien et son reflet",
        "The Peacock and Crane":"Le paon et la cigogne",
        "The Goose with Golden Eggs":"L'oie aux oeufs d'or",
        "The Bear Teaches the Fox":"L'ours apprend au renard",
        "The Crow and Fox":"Le corbeau et le renard",
        "The Stork and Fox":"La cigogne et le renard",
        "The Lion, Bear and Fox":"Le lion, l'ours et le renard",
        "The Fox and the Grapes":"Le renard et les raisins",
        "The Fox and Hedgehog":"Le renard et l'hérisson",
        "The Boy Who Cried Wolf":"Le garçon qui criait au loup",
        "The Bear and Two Travelers":"L'ours et les deux voyageurs",
        "The Three Bears":"Les trois ours",
        "The Hare and Lion":"Le Lièvre et le Lion",
        "The Sheep, Lamb, Wolf and Rabbit":"Le mouton, l'agneau, le loup et le lapin",
        "Beauty and the Beast":"La belle et la bête",
        "The Impatient Farmer":"Le paysan impatient",
        "The Bear Prince":"Le prince des ours",
        "The Monkeys and Dragonflies":"Les singes et les libellules",
        "The Nightingale":"Le rossignol",
        "The Scared Rabbit":"Le lapin effrayé",
        "The Giant Turnip":"Le navet géant",
        "The Story of Saci":"L'histoire de Saci",
        "The Man Who Spoke to Animals":"L'homme qui parlait aux animaux",
        "Red Riding Hood":"Le petit Chaperon rouge",
        "Jack and the Beanstalk":"Jack et le haricot magique",
        "Hansel and Gretel":"Hansel et Gretel",
        "Cinderella":"Cinderella",
        "Ali Baba and the 40 Thieves":"Alibaba et les 40 voleurs",
        "Snow White":"Blanc comme neige",
        "Pinocchio":"Pinocchio",
        "Aladdin":"Aladdin et le Roi des voleurs",
        "Peter Pan":"Peter Pan",
        "Rapunzel":"Rapunzel",
        "Gulliver's Adventures":"Les Aventures de Gulliver",
        "Around the World":"Aux quatre coins du monde",
        "Funny Questions":"Questions drôles",
        "Crazy Animals":"Animaux fous",
        "The Babylonian Tree":"L'arbre babylonien",
        "The Princess and the Pea":"La princesse et le petit pois",
        "The King's New Clothes":"Les nouveaux habits du Roi",
        "The Muscians of Bremen":"Les musiciens de Bremen",
        "The Runaway Pancake":"La crêpe fugitive",
        "Cattarinetta":"Cattarinetta",
        "The Fastest Toy":"Le jouet les plus rapide",
    },
    ar: {
        "Lesson": "درس",
        "Game": "لعبة",
        "Test": "اختبار",
        "Flashcards": "بطاقات تعليمية",
        "Worksheet": "ورقة عمل",
        "Song": "أغنية",
        "Book": "كتاب",
        "Poster": "ملصق",
        "Vocabulary": "مفردة لغوية",
        "Activity":"نشاط",
        "Video":"فيديو",
        "poster":"ملصق",
        "Animals":"الحيوانات",
        "Numbers":"الأعداد",
        "Colors":"الألوان",
        "Food":"الطعام",
        "Fruit and Vegetables":"الفاكهة والخضراوات",
        "In the House":"في المنزل",
        "Nature":"الطبيعة",
        "Actions":"الحركات",
        "Family":"الأسرة",
        "Vehicles":"وسائل المواصلات",
        "Body":"أجزاء الجسم",
        "Clothes":"الملابس",
        "Words":"كلمة",
        "Alphabet":"الحروف",
        "Phonics":"الصوتيات",
        "Baby Animals":"صغار الحيوانات",
        "Shapes":"الأشكال",
        "Veggies":"الخضراوات",
        "Fruit":"الفاكهة",
        "Nuts":"المكسرات",
        "Weather":"الطقس",
        "Adjectives":"الصفات",
        "Comparisons":"المقارنات",
        "Kitchen":"المطبخ",
        "Groceries":"البقالة",
        "Bathroom":"دورة المياه",
        "In the house":"في المنزل",
        "Tools":"الأدوات",
        "Shopping":"التسوق",
        "School Subjects":"المواد الدراسية",
        "Traveling":"السفر",
        "Places":"الأماكن",
        "Musical Instruments":"الآلات الموسيقية",
        "Body Parts":"أجزاء الجسم",
        "Health":"الصحة",
        "Pronouns":"الضمائر",
        "Possessions":"الملكية",
        "Professions":"المهن",
        "Emotions":"المشاعر",
        "Verbs":"الأفعال",
        "Daily Activities":"الأنشطة اليومية",
        "House Chores":"الأعمال المنزلية",
        "Sports":"الرياضة",
        "Hobbies":"الهوايات",
        "Months":"الشهور",
        "Seasons":"فصول السنة",
        "Days":"الأيام",
        "Time":"الوقت",
        "House Items":"أشياء المنزل",
        "words Song":"أغنية كلمات",
        "English":"الإنجليزية",
        "English Alphabet":"الحروف الإنجليزية",
        "Months Days":"الشهور الأيام",
        "Days Time":"الأيام الوقت",
        "Alphabet Video":"فيديو الحروف",
        "Alphabet song":"أغنية الحروف",
        "Alphabet Worksheet":"ورقة عمل الحروف",
        "Alphabet Book":"كتاب الحروف",
        "Shapes Flashcards":"البطاقات التعليمية للأشكال",
        "Weather Flashcards":"البطاقات التعليمية للطقس",
        "Possessions Flashcards":"البطاقات التعليمية للملكية",
        "Months Days Flashcards":"البطاقات التعليمية للشهور والأيام",
        "English Phonics": "صوتيات اللغة الإنجليزية",
        "Time Flashcards": "البطاقات التعليمية للوقت",
        "Hello!":"مرحبًا!",
        "What Is Your Name?":"ما اسمك؟",
        "How Old Are You?":"كم عمرك؟",
        "Where Are You From?":"من أين أنت؟",
        "What Is That?":"ما هذا؟",
        "Where Is Dobby?":"أين دوبي؟",
        "My Day":"يومي",
        "Family Members":"أفراد الأسرة",
        "What Are You Doing?":"ماذا تفعل؟",
        "Who Are You?":"من أنت؟",
        "I Want Strawberry Ice Cream!":"أنا أريد آيس كريم الفراولة!",
        "What Can You Do?":"ما الذي تستطيع القيام به؟",
        "Terry and Rexy - Numbers":"تيري وريكسي - الأعداد",
        "Terry and Rexy - Animals":"تيري وريكسي - الحيوانات",
        "Terry and Rexy - Colors":"تيري وريكسي - الألوان",
        "Terry and Rexy - Food":"تيري وريكسي - الطعام",
        "Terry and Rexy - Verbs":"تيري وريكسي - الأفعال",
        "Terry and Rexy - Body Parts":"تيري وريكسي - أجزاء الجسم",
        "Terry and Rexy - Clothes":"تيري وريكسي - الملابس",
        "Terry and Rexy - Vehicles":"تيري وريكسي - وسائل المواصلات",
        "Terry and Rexy - Shapes":"تيري وريكسي - الأشكال",
        "Terry and Rexy - General Phrases":"تيري وريكسي - عبارات عامة",
        "The Lion and Mouse":"الأسد والفأر",
        "The Ant and Grasshopper":"النملة والجندب",
        "The Tortoise and Hare":"السلحفاة والأرنب",
        "The Dog and His Reflection":"الكلب وصورته",
        "The Peacock and Crane":"الطاووس واللقلق",
        "The Goose with Golden Eggs":"الإوزة التي تبيض ذهبًا",
        "The Bear Teaches the Fox":"الدب يُعلِّم الثعلب",
        "The Crow and Fox":"الغراب والثعلب",
        "The Stork and Fox":"اللقلق والثعلب",
        "The Lion, Bear and Fox":"الأسد والدب والثعلب",
        "The Fox and the Grapes":"الثعلب والعنب",
        "The Fox and Hedgehog":"الثعلب والقنفذ",
        "The Boy Who Cried Wolf":"الفتى الذي ادَّعى وجود ذئب",
        "The Bear and Two Travelers":"الدب والمسافران",
        "The Three Bears":"الدببة الثلاثة",
        "The Hare and Lion":"الأرنب والأسد",
        "The Sheep, Lamb, Wolf and Rabbit":"الخروف والحمل والذئب والأرنب",
        "Beauty and the Beast":"الجميلة والوحش",
        "The Impatient Farmer":"المزارع المتعجل",
        "The Bear Prince":"الأمير الدب",
        "The Monkeys and Dragonflies":"القرد واليعسوب",
        "The Nightingale":"العندليب",
        "The Scared Rabbit":"الأرنب المذعور",
        "The Giant Turnip":"اللفت العملاق",
        "The Story of Saci":"قصة ساسي",
        "The Man Who Spoke to Animals":"الرجل الذي تحدث مع الحيوانات",
        "Red Riding Hood":"ذات الرداء الأحمر",
        "Jack and the Beanstalk":"جاك وشجرة الفاصولياء",
        "Hansel and Gretel":"هانسيل وجريتل",
        "Cinderella":"سندريلا",
        "Ali Baba and the 40 Thieves":"علي بابا والأربعون لصًا",
        "Snow White":"بياض الثلج",
        "Pinocchio":"بينوكيو",
        "Aladdin":"علاء الدين",
        "Peter Pan":"بيتر بان",
        "Rapunzel":"رابونزل",
        "Gulliver's Adventures":"مغامرات جاليفر",
        "Around the World":"حول العالم",
        "Funny Questions":"أسئلة مضحكة",
        "Crazy Animals":"حيوانات مسلية",
        "The Babylonian Tree":"الشجرة البابلية",
        "The Princess and the Pea":"الأميرة وحبة البازلاء",
        "The King's New Clothes":"ملابس الملك الجديدة",
        "The Muscians of Bremen":"عازفو بريمن",
        "The Runaway Pancake":"الفطيرة الهاربة",
        "Cattarinetta":"كاتارينيتا",
        "The Fastest Toy":"أسرع لعبة",
    },
    hi:{
        "Lesson": "लैसन",
        "Game": "गेम",
        "Test": "टेस्ट",
        "Flashcards": "फ्लैशकार्ड्स",
        "Worksheet": "वर्कशीट",
        "Song": "गाना",
        "Book": "किताब",
        "Poster": "पोस्टर",
        "Vocabulary": "शब्दावली",
        "Activity": "गतिविधि",
        "Video": "वीडियो",
        "poster":"पोस्टर",
        "Animals":"पशु",
        "Numbers":"नंबर",
        "Colors":"रंग",
        "Food":"भोजन",
        "Fruit and Vegetables":"फल और सब्जियां",
        "In the House":"घर में",
        "Nature":"प्रकृति",
        "Actions":"क्रिया",
        "Family":"परिवार",
        "Vehicles":"वाहन",
        "Body":"शरीर",
        "Clothes":"कपड़े",
        "Words":"शब्द",
        "Alphabet":"वर्णमाला",
        "Phonics":"ध्वन्यात्मकता",
        "Baby Animals":"बेबी पशु",
        "Shapes":"आकार",
        "Veggies":"सब्जियां",
        "Fruit":"फल",
        "Nuts":"मेवे",
        "Weather":"मौसम",
        "Adjectives":"विशेषण",
        "Comparisons":"तुलना",
        "Kitchen":"रसोई",
        "Groceries":"किराने का सामान ",
        "Bathroom":"स्नानघर",
        "In the house":"घर में",
        "Tools":"उपकरण",
        "Shopping":"खरीदारी",
        "School Subjects":"स्कूल विषय",
        "Traveling":"यात्रा",
        "Places":"स्थान",
        "Musical Instruments":"संगीत वाद्ययंत्र",
        "Body Parts":"शरीर के अंग",
        "Health":"स्वास्थ्य",
        "Pronouns":"सर्वनाम",
        "Possessions":"संपत्ति",
        "Professions":"पेशे",
        "Emotions":"भावनाएं",
        "Verbs":"क्रिया",
        "Daily Activities":"दैनिक गतिविधियां ",
        "House Chores":"घर के काम",
        "Sports":"खेल",
        "Hobbies":"शौक",
        "Months":"महीने",
        "Seasons":"मौसम",
        "Days":"दिन",
        "Time":"समय",
        "House items": "हाउस आइटम्स",
        "words Song": "शब्द गीत",
        "English":"अंग्रेजी ",
        "English Alphabet": "अंग्रेजी की वर्णमाला",
        "Months Days": "महीने का दिन",
        "Days Time": "दिन का समय",
        "Alphabet Video": "वर्णमाला वीडियो",
        "Alphabet song": "वर्णमाला गीत",
        "Alphabet Worksheet": "वर्णमाला वर्कशीट",
        "Alphabet Book": "वर्णमाला पुस्तक",
        "Shapes Flashcards": "आकृतियाँ फ़्लैशकार्ड",
        "Weather Flashcards": "मौसम फ्लैशकार्ड",
        "Possessions Flashcards": "संपत्ति फ्लैशकार्ड",
        "Months Days Flashcards": "महीने दिन फ्लैशकार्ड",
        "English Phonics": "अंग्रेजी ध्वन्यात्मकता",
        "Time Flashcards":  "टाइम फ्लैशकार्ड",
        "Hello!":"नमस्ते!",
        "What Is Your Name?":"तुम्हारा नाम क्या हे?",
        "How Old Are You?":"आपकी आयु कितनी है?",
        "Where Are You From?":"आप कहाँ से हैं?",
        "What Is That?":"वह क्या है?",
        "Where Is Dobby?":"डॉबी कहाँ है?",
        "My Day":"मेरा दिन",
        "Family Members":"परिवार के सदस्य",
        "What Are You Doing?":"तुम क्या कर रहे हो?",
        "Who Are You?":"आप कौन हैं?",
        "I Want Strawberry Ice Cream!":"मुझे स्ट्रॉबेरी आइसक्रीम चाहिए!",
        "What Can You Do?":"आप क्या कर सकते हैं?",
        "Terry and Rexy - Numbers":"टेरी और रेक्सी - नंबर",
        "Terry and Rexy - Animals":"टेरी और रेक्सी - पशु",
        "Terry and Rexy - Colors":"टेरी और रेक्सी - कलर्स",
        "Terry and Rexy - Food":"टेरी और रेक्सी - भोजन",
        "Terry and Rexy - Verbs":"टेरी और रेक्सी - क्रिया",
        "Terry and Rexy - Body Parts":"टेरी और रेक्सी - शरीर के अंग",
        "Terry and Rexy - Clothes":"टेरी और रेक्सी - कपड़े",
        "Terry and Rexy - Vehicles":"टेरी और रेक्सी - वाहन",
        "Terry and Rexy - Shapes":"टेरी और रेक्सी - आकार",
        "Terry and Rexy - General Phrases":"टेरी और रेक्सी - सामान्य वाक्यांश",
        "The Lion and Mouse":"शेर और चूहा ",
        "The Ant and Grasshopper":"चींटी और टिड्डा",
        "The Tortoise and Hare":"कछुआ और खरगोश",
        "The Dog and His Reflection":"कुत्ता और उसका प्रतिबिंब",
        "The Peacock and Crane":"मोर और क्रेन",
        "The Goose with Golden Eggs":"सुनहरे अंडे के साथ हंस",
        "The Bear Teaches the Fox":"भालू लोमड़ी सिखाता है",
        "The Crow and Fox":"कौवा और लोमड़ी",
        "The Stork and Fox":"सारस और फॉक्स",
        "The Lion, Bear and Fox":"शेर, भालू और लोमड़ी",
        "The Fox and the Grapes":"लोमड़ी और अंगूर",
        "The Fox and Hedgehog":"लोमड़ी और हेजहोग",
        "The Boy Who Cried Wolf":"लड़का है जो भेड़िया सा रोया",
        "The Bear and Two Travelers":"भालू और दो यात्री",
        "The Three Bears":"तीन भालू",
        "The Hare and Lion":"हेयर और शेर",
        "The Sheep, Lamb, Wolf and Rabbit":"भेड़, भेड़ का बच्चा, भेड़िया और खरगोश",
        "Beauty and the Beast":"ब्यूटी एंड द बीस्ट",
        "The Impatient Farmer":"अधीर किसान",
        "The Bear Prince":"भालू राजकुमार",
        "The Monkeys and Dragonflies":"बंदर और ड्रैगनफलीज़",
        "The Nightingale":"बुलबुल",
        "The Scared Rabbit":"डरा हुआ खरगोश",
        "The Giant Turnip":"विशालकाय शलजम",
        "The Story of Saci":"Saci . की कहानी",
        "The Man Who Spoke to Animals":"वह आदमी जिसने जानवरों से बात की",
        "Red Riding Hood":"रेड राइडिंग हुड",
        "Jack and the Beanstalk":"जैक और शैतान का खज़ाना",
        "Hansel and Gretel":"हँसेल और ग्रेटल",
        "Cinderella":"सिंडरेला",
        "Ali Baba and the 40 Thieves":"अली बाबा और 40 चोर",
        "Snow White":"स्नो व्हाइट",
        "Pinocchio":"पिनोच्चियो",
        "Aladdin":"अलादीन",
        "Peter Pan":"पीटर पैन",
        "Rapunzel":"रॅपन्ज़ेल",
        "Gulliver's Adventures":"गुलिवर्स एडवेंचर्स",
        "Around the World":"अराउंड द वर्ल्ड ",
        "Funny Questions":"मजेदार सवाल",
        "Crazy Animals":"पागल जानवर",
        "The Babylonian Tree":"बेबीलोन का पेड़",
        "The Princess and the Pea":"राजकुमारी और मटर",
        "The King's New Clothes":"राजा के नए कपड़े",
        "The Muscians of Bremen":"ब्रेमेन के संगीतकार",
        "The Runaway Pancake":"भगोड़ा पैनकेक",
        "Cattarinetta":"कैटरिनेटा",
        "The Fastest Toy":"सबसे तेज़ खिलौना",
      },
    pt:{
        "Lesson": "Aula",
        "Game": "Jogo",
        "Test": "Teste",
        "Flashcards": "Cartões de Memória",
        "Worksheet": "Folha de Exercícios",
        "Song": "Canção",
        "Book": "Livro",
        "Poster": "Pôster",
        "Vocabulary": "Vocabulário",
        "Activity": "Atividade",
        "Video": "Vídeo",
        "poster":"pôster",
        "Animals":"Animais",
        "Numbers":"Números",
        "Colors":"Cores",
        "Food":"Comida",
        "Fruit and Vegetables":"Frutas e Legumes",
        "In the House":"Em Casa",
        "Nature":"Natureza",
        "Actions":"Ações",
        "Family":"Família",
        "Vehicles":"Veículos",
        "Body":"Corpo",
        "Clothes":"Roupas",
        "Words":"Palavras",
        "Alphabet":"Alfabeto",
        "Phonics":"Fonética",
        "Baby Animals":"Animais Bebês",
        "Shapes":"Formas",
        "Veggies":"Vegetais",
        "Fruit":"Frutas",
        "Nuts":"Nozes e Castanhas",
        "Weather":"Clima",
        "Adjectives":"Adjetivos",
        "Comparisons":"Comparações",
        "Kitchen":"Cozinha",
        "Groceries":"Alimentos",
        "Bathroom":"Banheiro",
        "In the house":"Em Casa",
        "Tools":"Ferramentas",
        "Shopping":"Compras",
        "School Subjects":"Matérias Escolares ",
        "Traveling":"Viajando",
        "Places":"Lugares",
        "Musical Instruments":"Instrumentos Musicais",
        "Body Parts":"Partes do Corpo",
        "Health":"Saúde",
        "Pronouns":"Pronomes",
        "Possessions":"Posse",
        "Professions":"Profissões",
        "Emotions":"Emoções",
        "Verbs":"Verbos",
        "Daily Activities":"Atividades Diárias",
        "House Chores":"Tarefas Domésticas",
        "Sports":"Esportes",
        "Hobbies":"Passatempos",
        "Months":"Meses",
        "Seasons":"Estações",
        "Days":"Dias",
        "Time":"Tempo",
        "House Items":"Itens da casa",
        "words Song":"Canção das palavras",
        "English":"Inglês",
         "English Alphabet": "Alfabeto em Inglês",
        "Months Days": "Meses Dias",
        "Days Time": "Dias Hora",
        "Alphabet Video": "Vídeo do Alfabeto",
        "Alphabet song": "Canção do alfabeto",
        "Alphabet Worksheet": "Folha de Exercícios do Alfabeto",
        "Alphabet Book": "Livro do Alfabeto",
        "Shapes Flashcards": "Cartões de Memória das Formas",
        "Weather Flashcards": "Cartões de Memória do Clima",
        "Possessions Flashcards": "Cartões de Memória de Posse",
        "Months Days Flashcards": "Cartões de Memória dos Meses e Dias",
        "English Phonics": "Fonética Inglesa",
        "Time Flashcards":  "Cartões de Memória do Tempo",
        "Hello!":"Olá!",
        "What Is Your Name?":"Qual é o Seu Nome?",
        "How Old Are You?":"Quantos Anos Você Tem?",
        "Where Are You From?":"De Onde Você É?",
        "What Is That?":"O Que é Aquilo?",
        "Where Is Dobby?":"Onde está Dobby?",
        "My Day":"Meu Dia",
        "Family Members":"Membros da Família",
        "What Are You Doing?":"O Que Você Está Fazendo?",
        "Who Are You?":"Quem é Você?",
        "I Want Strawberry Ice Cream!":"Eu Quero Sorvete de Morango!",
        "What Can You Do?":"O Que Você Pode Fazer?",
        "Terry and Rexy - Numbers":"Terry e Rexy - Números",
        "Terry and Rexy - Animals":"Terry e Rexy - Animais",
        "Terry and Rexy - Colors":"Terry e Rexy - Cores",
        "Terry and Rexy - Food":"Terry e Rexy - Comida",
        "Terry and Rexy - Verbs":"Terry e Rexy - Verbos",
        "Terry and Rexy - Body Parts":"Terry e Rexy - Partes do Corpo",
        "Terry and Rexy - Clothes":"Terry e Rexy - Roupas",
        "Terry and Rexy - Vehicles":"Terry e Rexy - Veículos",
        "Terry and Rexy - Shapes":"Terry e Rexy - Formas",
        "Terry and Rexy - General Phrases":"Terry e Rexy - Frases Gerais",
        "The Lion and Mouse":"O Leão e o Rato",
        "The Ant and Grasshopper":"A Formiga e o Gafanhoto",
        "The Tortoise and Hare":"A Tartaruga e a Lebre",
        "The Dog and His Reflection":"O Cachorro e seu Reflexo",
        "The Peacock and Crane":"O Pavão e a Garça",
        "The Goose with Golden Eggs":"A Gansa dos Ovos de Ouro",
        "The Bear Teaches the Fox":"O Urso Ensina a Raposa",
        "The Crow and Fox":"O Corvo e a Raposa",
        "The Stork and Fox":"A Cegonha e a Raposa",
        "The Lion, Bear and Fox":"O Leão, o Urso e a Raposa",
        "The Fox and the Grapes":"A Raposa e as Uvas",
        "The Fox and Hedgehog":"A Raposa e o Porco-Espinho",
        "The Boy Who Cried Wolf":"O Menino Mentiroso",
        "The Bear and Two Travelers":"O Urso e os Dois Viajantes",
        "The Three Bears":"Os Três Ursos",
        "The Hare and Lion":"A Lebre e o Leão",
        "The Sheep, Lamb, Wolf and Rabbit":"A Ovelha, o Cordeiro, o Lobo e o Coelho",
        "Beauty and the Beast":"A Bela e a Fera",
        "The Impatient Farmer":"O Fazendeiro Impaciente",
        "The Bear Prince":"O Príncipe Urso",
        "The Monkeys and Dragonflies":"Os Macacos e as Libélulas",
        "The Nightingale":"O Rouxinol",
        "The Scared Rabbit":"O Coelho Assustado",
        "The Giant Turnip":"O Nabo Gigante",
        "The Story of Saci":"A História do Saci",
        "The Man Who Spoke to Animals":"O Homem que Falava com os Animais",
        "Red Riding Hood":"Chapeuzinho Vermelho",
        "Jack and the Beanstalk":"João e o Pé de Feijão",
        "Hansel and Gretel":"João e Maria",
        "Cinderella":"Cinderela",
        "Ali Baba and the 40 Thieves":"Ali Babá e os 40 Ladrões",
        "Snow White":"Branca de Neve",
        "Pinocchio":"Pinóquio",
        "Aladdin":"Aladim",
        "Peter Pan":"Peter Pan",
        "Rapunzel":"Rapunzel",
        "Gulliver's Adventures":"As Aventuras de Gulliver",
        "Around the World":"Ao Redor do Mundo",
        "Funny Questions":"Perguntas Divertidas",
        "Crazy Animals":"Animais Engraçados",
        "The Babylonian Tree":"A Árvore da Babilônia",
        "The Princess and the Pea":"A Princesa e a Ervilha",
        "The King's New Clothes":"A Nova Roupa do Rei",
        "The Muscians of Bremen":"Os Músicos de Bremen",
        "The Runaway Pancake":"A Panqueca Fugitiva",
        "Cattarinetta":"Catarineta",
        "The Fastest Toy":"O Brinquedo Mais Rápido",
      },
    pl:{
        "Lesson": "Lekcja",
        "Game": "Gra",
        "Test": "Test",
        "Flashcards": "Fiszki",
        "Worksheet": "Arkusz ćwiczeń",
        "Song": "Piosenka",
        "Book": "Książka",
        "Poster": "Plakat",
        "Vocabulary": "Słownictwo",
        "Activity": "Zadanie",
        "Video": "Wideo",
        "poster":"plakat",
        "Animals":"Zwierzęta",
        "Numbers":"Liczby",
        "Colors":"Kolory",
        "Food":"Jedzenie",
        "Fruit and Vegetables":"Owoce i warzywa",
        "In the House":"W domu",
        "Nature":"Natura",
        "Actions":"Działania",
        "Family":"Rodzina",
        "Vehicles":"Pojazdy",
        "Body":"Ciało",
        "Clothes":"Ubrania",
        "Words":"słów",
        "Alphabet":"Alfabet",
        "Phonics":"Wymowa",
        "Baby Animals":"Młode zwierzęta",
        "Shapes":"Kształty",
        "Veggies":"Warzywa",
        "Fruit":"Owoce",
        "Nuts":"Orzechy",
        "Weather":"Pogoda",
        "Adjectives":"Przymiotniki",
        "Comparisons":"Porównania",
        "Kitchen":"Kuchnia",
        "Groceries":"Artykuły spożywcze",
        "Bathroom":"Łazienka",
        "In the house":"W domu",
        "Tools":"Narzędzia",
        "Shopping":"Zakupy",
        "School Subjects":"Przedmioty szkolne",
        "Traveling":"Podróżowanie",
        "Places":"Miejsca",
        "Musical Instruments":"Instrumenty muzyczne",
        "Body Parts":"Części ciała",
        "Health":"Zdrowie",
        "Pronouns":"Zaimki",
        "Possessions":"Własność",
        "Professions":"Zawody",
        "Emotions":"Emocje",
        "Verbs":"Czasowniki",
        "Daily Activities":"Codzienne czynności",
        "House Chores":"Obowiązki domowe",
        "Sports":"Sport",
        "Hobbies":"Hobby",
        "Months":"Miesiące",
        "Seasons":"Pory roku",
        "Days":"Dni",
        "Time":"Czas",
        "House items": "Artykuły domowe",
        "words Song": "słów Piosenka",
        "English":"English",
        "English Alphabet": "Angielski alfabet",
        "Months Days":"Miesiące Dni",
        "Days Time": "Dni Czas",
        "Alphabet Video": "Wideo o alfabecie",
        "Alphabet song": "Piosenka o alfabecie",
        "Alphabet Worksheet": "Arkusz ćwiczeń do alfabetu",
        "Alphabet Book": "Elementarz",
        "Shapes Flashcards": "Fiszki Kształty",
        "Weather Flashcards": "Fiszki Pogoda",
        "Possessions Flashcards": "Fiszki Posiadane przedmioty",
        "Months Days Flashcards": "Fiszki Miesiące Dni",
        "English Phonics": "Wymowa angielska",
        "Time Flashcards ": "Fiszki Czas",
        "Hello!":"Witam!",
        "What Is Your Name?":"Jak masz na imię?",
        "How Old Are You?":"Ile ty masz lat?",
        "Where Are You From?":"Skąd jesteś?",
        "What Is That?":"Co to jest?",
        "Where Is Dobby?":"Gdzie jest Dobby?",
        "My Day":"Mój dzień",
        "Family Members":"Członkowie rodziny",
        "What Are You Doing?":"Co robisz?",
        "Who Are You?":"Kim jesteś?",
        "I Want Strawberry Ice Cream!":"Chcę truskawkowe lody!",
        "What Can You Do?":"Co możesz zrobić?",
        "Terry and Rexy - Numbers":"Terry i Rexy - Liczby",
        "Terry and Rexy - Animals":"Terry i Rexy - Zwierzęta",
        "Terry and Rexy - Colors":"Terry i Rexy - Kolory",
        "Terry and Rexy - Food":"Terry i Rexy - Jedzenie",
        "Terry and Rexy - Verbs":"Terry i Rexy - Czasowniki",
        "Terry and Rexy - Body Parts":"Terry i Rexy - Części ciała",
        "Terry and Rexy - Clothes":"Terry i Rexy - Ubrania",
        "Terry and Rexy - Vehicles":"Terry i Rexy - Pojazdy",
        "Terry and Rexy - Shapes":"Terry i Rexy - Kształty",
        "Terry and Rexy - General Phrases":"Terry i Rexy - Zwroty ogólne",
        "The Lion and Mouse":"Lew i Mysz",
        "The Ant and Grasshopper":"Mrówka i Konik polny",
        "The Tortoise and Hare":"Żółw i Zając",
        "The Dog and His Reflection":"Pies i jego odbicie",
        "The Peacock and Crane":"Paw i Żuraw",
        "The Goose with Golden Eggs":"Gęś znosząca złote jajka",
        "The Bear Teaches the Fox":"Niedźwiedź uczy lisa",
        "The Crow and Fox":"Wrona i Lis",
        "The Stork and Fox":"Bocian i Lis",
        "The Lion, Bear and Fox":"Lew, Niedźwiedź i Lis",
        "The Fox and the Grapes":"Lis i Winogrona",
        "The Fox and Hedgehog":"Lis i Jeż",
        "The Boy Who Cried Wolf":"Chłopiec, który wołał o pomoc",
        "The Bear and Two Travelers":"Niedźwiedź i dwóch podróżników",
        "The Three Bears":"Trzy Niedźwiadki",
        "The Hare and Lion":"Zając i Lew",
        "The Sheep, Lamb, Wolf and Rabbit":"Owca, Baranek, Wilk i Królik",
        "Beauty and the Beast":"Piękna i Bestia",
        "The Impatient Farmer":"Niecierpliwy rolnik",
        "The Bear Prince":"Niedźwiedzi książę",
        "The Monkeys and Dragonflies":"Małpy i Ważki",
        "The Nightingale":"Słowik",
        "The Scared Rabbit":"Przestraszony królik",
        "The Giant Turnip":"Wielka rzepa",
        "The Story of Saci":"Historia Saci",
        "The Man Who Spoke to Animals":"Człowiek, który rozmawiał ze zwierzętami",
        "Red Riding Hood":"Czerwony Kapturek",
        "Jack and the Beanstalk":"Jaś i magiczna fasola",
        "Hansel and Gretel":"Jaś i Małgosia",
        "Cinderella":"Kopciuszek",
        "Ali Baba and the 40 Thieves":"AliBaba i 40 rozbójników",
        "Snow White":"Królewna Śnieżka",
        "Pinocchio":"Pinokio",
        "Aladdin":"Aladyn",
        "Peter Pan":"Piotruś Pan",
        "Rapunzel":"Roszpunka",
        "Gulliver's Adventures":"Przygody Guliwera",
        "Around the World":"Dookoła świata",
        "Funny Questions":"Zabawne pytania",
        "Crazy Animals":"Szalone zwierzęta",
        "The Babylonian Tree":"Drzewo asyryjskie",
        "The Princess and the Pea":"Księżniczka na ziarnku grochu",
        "The King's New Clothes":"Nowe szaty króla",
        "The Muscians of Bremen":"Muzykanci z Bremy",
        "The Runaway Pancake":"Uciekający piernikowy ludzik",
        "Cattarinetta":"Cattarinetta",
        "The Fastest Toy":"Najszybsza zabawka",
    },
    es:{
        "Lesson": "Lección",
        "Game": "Juego",
        "Test": "Evaluación",
        "Flashcards": "Tarjetas Didácticas",
        "Worksheet": "Hoja de ejercicios",
        "Song": "Canción",
        "Book": "Libro",
        "Poster": "Póster",
        "Vocabulary": "Vocabulario",
        "Activity": "Actividad",
        "Video": "Video",
        "poster":"póster",
        "Animals":"Animales",
        "Numbers":"Números",
        "Colors":"Colores",
        "Food":"Comida",
        "Fruit and Vegetables":"Frutas y Vegetales",
        "In The House":"En la Casa",
        "Nature":"Naturaleza",
        "Actions":"Acciones",
        "Family":"Familia",
        "Vehicles":"Vehículos",
        "Body":"Cuerpo",
        "Clothes":"Ropa",
        "Words":"Palabras",
        "Alphabet":"Alfabeto",
        "Phonics":"Fonética",
        "Baby Animals":"Animales Bebés",
        "Shapes":"Formas",
        "Veggies":"Vegetales",
        "Fruit":"Fruta",
        "Nuts":"Nueces",
        "Weather":"Clima",
        "Adjectives":"Adjetivos",
        "Comparisons":"Comparaciones",
        "Kitchen":"Cocina",
        "Groceries":"Comestibles",
        "Bathroom":"Baño",
        "In the house":"En la casa",
        "Tools":"Herramientas",
        "Shopping":"De compras",
        "School Subjects":"Materias de la Escuela",
        "Traveling":"Viajes",
        "Places":"Lugares",
        "Musical Instruments":"Instrumentos Musicales",
        "Body Parts":"Partes del Cuerpo",
        "Health":"Salud",
        "Pronouns":"Pronombres",
        "Possessions":"Posesiones",
        "Professions":"Profesiones",
        "Emotions":"Emociones",
        "Verbs":"Verbos",
        "Daily Activities":"Actividades Diarias",
        "House Chores":"Tareas del Hogar",
        "Sports":"Deportes",
        "Hobbies":"Pasatiempos",
        "Months":"Meses",
        "Seasons":"Estaciones del Año",
        "Days":"Días",
        "Time":"La Hora",
        "House items": "Objetos de la Casa",
        "words Song": "Canción de palabras",
        "English":"Inglés",
        "English Alphabet": "Alfabeto en Inglés",
        "Months Days": "Meses Días",
        "Days Time": "Días Tiempo",
        "Alphabet Video": "Video del Alfabeto",
        "Alphabet song": "Canción del Alfabeto",
        "Alphabet Worksheet": "Hoja de Ejercicios del Alfabeto",
        "Alphabet Book": "Libro del Alfabeto",
        "Shapes Flashcards": "Tarjetas Didácticas de las Formas",
        "Weather Flashcards": "Tarjetas Didácticas del Clima",
        "Possessions Flashcards": "Tarjetas Didácticas de Posesiones",
        "Months Days Flashcards": "Tarjetas Didácticas de Meses Días",
        "English Phonics": "Fonética en Inglés",
        "Time Flashcards":  "Tarjetas Didácticas del Tiempo",
        "Hello!":"¡Hola!",
        "What Is Your Name?":"¿Cómo Te Llamas?",
        "How Old Are You?":"¿Cuántos Años Tienes?",
        "Where Are You From?":"¿De Dónde Eres?",
        "What Is That?":"¿Qué Es Eso?",
        "Where Is Dobby?":"¿Dónde está Dobby?",
        "My Day":"Mi Día",
        "Family Members":"Miembros de la Familia",
        "What Are You Doing?":"¿Qué Estás Haciendo?",
        "Who Are You?":"¿Quién Eres?",
        "I Want Strawberry Ice Cream!":"¡Quiero Helado de Fresa!",
        "What Can You Do?":"¿Qué Puedes Hacer?",
        "Terry and Rexy - Numbers":"Terry y Rexy - Números",
        "Terry and Rexy - Animals":"Terry y Rexy - Animales",
        "Terry and Rexy - Colors":"Terry y Rexy - Colores",
        "Terry and Rexy - Food":"Terry y Rexy - Comida",
        "Terry and Rexy - Verbs":"Terry y Rexy - Verbos",
        "Terry and Rexy - Body Parts":"Terry y Rexy - Partes del Cuerpo",
        "Terry and Rexy - Clothes":"Terry y Rexy - Ropa",
        "Terry and Rexy - Vehicles":"Terry y Rexy - Vehículos",
        "Terry and Rexy - Shapes":"Terry y Rexy - Formas",
        "Terry and Rexy - General Phrases":"Terry y Rexy - Frases Generales",
        "The Lion and Mouse":"El León y el Ratón",
        "The Ant and Grasshopper":"La Hormiga y el Saltamontes",
        "The Tortoise and Hare":"La Tortuga y la Liebre",
        "The Dog and His Reflection":"El Perro y Su Reflejo",
        "The Peacock and Crane":"El Pavorreal y la Grulla",
        "The Goose with Golden Eggs":"La Oca de los Huevos de Oro",
        "The Bear Teaches the Fox":"El Oso le Enseña a la Zorra",
        "The Crow and Fox":"El Cuervo y la Zorra",
        "The Stork and Fox":"La Cigüeña y la Zorra",
        "The Lion, Bear and Fox":"El León, el Oso y la Zorra",
        "The Fox and the Grapes":"La Zorra y las Uvas",
        "The Fox and Hedgehog":"La Zorra y el Erizo",
        "The Boy Who Cried Wolf":"El Niño Que Gritaba Lobo",
        "The Bear and Two Travelers":"El Oso y los Dos Viajeros",
        "The Three Bears":"Los Tres Osos",
        "The Hare and Lion":"La Liebre y el León",
        "The Sheep, Lamb, Wolf and Rabbit":"La Oveja, el Borrego, el Lobo y el Conejo",
        "Beauty and the Beast":"La Bella y la Bestia",
        "The Impatient Farmer":"El Granjero Impaciente",
        "The Bear Prince":"El Príncipe Oso",
        "The Monkeys and Dragonflies":"Los Monos y las Libélulas",
        "The Nightingale":"El Ruiseñor",
        "The Scared Rabbit":"El Conejo Asustado",
        "The Giant Turnip":"El Nabo Gigante",
        "The Story of Saci":"La Historia de Saci",
        "The Man Who Spoke to Animals":"El Hombre que Hablaba con los Animales",
        "Red Riding Hood":"Caperucita Roja",
        "Jack and the Beanstalk":"Jack y las Habichuelas Mágicas",
        "Hansel and Gretel":"Hansel y Gretel",
        "Cinderella":"Cenicienta",
        "Ali Baba and the 40 Thieves":"Alí Baba y los 40 Ladrones",
        "Snow White":"Blanca Nieves",
        "Pinocchio":"Pinocho",
        "Aladdin":"Aladino",
        "Peter Pan":"Peter Pan",
        "Rapunzel":"Rapunzel",
        "Gulliver's Adventures":"Las Aventuras de Gulliver",
        "Around the World":"Alrededor del Mundo",
        "Funny Questions":"Preguntas Divertidas",
        "Crazy Animals":"Animales Locos",
        "The Babylonian Tree":"El Árbol Babilónico",
        "The Princess and the Pea":"La Princesa y el Guisante",
        "The King's New Clothes":"El Traje Nuevo del Emperador",
        "The Muscians of Bremen":"Los Músicos de Bremen",
        "The Runaway Pancake":"El Panqueque Fugitivo",
        "Cattarinetta":"Cattarinetta",
        "The Fastest Toy":"El Juguete Más Veloz",
},
    tr: {
        "Lesson": "Ders",
        "Game": "Oyun",
        "Test": "Test",
        "Flashcards": "Bilgi Kartları",
        "Worksheet": "Çalışma Tablosu",
        "Song": "Şarkı",
        "Book": "Kitap",
        "Poster": "Poster",
        "Vocabulary": "Kelime Bilgisi",
        "Activity": "Aktivite",
        "Video": "Video",
        "poster":"poster",
        "Animals":"Hayvanlar",
        "Numbers":"Sayılar",
        "Colors":"Renkler",
        "Food":"Yiyecekler",
        "Fruit and Vegetables":"Meyve ve Sebzeler",
        "In the House":"Evde",
        "Nature":"Doğa",
        "Actions":"Eylemler",
        "Family":"Aile",
        "Vehicles":"Araçlar",
        "Body":"Vücudumuz",
        "Clothes":"Giysiler",
        "Words":"Kelime",
        "Alphabet":"Alfabe",
        "Phonics":"Ses Bilgisi",
        "Baby Animals":"Yavru Hayvanlar",
        "Shapes":"Şekiller",
        "Veggies":"Sebzeler",
        "Fruit":"Meyveler",
        "Nuts":"Yemişler",
        "Weather":"Hava Durumu",
        "Adjectives":"Sıfatlar",
        "Comparisons":"Karşılaştırmalar",
        "Kitchen":"Mutfak",
        "Groceries":"Market Alışverişi",
        "Bathroom":"Banyo",
        "In the house":"Evde",
        "Tools":"Araçlar",
        "Shopping":"Alışveriş",
        "School Subjects":"Okul Konuları",
        "Traveling":"Seyahat",
        "Places":"Yerler",
        "Musical Instruments":"Müzik Aletleri",
        "Body Parts":"Vücudumuz",
        "Health":"Sağlık",
        "Pronouns":"Zamirler",
        "Possessions":"İyelik Ekleri",
        "Professions":"Meslekler",
        "Emotions":"Duygular",
        "Verbs":"Fiiller",
        "Daily Activities":"Günlük Aktiviteler",
        "House Chores":"Ev İşleri",
        "Sports":"Spor",
        "Hobbies":"Hobiler",
        "Months":"Aylar",
        "Seasons":"Mevsimler",
        "Days":"Günler",
        "Time":"Zaman",
        "House items": "Ev eşyaları",
        "words Song": "kelime Şarkısı",
        "English":"İngilizce",
        "English Alphabet": "İngilizce Alfabe",
        "Months Days": "Aylar Günler",
        "Days Time": "Günün Saatleri",
        "Alphabet Video": "Alfabe Videosu",
        "Alphabet song": "Alfabe şarkısı",
        "Alphabet Worksheet": "Alfabe Alıştırması",
        "Alphabet Book": "Alfabe Kitabı",
        "Shapes Flashcards": "Şekiller Bilgi Kartları",
        "Weather Flashcards": "Hava Koşulları Bilgi Kartları",
        "Possessions Flashcards": "İyelik Bilgi Kartları",
        "Months Days Flashcards": "Ayın Günleri Bilgi Kartları",
        "English Phonics": "İngilizce Ses Bilgisi",
        "Time Flashcards": "Zaman Bilgi Kartları",
        "Hello!":"Merhaba!",
        "What Is Your Name?":"İsmin Ne?",
        "How Old Are You?":"Kaç Yaşındasın?",
        "Where Are You From?":"Nerelisin?",
        "What Is That?":"Bu Nedir?",
        "Where Is Dobby?":"Dobby Nerede?",
        "My Day":"Bir Günüm",
        "Family Members":"Aile Üyeleri",
        "What Are You Doing?":"Ne Yapıyorsun?",
        "Who Are You?":"Sen Kimsin?",
        "I Want Strawberry Ice Cream!":"Çilekli Dondurma İstiyorum!",
        "What Can You Do?":"Ne Yapabilirsin?",
        "Terry and Rexy - Numbers":"Terry ve Rexy - Sayılar",
        "Terry and Rexy - Animals":"Terry ve Rexy - Hayvanlar",
        "Terry and Rexy - Colors":"Havlu ve Rexy - Renkler",
        "Terry and Rexy - Food":"Terry ve Rexy - Yiyecekler",
        "Terry and Rexy - Verbs":"Terry ve Rexy - Fiiller",
        "Terry and Rexy - Body Parts":"Terry ve Rexy - Vücudumuzun Bölümleri",
        "Terry and Rexy - Clothes":"Terry ve Rexy - Giysiler",
        "Terry and Rexy - Vehicles":"Terry ve Rexy - Araçlar",
        "Terry and Rexy - Shapes":"Terry ve Rexy - Şekiller",
        "Terry and Rexy - General Phrases":"Terry ve Rexy - Genel İfadeler",
        "The Lion and Mouse":"Aslan ve Fare",
        "The Ant and Grasshopper":"Karınca ve Çekirge",
        "The Tortoise and Hare":"Kaplumbağa ve Tavşan",
        "The Dog and His Reflection":"Köpek ve Yansıması",
        "The Peacock and Crane":"Tavuskuşu ve Turna",
        "The Goose with Golden Eggs":"Altın Yumurtlayan Kaz",
        "The Bear Teaches the Fox":"Ayı Tilkiye Öğretiyor",
        "The Crow and Fox":"Karga ve Tilki",
        "The Stork and Fox":"Leylek ve Tilki",
        "The Lion, Bear and Fox":"Aslan, Ayı ve Tilki",
        "The Fox and the Grapes":"Tilki ve Üzümler",
        "The Fox and Hedgehog":"Tilki ve Kirpi",
        "The Boy Who Cried Wolf":"Yalancı Çoban",
        "The Bear and Two Travelers":"Ayı ve İki Yolcu",
        "The Three Bears":"Üç Ayı",
        "The Hare and Lion":"Tavşan ve Aslan",
        "The Sheep, Lamb, Wolf and Rabbit":"Koyun, Kuzu, Kurt ve Tavşan",
        "Beauty and the Beast":"Güzel ve Çirkin",
        "The Impatient Farmer":"Sabırsız Çiftçi",
        "The Bear Prince":"Ayı Prens",
        "The Monkeys and Dragonflies":"Maymunlar ve Yusufçuklar",
        "The Nightingale":"Bülbül",
        "The Scared Rabbit":"Korkmuş Tavşan",
        "The Giant Turnip":"Dev Şalgam",
        "The Story of Saci":"Saci'nin Hikayesi",
        "The Man Who Spoke to Animals":"Hayvanlarla Konuşan Adam",
        "Red Riding Hood":"Kırmızı Başlıklı Kız",
        "Jack and the Beanstalk":"Jack ve Fasulye Sırığı",
        "Hansel and Gretel":"Hansel ve Gretel",
        "Cinderella":"Kül Kedisi",
        "Ali Baba and the 40 Thieves":"Ali Baba ve 40 Haramiler",
        "Snow White":"Pamuk Prenses",
        "Pinocchio":"Pinokyo",
        "Aladdin":"Alaaddin",
        "Peter Pan":"Peter Pan",
        "Rapunzel":"Rapunzel",
        "Gulliver's Adventures":"Gulliver'in Maceraları",
        "Around the World":"Seksen Günde Devriâlem",
        "Funny Questions":"Komik Sorular",
        "Crazy Animals":"Çılgın Hayvanlar",
        "The Babylonian Tree":"Babil Ağacı",
        "The Princess and the Pea":"Prenses ve Bezelye Tanesi",
        "The King's New Clothes":"Çıplak Kral",
        "The Muscians of Bremen":"Bremen Mızıkacıları",
        "The Runaway Pancake":"Kaçak Gözleme",
        "Cattarinetta":"Cattarinetta",
        "The Fastest Toy":"En Hızlı Oyuncak",
    },
    zh: {
        "Lesson": "课堂",
        "Game": "游戏",
        "Test": "测验",
        "Flashcards": "抽认卡",
        "Worksheet": "工作表",
        "Song": "歌曲",
        "Book": "书",
        "Poster": "海报",
        "Vocabulary": "词汇",
        "Activity": "活动",
        "Video": "视频",
        "poster":"海报",
        "Animals":"动物",
        "Numbers":"数字",
        "Colors":"颜色",
        "Food":"食物",
        "Fruit and Vegetables":"水果和蔬菜",
        "In the House":"房子里 ",
        "Nature":"自然",
        "Actions":"动作",
        "Family":"家庭",
        "Vehicles":"车辆",
        "Body":"身体 ",
        "Clothes":"衣服",
        "Words":"字",
        "Alphabet":"字母",
        "Phonics":"拼音",
        "Baby Animals":"小动物",
        "Shapes":"形状",
        "Veggies":"蔬菜",
        "Fruit":"水果",
        "Nuts":"坚果",
        "Weather":"天气",
        "Adjectives":"形容词",
        "Comparisons":"比较",
        "Kitchen":"厨房",
        "Groceries":"杂货",
        "Bathroom":"浴室",
        "In the house":"房子里",
        "Tools":"工具",
        "Shopping":"购物",
        "School Subjects":"学校科目",
        "Traveling":"旅行",
        "Places":"地方",
        "Musical Instruments":"乐器",
        "Body Parts":"身体部位",
        "Health":"健康",
        "Pronouns":"代词",
        "Possessions":"财产",
        "Professions":"专业",
        "Emotions":"情绪",
        "Verbs":"动词",
        "Daily Activities":"日常活动",
        "House Chores":"家务",
        "Sports":"运动",
        "Hobbies":"爱好",
        "Months":"月份",
        "Seasons":"四季",
        "Days":"日子",
        "Time":"时间",
        "House items": "家居用品",
        "words Song": "字歌",
        "English":"英语",
        "English Alphabet": "英文字母",
        "Months Days": "月日",
        "Days Time": "日时",
        "Alphabet Video": "字母视频",
        "Alphabet song": "字母歌",
        "Alphabet Worksheet": "字母工作表",
        "Alphabet Book": "字母书",
        "Shapes Flashcards": "形状抽认卡",
        "Weather Flashcards": "天气抽认卡",
        "Possessions Flashcards": "财产抽认卡",
        "Months Days Flashcards": "月日抽认卡",
        "English Phonics": "英语拼音",
        "Time Flashcards":  "时间抽认卡",
        "Hello!":"你好！",
        "What Is Your Name?":"你叫什么名字？",
        "How Old Are You?":"你今年几岁？",
        "Where Are You From?":"你从哪来？",
        "What Is That?":"那是什么？",
        "Where Is Dobby?":"多比在哪里？",
        "My Day":"我的一天",
        "Family Members":"家庭成员",
        "What Are You Doing?":"你在做什么？",
        "Who Are You?":"你是谁？",
        "I Want Strawberry Ice Cream!":"我要草莓冰淇淋！",
        "What Can You Do?":"你能做什么？",
        "Terry and Rexy - Numbers":"特里和雷克西 - 数字",
        "Terry and Rexy - Animals":"特里和雷克西 - 动物",
        "Terry and Rexy - Colors":"特里和雷克西 - 颜色",
        "Terry and Rexy - Food":"特里和雷克西 - 食物",
        "Terry and Rexy - Verbs":"特里和雷克西 - 动词",
        "Terry and Rexy - Body Parts":"特里和雷克西 - 身体部位",
        "Terry and Rexy - Clothes":"特里和雷克西 - 衣服",
        "Terry and Rexy - Vehicles":"特里和雷克西 - 车辆",
        "Terry and Rexy - Shapes":"特里和雷克西 - 形状",
        "Terry and Rexy - General Phrases":"特里和雷克西 - 一般短语",
        "The Lion and Mouse":"狮子与老鼠",
        "The Ant and Grasshopper":"蚂蚁与蚱蜢",
        "The Tortoise and Hare":"龟兔赛跑",
        "The Dog and His Reflection":"狗与倒影",
        "The Peacock and Crane":"孔雀与鹤",
        "The Goose with Golden Eggs":"鹅与金蛋",
        "The Bear Teaches the Fox":"熊给狐狸的教训",
        "The Crow and Fox":"乌鸦与狐狸",
        "The Stork and Fox":"鹳与狐狸",
        "The Lion, Bear and Fox":"狮子、熊与狐狸",
        "The Fox and the Grapes":"狐狸与葡萄",
        "The Fox and Hedgehog":"狐狸与刺猬",
        "The Boy Who Cried Wolf":"狼来了",
        "The Bear and Two Travelers":"熊与两名旅行者",
        "The Three Bears":"三只熊",
        "The Hare and Lion":"野兔与狮子",
        "The Sheep, Lamb, Wolf and Rabbit":"绵羊、羔羊、狼与兔子",
        "Beauty and the Beast":"美女与野兽",
        "The Impatient Farmer":"没有耐心的农夫",
        "The Bear Prince":"熊王子",
        "The Monkeys and Dragonflies":"猴子与蜻蜓",
        "The Nightingale":"夜莺",
        "The Scared Rabbit":"受惊的兔子",
        "The Giant Turnip":"拔萝卜",
        "The Story of Saci":"萨奇的故事",
        "The Man Who Spoke to Animals":"对动物说话的人",
        "Red Riding Hood":"小红帽",
        "Jack and the Beanstalk":"杰克与魔豆",
        "Hansel and Gretel":"汉赛尔与格莱特",
        "Cinderella":"灰姑娘",
        "Ali Baba and the 40 Thieves":"阿里巴巴与四十大盗",
        "Snow White":"白雪公主",
        "Pinocchio":"木偶奇遇记",
        "Aladdin":"阿拉丁",
        "Peter Pan":"彼得潘",
        "Rapunzel":"长发公主",
        "Gulliver's Adventures":"格列佛历险记",
        "Around the World":"环游世界",
        "Funny Questions":"搞笑问题",
        "Crazy Animals":"疯狂的动物",
        "The Babylonian Tree":"巴比伦之树",
        "The Princess and the Pea":"公主与豌豆",
        "The King's New Clothes":"国王的新衣",
        "The Muscians of Bremen":"不来梅的音乐家",
        "The Runaway Pancake":"失控的煎饼",
        "Cattarinetta":"卡塔里内塔",
        "The Fastest Toy":"速度最快的玩具",
    },
    it: {
        "Lesson": "Lezione",
        "Game": "Gioco",
        "Test": "Test",
        "Flashcards": "Schede didattiche",
        "Worksheet": "Esercizio",
        "Song": "Canzone",
        "Book": "Libro",
        "Poster": "Cartellone",
        "Vocabulary": "Vocabolario",
        "Activity": "Attività",
        "Video": "Video",
        "poster":"cartellone",
        "Animals":"Animali",
        "Numbers":"Numeri",
        "Colors":"Colori",
        "Food":"Cibo",
        "Fruit and Vegetables":"Frutta e verdura",
        "In the House":"In casa",
        "Nature":"Natura",
        "Actions":"Azioni",
        "Family":"Famiglia",
        "Vehicles":"Veicoli",
        "Body":"Corpo",
        "Clothes":"Abbigliamento",
        "Words":"parole",
        "Alphabet":"Alfabeto",
        "Phonics":"Fonetica",
        "Baby Animals":"Animaletti",
        "Shapes":"Forme",
        "Veggies":"Verdurine",
        "Fruit":"Frutta",
        "Nuts":"Frutta a guscio",
        "Weather":"Meteo",
        "Adjectives":"Aggettivi",
        "Comparisons":"Paragoni",
        "Kitchen":"Cucina",
        "Groceries":"Generi alimentari",
        "Bathroom":"Bagno",
        "In the house":"In casa",
        "Tools":"Attrezzi",
        "Shopping":"Spesa",
        "School Subjects":"Materie scolastiche",
        "Traveling":"Viaggi",
        "Places":"Posti",
        "Musical Instruments":"Strumenti musicali",
        "Body Parts":"Parti del corpo",
        "Health":"Salute",
        "Pronouns":"Pronomi",
        "Possessions":"Possessivi",
        "Professions":"Professioni",
        "Emotions":"Emozioni",
        "Verbs":"Verbi",
        "Daily Activities":"Attività quotidiane",
        "House Chores":"Faccende domestiche",
        "Sports":"Sport",
        "Hobbies":"Hobby",
        "Months":"Mesi",
        "Seasons":"Stagioni",
        "Days":"Giorni",
        "Time":"Tempo",
        "House items": "Oggetti della casa",
        "words Song": "Canzone di parole",
        "English":"inglese",
        "English Alphabet": "Alfabeto inglese",
        "Months Days": "Mesi Giorni",
        "Days Time": "Giorni Ora",
        "Alphabet Video": "Video dell'alfabeto",
        "Alphabet song": "Canzone dell'alfabeto",
        "Alphabet Worksheet": "Esercizi sull'alfabeto",
        "Alphabet Book": "Libro dell'alfabeto",
        "Shapes Flashcards": "Schede didattiche sulle forme",
        "Weather Flashcards": "Schede didattiche sul meteo",
        "Possessions Flashcards": "Schede didattiche sui possessivi",
        "Months Days Flashcards": "Schede didattiche su Mesi Giorni",
        "English Phonics": "Fonetica inglese",
        "Time Flashcards":  "Schede didattiche sul tempo",
        "Hello!":"Ciao!",
        "What Is Your Name?":"Come ti chiami?",
        "How Old Are You?":"Quanti anni hai?",
        "Where Are You From?":"Da dove vieni?",
        "What Is That?":"Che cos'è quello?",
        "Where Is Dobby?":"Dov'è Dobby?",
        "My Day":"La mia giornata",
        "Family Members":"Familiari",
        "What Are You Doing?":"Cosa stai facendo?",
        "Who Are You?":"Chi sei tu?",
        "I Want Strawberry Ice Cream!":"Voglio il gelato alla fragola!",
        "What Can You Do?":"Cosa sai fare?",
        "Terry and Rexy - Numbers":"Terry e Rexy - Numeri",
        "Terry and Rexy - Animals":"Terry e Rexy - Animali",
        "Terry and Rexy - Colors":"Terry e Rexy - Colori",
        "Terry and Rexy - Food":"Terry e Rexy - Cibo",
        "Terry and Rexy - Verbs":"Terry e Rexy - Verbi",
        "Terry and Rexy - Body Parts":"Terry e Rexy - Parti del corpo",
        "Terry and Rexy - Clothes":"Terry e Rexy - Abbigliamento",
        "Terry and Rexy - Vehicles":"Terry e Rexy - Veicoli",
        "Terry and Rexy - Shapes":"Terry e Rexy - Forme",
        "Terry and Rexy - General Phrases":"Terry e Rexy - Frasi generali",
        "The Lion and Mouse":"Il leone e il topo",
        "The Ant and Grasshopper":"La cicala e la formica",
        "The Tortoise and Hare":"La lepre e la tartaruga",
        "The Dog and His Reflection":"Il cane e l'osso",
        "The Peacock and Crane":"Il pavone e la gru",
        "The Goose with Golden Eggs":"La gallina dalle uova d'oro",
        "The Bear Teaches the Fox":"L'orso insegna alla volpe",
        "The Crow and Fox":"Il corvo e la volpe",
        "The Stork and Fox":"La cicogna e la volpe",
        "The Lion, Bear and Fox":"Il leone, l'orso e la volpe",
        "The Fox and the Grapes":"La volpe e l'uva",
        "The Fox and Hedgehog":"Il riccio e la volpe",
        "The Boy Who Cried Wolf":"Al lupo! Al lupo!",
        "The Bear and Two Travelers":"I due viandanti e l'orso",
        "The Three Bears":"I tre orsi",
        "The Hare and Lion":"Il leone e la lepre",
        "The Sheep, Lamb, Wolf and Rabbit":"La pecora, l'agnello, il lupo e il coniglio",
        "Beauty and the Beast":"La bella e la bestia",
        "The Impatient Farmer":"Il contadino impaziente",
        "The Bear Prince":"Il principe orso",
        "The Monkeys and Dragonflies":"Le scimmie e le libellule",
        "The Nightingale":"L'usignolo",
        "The Scared Rabbit":"Il coniglio spaventato",
        "The Giant Turnip":"La rapa gigante",
        "The Story of Saci":"La storia di Saci",
        "The Man Who Spoke to Animals":"L'uomo che parlava agli animali",
        "Red Riding Hood":"Cappuccetto Rosso",
        "Jack and the Beanstalk":"Jack e la pianta di fagioli",
        "Hansel and Gretel":"Hansel e Gretel",
        "Cinderella":"Cenerentola",
        "Ali Baba and the 40 Thieves":"Alì Babà e i 40 ladroni",
        "Snow White":"Biancaneve",
        "Pinocchio":"Pinocchio",
        "Aladdin":"Aladdin",
        "Peter Pan":"Peter Pan",
        "Rapunzel":"Raperonzolo",
        "Gulliver's Adventures":"Le avventure di Gulliver",
        "Around the World":"Il giro del mondo",
        "Funny Questions":"Domande divertenti",
        "Crazy Animals":"Animali pazzerelli",
        "The Babylonian Tree":"L'albero babilonese",
        "The Princess and the Pea":"La principessa e il pisello",
        "The King's New Clothes":"I vestiti nuovi dell'imperatore",
        "The Muscians of Bremen":"I musicanti di Brema",
        "The Runaway Pancake":"Omino di pan di zenzero",
        "Cattarinetta":"Cattarinetta",
        "The Fastest Toy":"Il giocattolo più veloce",
    },
    ko: {
        "Lesson":  "레슨",
        "Game":  "게임",
        "Test":  "시험",
        "Flashcards":  "플래시카드",
        "Worksheet":  "워크시트",
        "Song":  "노래",
        "Book":  "책",
        "Poster":  "포스터",
        "Vocabulary":  "어휘",
        "Activity": "활동",
        "Video": "비디오",
        "poster": "포스터",
        "Animals":"동물",
        "Numbers":"숫자",
        "Colors":"색상",
        "Food":"식품",
        "Fruit and Vegetables":"과일 및 채소 ",
        "In the House":"집안에서",
        "Nature":"자연",
        "Actions":"활동",
        "Family":"가족",
        "Vehicles":"차량",
        "Body":"신체",
        "Clothes":"의류",
        "Words":"개의 단어",
        "Alphabet":"알파벳",
        "Phonics":"파닉스",
        "Baby Animals":"아기 동물",
        "Shapes":"도형",
        "Veggies":"채소",
        "Fruit":"과일",
        "Nuts":"너트",
        "Weather":"날씨",
        "Adjectives":"형용사",
        "Comparisons":"비교",
        "Kitchen":"주방",
        "Groceries":"식료품",
        "Bathroom":"욕실",
        "In the house":"집안에서 ",
        "Tools":"도구",
        "Shopping":"쇼핑",
        "School Subjects":"학교 과목",
        "Traveling":"여행",
        "Places":"장소",
        "Musical Instruments":"악기",
        "Body Parts":"신체 부위",
        "Health":"건강",
        "Pronouns":"대명사",
        "Possessions":"소유물",
        "Professions":"직업",
        "Emotions":"감정",
        "Verbs":"동사",
        "Daily Activities":"일일 활동",
        "House Chores":"집안일",
        "Sports":"스포츠",
        "Hobbies":"취미",
        "Months":"개월",
        "Seasons":"계절",
        "Days":"일",
        "Time":"시간",
        "House items": "집 아이템",
        "words Song":  "단어 연습 노래",
        "English":"영어",
        "English Alphabet": "영어 알파벳",
        "Months Days": "개월 일",
        "Days Time": "일 시간",
        "Alphabet Video": "알파벳 비디오",
        "Alphabet song": "알파벳 노래",
        "Alphabet Worksheet": "알파벳 워크시트",
        "Alphabet Book": "알파벳 책",
        "Shapes Flashcards": "도형 플래시카드",
        "Weather Flashcards": "날씨 플래시카드",
        "Possessions Flashcards": "소유물 플래시카드",
        "Months Days Flashcards": "개월 일 플래시카드",
        "English Phonics": "영어 파닉스",
        "Time Flashcards":  "시간 플래시카드",
        "Hello!":"안녕하세요!",
        "What Is Your Name?":"이름이 뭐예요?",
        "How Old Are You?":"몇 살이예요?",
        "Where Are You From?":"어디서 왔나요?",
        "What Is That?":"이게 뭐예요?",
        "Where Is Dobby?":"도비는 어딨어요?",
        "My Day":"나의 하루",
        "Family Members":"가족 구성원",
        "What Are You Doing?":"뭐하고 있나요?",
        "Who Are You?":"누구세요?",
        "I Want Strawberry Ice Cream!":"딸기 아이스크림을 원해요!",
        "What Can You Do?":"무엇을 할 수 있을까요?",
        "Terry and Rexy - Numbers":"테리와 렉시 - 숫자",
        "Terry and Rexy - Animals":"테리와 렉시 - 동물",
        "Terry and Rexy - Colors":"테리와 렉시 - 색상",
        "Terry and Rexy - Food":"테리와 렉시 - 음식",
        "Terry and Rexy - Verbs":"테리와 렉시 - 동사",
        "Terry and Rexy - Body Parts":"테리와 렉시 - 신체 부위",
        "Terry and Rexy - Clothes":"테리와 렉시 - 옷",
        "Terry and Rexy - Vehicles":"테리와 렉시 - 차량",
        "Terry and Rexy - Shapes":"테리와 렉시 - 모양",
        "Terry and Rexy - General Phrases":"테리와 렉시 - 일반 문구",
        "The Lion and Mouse":"사자와 쥐",
        "The Ant and Grasshopper":"게미와 매뚜기",
        "The Tortoise and Hare":"거북이와 토끼",
        "The Dog and His Reflection":"개와 그의 모습",
        "The Peacock and Crane":"공작과 두루미",
        "The Goose with Golden Eggs":"황금알을 가진 거위",
        "The Bear Teaches the Fox":"곰이 여우를 가르친다",
        "The Crow and Fox":"까마귀와 여우",
        "The Stork and Fox":"황새와 여우",
        "The Lion, Bear and Fox":"사자, 곰과 여우",
        "The Fox and the Grapes":"여우와 포도",
        "The Fox and Hedgehog":"여우와 고슴도치",
        "The Boy Who Cried Wolf":"양치기 소년",
        "The Bear and Two Travelers":"곰과 두 명의 여행자",
        "The Three Bears":"곰 새마리",
        "The Hare and Lion":"토끼와 사자",
        "The Sheep, Lamb, Wolf and Rabbit":"양, 어린 양, 늑대 그리고 토끼",
        "Beauty and the Beast":"미녀와 야수",
        "The Impatient Farmer":"참을성이 없는 농부",
        "The Bear Prince":"곰 왕자",
        "The Monkeys and Dragonflies":"원숭이와 잠자리",
        "The Nightingale":"나이팅게일",
        "The Scared Rabbit":"겁에 질린 토끼",
        "The Giant Turnip":"거대한 순무",
        "The Story of Saci":"사키 이야기",
        "The Man Who Spoke to Animals":"동물과 대화한 남자",
        "Red Riding Hood":"빨간 모자",
        "Jack and the Beanstalk":"잭과 콩나무",
        "Hansel and Gretel":"헨젤과 그레텔",
        "Cinderella":"신데렐라",
        "Ali Baba and the 40 Thieves":"알리바바와 40인의 도둑",
        "Snow White":"백설 공주",
        "Pinocchio":"피노키오",
        "Aladdin":"알라딘",
        "Peter Pan":"피터 팬",
        "Rapunzel":"라푼젤",
        "Gulliver's Adventures":"걸리버 여행기",
        "Around the World":"어라운드 더 월드 (세계 일주)",
        "Funny Questions":"웃긴 질문",
        "Crazy Animals":"미친 동물들",
        "The Babylonian Tree":"바빌로니아 나무",
        "The Princess and the Pea":"공주와 완두콩",
        "The King's New Clothes":"왕의 새 옷",
        "The Muscians of Bremen":"브레멘의 음악가",
        "The Runaway Pancake":"도망친 팬케이크",
        "Cattarinetta":"카타리네타",
        "The Fastest Toy":"가장 빠른 장난감",
    },
    jp: {
        "Activity": "アクティビティ",
        "Video": "動画",
        "Lesson": "レッスン",
        "Game": "ゲーム",
        "Test": "テスト",
        "Flashcards": "フラッシュカード",
        "Worksheet": "ワークシート",
        "Song": "歌",
        "Book": "本",
        "Poster": "ポスター",
        "poster": "ポスター",
        "Vocabulary": "単語",
        "Animals": "動物",
        "Numbers": "数",
        "Colors": "色",
        "Food": "食べ物",
        "Fruit and Vegetables": "果物と野菜",
        "In the House": "家の中",
        "Nature": "自然",
        "Actions": "動き",
        "Family": "家族",
        "Vehicles": "乗り物",
        "Body": "からだ",
        "Clothes": "衣類",
        "Words": "単語",
        "Alphabet": "アルファベット",
        "Phonics": "発音",
        "Baby Animals": "動物の赤ちゃん",
        "Shapes": "形",
        "Veggies": "野菜",
        "Fruit": "果物",
        "Nuts": "木の実",
        "Weather": "天気",
        "Adjectives": "形容詞",
        "Comparisons": "比較",
        "Kitchen": "台所",
        "Groceries": "食料品",
        "Bathroom": "浴室",
        "In the house": "家の中",
        "Tools": "道具",
        "Shopping": "買い物",
        "School Subjects": "学用品",
        "Traveling": "旅行",
        "Places": "場所",
        "Musical Instruments": "楽器",
        "Body Parts": "からだの部分",
        "Health": "健康",
        "Pronouns": "代名詞",
        "Possessions": "所有詞",
        "Professions": "職業",
        "Emotions": "感情",
        "Verbs": "動詞",
        "Daily Activities": "日常生活",
        "House Chores": "家事",
        "Sports": "スポーツ",
        "Hobbies": "趣味",
        "Months": "暦の月",
        "Seasons": "季節",
        "Days": "暦の日",
        "Time": "時刻",
        "House Items": "家にあるもの",
        "words Song": "ワード 歌",
        "English": "英語",
        "English Alphabet": "英語のアルファベット",
        "Months Days": "月と日にち",
        "Days Time": "曜日と時間",
        "Alphabet Video": "アルファベットの動画",
        "Alphabet song": "アルファベットの歌",
        "Alphabet Worksheet": "アルファベットのワークシート",
        "Alphabet Book": "アルファベットの本",
        "Shapes Flashcards": "図形 フラッシュカード",
        "Weather Flashcards": "天気 フラッシュカード",
        "Possessions Flashcards": "持ち物 フラッシュカード",
        "Months Days Flashcards": "月と日にち フラッシュカード",
        "English Phonics": "英語のフォニックス",
        "Time Flashcards": "時間 フラッシュカード"
    }
}