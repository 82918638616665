<section [ngClass]="{ loader: !data, 'list-students': data }">
  <table mat-table [dataSource]="dataSource" matSort matSortActive="subuser.name" matSortDirection="asc" class="mat-elevation-z8">
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef>{{ 'STUDENT.INDEX' | translate }}</th>
      <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
    </ng-container>

    <ng-container matColumnDef="subuser.name">
      <th mat-header-cell *matHeaderCellDef>{{ 'PROFILE.STUDENT_NAME' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.subuser.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="studentCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PROFILE.STUDENT_CODE' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.studentCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="incompleteAssignments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PROFILE.STUDENT_INCOMPLETE_ASSIGNMENTS' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <span class="incomplete-assignments" (click)="openAssignmentsDialog(element)">{{ element.incompleteAssignments }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'STUDENT.ACTIONS' | translate }}</th>
      <td mat-cell *matCellDef="let student">
        <mat-form-field>
          <mat-label>{{ 'STUDENT.ACTIONS' | translate }}</mat-label>
          <mat-select disableRipple>
            <mat-option (click)="editStudent(student)">
              <mat-icon>edit</mat-icon>
              <span>{{ 'STUDENT.EDIT' | translate }}</span>
            </mat-option>
            <mat-option (click)="removeStudent(student)">
              <mat-icon>cancel</mat-icon>
              <span>{{ 'STUDENT.REMOVE' | translate }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
  </table>
  <div class="no-students" *ngIf="dataSource && dataSource.data.length === 0">{{ 'CLASSROOM.NO_STUDENTS' | translate }}</div>
</section>
