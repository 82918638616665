<div class="editPass">
  <div class="actions">
    <span class="title">{{ 'PROFILE.PASSWORD_CHANGE' | translate }} </span>
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
  </div>
  <div class="inputs">
    <form class="changePasswordForm customGroup" [formGroup]="changePasswordForm">
      <mat-form-field class="newPassword color-white">
        <mat-label class="color-white">{{ 'PROFILE.PASSWORD_NEW' | translate }}</mat-label>
        <input formControlName="newPassword" matInput placeholder="{{ 'PROFILE.PASSWORD_NEW' | translate }}" value="" class="color-white" />
      </mat-form-field>
      <ng-container *ngIf="changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').invalid">
        <mat-error *ngIf="changePasswordForm.get('newPassword').errors.required">
          {{ 'PROFILE.PASSWORD_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="changePasswordForm.get('newPassword').errors.invalidPassword && !changePasswordForm.get('newPassword').errors.minlength && !changePasswordForm.get('newPassword').errors.maxlength">
          Password must contain at least one number, uppercase letter and special symbol
        </mat-error>
        <mat-error *ngIf="changePasswordForm.get('newPassword').errors.minlength">
          {{ 'PROFILE.PASSWORD_REQUIREMENT' | translate }}
        </mat-error>
        <mat-error *ngIf="changePasswordForm.get('newPassword').errors.maxlength"> Max password length is 20 characters </mat-error>
      </ng-container>

      <mat-form-field class="confirmPassword">
        <mat-label class="color-white">{{ 'PROFILE.PASSWORD_CONFIRM' | translate }}</mat-label>
        <input class="color-white" matInput formControlName="confirmPassword" />
      </mat-form-field>
      <ng-container *ngIf="changePasswordForm.get('confirmPassword').touched">
        <mat-error *ngIf="changePasswordForm.get('confirmPassword').value !== changePasswordForm.get('newPassword').value"> Password mismatch </mat-error>
      </ng-container>
    </form>
  </div>
  <div class="buttons">
    <button mat-raised-button color="accent" (click)="saveNewPassword()" [disabled]="!changePasswordForm.valid || changePasswordForm.get('confirmPassword').value !== changePasswordForm.get('newPassword').value">
      {{ 'PROFILE.PASSWORD_SAVE' | translate }}
    </button>
  </div>
</div>
