import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { SubuserComponent } from './subuser.component'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle'
import { EditSubuserDialogModule } from 'src/app/shared/popups/Subuser-Dialogs/edit-subuser/editSubuser.module'
import { ConfirmationPopupModule } from 'src/app/shared/popups/Control-Dialogs/confirmation/confirmation.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatProgressBarModule,
    MatSelectModule,
    EditSubuserDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    ConfirmationPopupModule,
    MatSlideToggleModule,
    SoundsModule,
    LottieModule,
  ],
  declarations: [SubuserComponent],
})
export class SubuserModule {}
