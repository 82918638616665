import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProgressReportsComponent } from './progress-reports.component'
import { TranslateModule } from '@ngx-translate/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { TestResultModule } from '../../../shared/popups/Lesson-Dialogs/test-result/test-result.module'
import { TestPlanModule } from '../../../shared/popups/Lesson-Dialogs/test-plan/test-plan.module'
import { ProgressReportsPipeModule } from './progress-reports-pipe/progress-reports-pipe.module'
import { DetailedReportsModule } from './detailed-reports/detailed-reports.module'
import { TestResultsModule } from './test-results/test-results.module'
import { PracticeTimeModule } from './practice-time/practice-time.module'
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs'
import { AllTestReportsModule } from '../../../shared/popups/ProgressReports-Dialogs/all-test-reports/all-test-reports.module'
import { AllTimeLogsModule } from '../../../shared/popups/ProgressReports-Dialogs/all-time-logs/all-time-logs.module'
import { AllDetailedReportsModule } from '../../../shared/popups/ProgressReports-Dialogs/all-detailed-reports/all-detailed-reports.module'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { FormsModule } from '@angular/forms'
import { CoursesResolver } from '../../../helpers/resolvers/courses.resolver'
import { MatDialogModule } from '@angular/material/dialog'
import { ExportReportsDialogModule } from '../../../shared/popups/Export-Dialogs/export-reports/export-reports.module'

@NgModule({
  declarations: [ProgressReportsComponent],
  imports: [
    CommonModule,
    ProgressReportsPipeModule,
    TranslateModule,
    MatExpansionModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    TestResultModule,
    TestPlanModule,
    AllTestReportsModule,
    AllTimeLogsModule,
    AllDetailedReportsModule,
    DetailedReportsModule,
    TestResultsModule,
    PracticeTimeModule,
    MatDialogModule,
    ExportReportsDialogModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CoursesResolver],
})
export class ProgressReportsModule {}
