import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { TranslateModule } from '@ngx-translate/core'
import { AdjustGradeDialogComponent } from './adjust-grade.component'

@NgModule({
  declarations: [AdjustGradeDialogComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatInputModule],
})
export class AdjustGradeDialogModule {}
