import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { IAppState } from 'src/app/store/state/app.state'
import { Store, select } from '@ngrx/store'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { AuthService } from 'src/app/services/auth.service'
import { selectAllSubuser, selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { Router } from '@angular/router'
import { selectStatistics } from 'src/app/store/selectors/statistics.selectors'
import { TranslateService } from '@ngx-translate/core'
import { CheckParentPinComponent } from '../checkParentPin/checkParentPin.component'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { GetUser } from 'src/app/store/actions/user.actions'
import { ConfirmationPopupComponent } from '../../Control-Dialogs/confirmation/confirmation.component'
import { environment } from 'src/environments/environment'

@Component({
  templateUrl: 'current-user.component.html',
  styleUrls: ['current-user.component.scss'],
})
export class CurrentUserPopupComponent implements OnInit, OnDestroy {
  isMobile: boolean = false
  public unsubscribe$ = new Subject()

  public user$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser))
  public subuser$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectAllSubuser))
  public currentSubuser$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser))
  public statistics$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectStatistics))
  public user: any
  public showButton: boolean

  constructor(
    public _dialog: MatDialogRef<CurrentUserPopupComponent>,
    public dialog: MatDialog,
    private _store: Store<IAppState>,
    private _authService: AuthService,
    public router: Router,
    public translate: TranslateService
  ) {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)).subscribe((subuser) => {
      if (subuser) this.showButton = true
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkIfMobile() // Check on window resize
  }

  private checkIfMobile(): void {
    this.isMobile = window.innerWidth <= 500
  }

  ngOnInit(): void {
    if (!this.user)
      this._store.dispatch(new GetUser())

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      if (user) this.user = user.user
    })

    this.checkIfMobile()
  }

  selectSubuser(subuser) {
    this._dialog.close(subuser)
  }

  logout() {
    const dialog = this.dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.LOG_OUT',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      this._authService.logout()
      window.location.href = environment.logoutRedirectUrl
    })
  }

  closeDialog() {
    this._dialog.close()
  }

  openCheckParentPinDialog() {
    if (this.user.pin) {
      const dialog = this.dialog.open(CheckParentPinComponent, {
        closeOnNavigation: true,
        disableClose: true,
        hasBackdrop: true,
        data: 'PARENT_PIN.GO_TO',
      })

      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate(['/profile/parents/subusers'])
          this._dialog.close()
        }
      })
    } else {
      this.router.navigate(['/profile/parents/subusers'])
      this._dialog.close()
    }
  }

  public isLottie(url) {
    if (url.includes('.json')) {
      return true
    }
    return false
  }
}
