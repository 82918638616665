import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AllDetailedReportsComponent } from './all-detailed-reports.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { FormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [AllDetailedReportsComponent],
  imports: [CommonModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatSelectModule, FormsModule, TranslateModule],
})
export class AllDetailedReportsModule {}
