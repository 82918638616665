import { Action } from '@ngrx/store'

export enum EStatisticsActions {
  GetStatistics = '[Statistics] Get Statistics',
  GetStatisticsSuccess = '[Statistics] Get Statistics Success',
  GetStatisticsFailure = '[Statistics] Get Statistics Failure',
  GetDinosaurs = '[Statistics] Get Dinosaurs',
  GetDinosaursSuccess = '[Statistics] Get Dinosaurs Success',
  GetDinosaursFailure = '[Statistics] Get Dinosaurs Failure',
  GetOpenDinosaurs = '[Statistics] Get Open Dinosaurs',
  GetOpenDinosaursSuccess = '[Statistics] Get Open Dinosaurs Success',
  GetOpenDinosaursFailure = '[Statistics] Get Open Dinosaurs Failure',
  GetAchievements = '[Statistics] Get Achievements',
  GetAchievementsSuccess = '[Statistics] Get Achievements Success',
  GetAchievementsFailure = '[Statistics] Get Achievements Failure',
  GetStars = '[Statistics] Get Stars',
  GetStarsSuccess = '[Statistics] Get Stars Success',
  GetStarsFailure = '[Statistics] Get Stars Failure',
  GetProgress = '[Statistics] Get Progress',
  GetProgressSuccess = '[Statistics] Get Progress Success',
  GetProgressFailure = '[Statistics] Get Progress Failure',
  GetTestReport = '[Statistics] Get Test Report',
  GetTestReportSuccess = '[Statistics] Get Test Report Success',
  GetTestReportPdf = '[Statistics] Get Test Report Pdf',
  GetTestReportPdfSuccess = '[Statistics] Get Test Report Pdf Success',
}

export class GetStatistics implements Action {
  public readonly type = EStatisticsActions.GetStatistics
  constructor(public payload: any) {}
}

export class GetStatisticsSuccess implements Action {
  public readonly type = EStatisticsActions.GetStatisticsSuccess
  constructor(public payload: any) {}
}

export class GetStatisticsFailure implements Action {
  public readonly type = EStatisticsActions.GetStatisticsFailure
  constructor(public payload: any) {}
}

export class GetDinosaurs implements Action {
  public readonly type = EStatisticsActions.GetDinosaurs
  constructor(public payload: any) {}
}

export class GetDinosaursSuccess implements Action {
  public readonly type = EStatisticsActions.GetDinosaursSuccess
  constructor(public payload: any) {}
}

export class GetDinosaursFailure implements Action {
  public readonly type = EStatisticsActions.GetDinosaursFailure
  constructor(public payload: any) {}
}

export class GetOpenDinosaurs implements Action {
  public readonly type = EStatisticsActions.GetOpenDinosaurs
  constructor(public payload: any) {}
}

export class GetOpenDinosaursSuccess implements Action {
  public readonly type = EStatisticsActions.GetOpenDinosaursSuccess
  constructor(public payload: any) {}
}

export class GetOpenDinosaursFailure implements Action {
  public readonly type = EStatisticsActions.GetOpenDinosaursFailure
  constructor(public payload: any) {}
}

export class GetAchievements implements Action {
  public readonly type = EStatisticsActions.GetAchievements
  constructor(public payload: any) {}
}

export class GetAchievementsSuccess implements Action {
  public readonly type = EStatisticsActions.GetAchievementsSuccess
  constructor(public payload: any) {}
}

export class GetAchievementsFailure implements Action {
  public readonly type = EStatisticsActions.GetAchievementsFailure
  constructor(public payload: any) {}
}

export class GetStars implements Action {
  public readonly type = EStatisticsActions.GetStars
  constructor(public payload: any) {}
}

export class GetStarsSuccess implements Action {
  public readonly type = EStatisticsActions.GetStarsSuccess
  constructor(public payload: any) {}
}

export class GetStarsFailure implements Action {
  public readonly type = EStatisticsActions.GetStarsFailure
  constructor(public payload: any) {}
}

export class GetProgress implements Action {
  public readonly type = EStatisticsActions.GetProgress
  constructor(public payload: any) {}
}

export class GetProgressSuccess implements Action {
  public readonly type = EStatisticsActions.GetProgressSuccess
  constructor(public payload: any) {}
}

export class GetProgressFailure implements Action {
  public readonly type = EStatisticsActions.GetProgressFailure
  constructor(public payload: any) {}
}

export class GetTestReport implements Action {
  public readonly type = EStatisticsActions.GetTestReport
  constructor(public payload: any) {}
}

export class GetTestReportSuccess implements Action {
  public readonly type = EStatisticsActions.GetTestReportSuccess
  constructor(public payload: any) {}
}

// export class GetTestReportPdf implements Action {
//     public readonly type = EStatisticsActions.GetTestReportPdf;
//     constructor(public payload: any) { }
// }

// export class GetTestReportPdfSuccess implements Action {
//     public readonly type = EStatisticsActions.GetTestReportPdfSuccess;
//     constructor(public payload: any) { }
// }

export type StatisticsActions =
  | GetStatistics
  | GetStatisticsSuccess
  | GetStatisticsFailure
  | GetDinosaurs
  | GetDinosaursSuccess
  | GetDinosaursFailure
  | GetOpenDinosaurs
  | GetOpenDinosaursSuccess
  | GetOpenDinosaursFailure
  | GetAchievements
  | GetAchievementsSuccess
  | GetAchievementsFailure
  | GetStars
  | GetStarsSuccess
  | GetStarsFailure
  | GetProgress
  | GetProgressSuccess
  | GetProgressFailure
  | GetTestReportSuccess
  | GetTestReport
