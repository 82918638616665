import { createSelector } from '@ngrx/store'
import { IAppState } from '../state/app.state'
import { IProgressReportsState } from '../state/progress-reports.state'

const progressReportsState = (state: IAppState) => state.progressReports

export const selectLatestTestReports = createSelector(progressReportsState, (state: IProgressReportsState) => {
  const reports = state.testReports.map((i: any) => ({ ...i }))

  reports.forEach((report) => {
    if (!report.testReports.length) {
      return
    }

    report.latestTestReport = report.testReports.reduce((prev, curr) => {
      return prev.createdAtTimestamp > curr.createdAtTimestamp ? prev : curr
    })
  })

  return reports
})

export const selectCurrentTestReports = createSelector(progressReportsState, (state: IProgressReportsState) => state.currentTestReports)

export const selectTimeLogs = createSelector(progressReportsState, (state: IProgressReportsState) => state.timeLogs)

export const selectCurrentTimeLogs = createSelector(progressReportsState, (state: IProgressReportsState) => state.currentTimeLogs)

export const selectDetailedReports = createSelector(progressReportsState, (state: IProgressReportsState) => state.detailedReports)
