import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { IReport, IReportPage } from 'src/app/models/Report.model'
import { NotificationService } from 'src/app/services/notification.service'
import { ReportsService } from '../../services/reports.service'
import { EReportsActions, ReportProblem, ReportProblemPage, ReportProblemPageSuccess, ReportProblemSuccess } from '../actions/reports.actions'

@Injectable()
export class ReportsEffects {
  sendReport$: Observable<ReportProblemSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<ReportProblem>(EReportsActions.ReportProblem),
      map((action: ReportProblem) => action.payload),
      mergeMap((payload: IReport) => this._reportsService.reportProblem(payload)),
      mergeMap((report: any) => {
        return of(new ReportProblemSuccess(report))
      }),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  sendReportPage$: Observable<ReportProblemPageSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<ReportProblemPage>(EReportsActions.ReportProblemPage),
      map((action: ReportProblemPage) => action.payload),
      mergeMap((payload: IReportPage) => this._reportsService.reportPageProblem(payload)),
      mergeMap((report: any) => {
        return of(new ReportProblemPageSuccess(report))
      }),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  constructor(private _actions$: Actions, private _reportsService: ReportsService, private _notificationService: NotificationService) {}
}
