import { ISubuser } from './Subuser.model'
import { IUserOrStudent } from './User.model'
import { IThumbnail } from './Avatar.model'
import { IClassroom } from './Classroom.model'

export interface IStudent extends IUserOrStudent {
  subuser: ISubuser
  classroom: IClassroom
  classroomId: number
  id: number
  studentCode: string
  incompleteAssignments: number
  totalAssignments: number
}

export interface IStudentRequest {
  nickname: string
  ageRange: string
  studentCode: string
  schoolId: number
  classroomId: number
}

export function isStudent(object: any): object is IStudent {
  return 'studentCode' in object.user
}
