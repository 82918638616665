<article class="star-widget">
  <div *ngIf="!userStars" class="title">{{ 'HOME.REVIEWS_STARS.TITLE' | translate }}</div>
  <div *ngIf="userStars" class="user-rate">{{ 'HOME.REVIEWS_STARS.TEXT' | translate }} {{ userStars }} {{ 'HOME.REVIEWS_STARS.STARS' | translate }}</div>
  <div class="average">
    <span>{{ 'HOME.REVIEWS_STARS.AVERAGE' | translate }}: {{ average }}</span>
    <span> ({{ pageStars }} {{ 'HOME.REVIEWS_STARS.VOTES' | translate }})</span>
  </div>

  <div class="rate-cont">
    <div class="rate2" *ngFor="let star of stars; let i = index" [ngClass]="{ average: avg === star, userrate: userStars === star }">
      <span class="weight star" (click)="countStar(star)"></span>
    </div>
  </div>
</article>
