import { Component, Inject, OnInit } from '@angular/core'
import { LocalizeHelperService } from "src/app/services/localizHelper.service"
import { TranslateService } from '@ngx-translate/core'
import { FormatTextService } from '../../../services/formatText.service'
import { DOCUMENT } from "@angular/common"
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  selector: 'localization',
  templateUrl: 'localization.component.html',
  styleUrls: ['localization.component.scss'],
})
export class LocalizationComponent implements OnInit {

  public localizDropdown = false

  constructor(
    private localizeHelperService: LocalizeHelperService,
    public subUserStoreService: SubUserStoreService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private dom,
    private textService: FormatTextService
  ) { }

  ngOnInit() { }

  openLocalizationDropdown(event: Event) {
    this.localizDropdown = true
    event.stopPropagation()
  }

  selectLocalization(event: Event, lang) {
    this.localizeHelperService.setLang(lang)

    this.localizDropdown = false
    this.setDirAttr(lang)
    event.stopPropagation()
  }

  closeDropdown(event: Event) {
    this.localizDropdown = false
    event.stopPropagation()
  }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  setDirAttr(lang) {
    this.dom.body.setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr')
  }
}
