<section
  [ngClass]="{
    test: test
  }"
  class="game audioToPicture"
>
  <div class="game-help-text" (click)="audiohelpPlay()">
    <button mat-icon-button color="primary">
      <audio #audiohelp>
        <source src="/assets/images/sounds/instructions/{{ currentlang }}/ATI.mp3" type="audio/mp3" />
      </audio>
      <img src="/assets/images/games/audio4x.png" alt="audio icon" />
    </button>
    {{ 'GAME.PICTURE_TO_AUDIO.HELP_TEXT' | translate }}
  </div>
  <ng-container *ngIf="game">
    <img class="trees left" src="/assets/images/games/audio-to-picture/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/audio-to-picture/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/audio-to-picture/gameBackground3.svg" alt=" " />
  </ng-container>
  <ng-container *ngIf="test">
    <img class="trees left" src="/assets/images/tests/testBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/tests/testBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/tests/testBackground3.svg" alt=" " />
  </ng-container>
  <article class="contentWrapper">
    <div class="card">
      <div class="image" [ngStyle]="{ 'background-color': cardColors[0] }">
        <img [src]="altImage[0]" alt="{{ question.text }}" *ngIf="imgType != 'json'" />
        <ng-container *ngIf="imgType == 'json'">
          <ng-lottie *ngFor="let altm of altImage" class="lottie_p" style="width: 100%; height: 100%" [options]="{ path: altm }"> </ng-lottie>
        </ng-container>
      </div>
      <span class="title" *ngIf="game">{{ question.text }}</span>
    </div>
    <div class="audios answers fixed">
      <div class="audio" *ngFor="let answer of altAnswers" (click)="selectAnswer(answer)" [ngClass]="{ 'helpActivePlus': audiohelpIsPlaying }">
        <img src="/assets/images/games/btn_audio.svg" alt="sound icon" [ngClass]="{ 'active': !checked && selectedAnswer?.id !== answer.id }" />
        <img src="/assets/images/games/btn_audio_act.svg" alt="sound icon selected" [ngClass]="{ 'active': !checked && selectedAnswer?.id === answer.id }" />
        <img src="/assets/images/games/btn_audio.svg" alt="sound icon" [ngClass]="{ 'active': checked && selectedAnswer?.id !== answer.id && !answer.correct }" />
        <img src="/assets/images/games/correct_one.svg" alt="sound icon was correct" [ngClass]="{ 'active': checked && selectedAnswer?.id !== answer.id && answer.correct }" />
        <img src="/assets/images/games/btn_audio_correct.svg" alt="sound icon is correct" [ngClass]="{ 'active': checked && selectedAnswer?.id === answer.id && answer.correct }" />
        <img src="/assets/images/games/btn_audio_incorrect.svg" alt="sound icon is incorrect" [ngClass]="{ 'active': checked && selectedAnswer?.id === answer.id && !answer.correct }" />
      </div>
    </div>
  </article>
  <div class="balance-row"></div>
  <ng-container>
    <div *ngIf="checked && checkRightAnswer" class="anim stars">
      <img src="/assets/images/anim/check_stars.png" alt="check" />
    </div>
    <div class="continue" [ngClass]="{ 'continue-visible': selectedAnswer && !checked }" *ngIf="!checked" (click)="checkAnswer()" mat-button>
      <img src="/assets/images/games/check_cta.svg" alt="check icon" />
      <span>{{ 'BUTTONS.CHECK' | translate }}</span>
    </div>
    <div appSounds class="continue" [ngClass]="{ 'continue-visible': checked }" *ngIf="checked" (click)="setAnswer(selectedAnswer)" mat-button>
      <img src="/assets/images/games/continue_cta.svg" alt="continue icon" />
      <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
    </div>
  </ng-container>
</section>
