import { ClassroomActions, EClassroomActions } from '../actions/classrooms.actions'
import { IClassroomState, InitialClassroomState } from '../state/classrooms.state'

export const classroomsReducers = (state = InitialClassroomState, action: ClassroomActions): IClassroomState => {
  switch (action.type) {
    case EClassroomActions.GetClassrooms: {
      return {
        ...state,
      }
    }

    case EClassroomActions.GetClassroomsSuccess: {
      return {
        ...state,
        classrooms: action.payload,
      }
    }

    case EClassroomActions.UpdateClassroomSuccess: {
      const newState = state.classrooms.map((i) => {
        if (i.id === action.payload.updatedClassroom.id) {
          return action.payload.updatedClassroom
        }
        return i
      })
      return {
        ...state,
        classrooms: newState,
      }
    }

    case EClassroomActions.AddClassroomSuccess: {
      return {
        ...state,
        classrooms: state.classrooms.concat([action.payload.classroom]),
      }
    }

    case EClassroomActions.RemoveClassroomSuccess: {
      return {
        ...state,
        classrooms: state.classrooms.filter((c) => c.id !== action.payload.deletedClassroom.id),
      }
    }

    default:
      return state
  }
}
