import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators'
import { IGetUnitWithLessonsRequest } from 'src/app/models/Lesson.model'
import { LessonsService } from 'src/app/services/lessons.service'
import { NotificationService } from 'src/app/services/notification.service'
import {
  CompleteLesson,
  CompleteLessonSuccess,
  CompleteUnit,
  CompleteUnitSuccess,
  ELessonsActions,
  GetAvailableLocales,
  GetAvailableLocalesSuccess,
  GetLesson,
  GetLessons,
  GetLessonsSuccess,
  GetLessonSuccess,
  GetLocalizations,
  GetLocalizationsSuccess,
  GetPrints,
  GetPrintsSuccess,
  GetUnitWithLessons,
  GetUnitWithLessonsSuccess,
} from '../actions/lessons.actions'

@Injectable()
export class LessonsEffects {
  getLessons$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetLessons>(ELessonsActions.GetLessons),
      map((action: GetLessons) => action.payload),
      mergeMap((request: any) => this._lessonsService.getUnitsWithLessons(request)),
      exhaustMap((units: any) => of(new GetLessonsSuccess(units))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getUnitWithLessons$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetUnitWithLessons>(ELessonsActions.GetUnitWithLessons),
      map((action: GetUnitWithLessons) => action.payload),
      mergeMap((request: IGetUnitWithLessonsRequest) => this._lessonsService.getUnitWithLessons(request)),
      switchMap((units: any) => of(new GetUnitWithLessonsSuccess(units))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getPrints$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetPrints>(ELessonsActions.GetPrints),
      map((action: GetPrints) => action.payload),
      mergeMap((request: any) => this._lessonsService.getPrints(request)),
      mergeMap((prints: any) => of(new GetPrintsSuccess(prints))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getLesson$: Observable<GetLessonSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetLesson>(ELessonsActions.GetLesson),
      map((action: GetLesson) => action.payload),
      switchMap((request: any) => this._lessonsService.getLesson(request)),
      switchMap((lesson: any) => of(new GetLessonSuccess(lesson))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  completeLesson$: Observable<CompleteLessonSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<CompleteLesson>(ELessonsActions.CompleteLesson),
      map((action: CompleteLesson) => action.payload),
      mergeMap((payload: any) => this._lessonsService.completeLesson(payload)),
      mergeMap((response: any) => of(new CompleteLessonSuccess(response))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  completeUnit$: Observable<CompleteUnitSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<CompleteUnit>(ELessonsActions.CompleteUnit),
      map((action: CompleteUnit) => action.payload),
      mergeMap((payload: any) => this._lessonsService.completeUnit(payload)),
      mergeMap((response: any) => of(new CompleteUnitSuccess(response))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getLocalizations: Observable<GetLocalizationsSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetLocalizations>(ELessonsActions.GetLocalizations),
      switchMap((action: GetLocalizations) => this._lessonsService.getLocalizations(action.lessonId, action.locale)),
      switchMap((localizations: Array<object>) => of(new GetLocalizationsSuccess(localizations))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getAvailableLocales$: Observable<GetAvailableLocalesSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAvailableLocales>(ELessonsActions.GetAvailableLocales),
      map((action: GetAvailableLocales) => action.lessonId),
      switchMap((lessonId: number) => this._lessonsService.getAvailableLocales(lessonId)),
      switchMap((locales: Array<string>) => of(new GetAvailableLocalesSuccess(locales))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  constructor(private _actions$: Actions, private _lessonsService: LessonsService, private _notificationService: NotificationService) { }
}
