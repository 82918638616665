<div class="addSubuser">
  <div class="actions">
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>

    <span class="title">{{ 'CHILD.ADD_NEW_USER' | translate }}</span>
  </div>

  <div class="content">
    <div class="form">
      <div class="placeholder" (click)="openSelectAvatarPopup()" matTooltip=" {{ 'AVATAR.SELECT' | translate }}">
        <ng-container *ngIf="subuserForm.get('avatar').value && isLottie(subuserForm.get('avatar').value.src)">
          <ng-lottie *ngFor="let ava of avatars" class="ava-img" style="width: 100%; height: 100%" [options]="{ path: ava }"> </ng-lottie>
        </ng-container>

        <mat-icon *ngIf="!subuserForm.get('avatar').value">add_photo_alternate</mat-icon>
      </div>
      <form [formGroup]="subuserForm">
        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="nickname">{{ 'PROFILE.NAME' | translate }}<span class="required">*</span></label>
            <input type="text" id="nickname" placeholder="Enter name or nickname" required formControlName="nickname" minlength="4" maxlength="10" />
            <div class="white" *ngIf="subuserForm.controls.nickname.invalid && (subuserForm.controls.nickname.dirty || subuserForm.controls.nickname.touched)">{{ 'CREATE_CHILD.INVALID_NAME' | translate }}<span class="required">*</span></div>
          </div>
        </div>

        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="ageRange">{{ 'PROFILE.AGE' | translate }}<span class="required">*</span></label>
            <mat-select formControlName="ageRange" (openedChange)="onAgeDropdownToggle($event)" (selectionChange)="onAgeSelectionChange($event)" required placeholder="{{ 'PLACEHOLDERS.AGE' | translate }}">
              <mat-option value="3"><span>2-5 years </span><span *ngIf="ageDropdownOpened" class="small-tip">videos and books only</span></mat-option>
              <mat-option value="6"><span>6-10 years </span><span *ngIf="ageDropdownOpened" class="small-tip">full experience</span></mat-option>
              <mat-option value="12"><span>11+ years </span><span *ngIf="ageDropdownOpened" class="small-tip">books/games/test only</span></mat-option>
            </mat-select>
          </div>
        </div>

        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label>{{ 'PLACEHOLDERS.SELECT_COURSE' | translate }}<span class="required">*</span></label>
            <mat-select placeholder="{{ 'PLACEHOLDERS.CLASSROOM_COURSE' | translate }}">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let course of courses$ | async" [value]="course.id" (click)="selectCourse(course)">
                {{ course.name }}
                <img class="langSelectImg" src="/assets/images/languages/{{ course.slug }}.png" alt="select {{ course.slug }} language" />
              </mat-option>
            </mat-select>
          </div>
        </div>
      </form>
    </div>

    <div class="subscribe">
      <button mat-raised-button color="accent" [disabled]="!subuserForm.valid" (click)="createSubuser(subuserForm)">
        {{ 'CHILD.CREATE' | translate }}
      </button>
    </div>
  </div>
</div>
