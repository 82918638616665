import { ESchoolActions, SchoolActions } from '../actions/school.actions'
import { InitialSchoolState, ISchoolState } from '../state/school.state'

export const schoolReducers = (state = InitialSchoolState, action: SchoolActions): ISchoolState => {
  switch (action.type) {
    case ESchoolActions.GetSchoolSuccess: {
      return action.payload
    }

    case ESchoolActions.UpdateNumberAllocableStudents: {
      return {
        ...state,
        allocableStudents: action.newNumber,
      }
    }

    case ESchoolActions.AddAllocableStudents: {
      const newState = {
        ...state,
        allocableStudents: state.allocableStudents + action.number,
      }
      console.dir(newState)
      return newState
    }

    case ESchoolActions.SubtractAllocableStudents: {
      return {
        ...state,
        allocableStudents: state.allocableStudents - action.number,
      }
    }

    default:
      return state
  }
}
