import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { ISubuserRequest } from '../models/Subuser.model'

@Injectable({
  providedIn: 'root',
})
export class SubuserService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = `${environment.gatewayUrl}subuser/`
  }

  public createSubuser(request: ISubuserRequest) {
    return this.http.post(`${this.gatewayUrl}create`, request)
  }

  public getAllSubuser() {
    return this.http.get(`${this.gatewayUrl}all`)
  }

  public getSubuser(subuserId) {
    return this.http.get(this.gatewayUrl + subuserId)
  }

  public removeSubuser(id: number | string) {
    return this.http.delete(`${this.gatewayUrl}remove/${id}`)
  }

  public updateSubuser(request) {
    return this.http.post(`${this.gatewayUrl}edit`, request)
  }

  public updateSubuserAvatar(avatar: any) {
    return this.http.put(`${this.gatewayUrl}avatar`, {
      avatarId: avatar.id,
      subuserId: avatar.subuserId,
    })
  }

  public setCurrentUnit(request: any) {
    return this.http.put(`${this.gatewayUrl}currentUnit`, {
      unitId: request.unitId,
      subuserId: request.subuserId,
      courseId: request.courseId,
    })
  }

  public addPoints(request: any) {
    return this.http.put(`${this.gatewayUrl}points`, {
      points: request.points,
      subuserId: request.subuserId,
    })
  }

  public addCourseToSubuser(request) {
    return this.http.post(`${this.gatewayUrl}course/add`, request)
  }

  public deleteCourseFromSubuser(request) {
    return this.http.post(`${this.gatewayUrl}course/delete`, request)
  }

  public getSubuserStatistics(request) {
    const { subuserId, courseId } = request

    return this.http.get(`${this.gatewayUrl}statistics/personal`, {
      params: {
        subuserId,
        courseId,
      },
    })
  }

  public getSubuserReport(request) {
    return this.http.get(`${this.gatewayUrl}statistics/reports`, {
      params: {
        subuserId: request.subuserId,
        courseId: request.courseId,
      },
    })
  }

  public removeSubuserStatistic(request) {
    return this.http.delete(`${this.gatewayUrl}statistics`, {
      params: request,
    })
  }

  public getSubuserDinosaurs(subuserId: number) {
    return this.http.get(`${this.gatewayUrl}statistics/dinosaurs/${subuserId}`)
  }

  public getSubuserOpenDinosaurs(subuserId: number) {
    return this.http.get(`${this.gatewayUrl}statistics/opendinosaurs/${subuserId}`)
  }

  public getSubuserAchievements(subuserId: number) {
    return this.http.get(`${this.gatewayUrl}statistics/achievements/${subuserId}`)
  }

  public getSubuserStars(subuserId: number) {
    return this.http.get(`${this.gatewayUrl}statistics/stars/${subuserId}`)
  }

  public unlockLessons(request) {
    return this.http.put(`${this.gatewayUrl}options/lessonsMode`, request)
  }

  public getAllSubuserCourses() {
    return this.http.get(`${this.gatewayUrl}courses`)
  }
}
