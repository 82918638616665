import { createSelector } from '@ngrx/store'
import { IAppState } from '../state/app.state'
import { ICourseState } from '../state/courses.state'

const coursesState = (state: IAppState) => state.courses

export const selectAllCourses = createSelector(coursesState, (state: ICourseState) => state.courses)

export const selectCurrentCourse = createSelector(coursesState, (state: ICourseState) => state.selectedCourse)

// export const selectCurrentUnit = createSelector(
//     coursesState,
//     (state: ICourseState) => state.currentUnit
// )
