<div class="report">
  <div class="actions">
    <span class="title">No Response from website</span>

    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
  </div>

  <div class="content">
    <p>We are currently working on improving the website, work will be restored soon</p>
  </div>
</div>
