<div class="landing">
  <section class="mainComponent">
    <img src="assets/images/pictures/trees.png" class="trees" alt="trees" title="trees" />
    <img src="assets/images/pictures/mountain.png" class="mountain" alt="mountain" title="mountain" />
    <span class="rectRhombus orange"></span>
    <span class="rectRhombus small green"></span>
    <span class="rectCicrle red"></span>
    <span class="rectRhombus extraSmall orange"></span>
    <img src="assets/images/dinosaurs/oneDino.png" class="dino svgImage" />
    <article class="contentWrapper">
      <div class="text">
        <svg-icon [applyClass]="true" src="assets/images/dinosaurs/oneDino.svg"></svg-icon>
        <div class="titles">
          <h1 class="white baloo2">{{ 'HOMESCHOOLERS.TITLE' | translate }}</h1>
          <span [innerHTML]="'HOMESCHOOLERS.SUBTITLE' | translate"></span>
          <button class="primary orange">
            <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
          </button>
        </div>
      </div>
    </article>
  </section>

  <section class="gray">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" title="borderWhite" />
  </section>

  <section class="homeschooler">
    <article class="contentWrapper">
      <h2 class="blue baloo2">
        {{ 'HOMESCHOOLERS.TITLE' | translate }}
      </h2>
      <div class="text">
        <p [innerHTML]="'HOMESCHOOLERS.BLOCK_1.STR_1' | translate"></p>
        <p [innerHTML]="'HOMESCHOOLERS.BLOCK_1.STR_2' | translate"></p>
        <p [innerHTML]="'HOMESCHOOLERS.BLOCK_1.STR_3' | translate"></p>
      </div>
      <button class="primary orange">
        <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
      </button>
    </article>
  </section>
  <section class="gray" [ngClass]="{ hideBlock: currentLang !== 'en' }">
    <article class="contentWrapper">
      <h2 class="blue baloo2">
        {{ 'HOMESCHOOLERS.BLOCK_2.TITLE' | translate }}
      </h2>
      <div class="subtitle">
        <p [innerHTML]="'HOMESCHOOLERS.BLOCK_2.SUBTITLE' | translate"></p>
      </div>
      <div class="info">
        <div class="text">
          <p [innerHTML]="'HOMESCHOOLERS.BLOCK_2.STR_1' | translate"></p>
          <p [innerHTML]="'HOMESCHOOLERS.BLOCK_2.STR_2' | translate"></p>
          <p [innerHTML]="'HOMESCHOOLERS.BLOCK_2.STR_3' | translate"></p>
          <p [innerHTML]="'HOMESCHOOLERS.BLOCK_2.STR_4' | translate"></p>
          <p [innerHTML]="'HOMESCHOOLERS.BLOCK_2.STR_5' | translate"></p>
        </div>
        <div class="image">
          <img src="assets/images/dinosaurs/flyingDino.svg" class="flyingDino" alt="Flying Dino" title="Flying Dino" />
        </div>
        <button class="primary orange">
          <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
        </button>
      </div>
    </article>
    <img src="assets/images/pictures/borderWhite.png" class="border top" alt="" />
  </section>
  <section class="gray"><review-stars [schemeTitle]="metaTitle"></review-stars></section>
</div>
