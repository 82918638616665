export interface IProgressReportsState {
  testReports: []
  currentTestReports: []
  timeLogs: []
  currentTimeLogs: []
  detailedReports: []
}

export const InitialProgressReportsState: IProgressReportsState = {
  testReports: [],
  currentTestReports: [],
  timeLogs: [],
  currentTimeLogs: [],
  detailedReports: [],
}
