import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { StarsPopupComponent } from './stars.component'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, MatProgressBarModule, MatSelectModule, MatDialogModule, MatInputModule, MatButtonModule, MatIconModule, ReactiveFormsModule, TranslateModule],
  declarations: [StarsPopupComponent],
  providers: [],
})
export class StarsPopupModule {}
