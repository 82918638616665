import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ParentsNotificationsComponent } from './notifications.component'
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, MatSlideToggleModule, TranslateModule],
  declarations: [ParentsNotificationsComponent],
  providers: [],
})
export class ParentsNotificationsModule {}
