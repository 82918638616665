import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { select, Store } from '@ngrx/store'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { IAppState } from 'src/app/store/state/app.state'
import { IReport, IReportPage } from 'src/app/models/Report.model'
import { ReportProblem, ReportProblemSuccess, EReportsActions, ReportProblemPage } from 'src/app/store/actions/reports.actions'
import { Subject } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntil } from 'rxjs/operators'
import { NotificationService } from 'src/app/services/notification.service'
import { TranslateService } from '@ngx-translate/core'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { Router } from '@angular/router'
import { selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { ISubuser } from 'src/app/models/Subuser.model'
import { ICourse } from 'src/app/models/Course.model'
import { selectCurrentCourse } from 'src/app/store/selectors/courses.selectors'
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.scss'],
})
export class ReportProblemPopupComponent implements OnInit, OnDestroy {
  public reportForm: UntypedFormGroup
  private destroyed$ = new Subject<boolean>()
  public userId: number
  public url: boolean
  public currentSubuser: ISubuser
  public currentCourse: ICourse
  private unsubscribe$ = new Subject()
  public selectedType

  public userEmail = ''
  public userName = ''

  public descriptionLable1: String = ''
  public useAlert = false
  public isBook = false

  public deviceInfo = this.deviceService.getDeviceInfo()

  public fieldsText = [
    { text: 'WRONG ANSWER', desc1: 'Please explain' },
    { text: 'TEXT ISSUE', desc1: 'Please explain' },
    { text: 'IMAGE ISSUE', desc1: 'Please explain' },
    { text: 'AUDIO ISSUE', desc1: 'Please explain' },
    { text: 'NO AUDIO', desc1: 'NO AUDIO?<br>1. Do not click too fast.<br>2. Make sure you have fast, steady internet connection.<br>If problem persists, please tell us what device and browser you are using.', alert: true },
    { text: 'OTHER', desc1: 'Please explain' },
  ]

  constructor(
    private updates$: Actions,
    public dialogRef: MatDialogRef<ReportProblemPopupComponent>,
    private _store: Store<IAppState>,
    public notifications: NotificationService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public question: any,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _router: Router,
    private deviceService: DeviceDetectorService
  ) {
    this.updates$.pipe(ofType<ReportProblemSuccess>(EReportsActions.ReportProblemSuccess), takeUntil(this.destroyed$))

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((res) => {
      this.userEmail = res.user.email
      this.userName = `${res.user.firstName} ${res.user.lastName}`
      this.userId = res.user.id
    })
    if (_router.url.includes('lesson') && !_router.url.includes('lessons') && !_router.url.includes('reports')) {
      this.url = true
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)).subscribe((subuser) => {
      this.currentSubuser = subuser
    })
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentCourse)).subscribe((course) => {
      this.currentCourse = course
    })

    if (this.data && this.data.bookId) {
      this.isBook = true
      this.reportForm = new UntypedFormGroup({
        userId: new UntypedFormControl(this.userId),
        subuserId: new UntypedFormControl(+this.currentSubuser.id),
        subuserCourse: new UntypedFormControl(this.currentCourse.name),
        url: new UntypedFormControl(this.url),
        reason: new UntypedFormControl('', [Validators.required]),
        description: new UntypedFormControl('', [Validators.required]),
        bookId: new UntypedFormControl(this.data.bookId),
        pageNumber: new UntypedFormControl(this.data.pageNumber),
        bookName: new UntypedFormControl(this.data.bookName),
        browser: new UntypedFormControl(this.deviceInfo.browser),
        userAgent: new UntypedFormControl(this.deviceInfo.userAgent),
      })
    } else if (this.url) {
      this.reportForm = new UntypedFormGroup({
        userId: new UntypedFormControl(this.userId),
        subuserId: new UntypedFormControl(Number(this.currentSubuser.id)),
        questionId: new UntypedFormControl(this.question.id ? this.question.id : this.question[0].id, [Validators.required]),
        reason: new UntypedFormControl('', [Validators.required]),
        description: new UntypedFormControl('', [Validators.required]),
        browser: new UntypedFormControl(this.deviceInfo.browser),
        userAgent: new UntypedFormControl(this.deviceInfo.userAgent),
      })
    } else {
      this.reportForm = new UntypedFormGroup({
        userId: new UntypedFormControl(this.userId),
        subuserId: new UntypedFormControl(+this.currentSubuser.id),
        subuserCourse: new UntypedFormControl(this.currentCourse.name),
        url: new UntypedFormControl(''),
        reason: new UntypedFormControl('', [Validators.required]),
        description: new UntypedFormControl('', [Validators.required]),
        browser: new UntypedFormControl(this.deviceInfo.browser),
        userAgent: new UntypedFormControl(this.deviceInfo.userAgent),
      })
    }
  }

  setDescription(index) {
    this.descriptionLable1 = this.fieldsText[index].desc1
    this.useAlert = !!this.fieldsText[index].alert
  }

  sendReport(form: UntypedFormGroup) {
    if (form.invalid) return

    const request: IReport = form.value

    this.notifications.showNotification(`This problem is reported by: email: ${this.userEmail} username: ${this.userName}`, 5)

    this._store.dispatch(new ReportProblem(request))

    this.dialogRef.close()
  }

  sendReportPages(form: UntypedFormGroup) {
    if (form.invalid) return

    form.patchValue({ url: this._router.url })

    const request: IReportPage = form.value

    this._store.dispatch(new ReportProblemPage(request))

    this.notifications.showNotification(`This problem is reported by: email: ${this.userEmail} username: ${this.userName}`, 5)

    this.dialogRef.close()
  }
}
