import { Action } from '@ngrx/store'
import { IReport, IReportPage } from 'src/app/models/Report.model'

export enum EReportsActions {
  ReportProblem = '[Reports] Report Problem',
  ReportProblemSuccess = '[Reports] Report Problem Success',
  ReportProblemPage = '[Reports] Report Problem Page',
  ReportProblemPageSuccess = '[Reports] Report Problem Page Success',
}

export class ReportProblem implements Action {
  public readonly type = EReportsActions.ReportProblem
  constructor(public payload: IReport) {}
}

export class ReportProblemSuccess implements Action {
  public readonly type = EReportsActions.ReportProblemSuccess
  constructor(public payload: any) {}
}

export class ReportProblemPage implements Action {
  public readonly type = EReportsActions.ReportProblemPage
  constructor(public payload: any) {}
}

export class ReportProblemPageSuccess implements Action {
  public readonly type = EReportsActions.ReportProblemPageSuccess
  constructor(public payload: any) {}
}

export type CoursesActions = ReportProblemSuccess | ReportProblem | ReportProblemPage | ReportProblemPageSuccess
