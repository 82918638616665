import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class defaultLanguageResolver implements Resolve<string> {
  constructor(private localizeHelperService: LocalizeHelperService, public translate: TranslateService) { }

  public resolve(route: ActivatedRouteSnapshot) {
    let currentLanguage = localStorage.getItem('currentLanguage')
    if (currentLanguage) {
      this.localizeHelperService.setURLLang(currentLanguage, false)

      return currentLanguage
    }

    const browserLanguage = this.translate.getBrowserLang()
    if (browserLanguage && this.localizeHelperService.localizationAllowed.includes(browserLanguage)) {
      this.localizeHelperService.setURLLang(browserLanguage, false)

      return browserLanguage
    }
  }
}
