import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import { ICourse } from 'src/app/models/Course.model'
import { CoursesService } from 'src/app/services/courses.service'
import { NotificationService } from 'src/app/services/notification.service'
import { ECoursesActions, GetAllCourses, GetAllCoursesSuccess } from '../actions/courses.actions'

@Injectable()
export class CoursesEffects {
  getCourses$: Observable<GetAllCoursesSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAllCourses>(ECoursesActions.GetAllCourses),
      mergeMap(() => this._coursesService.getAllCourses()),
      mergeMap((courses: Array<ICourse>) => {
        return of(new GetAllCoursesSuccess(courses))
      }),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  constructor(private _actions$: Actions, private _coursesService: CoursesService, private _notificationService: NotificationService) {}
}
