<div class="addTeacher">
  <div class="actions">
    <span class="title">{{ 'TEACHER.CREATE' | translate }} </span>
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>

  <div class="content">
    <div class="form">
      <form [formGroup]="form">
        <mat-form-field class="firstName">
          <mat-label>{{ 'TEACHER.FIRST_NAME' | translate }} </mat-label>
          <input matInput placeholder="{{ 'PLACEHOLDERS.FIRST_NAME' | translate }}" required formControlName="firstName" />
        </mat-form-field>
        <mat-form-field class="lastName">
          <mat-label>{{ 'TEACHER.LAST_NAME' | translate }} </mat-label>
          <input matInput placeholder="{{ 'PLACEHOLDERS.LAST_NAME' | translate }}" required formControlName="lastName" />
        </mat-form-field>
        <mat-form-field class="email">
          <mat-label>{{ 'TEACHER.EMAIL' | translate }} </mat-label>
          <input matInput placeholder="{{ 'PLACEHOLDERS.EMAIL' | translate }}" required formControlName="email" />
        </mat-form-field>
        <mat-form-field class="password">
          <mat-label>{{ 'TEACHER.PASSWORD' | translate }} </mat-label>
          <input matInput placeholder="{{ 'PLACEHOLDERS.PASSWORD' | translate }}" required formControlName="password" />
        </mat-form-field>

        <mat-error *ngIf="form.controls.email.getError('duplicate')">{{ 'TEACHER.ERROR_DUPLICATE' | translate }}</mat-error>
      </form>
    </div>
  </div>

  <div class="save">
    <button mat-raised-button color="accent" [disabled]="!form.valid" (click)="createTeacher(form)">
      {{ 'TEACHER.CREATE' | translate }}
    </button>
  </div>
</div>
