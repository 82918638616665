<div class="landing">
  <section class="mainComponent">
    <img src="assets/images/pictures/trees.png" class="trees" alt="trees" title="Trees" />
    <img src="assets/images/pictures/mountain.png" class="mountain" alt="mountain" title="Mountain" />
    <span class="rectRhombus orange"></span>
    <span class="rectRhombus small green"></span>
    <span class="rectCicrle red"></span>
    <span class="rectRhombus extraSmall orange"></span>
    <img src="assets/images/dinosaurs/oneDino.png" class="dino svgImage" />
    <article class="contentWrapper">
      <div class="text">
        <svg-icon class="svgImage" [applyClass]="true" src="assets/images/dinosaurs/oneDino.svg"></svg-icon>
        <div class="titles">
          <h1 class="white baloo2">{{ 'LANG_COURSES.TITLE' | translate }}</h1>
          <span>{{ 'LANG_COURSES.SUB_TITLE' | translate }}</span>
          <button class="primary orange">
            <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
          </button>
        </div>
      </div>
    </article>
  </section>

  <section class="gray">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" title="Border White" />
  </section>

  <section class="languageCourses">
    <article class="contentWrapper">
      <a routerLink="{{ getURLLangPart() }}/learn-albanian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/albanian.png" class="circle" alt="flag-of-albanian" title="Albanian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Albanian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-arabic-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/arabic.png" class="circle" alt="flag-of-arabic" title="Arabic" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Arabic' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-armenian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/armenian.png" class="circle" alt="flag-of-armenian" title="Armenian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Armenian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-bulgarian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/bulgarian.png" class="circle" alt="flag-of-bulgarian" title="Bulgarian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Bulgarian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-cantonese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/cantonese.png" class="circle" alt="flag-of-cantonese" title="Cantonese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Cantonese' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-chinese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/chinese.png" class="circle" alt="flag-of-chinese" title="Chinese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Chinese' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-creole-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/creole.png" class="circle" alt="flag-of-creole" title="Creole" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Creole' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-croatian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/croatian.png" class="circle" alt="flag-of-croatian" title="Croatian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Croatian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-czech-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/czech.png" class="circle" alt="flag-of-czech" title="Czech" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Czech' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-danish-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/danish.png" class="circle" alt="flag-of-danish" title="Danish" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Danish' | translate }}</span>
      </a>

      <a routerLink="{{ getURLLangPart() }}/learn-dutch-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/dutch.png" class="circle" alt="flag-of-dutch" title="Dutch" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Dutch' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-english-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/english.png" class="circle" alt="flag-of-english" title="English" />
        </div>
        <span>{{ 'LANGUAGE_NAME.English' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-euportuguese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/portuguese-eu.png" class="circle" alt="flag-of-portuguese" title="Portuguese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Portuguese_eu' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-finnish-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/finnish.png" class="circle" alt="flag-of-finnish" title="Finnish" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Finnish' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-french-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/french.png" class="circle" alt="flag-of-french" title="French" />
        </div>
        <span>{{ 'LANGUAGE_NAME.French' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-german-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/german.png" class="circle" alt="flag-of-german" title="German" />
        </div>
        <span>{{ 'LANGUAGE_NAME.German' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-greek-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/greek.png" class="circle" alt="flag-of-greek" title="Greek" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Greek' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-gujarati-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/hindi.png" class="circle" alt="flag-of-hindi" title="Hindi" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Gujarati' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-hawaiian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/hawaiian.png" class="circle" alt="flag-of-hawaiian" title="Hawaiian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Hawaiian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-hebrew-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/hebrew.png" class="circle" alt="flag-of-hebrew" title="Hebrew" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Hebrew' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-hindi-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/hindi.png" class="circle" alt="flag-of-hindi" title="Hindi" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Hindi' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-hungarian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/hungarian.png" class="circle" alt="flag-of-hungarian" title="Hungarian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Hungarian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-indonesian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/indonesian.png" class="circle" alt="flag-of-indonesian" title="Indonesian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Indonesian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-irish-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/irish.png" class="circle" alt="flag-of-irish" title="Irish" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Irish' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-italian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/italian.png" class="circle" alt="flag-of-italian" title="Italian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Italian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-japanese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/japanese.png" class="circle" alt="flag-of-japanese" title="Japanese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Japanese' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-korean-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/korean.png" class="circle" alt="flag-of-korean" title="Korean" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Korean' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-latin-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/latin.png" class="circle" alt="flag-of-latin" title="Latin" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Latin' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-malay-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/malay.png" class="circle" alt="flag-of-malay" title="Malay" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Malay' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-norwegian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/norwegian.png" class="circle" alt="flag-of-norwegian" title="Norwegian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Norwegian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-persian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/persian.png" class="circle" alt="flag-of-persian" title="Farsi - Persian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Persian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-polish-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/polish.png" class="circle" alt="flag-of-polish" title="Polish" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Polish' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-portuguese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/portuguese-br.png" class="circle" alt="flag-of-portuguese" title="Portuguese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Portuguese' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-punjabi-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/hindi.png" class="circle" alt="flag-of-hindi" title="Hindi" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Punjabi' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-romanian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/romanian.png" class="circle" alt="flag-of-romanian" title="Romanian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Romanian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-russian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/russian.png" class="circle" alt="flag-of-russian" title="Russian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Russian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-serbian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/serbian.png" class="circle" alt="flag-of-serbian" title="Serbian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Serbian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-slovak-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/slovak.png" class="circle" alt="flag-of-slovak" title="Slovak" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Slovak' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-slovenian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/slovenian.png" class="circle" alt="flag-of-slovenian" title="Slovenian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Slovenian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-spanish-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/spanish.png" class="circle" alt="flag-of-spanish" title="Spanish" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Spanish' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-swahili-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/swahili.png" class="circle" alt="flag-of-swahili" title="Swahili" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Swahili' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-swedish-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/swedish.png" class="circle" alt="flag-of-swedish" title="Swedish" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Swedish' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-tagalog-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/tagalog.png" class="circle" alt="flag-of-tagalog" title="Tagalog" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Tagalog' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-thai-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/thai.png" class="circle" alt="flag-of-thai" title="Thai" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Thai' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-turkish-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/turkish.png" class="circle" alt="flag-of-turkish" title="Turkish" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Turkish' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-ukrainian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/ukrainian.png" class="circle" alt="flag-of-ukrainian" title="Ukrainian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Ukrainian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-urdu-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/urdu.png" class="circle" alt="flag-of-urdu" title="Urdu" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Urdu' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-vietnamese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/vietnamese.png" class="circle" alt="flag-of-vietnamese" title="Vietnamese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Vietnamese' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-welsh-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/welsh.png" class="circle" alt="flag-of-welsh" title="Welsh" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Welsh' | translate }}</span>
      </a>
    </article>
    <review-stars></review-stars>
  </section>
</div>
