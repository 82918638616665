<div class="infoPopup">
  <div class="actions">
    <span *ngIf="!dbBlockData" class="title">{{ 'SUBSCRIPTIONS.SUBSCRIBE_PLEASE' | translate }}</span>
    <span *ngIf="dbBlockData" class="title">{{ dbBlockTitle }}</span>
    <span appSounds class="close" (click)="dialogRef.close()">+</span>
  </div>

  <div class="dinosaur">
    <span *ngIf="!dbBlockData" [innerHTML]="'SUBSCRIPTIONS.NEED_SUBSCRIBE' | translate"></span>
    <span *ngIf="dbBlockData" [innerHTML]="dbBlockText"></span>
  </div>
  <img class="dinosaurImg" src="assets/images/pictures/subscriptions/threeDinosaurs.svg" />
  <div class="subscribe">
    <button appSounds mat-raised-button (click)="openCheckParentPinDialog()" color="accent">
      {{ 'BUTTONS.SUBSCRIBE_NOW' | translate }}
    </button>
  </div>
</div>
