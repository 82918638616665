import { NgModule } from '@angular/core'
import { LessonEndPopupComponent } from './lesson-end.component'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  declarations: [LessonEndPopupComponent],
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule, MatCardModule, MatDialogModule, TranslateModule, SoundsModule, LottieModule],
})
export class LessonEndPopupModule {}
