import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { LessonSubscribePopupComponent } from './lesson-subscribe.component'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'

@NgModule({
  declarations: [LessonSubscribePopupComponent],
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule, MatCardModule, MatButtonModule, MatDialogModule, TranslateModule, SoundsModule],
})
export class LessonSubscribePopupModule {}
