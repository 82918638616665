<section class="tutorials">
  <article class="contentWrapper">
    <div class="title">{{ 'LINKS.TUTORIALS' | translate }}</div>
    <div class="content">
      <div class="search">
        <mat-form-field class="example-form-field">
          <mat-label>{{ 'BUTTONS.SEARCH' | translate }}</mat-label>
          <input matInput type="text" #searchText />
          <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="searchText.value = ''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="list">
        <ng-container *ngFor="let item of tutorials">
          <div *ngIf="!searchText.value || item.title.toLowerCase().includes(searchText.value.toLowerCase())" class="tutorial" (click)="openVideoPopup(item.videoUrl)">
            <div class="thumbnail">
              <img [src]="item.thumbnail.replace('/assets', '/assets/images')" [alt]="item.title" />
            </div>
            <p>{{ item.title }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </article>
</section>
