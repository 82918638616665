import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ClassroomService {
  constructor(private http: HttpClient) {}

  public getClassrooms(schoolId: number) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms`)
  }

  public updateClassroom(schoolId: number, classroomId: number, payload) {
    return this.http.put(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}`, payload)
  }

  public createClassroom(payload: any) {
    return this.http.post(`${environment.gatewayUrl}schools/${payload.schoolId}/classrooms`, payload)
  }

  public removeClassroom(schoolId: number, classroomId: number) {
    return this.http.delete(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}`)
  }
}
