export class SortProgressReportsUtil {
  public static sort(sortMethod: string, items: Array<any>) {
    if (!SortProgressReportsUtil.supports(sortMethod)) {
      throw new Error(`Sort method not supported "${sortMethod}"`)
    }

    switch (sortMethod) {
      case 'name_a-z':
        return SortProgressReportsUtil.sortByNameAsc(items)
      case 'name_z-a':
        return SortProgressReportsUtil.sortByNameDesc(items)
    }
  }

  private static sortByNameAsc(items: Array<any>): Array<any> {
    return items.slice().sort((a, b) => (a.subuser.name > b.subuser.name ? 1 : -1))
  }

  private static sortByNameDesc(items: Array<any>): Array<any> {
    return items.slice().sort((a, b) => (b.subuser.name > a.subuser.name ? 1 : -1))
  }

  private static supports(sortMethod) {
    return ['name_a-z', 'name_z-a'].includes(sortMethod)
  }
}
