import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PracticeTimeComponent } from './practice-time.component'
import { ProgressReportsPipeModule } from '../progress-reports-pipe/progress-reports-pipe.module'
import { TranslateModule } from '@ngx-translate/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { TestResultModule } from '../../../../shared/popups/Lesson-Dialogs/test-result/test-result.module'
import { TestPlanModule } from '../../../../shared/popups/Lesson-Dialogs/test-plan/test-plan.module'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  declarations: [PracticeTimeComponent],
  imports: [CommonModule, ProgressReportsPipeModule, TranslateModule, MatExpansionModule, MatIconModule, TestResultModule, TestPlanModule, LottieModule],
  exports: [PracticeTimeComponent],
})
export class PracticeTimeModule {}
