import { Component, Inject, OnInit } from '@angular/core'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { AnimationOptions } from 'ngx-lottie'

@Component({
  templateUrl: 'dino-view.component.html',
  styleUrls: ['dino-view.component.scss'],
})
export class DinoViewPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DinoViewPopupComponent>, @Inject(MAT_DIALOG_DATA) public dinosaur: any) {}

  options: AnimationOptions = {
    path: this.dinosaur.picture,
  }

  ngOnInit() {}
}
