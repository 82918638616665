import { createSelector } from '@ngrx/store'
import { IAppState } from '../state/app.state'
import { ISubuserState } from '../state/subuser.state'

const subuserState = (state: IAppState) => state.subuser

export const selectSubuserState = createSelector(subuserState, (state: ISubuserState) => state)

export const selectAllSubuser = createSelector(subuserState, (state: ISubuserState) => state.subusers)

export const selectCurrentSubuser = createSelector(subuserState, (state: ISubuserState) => state.currentSubuser)

export const selectSubuser = createSelector(subuserState, (state: ISubuserState) => state.subuser)

export const selectSubuserReport = createSelector(subuserState, (state: ISubuserState) => state.subuserReport)

export const selectCurrentSubuserDetailedReport = createSelector(subuserState, (state: ISubuserState) => state.subuserDetailedReports)

export const selectAllSubuserCourses = createSelector(subuserState, (state: ISubuserState) => state.allCourses)
