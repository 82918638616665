import { Component, OnDestroy } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { NavigationStart, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { rangomSpinner } from '../../../../helpers/utils/spiner.util'

@Component({
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss'],
})
export class CurriculumDialogComponent implements OnDestroy {
  public loaded = false
  public loaderClass: string = rangomSpinner()

  public unsubscribe$ = new Subject()

  constructor(public dialogRef: MatDialogRef<CurriculumDialogComponent>, private _router: Router) {
    _router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(() => this.closeDialog())
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  curriculumLoaded() {
    this.loaded = true
  }

  onNoClick(): void {
    this.closeDialog()
  }

  closeDialog() {
    this.loaded = false
    this.dialogRef.close()
  }
}
