import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { CurriculumModule } from '../../../../views/curriculum/curriculum.module'
import { CurriculumDialogComponent } from './curriculum.component'

@NgModule({
  declarations: [CurriculumDialogComponent],
  imports: [CommonModule, CurriculumModule, MatDialogModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CurriculumDialogModule {}
