import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from '../../../../helpers/directives/sounds-directive/sounds.module'
import { AddClassroomDialogComponent } from './add-classroom.component'

@NgModule({
  declarations: [AddClassroomDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslateModule,
    SoundsModule,
    MatSlideToggleModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AddClassroomDialogModule {}
