import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { BookPlayerComponent } from './media-player.component'
import { RouterModule } from '@angular/router'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle'
import { FormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { BookEndPopupModule } from '../../popups/Lesson-Dialogs/book-end/book-end.module'
import { ReportProblemPopupModule } from '../../popups/Report-Dialogs/report-problem/report-problem.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { MatSliderModule } from '@angular/material/slider'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  declarations: [BookPlayerComponent],

  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    BookEndPopupModule,
    TranslateModule,
    ReportProblemPopupModule,
    SoundsModule,
    MatSliderModule,
    LottieModule,
  ],
  exports: [BookPlayerComponent],
})
export class BookPlayerModule {}
