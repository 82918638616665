import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatBadgeModule } from '@angular/material/badge'
import { MatRippleModule } from '@angular/material/core'
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { MenuPopupModule } from '../../../../shared/popups/Control-Dialogs/menu/menu.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { CurriculumDialogModule } from '../../../../shared/popups/Curriculum-Dialogs/curriculum/curriculum.module'
import { StudentAssignmentsDialogModule } from '../../../../shared/popups/Assignment-Dialogs/student-assignments/student-assignments.module'
import { MobileDashboardComponent } from './mobile-dashboard.component'

@NgModule({
  declarations: [MobileDashboardComponent],

  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatBadgeModule,
    MatMenuModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MenuPopupModule,
    RouterModule,
    TranslateModule,
    SoundsModule,
    CurriculumDialogModule,
    StudentAssignmentsDialogModule,
  ],
  exports: [MobileDashboardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MobileDashboardModule {}
