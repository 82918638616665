<div class="report">
  <div class="actions">
    <span class="title">{{ 'REPORT_PROBLEM.TITLE' | translate }}</span>
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>

  <div class="content">
    <form [formGroup]="reportForm">
      <mat-form-field>
        <mat-label>{{ 'REPORT_PROBLEM.TYPE' | translate }}</mat-label>
        <mat-select [(value)]="selectedType" formControlName="reason">
          <mat-option *ngFor="let text of fieldsText; let i = index" [value]="text.text" (click)="(selectedType); setDescription(i)">
            {{ text.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="description">
        <div>
          <mat-label [innerHTML]="descriptionLable1"></mat-label>
          <textarea rows="5" matInput="matInput" placeholder="details" formControlName="description"></textarea>
        </div>
      </mat-form-field>
    </form>
  </div>

  <div>
    <p><b>Reported by:</b></p>
    <p><span>Email: </span>{{ userEmail }}</p>
    <p><span>Name: </span>{{ userName }}</p>
  </div>

  <div *ngIf="url && !isBook" class="subscribe">
    <button mat-raised-button="mat-raised-button" (click)="sendReport(reportForm)" color="accent" [disabled]="!reportForm.valid">
      {{ 'REPORT_PROBLEM.SEND' | translate }}
    </button>
  </div>
  <div *ngIf="!url || isBook" class="subscribe">
    <button mat-raised-button="mat-raised-button" (click)="sendReportPages(reportForm)" color="accent" [disabled]="!reportForm.valid">
      {{ 'REPORT_PROBLEM.SEND' | translate }}
    </button>
  </div>
</div>
