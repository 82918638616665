<div>
  <video controls controlsList="nodownload" *ngIf="this.data && !this.data.includes('youtube.com')">
    <source [src]="this.data" type="video/mp4" />
  </video>
  <iframe *ngIf="this.data && this.data.includes('youtube.com')" width="100%" height="100%" [src]="this.safeURL" frameborder="0" allowfullscreen (load)="videoLoaded()"></iframe>

  <div class="loaderBlock" *ngIf="spinnerActive">
    <div class="circleDotLoader"></div>
  </div>
</div>
