<div class="landing">
  <section class="mainComponent">
    <img src="assets/images/pictures/trees.png" class="trees" alt="trees" title="Trees" />
    <img src="assets/images/pictures/mountain.png" class="mountain" alt="mountain" title="Mountain" />
    <span class="rectRhombus orange"></span>
    <span class="rectRhombus small green"></span>
    <span class="rectCicrle red"></span>
    <span class="rectRhombus extraSmall orange"></span>
    <img src="assets/images/dinosaurs/oneDino.png" class="dino svgImage" />
    <article class="contentWrapper">
      <div class="text">
        <svg-icon [applyClass]="true" src="assets/images/dinosaurs/oneDino.svg"></svg-icon>
        <div class="titles">
          <h1 class="white baloo2">{{ 'REVIEWS.TITLE' | translate }}</h1>
          <span [innerHTML]="'REVIEWS.SUBTITLE' | translate"></span>
          <button class="primary orange">
            <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
          </button>
        </div>
      </div>
    </article>
  </section>

  <section class="gray">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" title="Border White" />
  </section>

  <section class="reviews">
    <article class="contentWrapper">
      <h2 class="blue baloo2">
        {{ 'REVIEWS.BLOCK_TITLE_1' | translate }}
      </h2>
      <p>
        {{ 'REVIEWS.BLOCK_TEXT_1' | translate }}
      </p>
      <div #sliderVideo class="sliderVideo-container">
        <div class="slideBlock fades">
          <a title="play" (click)="showVideos(1)">
            <svg-icon src="assets/images/icons/play.svg"></svg-icon>
          </a>
          <iframe autoplay="0" class="showVideo" src="//www.youtube.com/embed/Y1aujY1z4dc?autoplay=0" frameborder="0" allowfullscreen=""></iframe>
        </div>

        <div class="slideBlock fades">
          <a title="play" (click)="showVideos(2)">
            <svg-icon src="assets/images/icons/play.svg"></svg-icon>
          </a>
          <iframe class="showVideo" src="//www.youtube.com/embed/kLbNp3HshtY?autoplay=0" frameborder="0" allowfullscreen=""></iframe>
        </div>

        <div class="slideBlock fades">
          <a title="play" (click)="showVideos(3)">
            <svg-icon src="assets/images/icons/play.svg"></svg-icon>
          </a>
          <iframe class="showVideo" src="//www.youtube.com/embed/LGrbBG-OPxE?autoplay=0" frameborder="0" allowfullscreen=""></iframe>
        </div>

        <div class="slideBlock fades">
          <a title="play" (click)="showVideos(4)">
            <svg-icon src="assets/images/icons/play.svg"></svg-icon>
          </a>
          <iframe class="showVideo" src="//www.youtube.com/embed/wrgxv6BHKII?autoplay=0" frameborder="0" allowfullscreen=""></iframe>
        </div>

        <a #prevWrapper class="previous" (click)="prev(-1)">&#10094;</a>
        <a #nextWrapper class="after" (click)="next(1)">&#10095;</a>
      </div>

      <button class="primary orange">
        <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
      </button>
      <br />
      <h2 class="blue baloo2 customer">{{ 'REVIEWS.BLOCK_TITLE_2' | translate }}</h2>
      <testimonials-section></testimonials-section>
    </article>
  </section>

  <section class="reviewsSectionSecond gray">
    <img src="assets/images/pictures/borderWhite.png" class="border top" title="Border White" alt="" />

    <article class="contentWrapper">
      <img src="assets/images/dinosaurs/threeDinosaurs.svg" class="dino" title="Three Dinosaurs" alt="Three Dinosaurs" />
    </article>
    <h2 class="blue baloo2">{{ 'REVIEWS.BLOCK_TITLE_3' | translate }}</h2>
    <div #sliderWrapper class="slideshow-container">
      <div class="quotesBlock top">
        <svg-icon src="assets/images/icons/quoteTop.svg"></svg-icon>
        <svg-icon src="assets/images/icons/quoteTop.svg"></svg-icon>
      </div>
      <div class="mySlides fade">
        <h3 class="gray openSans">Randi C.</h3>
        <div class="blockStar">
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        </div>
        <p class="openSans gray">{{ 'REVIEWS.REVIEW_ITEM_1' | translate }}</p>
      </div>

      <div class="mySlides fade">
        <h3 class="gray openSans">Erin B.</h3>
        <div class="blockStar">
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        </div>
        <p class="gray openSans">{{ 'REVIEWS.REVIEW_ITEM_2' | translate }}</p>
      </div>

      <div class="mySlides fade">
        <h3 class="gray openSans">April W.</h3>
        <div class="blockStar">
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        </div>
        <p class="gray openSans">{{ 'REVIEWS.REVIEW_ITEM_3' | translate }}</p>
      </div>

      <div class="mySlides fade">
        <h3 class="gray openSans">Jessica K.</h3>
        <div class="blockStar">
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        </div>
        <p class="gray openSans">{{ 'REVIEWS.REVIEW_ITEM_4' | translate }}</p>
      </div>

      <div class="mySlides fade">
        <h3 class="gray openSans">J. Dawn</h3>
        <div class="blockStar">
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        </div>
        <p class="gray openSans">{{ 'REVIEWS.REVIEW_ITEM_5' | translate }}</p>
      </div>

      <div class="mySlides fade">
        <h3 class="gray openSans">Acavano</h3>
        <div class="blockStar">
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        </div>
        <p class="gray openSans">{{ 'REVIEWS.REVIEW_ITEM_6' | translate }}</p>
      </div>

      <div class="mySlides fade">
        <h3 class="gray openSans">D12</h3>
        <div class="blockStar">
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        </div>
        <p class="gray openSans">{{ 'REVIEWS.REVIEW_ITEM_7' | translate }}</p>
      </div>

      <div class="mySlides fade">
        <h3 class="gray openSans">Krysti M</h3>
        <div class="blockStar">
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        </div>
        <p class="gray openSans">{{ 'REVIEWS.REVIEW_ITEM_8' | translate }}</p>
      </div>

      <div class="mySlides fade">
        <h3 class="gray openSans">Filip</h3>
        <div class="blockStar">
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
          <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        </div>
        <p class="gray openSans">{{ 'REVIEWS.REVIEW_ITEM_9' | translate }}</p>
      </div>
      <div class="quotesBlock bottom">
        <svg-icon src="assets/images/icons/quoteBottom.svg"></svg-icon>
        <svg-icon src="assets/images/icons/quoteBottom.svg"></svg-icon>
      </div>
    </div>
    <div #dotsWrapper style="text-align: center">
      <span class="dot" (click)="currentSlide(1)"></span>
      <span class="dot" (click)="currentSlide(2)"></span>
      <span class="dot" (click)="currentSlide(3)"></span>
      <span class="dot" (click)="currentSlide(4)"></span>
      <span class="dot" (click)="currentSlide(5)"></span>
      <span class="dot" (click)="currentSlide(6)"></span>
      <span class="dot" (click)="currentSlide(7)"></span>
      <span class="dot" (click)="currentSlide(8)"></span>
      <span class="dot" (click)="currentSlide(9)"></span>
    </div>

    <button class="primary orange">
      <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.TRY_FREE' | translate }}</a>
    </button>

    <review-stars [schemeTitle]="metaTitle"></review-stars>
  </section>
</div>
