import { Component, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Store, select } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { selectCurrentCourse } from 'src/app/store/selectors/courses.selectors'
import { IAppState } from 'src/app/store/state/app.state'

@Component({
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuPopupComponent implements OnInit {
  constructor(private _store: Store<IAppState>, private dialogRef: MatDialogRef<MenuPopupComponent>, public translate: TranslateService) {}

  public course$ = this._store.pipe(select(selectCurrentCourse))

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close()
  }
}
