import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ExportGradebookDialogComponent } from './export-gradebook.component'
import { TranslateModule } from '@ngx-translate/core'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'

@NgModule({
  declarations: [ExportGradebookDialogComponent],
  imports: [CommonModule, TranslateModule, MatFormFieldModule, MatSelectModule, MatButtonModule],
})
export class ExportGradebookDialogModule {}
