import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { ITeacher, ITeacherRequest } from '../models/Teacher.model'

@Injectable({
  providedIn: 'root',
})
export class TeacherService {
  constructor(private http: HttpClient) {}

  public createTeacher(payload: any) {
    return this.http.post(`${environment.gatewayUrl}schools/${payload.schoolId}/teachers`, payload)
  }

  public updateTeacher(teacher: ITeacher, request: ITeacherRequest) {
    return this.http.put(`${environment.gatewayUrl}schools/${teacher.schoolId}/teachers/${teacher.id}`, request)
  }

  public deleteTeacher(schoolId: number, teacherId: number) {
    return this.http.delete(`${environment.gatewayUrl}schools/${schoolId}/teachers/${teacherId}`)
  }

  public getTeachers(schoolId: number) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/teachers/`)
  }
}
