import { Action } from '@ngrx/store'
import { IClassroom, IClassroomRequest } from '../../models/Classroom.model'

export enum EClassroomActions {
  AddClassroom = '[Classroom] Add Classroom',
  AddClassroomSuccess = '[Classroom] Add Classroom Success',
  AddClassroomFailure = '[Classroom] Add Classroom Failure',
  GetClassrooms = '[Classroom] Get Classrooms',
  GetClassroomsSuccess = '[Classroom] Get Classrooms Success',
  GetClassroomsFailure = '[Classroom] Get Classrooms Failure',
  UpdateClassroom = '[Classroom] Update Classroom',
  UpdateClassroomSuccess = '[Classroom] Update Classroom Success',
  UpdateClassroomFailure = '[Classroom] Update Classroom Failure',
  RemoveClassroom = '[Classroom] Remove Classroom',
  RemoveClassroomSuccess = '[Classroom] Remove Classroom Success',
  RemoveClassroomFailure = '[Classroom] Remove Classroom Failure',
}

export class AddClassroom implements Action {
  public readonly type = EClassroomActions.AddClassroom
  constructor(public payload: IClassroomRequest) {}
}

export class AddClassroomSuccess implements Action {
  public readonly type = EClassroomActions.AddClassroomSuccess
  constructor(public payload: any) {}
}

export class AddClassroomFailure implements Action {
  public readonly type = EClassroomActions.AddClassroomFailure
  constructor(public payload: any) {}
}

export class UpdateClassroom implements Action {
  public readonly type = EClassroomActions.UpdateClassroom
  constructor(public schoolId: number, public classroomId: number, public payload: IClassroom) {}
}

export class UpdateClassroomSuccess implements Action {
  public readonly type = EClassroomActions.UpdateClassroomSuccess
  constructor(public payload: any) {}
}

export class UpdateClassroomFailure implements Action {
  public readonly type = EClassroomActions.UpdateClassroomFailure
  constructor(public payload: any) {}
}

export class RemoveClassroom implements Action {
  public readonly type = EClassroomActions.RemoveClassroom
  constructor(public payload: IClassroom) {}
}

export class RemoveClassroomSuccess implements Action {
  public readonly type = EClassroomActions.RemoveClassroomSuccess
  constructor(public payload: any) {}
}

export class RemoveClassroomFailure implements Action {
  public readonly type = EClassroomActions.RemoveClassroomFailure
  constructor(public payload: any) {}
}

export class GetClassrooms implements Action {
  public readonly type = EClassroomActions.GetClassrooms
  constructor(public payload: any) {}
}

export class GetClassroomsSuccess implements Action {
  public readonly type = EClassroomActions.GetClassroomsSuccess
  constructor(public payload: Array<IClassroom>) {}
}

export class GetClassroomsFailure implements Action {
  public readonly type = EClassroomActions.GetClassroomsFailure
  constructor(public payload: any) {}
}

export type ClassroomActions =
  | AddClassroom
  | AddClassroomSuccess
  | AddClassroomFailure
  | UpdateClassroom
  | UpdateClassroomSuccess
  | UpdateClassroomFailure
  | RemoveClassroom
  | RemoveClassroomSuccess
  | RemoveClassroomFailure
  | GetClassrooms
  | GetClassroomsSuccess
  | GetClassroomsFailure
