import { AssignmentActions, EAssignmentActions } from '../actions/assignment.actions'
import { IAssignmentsState, InitialAssignmentsState } from '../state/assignments.state'

export const assignmentsReducers = (state = InitialAssignmentsState, action: AssignmentActions): IAssignmentsState => {
  switch (action.type) {
    case EAssignmentActions.GetAssignmentsSuccess: {
      return {
        ...state,
        assignments: action.assignments,
      }
    }

    case EAssignmentActions.GetStudentAssignmentsSuccess: {
      return {
        ...state,
        studentAssignments: action.assignments,
      }
    }

    case EAssignmentActions.GetStudentsAssignmentsSuccess: {
      if (action.append === true) {
        const lessons = state.studentsAssignments.lessons.concat(action.assignments.lessons)
        const studentsAssignments = {
          ...state.studentsAssignments,
          lessons,
          numAssignments: action.assignments.numAssignments,
        }

        return {
          ...state,
          studentsAssignments,
        }
      }

      return {
        ...state,
        studentsAssignments: action.assignments,
      }
    }

    case EAssignmentActions.AdjustStudentGradeSuccess: {
      const { assignmentCompleted, grades } = action.payload

      const adjustmentFound = state.studentsAssignments.lessons.some((lesson) => {
        return lesson.completed.some((completed) => completed.id === assignmentCompleted.id)
      })

      let newState = { ...state }

      if (adjustmentFound) {
        // if we found an existing adjustment, update the state
        const lessons = state.studentsAssignments.lessons.map((l) => {
          const completed = l.completed.map((c) => {
            if (c.id === assignmentCompleted.id) {
              return { ...c, ...assignmentCompleted }
            }
            return c
          })

          return { ...l, completed }
        })

        newState = {
          ...newState,
          studentsAssignments: { ...state.studentsAssignments, lessons },
        }
      } else {
        // no existing adjustment found, so push a new one
        const lessonIndex = state.studentsAssignments.lessons.findIndex((lesson) => {
          return lesson.id === assignmentCompleted.assignment.lessonId
        })

        if (lessonIndex !== -1) {
          const completed = [
            ...state.studentsAssignments.lessons[lessonIndex].completed.filter((c) => {
              return c.studentId !== assignmentCompleted.studentId
            }),
            assignmentCompleted,
          ]

          const lessons = state.studentsAssignments.lessons.map((lesson, index) => {
            if (index === lessonIndex) {
              return { ...lesson, completed }
            } else {
              return { ...lesson }
            }
          })

          newState = {
            ...newState,
            studentsAssignments: { ...state.studentsAssignments, lessons },
          }
        }
      }

      // update student grade
      const studentsGrades = state.studentsGrades.map((studentGrade) => {
        if (studentGrade.student.id === assignmentCompleted.studentId) {
          return { ...studentGrade, ...grades }
        }

        return studentGrade
      })

      newState = { ...newState, studentsGrades }

      return newState
    }

    case EAssignmentActions.GetStudentsGradesSuccess: {
      return { ...state, studentsGrades: action.studentsGrades }
    }

    case EAssignmentActions.DeleteAssignmentSuccess: {
      const assignments = state.assignments.filter((a) => a.id !== action.assignmentId)

      return {
        ...state,
        assignments,
      }
    }

    case EAssignmentActions.GetStudentGradesSuccess: {
      return { ...state, studentGrades: action.studentGrades }
    }

    default:
      return state
  }
}
