<section class="header">
  <header class="mainHeader">
    <div>
      <div class="left">
        <a routerLink="{{ getURLLangPart() }}/">
          <img src="assets/images/logos/logo-wy.svg" class="logo" alt="DinoLingo Logo" />
        </a>
      </div>
      <div class="right">
        <a class="header-btn" routerLink="{{ getURLLangPart() }}/auth/registration">
          {{ 'HOME.BUTTONS.TRY_FREE' | translate }}
        </a>
        <a class="header-btn" routerLink="{{ getURLLangPart() }}/auth/login">{{ 'BUTTONS.LOGIN' | translate }}</a>

        <localization *ngIf="!hideLDD"></localization>
      </div>
    </div>
  </header>
</section>
