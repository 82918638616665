import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ClassroomsComponent } from './classrooms.component'
import { ListStudentsModule } from '../students/list-students/list-students.module'
import { AddClassroomDialogModule } from '../../../shared/popups/Classroom-Dialogs/add-classroom/add-classroom.module'
import { EditClassroomDialogModule } from '../../../shared/popups/Classroom-Dialogs/edit-classroom/edit-classroom.module'
import { AddStudentDialogModule } from '../../../shared/popups/Student-Dialogs/add-student/add-student.module'
import { TranslateModule } from '@ngx-translate/core'
import { SchoolResolver } from '../../../helpers/resolvers/school.resolver'

@NgModule({
  declarations: [ClassroomsComponent],
  imports: [CommonModule, TranslateModule, AddStudentDialogModule, ListStudentsModule, AddClassroomDialogModule, EditClassroomDialogModule],
  providers: [SchoolResolver],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ClassroomsModule {}
