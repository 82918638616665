import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { AudioToTextGameComponent } from './audio-to-text.component'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatRippleModule } from '@angular/material/core'
import { TranslateModule } from '@ngx-translate/core'
import { GameLocalizationPipeModule } from 'src/app/helpers/pipes/game-localization/game-localization-pipe.module'

@NgModule({
    declarations: [AudioToTextGameComponent],
    exports: [AudioToTextGameComponent],
    imports: [CommonModule, FormsModule, RouterModule, MatButtonModule, MatIconModule, MatRippleModule, TranslateModule, GameLocalizationPipeModule]
})
export class AudioToTextGameModule {}
