import { Component, OnDestroy } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { GetAchievements } from 'src/app/store/actions/statistics.actions'
import { selectAchievements } from 'src/app/store/selectors/statistics.selectors'
import { selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { IAppState } from 'src/app/store/state/app.state'

@Component({
  templateUrl: 'achievements.component.html',
  styleUrls: ['achievements.component.scss'],
})
export class AchievementsPopupComponent implements OnDestroy {
  constructor(public dialogRef: MatDialogRef<AchievementsPopupComponent>, private _store: Store<IAppState>, private _router: Router, public translate: TranslateService) {}
  public unsubscribe$ = new Subject()

  public user$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser))
  public achievements$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectAchievements))

  public subuser$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)).subscribe((subuser) => {
    if (subuser) {
      this._store.dispatch(new GetAchievements(subuser.id))
    }
  })

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
