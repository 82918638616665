import { Action } from '@ngrx/store'
import { IUser } from 'src/app/models/User.model'
import { IThumbnail } from 'src/app/models/Avatar.model'

export enum EThumbnailsActions {
  GetThumbnails = '[Thumbnails] Get All Thumbnails',
  GetThumbnailsSuccess = '[Thumbnails] Get All Thumbnails Success',
}

export class GetThumbnails implements Action {
  public readonly type = EThumbnailsActions.GetThumbnails
}

export class GetThumbnailsSuccess implements Action {
  public readonly type = EThumbnailsActions.GetThumbnailsSuccess
  constructor(public payload: IThumbnail[]) {}
}

export type ThumbnailsActions = GetThumbnails | GetThumbnailsSuccess
