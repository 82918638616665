import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LandingRedirectService } from 'src/app/services/landingRedirect.service';

@Injectable({
  providedIn: 'root'
})
export class LandingRedirectGuard implements CanActivateChild {
  constructor(private landingRedirectService: LandingRedirectService) { }

  canActivateChild(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.landingRedirectService.checkAndRedirect(state.url);
  }
}
