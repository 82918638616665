import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ThumbnailsService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = `${environment.gatewayUrl}thumbnails/`
  }

  public getThumbnails(): Observable<any> {
    return this.http.get(`${this.gatewayUrl}all`)
  }
}
