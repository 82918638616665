import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { IAppState } from '../../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { Actions, ofType } from '@ngrx/effects'
import { IStudent } from '../../../../../models/Student.model'
import { MatSort } from '@angular/material/sort'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { IAssignment, IStudentGrades } from '../../../../../models/Assignment.model'
import { filter, map, takeUntil } from 'rxjs/operators'
import { selectCurrentStudent } from '../../../../../store/selectors/students.selectors'
import { EAssignmentActions, GetStudentAssignments, GetStudentAssignmentsSuccess, GetStudentGrades } from '../../../../../store/actions/assignment.actions'
import { GetCurrentStudent } from '../../../../../store/actions/students.actions'
import { combineLatest, Subject } from 'rxjs'
import { ExportGradebookDialogComponent } from '../../../../../shared/popups/Export-Dialogs/export-gradebook/export-gradebook.component'
import { selectStudentGrades } from '../../../../../store/selectors/assignments.selectors'

@Component({
  selector: 'app-student-gradebook',
  templateUrl: './gradebook.component.html',
  styleUrls: ['./gradebook.component.scss'],
})
export class GradebookStudentComponent implements OnInit, OnDestroy {
  @Input() user

  public student: IStudent = null

  @ViewChild(MatSort) sort: MatSort

  public columns: string[] = ['dueDate', 'assignment', 'score', 'category']

  public studentAssignments: MatTableDataSource<IAssignment>

  public loaded = false

  public grades: IStudentGrades = null

  private unsubscribe$ = new Subject()

  constructor(private router: Router, private _store: Store<IAppState>, public translate: TranslateService, private _dialog: MatDialog, private _actions$: Actions) {}

  ngOnInit() {
    this._store.dispatch(new GetCurrentStudent())
    this._store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(selectCurrentStudent),
        filter((student) => !!student)
      )
      .subscribe((student) => {
        this.student = student

        this._store.dispatch(new GetStudentAssignments(student.classroom.schoolId, student.classroomId, student.id, true))
        this._store.dispatch(new GetStudentGrades(student.classroom.schoolId, student.classroomId, student.id))

        combineLatest([
          this._actions$.pipe(
            takeUntil(this.unsubscribe$),
            ofType<GetStudentAssignmentsSuccess>(EAssignmentActions.GetStudentAssignmentsSuccess),
            map((res: GetStudentAssignmentsSuccess) => res.assignments)
          ),
          this._store.pipe(select(selectStudentGrades), takeUntil(this.unsubscribe$)),
        ])
          .pipe(filter(([assignments, grades]) => assignments !== null && grades !== null))
          .subscribe(([assignments, grades]) => {
            this.grades = grades

            this.studentAssignments = new MatTableDataSource<IAssignment>(assignments)
            this.studentAssignments.sort = this.sort
            this.loaded = true
          })
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  public getScore(assignment: IAssignment) {
    if (!assignment.assignmentsCompleteds.length) {
      return 'missing'
    }

    if ([1, 2, 6, 7, 8, 9, 10].includes(assignment.lesson.type.id)) {
      if (assignment.assignmentsCompleteds[0].adjustedProgress === 0) {
        return 'missing'
      }
    }

    // there should only be one array element
    // it is filtered by the server
    const completed = assignment.assignmentsCompleteds[0]

    if (completed.adjustedProgress) {
      return `${completed.adjustedProgress}%`
    }

    return `${completed.progress}%`
  }

  public getCategory(assignment: IAssignment) {
    switch (assignment.lesson.type.id) {
      case 1:
      case 2:
      case 6:
      case 7:
      case 8:
      case 9:
        return 'Lesson'

      case 10:
        return 'Worksheet'

      case 3:
      case 4:
      case 5:
        return 'Test'

      default:
        return 'unknown'
    }
  }

  openExportGradebookDialog() {
    this._dialog.open(ExportGradebookDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        classroom: this.student.classroom,
        student: this.student,
      },
    })
  }
}
