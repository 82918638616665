import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'
import { IBooksRequest, IBookCompleteRequest } from '../models/Book.model'

@Injectable({
  providedIn: 'root',
})
export class BooksService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = `${environment.gatewayUrl}books`
  }

  public getBooks(request: IBooksRequest) {
    return this.http.get(this.gatewayUrl, {
      params: {
        subuserId: request.subuserId.toString(),
        courseId: request.courseId.toString(),
        categoryId: request.categoryId ? request.categoryId.toString() : '',
        page: request.page.toString(),
        sortBy: request.sortBy,
        searchStr: request.searchStr,
      },
    })
  }

  public getBook(bookId: number) {
    return this.http.get(`${this.gatewayUrl}/${bookId}`)
  }

  public completeBook(request: IBookCompleteRequest) {
    return this.http.post(`${this.gatewayUrl}/complete/${request.bookId}`, request)
  }
}
