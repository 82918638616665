import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LoginAdminComponent } from './login-admin.component'

@NgModule({
  declarations: [LoginAdminComponent],
  imports: [RouterModule, CommonModule],
})
export class LoginAdminModule {}
