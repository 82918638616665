import { EPaymentActions, PaymentActions } from '../actions/payment.actions'
import { InitialPaymentState, IPaymentState } from '../state/payment.state'

export const paymentReducer = (state = InitialPaymentState, action: PaymentActions): IPaymentState => {
  switch (action.type) {
    case EPaymentActions.GetPlanSuccess: {
      return {
        ...state,
        plan: action.payload,
      }
    }

    case EPaymentActions.GetCustomerSuccess: {
      return {
        ...state,
        customer: action.payload,
      }
    }

    case EPaymentActions.GetPlansSuccess: {
      return {
        ...state,
        plans: action.payload,
      }
    }

    case EPaymentActions.UpdatePlanSuccess: {
      return {
        ...state,
        customer: action.payload,
      }
    }

    case EPaymentActions.UpdatePaymentMethodSuccess: {
      return {
        ...state,
        customer: action.payload,
      }
    }

    case EPaymentActions.GetPaymentMethodSuccess: {
      return {
        ...state,
        paymentMethod: action.payload,
      }
    }

    default:
      return state
  }
}
