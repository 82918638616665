import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { NotificationService } from 'src/app/services/notification.service'
import { ProgressReportsService } from '../../services/progress-reports.service'
import {
  EProgressReportsActions,
  GetStudentsDetailedReports,
  GetStudentsDetailedReportsFailure,
  GetStudentsDetailedReportsSuccess,
  GetStudentsTestReports,
  GetStudentsTestReportsFailure,
  GetStudentsTestReportsSuccess,
  GetStudentsTimeLogs,
  GetStudentsTimeLogsFailure,
  GetStudentsTimeLogsSuccess,
  GetStudentTestReports,
  GetStudentTestReportsFailure,
  GetStudentTestReportsSuccess,
  GetStudentTimeLog,
  GetStudentTimeLogFailure,
  GetStudentTimeLogSuccess,
  GetSubuserDetailedReports,
  GetSubuserDetailedReportsFailure,
  GetSubuserDetailedReportsSuccess,
  GetSubuserTestReport,
  GetSubuserTestReportFailure,
  GetSubuserTestReports,
  GetSubuserTestReportsFailure,
  GetSubuserTestReportsSuccess,
  GetSubuserTestReportSuccess,
  GetSubuserTimeLog,
  GetSubuserTimeLogFailure,
  GetSubuserTimeLogs,
  GetSubuserTimeLogsFailure,
  GetSubuserTimeLogsSuccess,
  GetSubuserTimeLogSuccess,
} from '../actions/progress-reports.actions'
import { IAppState } from '../state/app.state'

@Injectable()
export class ProgressReportsEffects {
  constructor(private _actions$: Actions, private _progressReportsService: ProgressReportsService, private _store: Store<IAppState>, private _notificationService: NotificationService) {}

  GetSubuserTestReports$: Observable<GetSubuserTestReportsSuccess | GetSubuserTestReportsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSubuserTestReports>(EProgressReportsActions.GetSubuserTestReports),
      mergeMap((action) => {
        return this._progressReportsService.getSubuserTestReports(action.courseId).pipe(
          map((reports: any) => new GetSubuserTestReportsSuccess(reports)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetSubuserTestReportsFailure(error))
          })
        )
      })
    )
  )

  GetSubuserTestReport$: Observable<GetSubuserTestReportSuccess | GetSubuserTestReportFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSubuserTestReport>(EProgressReportsActions.GetSubuserTestReport),
      mergeMap((action) => {
        return this._progressReportsService.getSubuserTestReport(action.subuserId, action.courseId).pipe(
          map((report: any) => new GetSubuserTestReportSuccess(report)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetSubuserTestReportFailure(error))
          })
        )
      })
    )
  )

  GetSubuserTimeLogs$: Observable<GetSubuserTimeLogsSuccess | GetSubuserTimeLogsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSubuserTimeLogs>(EProgressReportsActions.GetSubuserTimeLogs),
      mergeMap((action) => {
        return this._progressReportsService.getSubuserTimeLogs().pipe(
          map((reports: any) => new GetSubuserTimeLogsSuccess(reports)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetSubuserTimeLogsFailure(error))
          })
        )
      })
    )
  )

  GetSubuserTimeLog$: Observable<GetSubuserTimeLogSuccess | GetSubuserTimeLogFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSubuserTimeLog>(EProgressReportsActions.GetSubuserTimeLog),
      mergeMap((action) => {
        return this._progressReportsService.getSubuserTimeLog(action.subuserId).pipe(
          map((reports: any) => new GetSubuserTimeLogSuccess(reports)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetSubuserTimeLogFailure(error))
          })
        )
      })
    )
  )

  GetSubuserDetailedReports$: Observable<GetSubuserDetailedReportsSuccess | GetSubuserDetailedReportsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSubuserDetailedReports>(EProgressReportsActions.GetSubuserDetailedReports),
      mergeMap((action) => {
        return this._progressReportsService.getSubuserDetailedReports(action.courseId).pipe(
          map((reports: any) => new GetSubuserDetailedReportsSuccess(reports)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetSubuserDetailedReportsFailure(error))
          })
        )
      })
    )
  )

  getStudentsTestReports$: Observable<GetStudentsTestReportsSuccess | GetStudentsTestReportsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentsTestReports>(EProgressReportsActions.GetStudentsTestReports),
      mergeMap((action) => {
        return this._progressReportsService.getStudentsTestReports(action.schoolId, action.classroomId, action.courseId, action.lessonId).pipe(
          map((students: any) => new GetStudentsTestReportsSuccess(students)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetStudentsTestReportsFailure(error))
          })
        )
      })
    )
  )

  getStudentTestReports$: Observable<GetStudentTestReportsSuccess | GetStudentTestReportsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentTestReports>(EProgressReportsActions.GetStudentTestReports),
      mergeMap((action) => {
        return this._progressReportsService.getStudentTestReports(action.schoolId, action.classroomId, action.studentId, action.courseId).pipe(
          map((student: any) => new GetStudentTestReportsSuccess(student)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetStudentTestReportsFailure(error))
          })
        )
      })
    )
  )

  getStudentsTimeLogs$: Observable<GetStudentsTimeLogsSuccess | GetStudentsTimeLogsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentsTimeLogs>(EProgressReportsActions.GetStudentsTimeLogs),
      mergeMap((action) => {
        return this._progressReportsService.getStudentsTimeLogs(action.schoolId, action.classroomId).pipe(
          map((students: any) => new GetStudentsTimeLogsSuccess(students)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetStudentsTimeLogsFailure(error))
          })
        )
      })
    )
  )

  getStudentTimeLog$: Observable<GetStudentTimeLogSuccess | GetStudentTimeLogFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentTimeLog>(EProgressReportsActions.GetStudentTimeLog),
      mergeMap((action) => {
        return this._progressReportsService.getStudentTimeLog(action.schoolId, action.classroomId, action.studentId).pipe(
          map((students: any) => new GetStudentTimeLogSuccess(students)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetStudentTimeLogFailure(error))
          })
        )
      })
    )
  )

  getStudentsDetailedReports$: Observable<GetStudentsDetailedReportsSuccess | GetStudentsDetailedReportsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentsDetailedReports>(EProgressReportsActions.GetStudentsDetailedReports),
      mergeMap((action) => {
        return this._progressReportsService.getStudentsDetailedReports(action.schoolId, action.classroomId, action.lessonId).pipe(
          map((students: any) => new GetStudentsDetailedReportsSuccess(students)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetStudentsDetailedReportsFailure(error))
          })
        )
      })
    )
  )
}
