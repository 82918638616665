<div class="black-box" (click)="closeDropdown($event)" *ngIf="localizDropdown"></div>
<div class="localization">
  <div class="lang-arrow" [ngClass]="{ 'blue-background': localizDropdown }">
    <div class="lang-img" (click)="openLocalizationDropdown($event)">
      <img src="/assets/images/languages/short/{{ translate.currentLang }}.png" alt="selected-language-{{ translate.currentLang }}" />
    </div>
    <div class="items open" *ngIf="localizDropdown">
      <div *ngFor="let lang of translate.getLangs()" class="item" [ngClass]="{ active: translate.currentLang === lang }" (click)="selectLocalization($event, lang)">
        <img src="/assets/images/languages/short/{{ lang }}.png" alt="selected-language-{{ translate.currentLang }}" />
        <span>{{ formatLangName(lang) }}</span>
      </div>
    </div>

    <div class="arrow-down" (click)="openLocalizationDropdown($event)"><img src="/assets/images/icons/arrow-down-white.svg" alt="" /></div>
  </div>
</div>