import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthComponent } from './auth.component'
import { AuthLoginComponent } from './login/login.component'
import { AuthRegistrationComponent } from './registration/registration.component'
import { RestorePasswordComponent } from './restore-password/restore-password.component'
import { TechnicalProblemsComponent } from './technical-problems/technical-problems.component'
import { seometadata } from '../../../assets/i18n/seometadata'

const authRoutes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full',
      },

      {
        path: 'login',
        data: seometadata.login,
        component: AuthLoginComponent,
      },
      {
        path: 'registration',
        data: seometadata.registration,
        component: AuthRegistrationComponent,
      },
      {
        path: 'restore-password',
        data: seometadata.restore_password,
        component: RestorePasswordComponent,
      },
      {
        path: 'technical-problem',
        component: TechnicalProblemsComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
