import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { GetNotificationRequest } from '../models/Notification.model'

@Injectable({
  providedIn: 'root',
})
export class NotificService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = `${environment.gatewayUrl}notification/`
  }

  public GetNotification(request: GetNotificationRequest) {
    const params: any = {
      lang: request.lang,
      course: request.course,
    }
    return this.http.get(`${this.gatewayUrl}get`, {
      params,
    })
  }
}
