import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { NotificationService } from 'src/app/services/notification.service'
import { IClassroom, IClassroomRequest } from '../../models/Classroom.model'
import { ClassroomService } from '../../services/classroom.service'
import {
  AddClassroom,
  AddClassroomFailure,
  AddClassroomSuccess,
  EClassroomActions,
  GetClassrooms,
  GetClassroomsFailure,
  GetClassroomsSuccess,
  RemoveClassroom,
  RemoveClassroomFailure,
  RemoveClassroomSuccess,
  UpdateClassroom,
  UpdateClassroomFailure,
  UpdateClassroomSuccess,
} from '../actions/classrooms.actions'
import { AddAllocableStudents, SubtractAllocableStudents, UpdateNumberAllocableStudents } from '../actions/school.actions'
import { IAppState } from '../state/app.state'

@Injectable()
export class ClassroomsEffects {
  constructor(private _actions$: Actions, private _classroomService: ClassroomService, private _store: Store<IAppState>, private _notificationService: NotificationService) {}

  updateClassroom: Observable<UpdateClassroomSuccess | UpdateClassroomFailure | UpdateNumberAllocableStudents> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateClassroom>(EClassroomActions.UpdateClassroom),
      switchMap((action: UpdateClassroom) =>
        this._classroomService.updateClassroom(action.schoolId, action.classroomId, action.payload).pipe(
          mergeMap((classroom: any) => [new UpdateNumberAllocableStudents(classroom.updatedClassroom.school.allocableStudents), new UpdateClassroomSuccess(classroom)]),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new UpdateClassroomFailure(error))
          })
        )
      )
    )
  )

  addClassroom: Observable<AddClassroomSuccess | AddClassroomFailure | SubtractAllocableStudents> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddClassroom>(EClassroomActions.AddClassroom),
      map((action: AddClassroom) => action.payload),
      switchMap((payload: IClassroomRequest) =>
        this._classroomService.createClassroom(payload).pipe(
          mergeMap((classroom: IClassroom) => [new SubtractAllocableStudents(payload.allocatedStudents), new AddClassroomSuccess(classroom)]),
          catchError((error) => {
            this._notificationService.showNotificationError(error.error.clientMessage, 2)
            return of(new AddClassroomFailure(error))
          })
        )
      )
    )
  )

  deleteClassroom: Observable<RemoveClassroomSuccess | RemoveClassroomFailure | AddAllocableStudents> = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveClassroom>(EClassroomActions.RemoveClassroom),
      map((action: RemoveClassroom) => {
        return action.payload
      }),
      switchMap((classroom: IClassroom) =>
        this._classroomService.removeClassroom(classroom.schoolId, classroom.id).pipe(
          mergeMap((removedClassroom: any) => [new AddAllocableStudents(classroom.allocatedStudents), new RemoveClassroomSuccess(removedClassroom)]),
          catchError((error) => {
            this._notificationService.showNotificationError(error.error.clientMessage, 2)
            return of(new RemoveClassroomFailure(error))
          })
        )
      )
    )
  )

  getClassrooms$: Observable<GetClassroomsSuccess | GetClassroomsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetClassrooms>(EClassroomActions.GetClassrooms),
      switchMap((action) => {
        return this._classroomService.getClassrooms(action.payload).pipe(
          map((classrooms: Array<IClassroom>) => new GetClassroomsSuccess(classrooms)),
          catchError((error, stream) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return stream
          })
        )
      })
    )
  )
}
