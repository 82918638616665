import { Component, OnInit, Inject } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  templateUrl: './videopopup.component.html',
  styleUrls: ['./videopopup.component.scss'],
})
export class VideoPopupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data, public sanitizer: DomSanitizer) {}

  public spinnerActive = this.data && this.data.includes('youtube.com') ? true : false
  public safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.data)

  ngOnInit(): void {}

  public videoLoaded() {
    this.spinnerActive = false
  }
}
