import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { AddStudentDialogComponent } from './add-student.component'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    // AvatarSelectPopupModule,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslateModule,
    SoundsModule,
    LottieModule,
  ],
  declarations: [AddStudentDialogComponent],
})
export class AddStudentDialogModule {}
