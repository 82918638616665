import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { ICourse } from 'src/app/models/Course.model'
import { ISubuserRequest } from 'src/app/models/Subuser.model'
import { GetAllCourses } from 'src/app/store/actions/courses.actions'
import { AddSubuser, AddSubuserSuccess, ESubuserActions, GetAllSubuser } from 'src/app/store/actions/subuser.actions'
import { selectAllCourses } from 'src/app/store/selectors/courses.selectors'
import { selectAllSubuser } from 'src/app/store/selectors/subuser.selectors'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { IAppState } from 'src/app/store/state/app.state'
import { AvatarSelectPopupComponent } from '../avatar-select/avatar-select.component'

@Component({
  templateUrl: './addSubuser.component.html',
  styleUrls: ['./addSubuser.component.scss'],
})
export class AddSubuserDialogComponent implements OnInit {
  public subuserForm: UntypedFormGroup

  private destroyed$ = new Subject<boolean>()

  public unsubscribe$ = new Subject()
  public user: any
  constructor(private updates$: Actions, private _dialog: MatDialog, public dialogRef: MatDialogRef<AddSubuserDialogComponent>, private _store: Store<IAppState>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public dialogData) {
    this.updates$.pipe(ofType<AddSubuserSuccess>(ESubuserActions.AddSubuserSuccess), takeUntil(this.destroyed$)).subscribe((res: AddSubuserSuccess) => {
      this._store.dispatch(new GetAllSubuser())
      this.dialogRef.close()
    })
  }

  public courses$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectAllCourses))

  public subuser$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectAllSubuser))

  public avatars = []

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      this.user = user
    })

    this._store.dispatch(new GetAllCourses())
    this.subuserForm = new UntypedFormGroup({
      avatarId: new UntypedFormControl(''),
      avatar: new UntypedFormControl(''),
      courseId: new UntypedFormControl('', [Validators.required]),
      nickname: new UntypedFormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]),
      ageRange: new UntypedFormControl('', [Validators.required]),
    })
  }

  ageDropdownOpened = false

  onAgeDropdownToggle(event: any): void {
    this.ageDropdownOpened = event
  }

  onAgeSelectionChange(event: any): void {
    this.ageDropdownOpened = false
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  selectCourse(course: ICourse) {
    this.subuserForm.patchValue({
      courseId: course.id,
    })
  }

  createSubuser(form: UntypedFormGroup) {
    if (form.invalid) return
    const request: ISubuserRequest = form.value
    this._store.dispatch(new AddSubuser(request))
  }

  openSelectAvatarPopup() {
    const dialog = this._dialog.open(AvatarSelectPopupComponent, {
      hasBackdrop: true,
      width: '32rem',
      height: '32rem',
    })

    dialog
      .afterClosed()
      .pipe(filter((avatar) => avatar !== null))
      .subscribe((avatar) => {
        if (avatar) {
          this.avatars.unshift(avatar.src)
          this.subuserForm.patchValue({
            avatar,
            avatarId: avatar.id,
          })
        }
      })
  }

  public isLottie(url) {
    if (url.includes('.json')) {
      return true
    }
    return false
  }
}
