import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class PrintsService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = `${environment.gatewayUrl}prints`
  }

  public completedPrint(request) {
    return this.http.post(`${this.gatewayUrl}/complete`, request)
  }

  public getPrintPDF(slug) {
    return this.http.get(`${this.gatewayUrl}/getPDF`, {
      params: { slug: slug },
    })
  }
}
