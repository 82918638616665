import { createSelector } from '@ngrx/store'
import { IAppState } from '../state/app.state'
import { IStudentState } from '../state/students.state'

const studentState = (state: IAppState) => state.student

export const selectStudentsForClassroom = createSelector(studentState, (state: IStudentState, props) => {
  return state.students.filter((s) => s.classroomId === props.classroomId)
})

export const selectCurrentStudent = createSelector(studentState, (state: IStudentState) => {
  return state.currentStudent
})
