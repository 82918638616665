import { Action } from '@ngrx/store'
import { IPlan } from 'src/app/models/Payment.model'

export enum EPaymentActions {
  GetPlan = '[Payment] Get Plan',
  GetPlanSuccess = '[Payment] Get Plan Success',
  GetPlans = '[Payment] Get Plans',
  GetPlansSuccess = '[Payment] Get Plans Success',
  GetCustomer = '[Payment] Get Customer',
  GetCustomerSuccess = '[Payment] Get Customer Success',
  UpdatePlan = '[Payment] Update Plan',
  UpdatePlanSuccess = '[Payment] Update Plan Success',
  GetPaymentMethod = '[Payment] Get Payment Method',
  GetPaymentMethodSuccess = '[Payment] Get Payment Method Success',
  UpdatePaymentMethod = '[Payment] Update Payment Method',
  UpdatePaymentMethodSuccess = '[Payment] Update Payment Method Success',
  ActivateStoppedSubscription = '[Payment] Activate Stopped Subscription',
  ActivateStoppedSubscriptionSuccess = '[Payment] Activate Stopped Subscription Success',
}

export class GetPlan implements Action {
  public readonly type = EPaymentActions.GetPlan
  constructor(public payload: number | string) {}
}

export class GetPlanSuccess implements Action {
  public readonly type = EPaymentActions.GetPlanSuccess
  constructor(public payload: IPlan) {}
}

export class GetCustomer implements Action {
  public readonly type = EPaymentActions.GetCustomer
  constructor(public payload: number | string) {}
}

export class GetCustomerSuccess implements Action {
  public readonly type = EPaymentActions.GetCustomerSuccess
  constructor(public payload: any) {}
}

export class GetPlans implements Action {
  public readonly type = EPaymentActions.GetPlans
}

export class GetPlansSuccess implements Action {
  public readonly type = EPaymentActions.GetPlansSuccess
  constructor(public payload: any) {}
}

export class UpdatePlan implements Action {
  public readonly type = EPaymentActions.UpdatePlan
  constructor(public payload: any) {}
}

export class UpdatePlanSuccess implements Action {
  public readonly type = EPaymentActions.UpdatePlanSuccess
  constructor(public payload: any) {}
}

export class GetPaymentMethod implements Action {
  public readonly type = EPaymentActions.GetPaymentMethod
  constructor(public payload: string) {}
}

export class GetPaymentMethodSuccess implements Action {
  public readonly type = EPaymentActions.GetPaymentMethodSuccess
  constructor(public payload: any) {}
}

export class UpdatePaymentMethod implements Action {
  public readonly type = EPaymentActions.UpdatePaymentMethod
  constructor(public payload: any) {}
}

export class UpdatePaymentMethodSuccess implements Action {
  public readonly type = EPaymentActions.UpdatePaymentMethodSuccess
  constructor(public payload: any) {}
}

export class ActivateStoppedSubscription implements Action {
  public readonly type = EPaymentActions.ActivateStoppedSubscription
  constructor(public payload: any) {}
}

export class ActivateStoppedSubscriptionSuccess implements Action {
  public readonly type = EPaymentActions.ActivateStoppedSubscriptionSuccess
  constructor(public payload: any) {}
}

export type PaymentActions =
  | GetPlan
  | GetPlanSuccess
  | GetCustomer
  | GetCustomerSuccess
  | GetPlans
  | GetPlansSuccess
  | UpdatePlan
  | UpdatePlanSuccess
  | GetPaymentMethod
  | GetPaymentMethodSuccess
  | UpdatePaymentMethod
  | UpdatePaymentMethodSuccess
  | ActivateStoppedSubscription
  | ActivateStoppedSubscriptionSuccess
