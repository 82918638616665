<div class="appmessage">
  <div class="actions">
    <div class="close"><span (click)="close()">+</span></div>
  </div>
  <div class="main">
    <div class="text">
      <p class="blue">{{ 'APP_MESSAGE.TITLE' | translate }}</p>
      <p>
        <a href="https://apps.apple.com/us/app/dinolingo-languages-for-kids/id1537076111" target="_blank"><img src="/assets/images/store/ios.png" /></a>
      </p>
      <p>
        <a href="https://play.google.com/store/apps/details?id=com.dinolingo.dinolearning" target="_blank"><img src="/assets/images/store/gp.png" /></a>
      </p>
      <p>The mobile website is optimized for account management. For an enhanced experience with videos, games, and activities, please download our mobile app.</p>
    </div>
  </div>
</div>
