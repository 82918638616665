import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { LottieModule } from 'ngx-lottie'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { DinoViewPopupComponent } from './dino-view.component'

@NgModule({
  declarations: [DinoViewPopupComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatCardModule, MatButtonModule, MatDialogModule, SoundsModule, LottieModule],
})
export class DinoViewPopupModule {}
