import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SiteStatusService } from 'src/app/services/siteStatus.service'

@Component({
  templateUrl: './technical-problems.component.html',
  styleUrls: ['./technical-problems.component.scss'],
})
export class TechnicalProblemsComponent implements OnInit {
  // TODO: ADD TRANSLATION
  constructor(public siteStatusService: SiteStatusService, private _router: Router) {}
  ngOnInit(): void {
    this.siteStatusService.handelStatusSite().subscribe((res: any) => {
      const status = res
      if (status.isActive) {
        this._router.navigate(['/profile/lesson-plan'])
      }
    })
  }
}
