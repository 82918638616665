import { Component, OnInit, OnDestroy } from '@angular/core'
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { Store } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { EAuthActions, SignIn, SignInFailure, SignInSuccess } from 'src/app/store/actions/auth.actions'
import { IUserLogin } from 'src/app/models/User.model'
import { DeviceDetectorService } from 'ngx-device-detector'
import { SocialUser } from '@abacritt/angularx-social-login'
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'
import { ActivatedRoute } from '@angular/router'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { LoginStudentComponent } from '../login-student/login-student.component'
import { FormatTextService } from '../../../services/formatText.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { SEOService } from 'src/app/services/seo.service'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthLoginComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    private deviceService: DeviceDetectorService,
    public translate: TranslateService,
    public _dialog: MatDialog,
    private textService: FormatTextService,
    private route: ActivatedRoute,
    private localizeHelperService: LocalizeHelperService,
    private _seoService: SEOService,
    private _actions$: Actions,
  ) {}

  public deviceInfo = this.deviceService.getDeviceInfo()

  public loginFormGroup: UntypedFormGroup

  public currentLan: string

  public hidePassword = true

  public lURL

  public langParam = this.route.parent.snapshot.params && this.route.parent.snapshot.params.lang ? this.route.parent.snapshot.params.lang : ''

  public loading = false

  public unsubscribe$ = new Subject()

  ngOnInit() {
    const { meta } = this.route.snapshot.data

    this.currentLan = this.localizeHelperService.getLangForced()

    this.lURL = this.localizeHelperService.getURLLang()

    const localMeta = meta[this.currentLan] == undefined ? meta['en'] : meta[this.currentLan]

    this._seoService.updateTitle(localMeta.title)
    this._seoService.updateDescription(localMeta.description)
    this._seoService.createCanonicalLink(localMeta.canonical)
    this.localizeHelperService.createLangMetaLinks()

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lURL = this.localizeHelperService.getURLLang()
      this.currentLan = event.lang
      this._seoService.updateTitle(localMeta.title)
      this._seoService.updateDescription(localMeta.description)
      this._seoService.createCanonicalLink(localMeta.canonical)
    })

    this.loginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      os: ['', Validators.required],
      browser: ['', Validators.required],
      userAgent: ['', Validators.required],
    })
    this.loginFormGroup.patchValue({
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      userAgent: this.deviceInfo.userAgent,
    })
  }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  /**
   * Sign in with google
   */
  // public logInWithGoogle(): void {
  //   if (this.user && this.loggedIn) {
  //     const request = {
  //       email: this.user.email,
  //       googleId: this.user.id,
  //       os: this.deviceInfo.os,
  //       browser: this.deviceInfo.browser,
  //       userAgent: this.deviceInfo.userAgent,
  //     }

  //     return this.store.dispatch(new SignIn(request))
  //   }

  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)

  //   this.authService.authState.subscribe((user) => {
  //     if (user) {
  //       const request = {
  //         email: user.email,
  //         googleId: user.id,
  //         os: this.deviceInfo.os,
  //         browser: this.deviceInfo.browser,
  //         userAgent: this.deviceInfo.userAgent,
  //       }

  //       this.store.dispatch(new SignIn(request))
  //     }
  //   })
  // }

  public login(formGroup: UntypedFormGroup) {
    this.loading = true

    const request: IUserLogin = formGroup.value
    // USER LOGIN
    this.store.dispatch(new SignIn(request));

    // Subscribe to both success and failure actions in a single stream
    this._actions$
    .pipe(
      ofType(EAuthActions.SignInSuccess, EAuthActions.SignInFailure), // Listen to both success and failure actions
      takeUntil(this.unsubscribe$) // Ensures the subscription is properly cleaned up
    )
    .subscribe((result: Array<string> | SignInSuccess | SignInFailure) => {
      if (result instanceof SignInSuccess) {
        // Handle success action
        // console.log('login success. response:', result);
      } else if (result instanceof SignInFailure) {
        // Handle failure action
        // console.log('login failed. response', result);
      }
      this.loading = false
    });
  }

  get email() {
    return this.loginFormGroup.get('email')
  }

  get password() {
    return this.loginFormGroup.get('password')
  }

  studentLogin() {
    this._dialog.open(LoginStudentComponent, {
      width: '22rem',
      height: '22rem',
    })
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
