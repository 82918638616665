import { Component, Output, EventEmitter, Input, OnInit, ChangeDetectionStrategy, OnChanges, ElementRef, ViewChild } from '@angular/core'
import { AudioService } from 'src/app/services/audio.service'
import { IQuestion } from 'src/app/models/Question.model'
import { IAnswer } from 'src/app/models/Answer.model'
import { ICourse } from '../../../models/Course.model'
import { RandomizeService } from 'src/app/services/randomize.service'
import { of } from 'rxjs'
import { delay, take } from 'rxjs/operators'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import * as Sentry from "@sentry/angular"

@Component({
  selector: 'audio-to-text',
  templateUrl: './audio-to-text.component.html',
  styleUrls: ['./audio-to-text.component.scss', '../games.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioToTextGameComponent implements OnInit, OnChanges {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Input() translation: string
  @Input() localizations: Array<object>
  @Input() course: ICourse
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  public dir = 'ltr'
  public dir2 = 'ltr'

  constructor(private _RandomizeService: RandomizeService, private localizeHelperService: LocalizeHelperService, private audioService: AudioService) {}

  public questionId: number
  public selectedAnswer: IAnswer = null
  public checked = false
  public answers = []

  ngOnChanges() {
    if (this.translation === 'ar' || this.translation === 'fa' || this.translation === 'he') {
      this.dir2 = 'rtl'
    } else {
      this.dir2 = 'ltr'
    }

    this.selectedAnswer = null
    this.checked = false
    if (this.answers.length > 0 && this.question.id != this.questionId) {
      this.answers = []
      this.createAnswersArray(this.question)
      this.playInitialAudio()
    }
  }

  ngOnInit(): void {
    if (this.course.slug === 'arabic' || this.course.slug === 'persian' || this.course.slug === 'hebrew') {
      this.dir = 'rtl'
    }

    this.currentlang = this.localizeHelperService.getLangForced()

    this.questionId = this.question.id
    this.createAnswersArray(this.question)

    this.audioService.preloadAudio(this.question.audioSrc, this.question.audioSrc)

    this.playInitialAudio()
  }

  ngOnDestroy() {
    this.audioService.stopAllAudio()
  }

  public audiohelpPlay() {
    this.audiohelp.nativeElement.play().catch((error) => {
      Sentry.captureException(error, {
        level: "error",
        extra: {
          location: 'audiohelpPlay AudioToTextGameComponent',
          questionId: this.question.id,
          currentlang: this.currentlang,
        }
      })
    })
  }

  public playInitialAudio() {
    of(true)
      .pipe(delay(600), take(1))
      .subscribe(() => this.playAudio(this.question.audioSrc))
  }

  public createAnswersArray(question) {
    for (let i = 0; i < question.answers.length; i++) {
      const answer = {
        audioSrc: question.answers[i].audioSrc,
        correct: question.answers[i].correct,
        id: question.answers[i].id,
        name: question.answers[i].name,
        pictureSrc: question.answers[i].pictureSrc,
        questionId: question.answers[i].questionId,
        text: question.answers[i].text,
        localization: question.answers[i].localization,
        localizationId: question.answers[i].localizationId,
      }
      this.answers.push(answer)
    }

    this._RandomizeService.shuffle(this.answers)
  }

  public selectAnswer(answer) {
    if (this.checked) return
    this.selectedAnswer = answer
  }

  public playAudio(src) {
    this.audioService.playAudio(src, src)
  }

  public setAnswer(answer) {
    if (answer.correct) {
      this.success.emit({ question: this.question, answer })
    } else {
      this.failed.emit({ question: this.question, answer })
    }
    this.audioService.stopAudio(this.question.audioSrc)
  }

  public checkAnswer() {
    this.checked = true
    if (this.selectedAnswer.correct) {
      this.audioService.playSoundCorrect()
    } else {
      this.audioService.playSoundIncorrect()
    }
  }

  public get checkRightAnswer() {
    return this.selectedAnswer.correct
  }
}
