import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './helpers/guards/auth.guard'
import { NoneAuthGuard } from './helpers/guards/no-auth.guard'
import { langURLLocalizationsResolver } from './helpers/resolvers/langURLLocalizations.resolver'
import { defaultLanguageResolver } from './helpers/resolvers/defaultLanguage.resolver'
import { SubuserResolver } from './helpers/resolvers/subuser.resolver'
import { UserResolver } from './helpers/resolvers/user.resolver'
import { LoginAdminComponent } from './views/auth/login-admin/login-admin.component'

const routes: Routes = [
  {
    path: 'login-as-admin/:accessToken/:refreshToken',
    component: LoginAdminComponent,
  },
  {
    path: 'auth',
    canActivate: [NoneAuthGuard],
    resolve: { lang: defaultLanguageResolver },
    loadChildren: () => import('./views/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: ':lang/auth',
    canActivate: [NoneAuthGuard],
    resolve: { lang: langURLLocalizationsResolver },
    loadChildren: () => import('./views/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    resolve: {
      user: UserResolver,
      subuser: SubuserResolver,
    },
    loadChildren: () => import('./views/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'subscriptions',
    resolve: {
      user: UserResolver,
      subuser: SubuserResolver,
    },
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/profile/parents/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
  },
  {
    path: '',
    canActivate: [NoneAuthGuard],
    loadChildren: () => import('./views/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    redirectTo: '/profile/lessons',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
