export const getLanguageMap = (): any[] => {
  return [
    { abbr: 'ar', name: 'Arabic' },
    { abbr: 'hy', name: 'Armenian' },
    { abbr: 'yue', name: 'Cantonese' },
    { abbr: 'zh', name: 'Chinese' },
    { abbr: 'bi', name: 'Creole' },
    { abbr: 'hr', name: 'Croatian' },
    { abbr: 'nl', name: 'Dutch' },
    { abbr: 'en', name: 'English' },
    { abbr: 'pt', name: 'European Portuguese' },
    { abbr: 'fr', name: 'French' },
    { abbr: 'de', name: 'German' },
    { abbr: 'el', name: 'Greek' },
    { abbr: 'he', name: 'Hebrew' },
    { abbr: 'hi', name: 'Hindi' },
    { abbr: 'hu', name: 'Hungarian' },
    { abbr: 'id', name: 'Indonesian' },
    { abbr: 'it', name: 'Italian' },
    { abbr: 'jp', name: 'Japanese' },
    { abbr: 'ko', name: 'Korean' },
    { abbr: 'fa', name: 'Farsi - Persian' },
    { abbr: 'pl', name: 'Polish' },
    { abbr: 'pt-br', name: 'Portuguese' },
    { abbr: 'ro', name: 'Romanian' },
    { abbr: 'ru', name: 'Russian' },
    { abbr: 'sr', name: 'Serbian' },
    { abbr: 'es', name: 'Spanish' },
    { abbr: 'tl', name: 'Tagalog' },
    { abbr: 'th', name: 'Thai' },
    { abbr: 'tr', name: 'Turkish' },
    { abbr: 'ua', name: 'Ukrainian' },
    { abbr: 'vi', name: 'Vietnamese' },
  ]
}
