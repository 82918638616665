import { BooksActions, EBooksActions } from '../actions/books.actions'
import { IBooksState, InitialBooksState } from '../state/books.state'

export const booksReducer = (state = InitialBooksState, action: BooksActions): IBooksState => {
  switch (action.type) {
    case EBooksActions.GetBooksSuccess: {
      return {
        ...state,
        books: action.payload.books,
        paginator: action.payload.paginator,
        totalBooks: action.payload.total,
      }
    }

    case EBooksActions.GetBookSuccess: {
      return {
        ...state,
        currentBook: action.payload,
      }
    }

    default:
      return state
  }
}
