import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { AvatarSelectPopupComponent } from './avatar-select.component'
import { TranslateModule } from '@ngx-translate/core'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  declarations: [AvatarSelectPopupComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatCardModule, MatDialogModule, TranslateModule, LottieModule],
})
export class AvatarSelectPopupModule {}
