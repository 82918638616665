import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { TranslateModule } from '@ngx-translate/core'
import { TestResultComponent } from './test-result.component'

@NgModule({
  declarations: [TestResultComponent],
  imports: [CommonModule, MatButtonModule, MatCardModule, MatIconModule, TranslateModule],
})
export class TestResultModule {}
