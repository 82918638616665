import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'loading-screen',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingScreenComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
