import { Injectable } from '@angular/core'
import * as Sentry from '@sentry/angular'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { IThumbnail } from 'src/app/models/Avatar.model'
import { isStudent } from 'src/app/models/Student.model'
import { NotificationService } from 'src/app/services/notification.service'
import { UserService } from 'src/app/services/user.service'
import { DeleteUser, DeleteUserSuccess, EUserActions, GetUser, GetUserSuccess, ManageBooksAutoplay, ManageBooksAutoplaySuccess, UpdateUser, UpdateUserAvatar, UpdateUserAvatarSuccess, UpdateUserPassword, UpdateUserPasswordSuccess, UpdateUserSuccess } from '../actions/user.actions'

@Injectable()
export class UserEffects {
  getUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetUser>(EUserActions.GetUser),
      mergeMap(() => this._userService.getUser()),
      mergeMap((user: any) => {
        const modifiedUser = {
          ...user,
          userType: isStudent(user) ? 'student' : user.user.role,
        }

        Sentry.setUser({ id: modifiedUser.user.id })
        Sentry.setContext('User Info', { type: modifiedUser.userType, role: user.user.role })
        return of(new GetUserSuccess(modifiedUser))
      }),
      catchError((err, caught) => {
        const { error } = err
        if (error) {
          this._notificationService.showNotificationError(error.clientMessage, 2)
        }
        return caught
      })
    )
  )

  updateUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateUser>(EUserActions.UpdateUser),
      map((action: UpdateUser) => action.payload),
      mergeMap((payload: any) => this._userService.updateUser(payload)),
      mergeMap((updatedUser: any) => [new UpdateUserSuccess(updatedUser), new GetUser()]),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  updateUserPassword$: Observable<UpdateUserPasswordSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateUserPassword>(EUserActions.UpdateUserPassword),
      map((action: UpdateUserPassword) => action.payload),
      mergeMap((payload: any) => this._userService.updateUserPassword(payload)),
      mergeMap((updateUserPassword: any) => {
        if(typeof updateUserPassword === 'object' && 'accessToken' in updateUserPassword && 'refreshToken' in updateUserPassword) {
          localStorage.setItem('accessToken', updateUserPassword.accessToken)
          localStorage.setItem('refreshToken', updateUserPassword.refreshToken)
        }
        return [new UpdateUserPasswordSuccess(updateUserPassword)]
      }),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  manageBooksAutoplay$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ManageBooksAutoplay>(EUserActions.ManageBooksAutoplay),
      map((action: ManageBooksAutoplay) => action.payload),
      mergeMap((payload: any) => this._userService.manageBooksAuoplay(payload)),
      mergeMap((updatedStatus: any) => [new ManageBooksAutoplaySuccess(updatedStatus), new GetUser()]),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  updateUserAvatar$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateUserAvatar>(EUserActions.UpdateUserAvatar),
      map((action: UpdateUserAvatar) => action.payload),
      mergeMap((payload: IThumbnail) => this._userService.updateUserAvatar(payload)),
      mergeMap((updatedUser: any) => [new UpdateUserAvatarSuccess(updatedUser), new GetUser()]),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  deleteUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType<DeleteUser>(EUserActions.DeleteUser),
      map((action: DeleteUser) => action),
      mergeMap(() => this._userService.deleteUser()),
      mergeMap((deletedUser: any) => [new DeleteUserSuccess(deletedUser), new GetUser()]),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  constructor(private _actions$: Actions, private _userService: UserService, private _notificationService: NotificationService) { }
}
