import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { ClickAndTellGameComponent } from './click-and-tell.component'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { ReportProblemPopupModule } from '../../popups/Report-Dialogs/report-problem/report-problem.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, MatCardModule, MatButtonModule, MatIconModule, ReportProblemPopupModule, TranslateModule, SoundsModule, LottieModule],
  declarations: [ClickAndTellGameComponent],
  exports: [ClickAndTellGameComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ClickAndTellGameModule {}
