<section class="menu">
  <button (click)="closeDialog()" mat-icon-button color="warn">
    <img src="/assets/images/icons/closeIcon.svg" alt="close" />
  </button>
  <img class="dino" src="/assets/images/logos/dinoMenuPopup.svg" alt=" " />

  <div class="languageCircles">
    <img src="/assets/images/languages/{{ (course$ | async)?.slug }}.png" alt="selected course {{ (course$ | async)?.slug }}" />
    <span>{{ (course$ | async)?.name }}</span>
  </div>
  <div class="menuButtons">
    <button class="lesson" routerLink="/profile/lessons" (click)="closeDialog()" routerLinkActive="active">
      <div class="circle"></div>
      <div class="text-cont">
        <span>{{ 'HEADER_MENU.LESSON' | translate }}</span>
      </div>
    </button>
    <button class="books" routerLink="/profile/books" (click)="closeDialog()" routerLinkActive="active">
      <div class="circle"></div>
      <div class="text-cont">
        <span>{{ 'HEADER_MENU.BOOKS' | translate }}</span>
      </div>
    </button>
    <button class="stories" routerLink="/profile/stories" (click)="closeDialog()" routerLinkActive="active">
      <div class="circle"></div>
      <div class="text-cont">
        <span>{{ 'HEADER_MENU.STORIES' | translate }}</span>
      </div>
    </button>
    <button class="prints" routerLink="/profile/prints" (click)="closeDialog()" routerLinkActive="active">
      <div class="circle"></div>
      <div class="text-cont">
        <span>{{ 'HEADER_MENU.PRINTS' | translate }}</span>
      </div>
    </button>
    <button class="songs" routerLink="/profile/songs" (click)="closeDialog()" routerLinkActive="active">
      <div class="circle"></div>
      <div class="text-cont">
        <span>{{ 'HEADER_MENU.SONGS' | translate }}</span>
      </div>
    </button>
    <button class="report" routerLink="/reports" (click)="closeDialog()" routerLinkActive="active">
      <div class="circle"></div>
      <div class="text-cont">
        <span>{{ 'HEADER_MENU.REPORTS' | translate }} </span>
      </div>
    </button>
  </div>
</section>
