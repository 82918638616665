<section class="testimonials">
  <article class="contentWrapper">
    <div #reviewBlock class="reviewBlock">
      <div class="quotesBlock top">
        <svg-icon src="assets/images/icons/quoteTop.svg"></svg-icon>
        <svg-icon src="assets/images/icons/quoteTop.svg"></svg-icon>
      </div>
      <div class="blockStar">
        <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
        <svg-icon [applyClass]="true" src="assets/images/icons/star.svg"></svg-icon>
      </div>
      <p class="review fade">
        {{ 'HOME.BLOCK_5.REVIEW_1' | translate }}
      </p>
      <p class="review fade">
        {{ 'HOME.BLOCK_5.REVIEW_2' | translate }}
      </p>
      <p class="review fade">
        {{ 'HOME.BLOCK_5.REVIEW_3' | translate }}
      </p>
      <p class="review fade">
        {{ 'HOME.BLOCK_5.REVIEW_4' | translate }}
      </p>
      <p class="review fade">
        {{ 'HOME.BLOCK_5.REVIEW_5' | translate }}
      </p>
      <p class="review fade">
        {{ 'HOME.BLOCK_5.REVIEW_6' | translate }}
      </p>

      <div class="quotesBlock bottom">
        <svg-icon src="assets/images/icons/quoteBottom.svg"></svg-icon>
        <svg-icon src="assets/images/icons/quoteBottom.svg"></svg-icon>
      </div>
    </div>
    <div #customersWrapper class="customers">
      <div #reviewTriangle class="reviewTriangle"></div>
      <div (click)="checkReview($event, 1)" class="languageCircle">
        <img src="assets/images/testimonials/autumn.jpg" class="circle" alt="reviewer-photo" />
        <span>Autumn E.</span>
      </div>
      <div (click)="checkReview($event, 2)" class="languageCircle">
        <img src="assets/images/testimonials/britnee.jpg" class="circle" alt="reviewer-photo" />
        <span>Britney F.</span>
      </div>
      <div (click)="checkReview($event, 3)" class="languageCircle">
        <img src="assets/images/testimonials/paula.jpg" class="circle" alt="reviewer-photo" />
        <span>Paula R.</span>
      </div>
      <div (click)="checkReview($event, 4)" class="languageCircle">
        <img src="assets/images/testimonials/rivas.jpg" class="circle" alt="reviewer-photo" />
        <span>Paula C.</span>
      </div>
      <div (click)="checkReview($event, 5)" class="languageCircle">
        <img src="assets/images/testimonials/brittney.jpg" class="circle" alt="reviewer-photo" />
        <span>Britnee S.</span>
      </div>
      <div (click)="checkReview($event, 6)" class="languageCircle">
        <img src="assets/images/testimonials/heidi.jpg" class="circle" alt="reviewer-photo" />
        <span>Heidi B.</span>
      </div>
    </div>
  </article>
</section>
