import { ENotificationActions, NotificationActions } from '../actions/notification.actions'
import { initialNotificationState, INotificationState } from '../state/notification.state'

export const notificationReducer = (state = initialNotificationState, action: NotificationActions): INotificationState => {
  switch (action.type) {
    case ENotificationActions.GetNotificationSuccess: {
      return {
        ...state,
        notification: action.payload,
      }
    }

    default:
      return state
  }
}
