<div class="landing">
  <section class="blog">
    <h1>Dinolingo Blog</h1>
    <ul class="list">
      <li *ngFor="let item of list; let i = index">
        <a href="blog/{{ item.slug }}">{{ item.title }}</a>
      </li>
    </ul>
  </section>
</div>
