<div class="landing">
  <section class="mainComponent">
    <img src="assets/images/pictures/trees.png" class="trees" alt="trees" title="trees" />
    <img src="assets/images/pictures/mountain.png" class="mountain" alt="mountain" title="mountain" />
    <span class="rectRhombus orange"></span>
    <span class="rectRhombus small green"></span>
    <span class="rectCicrle red"></span>
    <span class="rectRhombus extraSmall orange"></span>
    <img src="assets/images/dinosaurs/oneDino.png" class="dino svgImage" />
    <article class="contentWrapper">
      <div class="text">
        <svg-icon [applyClass]="true" src="assets/images/dinosaurs/oneDino.svg"></svg-icon>
        <div class="titles">
          <h1 class="white baloo2">{{ 'CURRICULUM.TITLE' | translate }}</h1>
          <span [innerHTML]="'CURRICULUM.SUBTITLE' | translate"></span>
          <button class="primary orange">
            <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.TRY_FREE_NOW' | translate }}</a>
          </button>
        </div>
      </div>
    </article>
  </section>

  <section class="gray">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" title="borderWhite" />
  </section>

  <section class="curriculum">
    <article class="contentWrapper">
      <h2 class="blue baloo2">
        {{ 'CURRICULUM.TITLE_BLOCK' | translate }}
      </h2>
      <div class="title">
        <p>
          {{ 'CURRICULUM.SUBTITLE_BLOCK' | translate }}
        </p>
      </div>
    </article>
  </section>

  <section class="gray">
    <img src="assets/images/pictures/borderWhite.png" class="border top" alt="" title="borderWhite" />
  </section>

  <section class="image">
    <article class="contentWrapper">
      <img src="assets/images/dinosaurs/threeDinosaurs.svg" alt="threeDinosaurs" title="threeDinosaurs" />
      <button class="primary orange">
        <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.TRY_FREE_NOW' | translate }}</a>
      </button>
    </article>

    <review-stars [schemeTitle]="metaTitle"></review-stars>
  </section>
</div>
