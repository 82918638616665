import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms'
// import { AuthService } from 'angularx-social-login';
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { IUserLogin } from '../../../models/User.model'
import { SignIn } from '../../../store/actions/auth.actions'
import { Store } from '@ngrx/store'
import { IAppState } from '../../../store/state/app.state'
import { DeviceDetectorService } from 'ngx-device-detector'
import { take } from 'rxjs/operators'

@Component({
  templateUrl: './login-student.component.html',
  styleUrls: ['./login-student.component.scss'],
})
export class LoginStudentComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    // private authService: AuthService,
    private deviceService: DeviceDetectorService,
    public translate: TranslateService,
    private router: Router,
    public _dialog: MatDialog,
    public dialogRef: MatDialogRef<LoginStudentComponent>
  ) {}

  public deviceInfo = this.deviceService.getDeviceInfo()

  public studentLoginForm: UntypedFormGroup

  ngOnInit(): void {
    this.studentLoginForm = this.formBuilder.group({
      classroomCode: ['', [Validators.required]],
      studentCode: ['', [Validators.required]],
      os: ['', Validators.required],
      browser: ['', Validators.required],
      userAgent: ['', Validators.required],
    })

    this.studentLoginForm.patchValue({
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      userAgent: this.deviceInfo.userAgent,
    })

    // close dialog after login
    this.router.events.pipe(take(1)).subscribe(() => this.dialogRef.close())
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  get classroomCode() {
    return this.studentLoginForm.get('classroomCode')
  }

  get studentCode() {
    return this.studentLoginForm.get('studentCode')
  }

  studentLogin() {
    if (!this.studentLoginForm.valid) return

    const request: IUserLogin = this.studentLoginForm.value

    this.store.dispatch(new SignIn(request))
  }
}
