import { Component, Inject, OnInit } from '@angular/core'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { TranslateService } from '@ngx-translate/core'

@Component({
  templateUrl: 'stars.component.html',
  styleUrls: ['stars.component.scss'],
})
export class StarsPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<StarsPopupComponent>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public dialogData) {}

  ngOnInit(): void {}
}
