import { Directive, HostListener, Input } from '@angular/core'
import { DeviceDetectorService } from 'ngx-device-detector'
import { AudioService } from 'src/app/services/audio.service'

@Directive({
  selector: '[appSounds]',
})
export class SoundsDirective {
  constructor(private deviceService: DeviceDetectorService, private audioService: AudioService) {}

  @Input('appSounds') soundFiles: string

  @HostListener('click') onClick() {
    if (!this.deviceService.isMobile()) {
      if (this.soundFiles == 'turnpage') {
        this.audioService.playSoundTurnpage()
      }
    }
  }
}
