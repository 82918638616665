import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { VideoPopupComponent } from 'src/app/shared/popups/VideoPopup/videopopup.component'
import { TutorialsService } from '../../../services/tutorials.service'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss'],
})
export class TutorialsComponent implements OnInit {
  constructor(public translate: TranslateService, private _dialog: MatDialog, private tutorialsServise: TutorialsService, private route: ActivatedRoute) {}

  public tutorials = []

  ngOnInit(): void {
    const { target } = this.route.snapshot.data

    this.tutorialsServise.getTutorials(target).subscribe(
      (data: any) => {
        if (data) {
          this.tutorials = data
        }
      },
      (err) => {}
    )
  }

  public openVideoPopup(url) {
    const dialog = this._dialog.open(VideoPopupComponent, {
      panelClass: 'video-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: url,
    })
  }
}
