import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeaderComponent } from './header.component'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatBadgeModule } from '@angular/material/badge'
import { MatRippleModule } from '@angular/material/core'
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { MenuPopupModule } from '../../popups/Control-Dialogs/menu/menu.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { CurriculumDialogModule } from '../../popups/Curriculum-Dialogs/curriculum/curriculum.module'
import { StudentAssignmentsDialogModule } from '../../popups/Assignment-Dialogs/student-assignments/student-assignments.module'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  declarations: [HeaderComponent],

  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatBadgeModule,
    MatMenuModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MenuPopupModule,
    RouterModule,
    TranslateModule,
    SoundsModule,
    CurriculumDialogModule,
    StudentAssignmentsDialogModule,
    LottieModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
