import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ListStudentsComponent } from './list-students.component'
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar'
import { EditStudentDialogModule } from '../../../../shared/popups/Student-Dialogs/edit-student/edit-student.module'
import { ConfirmationPopupModule } from '../../../../shared/popups/Control-Dialogs/confirmation/confirmation.module'
import { TranslateModule } from '@ngx-translate/core'
import { StudentAssignmentsDialogModule } from '../../../../shared/popups/Assignment-Dialogs/student-assignments/student-assignments.module'

@NgModule({
  declarations: [ListStudentsComponent],
  exports: [ListStudentsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    EditStudentDialogModule,
    ConfirmationPopupModule,
    StudentAssignmentsDialogModule,
  ],
})
export class ListStudentsModule {}
