import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { LoadingScreenComponent } from './loading.component'

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [LoadingScreenComponent],
  exports: [LoadingScreenComponent],
})
export class LoadingScreenModule {}
