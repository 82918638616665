import { isPlatformServer } from '@angular/common'
import { Component, Inject, Injector, OnInit, OnDestroy, PLATFORM_ID } from '@angular/core'
import { RESPONSE } from '@nguniversal/express-engine/tokens'
import { Router, ActivatedRoute } from '@angular/router'
import { Meta } from '@angular/platform-browser'

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit, OnDestroy {
  constructor(@Inject(PLATFORM_ID) private platformId, private injector: Injector, private router: Router, private route: ActivatedRoute, private meta: Meta) {}

  ngOnInit(): void {
    if (this.route.snapshot.url[0].path.toLowerCase() !== this.route.snapshot.url[0].path) {
      this.router.navigate([this.route.snapshot.url[0].path.toLowerCase()])
    }
    this.meta.updateTag({ name: 'robots', content: 'noindex' })

    if (isPlatformServer(this.platformId)) {
      const response = this.injector.get(RESPONSE)
      response.status(404)
    }
  }

  ngOnDestroy() {
    this.meta.removeTag("name='robots'")
  }
}
