import { Component, OnDestroy, OnInit, Inject } from '@angular/core'
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { Router } from '@angular/router'
import { NotificationService } from 'src/app/services/notification.service'
import { TranslateService } from '@ngx-translate/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { OverlayModule } from '@angular/cdk/overlay'

@Component({
  templateUrl: './checkParentPin.component.html',
  styleUrls: ['./checkParentPin.component.scss'],
})
export class CheckParentPinComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<CheckParentPinComponent>,
    private _store: Store<IAppState>,
    public router: Router,
    public notifications: NotificationService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public buttonText
  ) {}

  public buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
  public pin = ''
  public user: any
  public unsubscribe$ = new Subject()

  public user$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser))

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      if (user) this.user = user.user
    })
  }

  addValue(symbol: string) {
    this.pin += symbol
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  checkPin() {
    if (this.pin == this.user.pin) {
      this.dialogRef.close(true)
    } else {
      this.notifications.showNotificationError('Incorrect Pin', 4)
    }
  }
}
