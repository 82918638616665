import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { LessonComponent } from '../../views/profile/lessons/lesson/lesson.component'
@Injectable()
export class YourComponentCanDeactivateGuardService implements CanDeactivate<LessonComponent> {
  canDeactivate(component: LessonComponent): boolean {
    console.log('component data', component.exitConfirm)
    if (component.exitConfirm) {
      return confirm('Are you sure you want to exit? Your progress will be lost')
    }
    return true
  }
}
