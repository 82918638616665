import { UserActions, EUserActions } from '../actions/user.actions'
import { IUserState, InitialUserState } from '../state/user.state'

export const userReducer = (state = InitialUserState, action: UserActions): IUserState => {
  switch (action.type) {
    case EUserActions.GetUserSuccess: {
      return {
        ...state,
        user: action.payload,
      }
    }

    default:
      return state
  }
}
