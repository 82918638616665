import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LandingRedirectService {
  constructor(private translate: TranslateService) { }

  checkAndRedirect(path: string): boolean {
    if (path.includes('sitemap.xml') || path.includes('robots.txt') || path.includes('google034a9e16ac25cf8d.html')) return true;

    const redirectPath = path == '/en' ? '/' : path;
    window.location.href = `https://dinolingo.com${redirectPath}`;

    return false;
  }
}
