import { Component, Inject, OnInit } from '@angular/core'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  templateUrl: './localization-select.component.html',
  styleUrls: ['./localization-select.component.scss'],
})
export class LocalizationSelectPopupComponent implements OnInit {
  public availableTranslations: any[]

  constructor(private _dialog: MatDialogRef<LocalizationSelectPopupComponent>, @Inject(MAT_DIALOG_DATA) private data) {
    this.availableTranslations = data.availableTranslations
  }

  ngOnInit(): void {}

  public close(): void {
    this._dialog.close()
  }

  public selectLanguage(language): void {
    this._dialog.close(language)
  }
}
