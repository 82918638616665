import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { PrivacyComponent } from './privacy.component'
import { TranslateModule } from '@ngx-translate/core'
import { TestimonialsModule } from '../common/testimonials/testimonials.module'
import { ReviewStarsModule } from '../common/review-stars/review-stars.module'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, TestimonialsModule, AngularSvgIconModule, TranslateModule, ReviewStarsModule],
  declarations: [PrivacyComponent],
})
export class PrivacyModule {}
