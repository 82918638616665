import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LocalizationDropdownComponent } from './localization-dropdown.component'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { LocalizationSelectPopupModule } from '../../../../../shared/popups/Lesson-Dialogs/localization-select/localization-select.module'

@NgModule({
  declarations: [LocalizationDropdownComponent],
  exports: [LocalizationDropdownComponent],
  imports: [CommonModule, MatSelectModule, LocalizationSelectPopupModule],
})
export class LocalizationDropdownModule {}
