import { Component, OnInit, Input } from '@angular/core'
import { GetIpUserService } from 'src/app/services/getIp.service'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
// import { DOCUMENT } from '@angular/common';

export interface IRewiwe {
  countReviews: number
  max: number
  min: number
  starsAverage: string
  userReview: number
}

@Component({
  selector: 'review-stars',
  templateUrl: './review-stars.component.html',
  styleUrls: ['./review-stars.component.scss'],
})
export class ReviewStarsComponent implements OnInit {
  @Input() schemeTitle

  constructor(
    private getUserIpService: GetIpUserService,
    private router: Router,
    public translate: TranslateService // private _renderer2: Renderer2, // @Inject(DOCUMENT) private _document: Document,
  ) {}

  public schema

  public url: string = this.router.url

  public userStars
  public average = '0'
  public pageStars = 0
  public avg = 0

  public arrayRes = []
  public stars: number[] = [5, 4, 3, 2, 1]

  ngOnInit() {
    const request = {
      url: this.url,
    }
    this._getDataForStars(request)
  }

  countStar(star) {
    const request = {
      url: this.url,
      stars: star,
    }
    this.getUserIpService.sendUserReviw(request).subscribe((res) => {
      const req = {
        url: this.url,
      }
      this._getDataForStars(request)
    })
  }

  private _getDataForStars(request) {
    this.getUserIpService.getUserReview(request).subscribe((res: IRewiwe) => {
      if (res) {
        this.pageStars = res.countReviews ? res.countReviews : 0
        this.average = res.starsAverage
        this.userStars = res.userReview
        this.avg = Math.round(+res.starsAverage)
      }
    })
  }
}
