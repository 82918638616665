import { Action } from '@ngrx/store'
import { IStudent, IStudentRequest } from 'src/app/models/Student.model'

export enum EStudentActions {
  AddStudent = '[Student] Add Student',
  AddStudentSuccess = '[Student] Add Student Success',
  AddStudentFailure = '[Student] Add Student Failure',
  UpdateStudent = '[Student] Update Student',
  UpdateStudentSuccess = '[Student] Update Student Success',
  UpdateStudentFailure = '[Student] Update Student Failure',
  RemoveStudent = '[Student] Remove Student',
  RemoveStudentSuccess = '[Student] Remove Student Success',
  RemoveStudentFailure = '[Student] Remove Student Failure',
  GetStudentsForClassroom = '[Student] Get Students For Classroom',
  GetStudentsForClassroomSuccess = '[Student] Get Students For Classroom Success',
  GetStudentsForClassroomFailure = '[Student] Get Students For Classroom Failure',
  GetCurrentStudent = '[Student] Get Current Student',
  GetCurrentStudentSuccess = '[Student] Get Current Student Success',
  GetCurrentStudentFailure = '[Student] Get Current Student Failure',
  GetStudents = '[Student] Get Students',
  GetStudentsSuccess = '[Student] Get Students Success',
  GetStudentsFailure = '[Student] Get Students Failure',
}

export class AddStudent implements Action {
  public readonly type = EStudentActions.AddStudent
  constructor(public payload: IStudentRequest) {}
}

export class AddStudentSuccess implements Action {
  public readonly type = EStudentActions.AddStudentSuccess
  constructor(public payload: any) {}
}

export class AddStudentFailure implements Action {
  public readonly type = EStudentActions.AddStudentFailure
  constructor(public payload: any) {}
}

export class UpdateStudent implements Action {
  public readonly type = EStudentActions.UpdateStudent
  constructor(public student: IStudent, public payload: IStudentRequest) {}
}

export class UpdateStudentSuccess implements Action {
  public readonly type = EStudentActions.UpdateStudentSuccess
  constructor(public payload: any) {}
}

export class UpdateStudentFailure implements Action {
  public readonly type = EStudentActions.UpdateStudentFailure
  constructor(public payload: any) {}
}

export class RemoveStudent implements Action {
  public readonly type = EStudentActions.RemoveStudent
  constructor(public payload: any | number) {}
}

export class RemoveStudentSuccess implements Action {
  public readonly type = EStudentActions.RemoveStudentSuccess
  constructor(public payload: any) {}
}

export class RemoveStudentFailure implements Action {
  public readonly type = EStudentActions.RemoveStudentFailure
  constructor(public payload: any) {}
}

export class GetStudents implements Action {
  public readonly type = EStudentActions.GetStudents
  constructor(public schoolId: number) {}
}

export class GetStudentsSuccess implements Action {
  public readonly type = EStudentActions.GetStudentsSuccess
  constructor(public students: Array<IStudent>) {}
}

export class GetStudentsFailure implements Action {
  public readonly type = EStudentActions.GetStudentsFailure
  constructor(public payload: any) {}
}

export class GetStudentsForClassroom implements Action {
  public readonly type = EStudentActions.GetStudentsForClassroom
  constructor(public schoolId: number, public classroomId: number) {}
}

export class GetStudentsForClassroomSuccess implements Action {
  public readonly type = EStudentActions.GetStudentsForClassroomSuccess
  constructor(public payload: any) {}
}

export class GetStudentsForClassroomFailure implements Action {
  public readonly type = EStudentActions.GetStudentsForClassroomFailure
  constructor(public payload: any) {}
}

export class GetCurrentStudent implements Action {
  public readonly type = EStudentActions.GetCurrentStudent
  constructor() {}
}

export class GetCurrentStudentSuccess implements Action {
  public readonly type = EStudentActions.GetCurrentStudentSuccess
  constructor(public student: IStudent) {}
}

export class GetCurrentStudentFailure implements Action {
  public readonly type = EStudentActions.GetCurrentStudentFailure
  constructor(public payload: any) {}
}

export type StudentActions =
  | AddStudent
  | AddStudentSuccess
  | AddStudentFailure
  | UpdateStudent
  | UpdateStudentSuccess
  | UpdateStudentFailure
  | RemoveStudent
  | RemoveStudentSuccess
  | RemoveStudentFailure
  | GetStudentsForClassroom
  | GetStudentsForClassroomSuccess
  | GetStudentsForClassroomFailure
  | GetCurrentStudent
  | GetCurrentStudentSuccess
  | GetCurrentStudentFailure
  | GetStudents
  | GetStudentsSuccess
  | GetStudentsFailure
