import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LoginStudentComponent } from './login-student.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { RouterModule } from '@angular/router'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'

@NgModule({
  declarations: [LoginStudentComponent],
  imports: [FormsModule, ReactiveFormsModule, RouterModule, MatCardModule, MatInputModule, MatIconModule, MatButtonModule, CommonModule, TranslateModule, SoundsModule],
})
export class LoginStudentModule {}
