import { IHeaderState, InitialHeaderState } from '../state/header.state'

import { EHeaderActions, HeaderActions } from '../actions/header.actions'

export const headerReducer = (state = InitialHeaderState, action: HeaderActions): IHeaderState => {
  switch (action.type) {
    case EHeaderActions.SetHeaderValue: {
      return {
        ...state,
        headerValue: action.payload,
      }
    }

    case EHeaderActions.SetDropdownOpen: {
      return {
        ...state,
        dropdownOpen: action.payload,
      }
    }

    default:
      return state
  }
}
