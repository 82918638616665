import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LocalizationSelectPopupComponent } from './localization-select.component'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'

@NgModule({
  declarations: [LocalizationSelectPopupComponent],
  imports: [CommonModule, MatDialogModule],
})
export class LocalizationSelectPopupModule {}
