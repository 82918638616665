import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatBadgeModule } from '@angular/material/badge'
import { MatRippleModule } from '@angular/material/core'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { ProfileLessonsComponent } from './lessons.component'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { TranslateModule } from '@ngx-translate/core'
import { LessonEndPopupModule } from 'src/app/shared/popups/Lesson-Dialogs/lesson-end/lesson-end.module'
import { LessonSubscribePopupModule } from 'src/app/shared/popups/Lesson-Dialogs/lesson-subscribe/lesson-subscribe.module'
import { LessonUnavailablePopupPopupModule } from 'src/app/shared/popups/Lesson-Dialogs/lesson-unavailable/lesson-unavailable.module'
import { LanguageSelectPopupModule } from 'src/app/shared/popups/Control-Dialogs/language-select/language-select.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { NotificationsModule } from 'src/app/shared/components/notifications/notifications.module'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  declarations: [ProfileLessonsComponent],

  imports: [
    CommonModule,
    LanguageSelectPopupModule,
    LessonSubscribePopupModule,
    LessonUnavailablePopupPopupModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCardModule,
    MatBadgeModule,
    MatRippleModule,
    RouterModule,
    MatDialogModule,
    LessonEndPopupModule,
    TranslateModule,
    SoundsModule,
    NotificationsModule,
    LottieModule,
  ],
})
export class ProfileLessonsModule {}
