export interface IStatisticsState {
  dinosaurs: any
  openDinosaurs: any
  dinosaursCount: number
  stars: any
  starsCount: number
  achievements: any
  achievementsCount: number
  progress: any
  testReport: any
  // testReportPdf: any;
}

export const InitialStatisticsState: IStatisticsState = {
  dinosaurs: null,
  openDinosaurs: null,
  dinosaursCount: null,
  stars: null,
  starsCount: null,
  achievements: null,
  achievementsCount: null,
  progress: null,
  testReport: null,
  // testReportPdf: null,
}
