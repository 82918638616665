import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import { IThumbnail } from 'src/app/models/Avatar.model'
import { NotificationService } from 'src/app/services/notification.service'
import { ThumbnailsService } from 'src/app/services/thumbnails.service'
import { EThumbnailsActions, GetThumbnails, GetThumbnailsSuccess } from '../actions/thumbnails.actions'

@Injectable()
export class ThumbnailsEffects {
  getThumbnails$: Observable<GetThumbnailsSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetThumbnails>(EThumbnailsActions.GetThumbnails),
      mergeMap(() => this._thumbnailsService.getThumbnails()),
      mergeMap((thumbnails: IThumbnail[]) => of(new GetThumbnailsSuccess(thumbnails))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  constructor(private _actions$: Actions, private _thumbnailsService: ThumbnailsService, private _notificationService: NotificationService) {}
}
