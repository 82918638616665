<div class="landing">
  <section class="mainComponent">
    <img src="assets/images/pictures/trees.png" class="trees" alt="trees" title="Trees" />
    <img src="assets/images/pictures/mountain.png" class="mountain" alt="mountain" title="Mountain" />
    <span class="rectRhombus orange"></span>
    <span class="rectRhombus small green"></span>
    <span class="rectCicrle red"></span>
    <span class="rectRhombus extraSmall orange"></span>
    <img src="assets/images/dinosaurs/oneDino.png" class="dino svgImage" />
    <article class="contentWrapper">
      <div class="text">
        <svg-icon [applyClass]="true" src="assets/images/dinosaurs/oneDino.svg"></svg-icon>
        <div class="titles">
          <h1 class="white baloo2">{{ 'HOW_IT_WORK.TITLE' | translate }}</h1>
          <span [innerHTML]="'HOW_IT_WORK.SUBTITLE' | translate"></span>
          <button class="primary orange">
            <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
          </button>
        </div>
      </div>
    </article>
  </section>

  <section class="gray">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" title="Border White" />
  </section>

  <section class="howItWorks">
    <article class="contentWrapper">
      <div class="content">
        <div class="cart">
          <svg-icon src="assets/images/wrapElements/languages.svg" [svgStyle]="{ 'width.rem': 8, 'height.rem': 9.5 }"> </svg-icon>

          <div class="info" [innerHTML]="'HOW_IT_WORK.CART_1' | translate"></div>
        </div>

        <div class="cart">
          <svg-icon src="assets/images/wrapElements/cup.svg" [svgStyle]="{ 'width.rem': 8, 'height.rem': 9.5 }"> </svg-icon>

          <div class="info" [innerHTML]="'HOW_IT_WORK.CART_2' | translate"></div>
        </div>

        <div class="cart">
          <svg-icon src="assets/images/wrapElements/dollar.svg" [svgStyle]="{ 'width.rem': 8, 'height.rem': 9.5 }"> </svg-icon>

          <div class="info" [innerHTML]="'HOW_IT_WORK.CART_3' | translate"></div>
        </div>

        <div class="cart">
          <div class="image">
            <svg-icon src="assets/images/wrapElements/bird.svg" [svgStyle]="{ 'width.rem': 8, 'height.rem': 9.5 }"> </svg-icon>
          </div>

          <div class="info" [innerHTML]="'HOW_IT_WORK.CART_4' | translate"></div>
        </div>

        <div class="cart">
          <div class="image">
            <svg-icon src="assets/images/wrapElements/play.svg" [svgStyle]="{ 'width.rem': 8, 'height.rem': 9.5 }"> </svg-icon>
          </div>

          <div class="info" [innerHTML]="'HOW_IT_WORK.CART_5' | translate"></div>
        </div>

        <div class="cart">
          <div class="image">
            <svg-icon src="assets/images/wrapElements/cactus.svg" [svgStyle]="{ 'width.rem': 8, 'height.rem': 9.5 }"> </svg-icon>
          </div>

          <div class="info" [innerHTML]="'HOW_IT_WORK.CART_6' | translate"></div>
        </div>

        <div class="cart">
          <div class="image">
            <svg-icon src="assets/images/wrapElements/eggs.svg" [svgStyle]="{ 'width.rem': 8, 'height.rem': 9.5 }"> </svg-icon>
          </div>

          <div class="info" [innerHTML]="'HOW_IT_WORK.CART_7' | translate"></div>
        </div>

        <div class="cart">
          <div class="image">
            <svg-icon src="assets/images/wrapElements/phones.svg" [svgStyle]="{ 'width.rem': 8, 'height.rem': 9.5 }"> </svg-icon>
          </div>

          <div class="info" [innerHTML]="'HOW_IT_WORK.CART_8' | translate"></div>
        </div>
      </div>
      <span class="rectRhombus extraSmall red"></span>
      <span class="rectRhombus extraSmall green"></span>
      <span class="rectRhombus small orange"></span>
      <button class="primary orange">
        <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
      </button>
    </article>
  </section>

  <section class="gamification">
    <article class="contentWrapper" [innerHTML]="'HOW_IT_WORK.GAMIFICATION' | translate"></article>

    <div class="image">
      <img src="assets/images/dinosaurs/twoDinosaursWithCup.svg" alt="twoDinosaursWithCup" title="Two Dinosaurs With Cup" />
    </div>

    <button class="primary orange">
      <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
    </button>
  </section>

  <section class="method" [ngClass]="{ hideBlock: currentLang !== 'en' }">
    <article class="contentWrapper">
      <h2 class="blue baloo2">
        {{ 'HOW_IT_WORK.METHODS.TITLE' | translate }}
      </h2>
      <div class="methods">
        <div class="method">
          <h2 class="baloo2 number orange">1</h2>
          <h3 class="gray baloo2">{{ 'HOW_IT_WORK.METHODS.METH_1_TITLE' | translate }}</h3>
          <div class="text">
            <p>{{ 'HOW_IT_WORK.METHODS.METH_1_TEXT' | translate }}</p>
          </div>
        </div>
        <div class="method">
          <h2 class="baloo2 number orange">2</h2>
          <h3 class="gray baloo2">{{ 'HOW_IT_WORK.METHODS.METH_2_TITLE' | translate }}</h3>
          <div class="text">
            <p>{{ 'HOW_IT_WORK.METHODS.METH_2_TEXT' | translate }}</p>
          </div>
        </div>
        <div class="method">
          <h2 class="baloo2 number orange">3</h2>
          <h3 class="gray baloo2">{{ 'HOW_IT_WORK.METHODS.METH_3_TITLE' | translate }}</h3>
          <div class="text">
            <p>{{ 'HOW_IT_WORK.METHODS.METH_3_TEXT' | translate }}</p>
          </div>
        </div>
        <div class="method">
          <h2 class="baloo2 number orange">4</h2>
          <h3 class="gray baloo2">{{ 'HOW_IT_WORK.METHODS.METH_4_TITLE' | translate }}</h3>
          <div class="text">
            <p>{{ 'HOW_IT_WORK.METHODS.METH_4_TEXT' | translate }}</p>
          </div>
        </div>
        <div class="method">
          <h2 class="baloo2 number orange">5</h2>
          <h3 class="gray baloo2">{{ 'HOW_IT_WORK.METHODS.METH_5_TITLE' | translate }}</h3>
          <div class="text">
            <p>{{ 'HOW_IT_WORK.METHODS.METH_5_TEXT' | translate }}</p>
          </div>
        </div>
        <div class="method">
          <h2 class="baloo2 number orange">6</h2>
          <h3 class="gray baloo2">{{ 'HOW_IT_WORK.METHODS.METH_6_TITLE' | translate }}</h3>
          <div class="text">
            <p>{{ 'HOW_IT_WORK.METHODS.METH_6_TEXT' | translate }}</p>
          </div>
        </div>
        <div class="method">
          <h2 class="baloo2 number orange">7</h2>

          <h3 class="gray baloo2">
            {{ 'HOW_IT_WORK.METHODS.METH_7_TITLE' | translate }}
          </h3>

          <div class="text">
            <p>
              {{ 'HOW_IT_WORK.METHODS.METH_7_TEXT' | translate }}
            </p>
          </div>
        </div>
        <div class="method">
          <h2 class="baloo2 number orange">8</h2>

          <h3 class="gray baloo2">
            {{ 'HOW_IT_WORK.METHODS.METH_8_TITLE' | translate }}
          </h3>

          <div class="text">
            <p>
              {{ 'HOW_IT_WORK.METHODS.METH_8_TEXT' | translate }}
            </p>
          </div>
        </div>
        <div class="method">
          <h2 class="baloo2 number orange">9</h2>

          <h3 class="gray baloo2">
            {{ 'HOW_IT_WORK.METHODS.METH_9_TITLE' | translate }}
          </h3>

          <div class="text">
            <p>
              {{ 'HOW_IT_WORK.METHODS.METH_9_TEXT' | translate }}
            </p>
          </div>
        </div>
        <div class="method">
          <h2 class="baloo2 number orange">10</h2>

          <h3 class="gray baloo2">
            {{ 'HOW_IT_WORK.METHODS.METH_10_TITLE' | translate }}
          </h3>

          <div class="text">
            <p>
              {{ 'HOW_IT_WORK.METHODS.METH_10_TEXT' | translate }}
            </p>
          </div>
        </div>
      </div>

      <button class="primary orange">
        <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
      </button>
    </article>
    <img src="assets/images/pictures/borderWhite.png" class="border top" alt="" title="Border White" />

    <review-stars [schemeTitle]="metaTitle"></review-stars>
  </section>
</div>
