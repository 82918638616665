import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class TutorialsService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = environment.gatewayUrl
  }

  public getTutorials(target) {
    return this.http.get(`${this.gatewayUrl}tutorials/get`, { params: { target } })
  }
}
