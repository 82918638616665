import { Action } from '@ngrx/store'
import { IAssignment, IAssignmentRequest, IStudentGrades, IStudentsAssignments, IStudentsGrades } from '../../models/Assignment.model'

export enum EAssignmentActions {
  AddAssignment = '[Assignment] Add Assignment',
  AddAssignmentSuccess = '[Assignment] Add Assignment Success',
  AddAssignmentFailure = '[Assignment] Add Assignment Failure',
  EditAssignment = '[Assignment] Edit Assignment',
  EditAssignmentSuccess = '[Assignment] Edit Assignment Success',
  EditAssignmentFailure = '[Assignment] Edit Assignment Failure',
  GetAssignments = '[Assignment] Get Student Assignments',
  GetAssignmentsSuccess = '[Assignment] Get Assignments Success',
  GetAssignmentsFailure = '[Assignment] Get Assignments Failure',
  GetStudentAssignments = '[Assignment] Get Assignments',
  GetStudentAssignmentsSuccess = '[Assignment] Get Student Assignments Success',
  GetStudentAssignmentsFailure = '[Assignment] Get Student Assignments Failure',
  GetStudentsAssignments = '[Assignment] Get Students Assignments',
  GetStudentsAssignmentsSuccess = '[Assignment] Get Students Assignments Success',
  GetStudentsAssignmentsFailure = '[Assignment] Get Students Assignments Failure',
  CompleteAssignment = '[Assignment] Complete Assignment',
  CompleteAssignmentSuccess = '[Assignment] Complete Assignment Success',
  CompleteAssignmentFailure = '[Assignment] Complete Assignment Failure',
  AdjustStudentGrade = '[Assignment] Adjust Student Grade',
  AdjustStudentGradeSuccess = '[Assignment] Adjust Student Grade Success',
  AdjustStudentGradeFailure = '[Assignment] Adjust Student Grade Failure',
  GetStudentsGrades = '[Assignment] Get Students Grades',
  GetStudentsGradesSuccess = '[Assignment] Get Students Grades Success',
  GetStudentsGradesFailure = '[Assignment] Get Students Grades Failure',
  DeleteAssignment = '[Assignment] Delete Assignment',
  DeleteAssignmentSuccess = '[Assignment] Delete Assignment Success',
  DeleteAssignmentFailure = '[Assignment] Delete Assignment Failure',
  GetStudentGrades = '[Assignment] Get Student Grades',
  GetStudentGradesSuccess = '[Assignment] Get Student Grades Success',
  GetStudentGradesFailure = '[Assignment] Get Student Grades Failure',
}

export class AddAssignment implements Action {
  public readonly type = EAssignmentActions.AddAssignment
  constructor(public schoolId: number, public classroomId: number, public payload: IAssignmentRequest) {}
}

export class AddAssignmentSuccess implements Action {
  public readonly type = EAssignmentActions.AddAssignmentSuccess
  constructor(public assignment: IAssignment) {}
}

export class AddAssignmentFailure implements Action {
  public readonly type = EAssignmentActions.AddAssignmentFailure
  constructor(public payload: any) {}
}

export class EditAssignment implements Action {
  public readonly type = EAssignmentActions.EditAssignment
  constructor(public schoolId: number, public classroomId: number, public assignmentId: number, public payload: IAssignmentRequest) {}
}

export class EditAssignmentSuccess implements Action {
  public readonly type = EAssignmentActions.EditAssignmentSuccess
  constructor(public assignment: IAssignment) {}
}

export class EditAssignmentFailure implements Action {
  public readonly type = EAssignmentActions.EditAssignmentFailure
  constructor(public payload: any) {}
}

export class GetAssignments implements Action {
  public readonly type = EAssignmentActions.GetAssignments
  constructor(public schoolId: number, public classroomId: number) {}
}

export class GetAssignmentsSuccess implements Action {
  public readonly type = EAssignmentActions.GetAssignmentsSuccess
  constructor(public assignments: Array<IAssignment>) {}
}

export class GetAssignmentsFailure implements Action {
  public readonly type = EAssignmentActions.GetAssignmentsFailure
  constructor(public payload: any) {}
}

export class GetStudentAssignments implements Action {
  public readonly type = EAssignmentActions.GetStudentAssignments
  constructor(public schoolId: number, public classroomId: number, public studentId: number, public all: boolean = false) {}
}

export class GetStudentAssignmentsSuccess implements Action {
  public readonly type = EAssignmentActions.GetStudentAssignmentsSuccess
  constructor(public assignments: Array<IAssignment>) {}
}

export class GetStudentAssignmentsFailure implements Action {
  public readonly type = EAssignmentActions.GetStudentAssignmentsFailure
  constructor(public payload: any) {}
}

export class GetStudentsAssignments implements Action {
  public readonly type = EAssignmentActions.GetStudentsAssignments
  constructor(public schoolId: number, public classroomId: number, public offset: number = 0, public limit: number = 10, public append: boolean = false) {}
}

export class GetStudentsAssignmentsSuccess implements Action {
  public readonly type = EAssignmentActions.GetStudentsAssignmentsSuccess
  constructor(public assignments: IStudentsAssignments, public append: boolean = false) {}
}

export class GetStudentsAssignmentsFailure implements Action {
  public readonly type = EAssignmentActions.GetStudentsAssignmentsFailure
  constructor(public payload: any) {}
}

export class CompleteAssignment implements Action {
  public readonly type = EAssignmentActions.CompleteAssignment
  constructor(public schoolId: number, public classroomId: number, public payload: any) {}
}

export class CompleteAssignmentSuccess implements Action {
  public readonly type = EAssignmentActions.CompleteAssignmentSuccess
  constructor(public payload: any) {}
}

export class CompleteAssignmentFailure implements Action {
  public readonly type = EAssignmentActions.CompleteAssignmentFailure
  constructor(public payload: any) {}
}

export class AdjustStudentGrade implements Action {
  public readonly type = EAssignmentActions.AdjustStudentGrade
  constructor(public schoolId: number, public classroomId: number, public studentId: number, public payload: { progress: number; lessonId: number }) {}
}

export class AdjustStudentGradeSuccess implements Action {
  public readonly type = EAssignmentActions.AdjustStudentGradeSuccess
  constructor(public payload: any) {}
}

export class AdjustStudentGradeFailure implements Action {
  public readonly type = EAssignmentActions.AdjustStudentGradeFailure
  constructor(public payload: any) {}
}

export class GetStudentsGrades implements Action {
  public readonly type = EAssignmentActions.GetStudentsGrades
  constructor(public schoolId: number, public classroomId: number) {}
}

export class GetStudentsGradesSuccess implements Action {
  public readonly type = EAssignmentActions.GetStudentsGradesSuccess
  constructor(public studentsGrades: Array<IStudentsGrades>) {}
}

export class GetStudentsGradesFailure implements Action {
  public readonly type = EAssignmentActions.GetStudentsGradesFailure
  constructor(public payload: any) {}
}

export class DeleteAssignment implements Action {
  public readonly type = EAssignmentActions.DeleteAssignment
  constructor(public schoolId: number, public classroomId: number, public assignmentId: number) {}
}

export class DeleteAssignmentSuccess implements Action {
  public readonly type = EAssignmentActions.DeleteAssignmentSuccess
  constructor(public payload: any, public assignmentId: number) {}
}

export class DeleteAssignmentFailure implements Action {
  public readonly type = EAssignmentActions.DeleteAssignmentFailure
  constructor(public payload: any) {}
}

export class GetStudentGrades implements Action {
  public readonly type = EAssignmentActions.GetStudentGrades
  constructor(public schoolId: number, public classroomId: number, public studentId: number) {}
}

export class GetStudentGradesSuccess implements Action {
  public readonly type = EAssignmentActions.GetStudentGradesSuccess
  constructor(public studentGrades: IStudentGrades) {}
}

export class GetStudentGradesFailure implements Action {
  public readonly type = EAssignmentActions.GetStudentGradesFailure
  constructor(public payload: any) {}
}

export type AssignmentActions =
  | AddAssignment
  | AddAssignmentSuccess
  | AddAssignmentFailure
  | EditAssignment
  | EditAssignmentSuccess
  | EditAssignmentFailure
  | GetAssignments
  | GetAssignmentsSuccess
  | GetAssignmentsFailure
  | GetStudentAssignments
  | GetStudentAssignmentsSuccess
  | GetStudentAssignmentsFailure
  | GetStudentsAssignments
  | GetStudentsAssignmentsSuccess
  | GetStudentsAssignmentsFailure
  | CompleteAssignment
  | CompleteAssignmentSuccess
  | CompleteAssignmentFailure
  | AdjustStudentGrade
  | AdjustStudentGradeSuccess
  | AdjustStudentGradeFailure
  | GetStudentsGrades
  | GetStudentsGradesSuccess
  | GetStudentsGradesFailure
  | DeleteAssignment
  | DeleteAssignmentSuccess
  | DeleteAssignmentFailure
  | GetStudentGrades
  | GetStudentGradesSuccess
  | GetStudentGradesFailure
