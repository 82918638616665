import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ParentsSubusersComponent } from './subusers.component'
import { MatRippleModule } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { AddSubuserDialogModule } from 'src/app/shared/popups/Subuser-Dialogs/add-subuser/addSubuser.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, AddSubuserDialogModule, MatRippleModule, MatIconModule, MatSnackBarModule, MatSortModule, MatTableModule, TranslateModule, SoundsModule, LottieModule],
  declarations: [ParentsSubusersComponent],
})
export class ParentsSubusersModule {}
