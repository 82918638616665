import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = `${environment.gatewayUrl}reports`
  }

  public reportProblem(request) {
    return this.http.post(`${this.gatewayUrl}/problem`, request)
  }

  public reportPageProblem(request) {
    return this.http.post(`${this.gatewayUrl}/pages/problem`, request)
  }
}
