import { ETeachersActions, TeachersActions } from '../actions/teachers.actions'
import { InitialTeacherState, ITeacherState } from '../state/teachers.state'

export const teachersReducers = (state = InitialTeacherState, action: TeachersActions): ITeacherState => {
  switch (action.type) {
    case ETeachersActions.GetTeachers: {
      return {
        ...state,
      }
    }

    case ETeachersActions.GetTeachersSuccess: {
      return {
        ...state,
        teachers: action.payload,
      }
    }

    case ETeachersActions.AddTeacherSuccess: {
      return {
        ...state,
        teachers: state.teachers.concat([action.payload]),
      }
    }

    case ETeachersActions.UpdateTeacherSuccess: {
      const newState = state.teachers.map((i) => {
        if (i.id === action.payload.updatedTeacher.id) {
          return action.payload.updatedTeacher
        }

        return i
      })
      return {
        ...state,
        teachers: newState,
      }
    }

    case ETeachersActions.RemoveTeacherSuccess: {
      return {
        ...state,
        teachers: state.teachers.filter((t) => t.id !== action.payload.deletedTeacher.id),
      }
    }

    default:
      return state
  }
}
