import { initialThumbnailsState, IThumbnailsState } from '../state/thumbnails.state'
import { ThumbnailsActions, EThumbnailsActions } from '../actions/thumbnails.actions'

export const thumbnailsReducer = (state = initialThumbnailsState, action: ThumbnailsActions): IThumbnailsState => {
  switch (action.type) {
    case EThumbnailsActions.GetThumbnailsSuccess: {
      return {
        ...state,
        thumbnails: action.payload,
      }
    }

    default:
      return state
  }
}
