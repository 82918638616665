<section class="bulk-create-students" *ngIf="loaded">
  <div class="title">{{ 'LINKS.BULK_CREATE_STUDENTS' | translate }}</div>
  <div class="back" routerLink='["/profile/teacher/students"]'><img src="assets/images/icons/arrowLeft.svg" alt="back" />{{ 'CREATE_STUDENT.BACK' | translate }}</div>
  <input type="file" accept="text/csv" style="display: none" ngModel #csvUpload (change)="processCsv($event)" />
  <button type="button" class="btn-import-csv" (click)="openFileDialog()">
    <img src="assets/images/icons/green-plus.svg" alt="import csv" />
    <span>{{ 'STUDENT.IMPORT_CSV' | translate }}</span>
  </button>
  <h4>{{ classroom.classroomName }}</h4>
  <h5>
    {{ 'CREATE_STUDENT.AVAILABLE' | translate }}: <span class="available-students" [ngClass]="{ 'none-available': availableStudents === 0 }">{{ availableStudents }}/{{ classroom.allocatedStudents }}</span>
  </h5>
  <article class="contentWrapper">
    <div class="form">
      <form [formGroup]="bulkStudentForm">
        <div class="form-row" formArrayName="students" *ngFor="let student of students.controls; index as i">
          <div [formGroupName]="i">
            <img src="assets/images/icons/redClose.svg" class="icon-remove" alt="remove" (click)="removeRow(i)" />
            <mat-form-field class="name">
              <mat-label>{{ 'PROFILE.STUDENT_NAME' | translate }} </mat-label>
              <input matInput placeholder="Enter name or nickname" formControlName="studentName" (blur)="onBlur(i)" />
              <mat-hint *ngIf="getStudentNameControl(i).invalid">
                <mat-error>{{ 'CREATE_STUDENT.EMPTY_ERROR' | translate }}</mat-error>
              </mat-hint>
            </mat-form-field>
            <mat-form-field class="name">
              <mat-label>{{ 'PROFILE.STUDENT_CODE' | translate }} </mat-label>
              <input matInput placeholder="Enter student code" formControlName="studentCode" />
              <mat-hint *ngIf="getStudentCodeControl(i).invalid">
                <mat-error>{{ 'CREATE_STUDENT.EMPTY_ERROR' | translate }}</mat-error>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <mat-hint *ngIf="students.invalid">
          <mat-error>{{ 'CREATE_STUDENT.UNIQUE_ERROR' | translate }}</mat-error>
        </mat-hint>
      </form>
      <mat-error class="error" *ngIf="classroom.availableStudents < students.length">{{ 'STUDENT.ERROR_MAX_EXCEEDED' | translate }}</mat-error>
      <div class="save">
        <button mat-raised-button color="accent" (click)="save()">
          {{ 'STUDENT.ADD_STUDENTS' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="availableStudents !== 0 && addRow()" [disabled]="availableStudents === 0">
          {{ 'STUDENT.ADD_ROW' | translate }}
        </button>
      </div>
    </div>
  </article>
</section>
