import { IThumbnail } from './Avatar.model'

export interface ISubuser {
  id: number
  avatar?: IThumbnail
  userId?: number
  courseId?: number
  currentUnitId?: number
  thumbnail?: IThumbnail
  lessonMode?: boolean
  name: string
  age: string
  progress?: number
  dinosaurs?: any
  achievements?: any
  stars?: any
  dinosaursProgress?: any
  subuserStars?: Array<any>
  courses?: Array<ISubuserCourses>
  createdAt?: string
  options?: Array<ISubuserCourses>
  studentId?: number
}

export interface ISubuserCourses {
  id: number
  langId: number
  learningLangId: number
  levelId: number
  name: string
  slug: string
}

export interface ISubuserRequest {
  courseId?: number
  thumbnail?: IThumbnail
  nickname: string
  ageRange: string
}

export interface ISubuserCourseRequest {
  courseId: number
  subuserId: number
}

export class SubuserAddCourseRequest {
  courseId: number

  subuserId: number
}

export class SubuserRemoveCourseRequest {
  courseId: number

  subuserId: number
}

export class ISubuserOptions {
  subuserId: number

  lastOpenedLessonId: number

  currentUnitId?: number
}
