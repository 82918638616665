<div class="dino-image">
  <img class="custom-dino-pos" src="/assets/images/pictures/LanguageVector.svg" alt="dino image" />
  <img class="custom-right-pos" src="/assets/images/pictures/rightArmDino.svg" alt="dino image" />
  <img class="custom-left-pos" src="/assets/images/pictures/leftArmDino.svg" alt="dino image" />
</div>

<div class="courseMenu">
  <div class="actions">
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
    <div class="title">{{ formatLangName(course$ | async) }}</div>
    <div class="total">{{ (subuser$ | async)?.courses.length }} {{ 'PLACEHOLDERS.LANG' | translate }}</div>
  </div>

  <div class="vertical-container">
    <div class="row-language" appSounds *ngFor="let course of (subuser$ | async)?.courses">
      <div class="checkbox" (click)="selectCourse(course)">
        <div class="lang-desc">
          <div class="img-dimension"><img class="circle" src="/assets/images/languages/{{ course.slug }}.png" /></div>

          <p>
            <span class="language-font">{{ formatLangName(course) | uppercase }}</span> <br />
            <span>{{ course.progress }} %</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer-row">
  <button (click)="openParentDashboard()">
    <span>{{ 'PARENT_DASHBOARD.BTN' | translate }}</span>
  </button>
</div>
