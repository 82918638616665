import { EStatisticsActions, StatisticsActions } from '../actions/statistics.actions'
import { InitialStatisticsState, IStatisticsState } from '../state/statistics.state'

export const statisticsReducer = (state = InitialStatisticsState, action: StatisticsActions): IStatisticsState => {
  switch (action.type) {
    case EStatisticsActions.GetStatisticsSuccess: {
      return {
        ...state,
        dinosaursCount: action.payload.dinosaurs,
        starsCount: action.payload.stars || 0,
        achievementsCount: action.payload.achievements,
        progress: action.payload.progress || 0,
      }
    }

    case EStatisticsActions.GetDinosaursSuccess: {
      return {
        ...state,
        dinosaurs: action.payload,
      }
    }

    case EStatisticsActions.GetOpenDinosaursSuccess: {
      return {
        ...state,
        openDinosaurs: action.payload,
      }
    }

    case EStatisticsActions.GetAchievementsSuccess: {
      return {
        ...state,
        achievements: action.payload,
      }
    }

    case EStatisticsActions.GetStarsSuccess: {
      return {
        ...state,
        stars: action.payload,
      }
    }

    case EStatisticsActions.GetProgressSuccess: {
      return {
        ...state,
        progress: action.payload,
      }
    }

    case EStatisticsActions.GetTestReportSuccess: {
      return {
        ...state,
        testReport: action.payload,
      }
    }

    default:
      return state
  }
}
