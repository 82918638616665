import { Action } from '@ngrx/store'
import { ITeacher, ITeacherRequest } from '../../models/Teacher.model'

export enum ETeachersActions {
  AddTeacher = '[Teachers] Add Teacher',
  AddTeacherSuccess = '[Teachers] Add Teacher Success',
  AddTeacherFailure = '[Teachers] Add Teacher Failure',
  GetTeachers = '[Teachers] Get Teachers',
  GetTeachersSuccess = '[Teachers] Get TeachersSuccess',
  GetTeachersFailure = '[Teachers] Get TeachersFailure',
  UpdateTeacher = '[Teachers] Update Teacher',
  UpdateTeacherSuccess = '[Teachers] Update Teacher Success',
  UpdateTeacherFailure = '[Teachers] Update Teacher Failure',
  RemoveTeacher = '[Teachers] Remove Teacher',
  RemoveTeacherSuccess = '[Teachers] Remove Teacher Success',
  RemoveTeacherFailure = '[Teachers] Remove Teacher Failure',
}

export class AddTeacher implements Action {
  public readonly type = ETeachersActions.AddTeacher
  constructor(public payload: ITeacherRequest) {}
}

export class AddTeacherSuccess implements Action {
  public readonly type = ETeachersActions.AddTeacherSuccess
  constructor(public payload: any) {}
}

export class AddTeacherFailure implements Action {
  public readonly type = ETeachersActions.AddTeacherFailure
  constructor(public payload: any) {}
}

export class GetTeachers implements Action {
  public readonly type = ETeachersActions.GetTeachers
  constructor(public schoolId: number) {}
}

export class GetTeachersSuccess implements Action {
  public readonly type = ETeachersActions.GetTeachersSuccess
  constructor(public payload: Array<ITeacher>) {}
}

export class GetTeachersFailure implements Action {
  public readonly type = ETeachersActions.GetTeachersFailure
  constructor(public payload: any) {}
}

export class UpdateTeacher implements Action {
  public readonly type = ETeachersActions.UpdateTeacher
  constructor(public teacher: ITeacher, public payload: ITeacherRequest) {}
}

export class UpdateTeacherSuccess implements Action {
  public readonly type = ETeachersActions.UpdateTeacherSuccess
  constructor(public payload: any) {}
}

export class UpdateTeacherFailure implements Action {
  public readonly type = ETeachersActions.UpdateTeacherFailure

  constructor(public payload: any) {}
}

export class RemoveTeacher implements Action {
  public readonly type = ETeachersActions.RemoveTeacher
  constructor(public schoolId: number, public teacherId: number) {}
}

export class RemoveTeacherSuccess implements Action {
  public readonly type = ETeachersActions.RemoveTeacherSuccess
  constructor(public payload: any) {}
}

export class RemoveTeacherFailure implements Action {
  public readonly type = ETeachersActions.RemoveTeacherFailure

  constructor(public payload: any) {}
}

export type TeachersActions =
  | AddTeacher
  | AddTeacherSuccess
  | AddTeacherFailure
  | GetTeachers
  | GetTeachersSuccess
  | GetTeachersFailure
  | UpdateTeacher
  | UpdateTeacherSuccess
  | UpdateTeacherFailure
  | RemoveTeacher
  | RemoveTeacherSuccess
  | RemoveTeacherFailure
