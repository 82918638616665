<section [ngClass]="{ test: test }" class="game arrangeText">
  <div class="game-help-text">
    <button mat-icon-button color="primary" (click)="audiohelpPlay()">
      <audio #audiohelp>
        <source src="/assets/images/sounds/instructions/{{ currentlang }}/AT.mp3" type="audio/mp3" />
      </audio>
      <mat-icon color="primary"> volume_down </mat-icon>
    </button>
    {{ 'GAME.ARRANGE_TEXT.HELP_TEXT' | translate }}
  </div>
  <ng-container *ngIf="game">
    <img class="trees left" src="/assets/images/games/text-to-text/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/text-to-text/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/text-to-text/gameBackground3.svg" alt=" " />
  </ng-container>
  <ng-container *ngIf="test">
    <img class="trees left" src="/assets/images/tests/testBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/tests/testBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/tests/testBackground3.svg" alt=" " />
  </ng-container>
  <article class="contentWrapper">
    <div class="answers">
      <div *ngFor="let textOption of textOptions" class="answer">
        <button class="answerSelect" [ngClass]="{ incorrect: checked && textOption.placedOrder !== textOption.order, correct: checked && textOption.placedOrder === textOption.order }" (click)="placeAnswer(textOption)">
          <span class="answerSelectText">{{ textOption.placedOrder || '?' }}</span>
        </button>
        <img class="audio" (click)="playAudio(textOption.audioSrc)" src="/assets/images/games/picture-to-audio/audio.svg" alt="play-back-sound" />
        <span class="answerText" [dir]="dir">{{ textOption.text }}</span>
      </div>
    </div>
  </article>
  <div *ngIf="checked && checkRightAnswer" class="anim stars"><img src="/assets/images/anim/check_stars.png" alt="check" /></div>
  <div class="continue" [ngClass]="{ 'continue-visible': !checked && allAnswersPlaced }" *ngIf="!checked" (click)="checkAnswer()" mat-button>
    <img src="/assets/images/games/check_cta.svg" alt="check icon" />
    <span>{{ 'BUTTONS.CHECK' | translate }}</span>
  </div>
  <div class="continue" [ngClass]="{ 'continue-visible': checked }" *ngIf="checked" (click)="setAnswer()" mat-button>
    <img src="/assets/images/games/continue_cta.svg" alt="continue icon" />
    <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
  </div>
</section>
