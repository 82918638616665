import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AllTimeLogsComponent } from './all-time-logs.component'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { NgChartsModule } from 'ng2-charts'
import { MatStepperModule } from '@angular/material/stepper'
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { FormsModule } from '@angular/forms'

@NgModule({
  declarations: [AllTimeLogsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    NgChartsModule,
    MatStepperModule,
    MatTabsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    TranslateModule,
  ],
})
export class AllTimeLogsModule {}
