import * as Sentry from "@sentry/angular"

export const playAudio = (source: string) => {
  let audio = new Audio(source)
  audio.load()
  audio.play().catch((error) => {
    Sentry.captureException(error, {
      level: "error",
      extra: {
        location: 'AudioUtil',
        source
      }
    })
  })
  return audio
}
