<section class="test-results">
  <article class="contentWrapper" *ngIf="loaded; else elseBlock">
    <div class="form-fields-container half-width" *ngIf="type === 'school' && assignmentOptions?.length">
      <div class="form-field-container">
        <label for="selectedAssignment">{{ 'PROGRESS_REPORTS.CHOOSE_ASSIGNMENT' | translate }}</label>
        <mat-select [(value)]="selectedAssignment" placeholder="{{ 'PROGRESS_REPORTS.CHOOSE_ASSIGNMENT' | translate }}">
          <mat-option *ngFor="let assignment of assignmentOptions" (click)="selectAssignment(assignment)" [value]="assignment">{{ assignment.lesson.name }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="column-labels">
      <div class="column-label column-label--latest-test">{{ 'PROGRESS_REPORTS.LABELS.LATEST_TEST' | translate }}</div>
      <div class="column-label column-label--completion">{{ 'PROGRESS_REPORTS.LABELS.COMPLETION' | translate }}</div>
    </div>
    <div class="level">
      <div class="unit">
        <div class="students" *ngFor="let report of testReports; index as i">
          <div class="student">
            <div class="student-avatar">
              <ng-lottie class="avatar" [options]="{ path: report.subuser.avatar.src }"> </ng-lottie>
            </div>
            <div class="student-name">{{ report.subuser.name }}</div>
          </div>

          <div class="tests">
            <mat-accordion *ngIf="report.latestTestReport?.unit; else elseBlock">
              <mat-expansion-panel hideToggle [expanded]="false">
                <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'48px'">
                  <mat-panel-title>
                    <div class="test-row">
                      <div class="left">
                        <h4>{{ report.latestTestReport?.unit.lessons[0].name }}</h4>
                      </div>
                      <div class="center">
                        <div>
                          <span class="result-title exist">{{ 'LEARNING_REPORT.RESULTS' | translate }}</span>
                          <mat-icon color="489600">arrow_drop_down</mat-icon>
                        </div>
                        <span *ngIf="!isMobileScreen"> ({{ report | bestScore : report.latestTestReport?.unit.lessons[0].id }}% {{ 'LEARNING_REPORT.RESULTS_TEXT' | translate }}) </span>
                      </div>
                      <div class="right">
                        <div (click)="openAllTestReportsPopup(report); $event.stopPropagation()">
                          <mat-icon color="primary">remove_red_eye</mat-icon>
                          <span>All test results</span>
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="test-results" *ngIf="report.latestTestReport">
                  <div class="result-row" *ngFor="let result of report | latestResults">
                    <span class="date">{{ result.createdAt | date : 'MMM dd, yyyy' }}</span>
                    <span
                      class="success"
                      [ngClass]="{
                        bronze: result.progress < 70,
                        silver: result.progress >= 70 && result.progress < 90,
                        gold: result.progress >= 90
                      }"
                      >{{ result.progress }}%
                      <span *ngIf="!isMobileScreen">
                        ({{ result.correctAnswers }}
                        {{ 'LEARNING_REPORT._FROM' | translate }}
                        {{ result.allAnswers }})
                      </span>
                    </span>

                    <div class="goto-details" (click)="openTestResultsPopup(result, report.latestTestReport?.unit.lessons[0].name, report.subuser.name)">
                      <span>{{ 'LEARNING_REPORT.DETAILS' | translate }} </span>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <ng-template #elseBlock>
              <div class="test-row">
                <div class="center">
                  <div>
                    <span class="result-title ftsize">{{ 'LEARNING_REPORT.NO_RESULTS' | translate }} </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </article>
  <ng-template #elseBlock>
    <div class="loader-back">
      <div class="loaderBlock">
        <div class="{{ loaderClass }}"></div>
      </div>
    </div>
  </ng-template>
</section>
