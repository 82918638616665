<div class="infoPopup">
  <div class="actions">
    <span class="title">{{ 'UNVALIABLE.TITLE' | translate }}</span>

    <span appSounds class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></span>
  </div>

  <div class="dinosaur">
    <span [innerHTML]="'UNVALIABLE.SUB_TITLE' | translate"></span>
  </div>

  <div class="goIt">
    <button appSounds mat-raised-button (click)="close()" color="accent">{{ 'UNVALIABLE.BUTTON' | translate }}</button>
  </div>
</div>
