import { IPlan } from 'src/app/models/Payment.model'

export interface IPaymentState {
  plan: IPlan
  customer: any
  plans: any
  paymentMethod: any
  // invoicesPdf: any;
}

export const InitialPaymentState: IPaymentState = {
  plan: null,
  customer: null,
  plans: null,
  paymentMethod: null,
  // invoicesPdf: null,
}
