import { Action } from '@ngrx/store'

export enum EHeaderActions {
  SetHeaderValue = '[Header] Set Header Value',
  SetDropdownOpen = '[Header] Set Dropdown Open',
}

export class SetHeaderValue implements Action {
  public readonly type = EHeaderActions.SetHeaderValue
  constructor(public payload: any) {}
}

export class SetDropdownOpen implements Action {
  public readonly type = EHeaderActions.SetDropdownOpen
  constructor(public payload: any) {}
}

export type HeaderActions = SetHeaderValue | SetDropdownOpen
