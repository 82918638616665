import { Injectable } from '@angular/core'
import * as Sentry from "@sentry/angular"

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private audioObjects: { [key: string]: HTMLAudioElement } = {}

  constructor() {}

  playAudio(key: string, url: string, stopOther: boolean = true, filterAWS = true, stopOtherExceptKey?: string, onEndedCallback?: () => void): number {
    if (filterAWS) {
      key = this.filterAWS(key)
    }

    if (stopOther) {
      this.stopAllAudio(stopOtherExceptKey)
    }

    if (this.audioObjects[key]) {
      // Remove any existing event listener to avoid duplicate callbacks.
      this.audioObjects[key].removeEventListener('ended', onEndedCallback)

      if (this.isPlaying(this.audioObjects[key])) {
        this.audioObjects[key].pause()
        this.audioObjects[key].currentTime = 0
      }
    } else {
      this.audioObjects[key] = new Audio(url)
      const context = new ((window as any).AudioContext || (window as any).webkitAudioContext)()
      this.audioObjects[key].load()
    }

    if (onEndedCallback) {
      this.audioObjects[key].addEventListener('ended', onEndedCallback)
    }

    this.audioObjects[key].play().catch((error) => {
      Sentry.captureException(error, {
        level: "error",
        extra: {
          location: 'AudioService',
          key,
          url
        }
      })
    })
    return this.audioObjects[key].duration
  }

  preloadAudio(key: string, url: string): void {
    key = this.filterAWS(key)

    if (!this.audioObjects[key]) {
      this.audioObjects[key] = new Audio(url)
      const context = new ((window as any).AudioContext || (window as any).webkitAudioContext)()
      this.audioObjects[key].load()
    }
  }

  preloadAudioList(list: [{ key: string; url: string }]): void {
    list.forEach((item) => {
      this.preloadAudio(item.key, item.url)
    })
  }

  filterAWS(key: string): string {
    if (key) {
      let arr = key.match(/audio\/([^\?]+)\?/gi)
      if (Array.isArray(arr) && arr.length > 0) {
        key = arr[0]
      }
    }

    return key
  }

  pauseAudio(key: string, filterAWS = true): void {
    if (filterAWS) {
      key = this.filterAWS(key)
    }
    if (this.audioObjects[key]) {
      if (this.isPlaying(this.audioObjects[key])) {
        this.audioObjects[key].pause()
      }
    }
  }

  stopAudio(key: string, filterAWS = true): void {
    if (filterAWS) {
      key = this.filterAWS(key)
    }
    if (this.audioObjects[key]) {
      if (this.isPlaying(this.audioObjects[key])) {
        this.audioObjects[key].pause()
        this.audioObjects[key].currentTime = 0
      }
    }
  }

  stopAudioAndDelete(key: string, filterAWS = true): void {
    if (filterAWS) {
      key = this.filterAWS(key)
    }
    if (this.audioObjects[key]) {
      if (this.isPlaying(this.audioObjects[key])) {
        this.audioObjects[key].pause()
        this.audioObjects[key].currentTime = 0
      }
      delete this.audioObjects[key]
    }
  }

  stopAllAudio(exceptKey?: string): void {
    Object.keys(this.audioObjects).forEach((key) => {
      if (key !== exceptKey) {
        if (this.isPlaying(this.audioObjects[key])) {
          this.audioObjects[key].pause()
          this.audioObjects[key].currentTime = 0
        }
      }
    })
  }

  deleteAllAudio(): void {
    Object.values(this.audioObjects).forEach((audio) => {
      if (this.isPlaying(audio)) {
        audio.pause()
        audio.currentTime = 0
      }
    })
    this.audioObjects = {}
  }

  isPlaying(audio) {
    return audio.currentTime > 0 && !audio.paused && !audio.ended && audio.readyState > audio.HAVE_CURRENT_DATA
  }

  playSoundCorrect() {
    this.playAudio('correct', '/assets/images/sounds/correct.mp3', false, false)
  }

  playSoundIncorrect() {
    this.playAudio('incorrect', '/assets/images/sounds/wrong.mp3', false, false)
  }

  playSoundMatch() {
    this.playAudio('match', '/assets/images/sounds/correctPair.mp3', false, false)
  }

  playSoundClick() {
    this.playAudio('click', '/assets/images/sounds/click4.mp3', false, false)
  }

  playSoundTurnpage() {
    this.playAudio('turnpage', '/assets/images/sounds/clicks-page-turns/turn_page.mp3', false, false)
  }
}
