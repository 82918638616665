import { IStudent } from '../../models/Student.model'
import { EStudentActions, StudentActions } from '../actions/students.actions'
import { InitialStudentState, IStudentState } from '../state/students.state'

export const studentsReducers = (state = InitialStudentState, action: StudentActions): IStudentState => {
  switch (action.type) {
    case EStudentActions.GetStudentsForClassroomSuccess: {
      let studentsToAdd = action.payload.students

      if (state.students.length > 0) {
        studentsToAdd = studentsToAdd.filter((i) => {
          return state.students.findIndex((x) => x.id === i.id) === -1
        })
      }

      return {
        ...state,
        students: state.students.concat(studentsToAdd),
      }
    }

    case EStudentActions.GetStudentsSuccess: {
      return {
        ...state,
        students: action.students,
      }
    }

    case EStudentActions.AddStudentSuccess: {
      const newStudent: IStudent = {
        ...action.payload.student,
        subuser: action.payload.subuser,
        classroom: action.payload.classroom,
      }
      return {
        ...state,
        students: state.students.concat([newStudent]),
      }
    }

    case EStudentActions.RemoveStudentSuccess: {
      return {
        ...state,
        students: state.students.filter((s) => s.id !== action.payload.deletedStudent.id),
      }
    }

    case EStudentActions.UpdateStudentSuccess: {
      const newState = state.students.map((i) => {
        if (i.id === action.payload.updatedStudent.id) {
          return action.payload.updatedStudent
        }

        return i
      })
      return {
        ...state,
        students: newState,
      }
    }

    case EStudentActions.GetCurrentStudentSuccess: {
      return {
        ...state,
        currentStudent: action.student,
      }
    }

    default:
      return state
  }
}
