import { Component, OnInit, Inject } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { TranslateService } from '@ngx-translate/core'
import { TestReportService } from 'src/app/services/testReport.service'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { downloadPdfLink, isIpad, openPdfLink } from 'src/app/helpers/utils/LessonUtil/lessonTest.util'
import { ConfirmationPopupComponent } from '../../Control-Dialogs/confirmation/confirmation.component'

@Component({
  templateUrl: 'test-result.component.html',
  styleUrls: ['test-result.component.scss'],
})
export class TestResultComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<TestResultComponent>, public _dialog: MatDialog, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data, public _logService: TestReportService) { }

  private request

  ngOnInit() {
    this.request = {
      id: this.data.reportData.id,
      allAnswers: this.data.reportData.allAnswers,
      correctAnswers: this.data.reportData.correctAnswers,
      name: this.data.lessonName,
      nickname: this.data.nickname,
    }
  }

  downlodResultPdf() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.DOWNLOAD_PDF',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      this._logService.getTestResultPdf(this.request).subscribe((res) => {
        downloadPdfLink(res.toString(), `${this.request.name}.pdf`, false, isIpad())
      })
    })
  }

  showPdf() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.PRINT_PDF',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      this._logService.getTestResultPdf(this.request).subscribe((res) => {
        openPdfLink(res)
      })
    })
  }

  arrangeTextAnswers(record: any) {
    const answers = []
    for (let i = 0; i < record.question.answers.length; i++) {
      answers.push({
        ...record.question.answers[i],
        subuserAnswer: record.subuserAnswer.find((subuserAnswer) => {
          return subuserAnswer.id === record.question.answers[i].id
        }),
      })
    }

    // sort answers by 'order'
    return answers.sort((a, b) => (a.order > b.order ? 1 : -1))
  }
}
