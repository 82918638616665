import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { FooterComponent } from './footer.component'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, AngularSvgIconModule, TranslateModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
