import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TestResultsComponent } from './test-results.component'
import { ProgressReportsPipeModule } from '../progress-reports-pipe/progress-reports-pipe.module'
import { TranslateModule } from '@ngx-translate/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { TestResultModule } from '../../../../shared/popups/Lesson-Dialogs/test-result/test-result.module'
import { TestPlanModule } from '../../../../shared/popups/Lesson-Dialogs/test-plan/test-plan.module'
import { AllTestReportsModule } from '../../../../shared/popups/ProgressReports-Dialogs/all-test-reports/all-test-reports.module'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  declarations: [TestResultsComponent],
  imports: [CommonModule, ProgressReportsPipeModule, TranslateModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatSelectModule, TestResultModule, TestPlanModule, AllTestReportsModule, LottieModule],
  exports: [TestResultsComponent],
})
export class TestResultsModule {}
