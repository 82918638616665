import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { LottieModule } from 'ngx-lottie'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { DinoViewPopupModule } from '../dino-view/dino-view.module'
import { DinosaursPopupComponent } from './dinosaurs.component'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, MatProgressBarModule, MatSelectModule, MatDialogModule, MatInputModule, MatButtonModule, MatIconModule, ReactiveFormsModule, DinoViewPopupModule, TranslateModule, SoundsModule, LottieModule],
  declarations: [DinosaursPopupComponent],
  providers: [],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DinosaursPopupModule {}
