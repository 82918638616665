import { NgModule } from '@angular/core'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { LandingComponent } from './landing.component'
import { HomeModule } from './home/home.module'
import { LandingRoutingModule } from './landing-routing.module'
import { FooterModule } from './common/footer/footer.module'
import { HeaderModule } from './common/header/header.module'
import { TestimonialsModule } from 'src/app/views/landing/common/testimonials/testimonials.module'
import { GoogleReviewsModule } from 'src/app/views/landing/common/google-reviews/google-reviews.module'
import { ReviewStarsModule } from 'src/app/views/landing/common/review-stars/review-stars.module'
import { AboutUsModule } from './about-us/about-us.module'
import { ContactUsModule } from './contactUs/contactUs.module'
import { CurriculumModule } from './curriculum/curriculum.module'
import { HomeschoolersModule } from './homeschoolers/homeschoolers.module'
import { HowItWorksModule } from './howItWorks/howItWorks.module'
import { LanguageCoursesModule } from './languageCourses/langcourses.module'
import { ParentsGuideModule } from './parentsGuide/parentsGuide.module'
import { ReviewsModule } from './reviews/reviews.module'
import { SchoolsModule } from './schools/schools.module'
import { TermsModule } from './terms/terms.module'
import { Terms2Module } from './terms2/terms2.module'
import { PrivacyModule } from './privacy/privacy.module'
import { Privacy2Module } from './privacy2/privacy2.module'
import { LearnForKidsModule } from './learnForKids/learnForKids.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { TopBannerModule } from '../blocks/topbanner/topbanner.module'
import { NotFoundModule } from '../landing/not-found/not-found.module'
import { BlogModule } from './blog/blog.module'
import { BlogListModule } from './blog/list/blogList.module'

@NgModule({
  declarations: [LandingComponent],
  imports: [
    LandingRoutingModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    SoundsModule,
    // Pages Start
    HomeModule,
    AboutUsModule,
    ContactUsModule,
    CurriculumModule,
    HomeschoolersModule,
    HowItWorksModule,
    LanguageCoursesModule,
    ParentsGuideModule,
    ReviewsModule,
    SchoolsModule,
    TermsModule,
    Terms2Module,
    PrivacyModule,
    Privacy2Module,
    LearnForKidsModule,
    // Pages End1
    FooterModule,
    HeaderModule,
    TestimonialsModule,
    GoogleReviewsModule,
    ReviewStarsModule,
    AngularSvgIconModule,
    TopBannerModule,
    NotFoundModule,
    BlogModule,
    BlogListModule,
  ],
})
export class LandingModule {}
