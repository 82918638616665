import { ESubuserActions, SubuserActions } from '../actions/subuser.actions'
import { InitialSubuserState, ISubuserState } from '../state/subuser.state'

export const subuserReducer = (state = InitialSubuserState, action: SubuserActions): ISubuserState => {
  switch (action.type) {
    case ESubuserActions.SelectSubuser: {
      return {
        ...state,
        currentSubuser: action.payload,
      }
    }

    case ESubuserActions.GetAllSubuserSuccess: {
      return {
        ...state,
        subusers: action.payload,
      }
    }

    case ESubuserActions.GetSubuserReportSuccess: {
      return {
        ...state,
        subuserReport: action.payload,
      }
    }

    case ESubuserActions.GetSubuserSuccess: {
      return {
        ...state,
        subuser: action.payload,
      }
    }

    case ESubuserActions.SetCurrentSubuserDetailedReports: {
      return {
        ...state,
        subuserDetailedReports: action.payload,
      }
    }

    case ESubuserActions.GetAllSubuserCoursesSuccess: {
      return {
        ...state,
        allCourses: action.payload,
      }
    }

    default:
      return state
  }
}
