import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AllTestReportsComponent } from './all-test-reports.component'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { ProgressReportsPipeModule } from '../../../../views/profile/progress-reports/progress-reports-pipe/progress-reports-pipe.module'

@NgModule({
  declarations: [AllTestReportsComponent],
  imports: [CommonModule, ProgressReportsPipeModule, MatButtonModule, MatExpansionModule, MatCardModule, MatIconModule, TranslateModule],
})
export class AllTestReportsModule {}
