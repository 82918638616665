import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { StudentAssignmentsDialogComponent } from './student-assignments.component'

@NgModule({
  declarations: [StudentAssignmentsDialogComponent],
  imports: [CommonModule, RouterModule, MatButtonModule, TranslateModule],
})
export class StudentAssignmentsDialogModule {}
