import { Pipe, PipeTransform } from '@angular/core'
import { IQuestion } from '../../../models/Question.model'
import { IAnswer } from '../../../models/Answer.model'

@Pipe({ name: 'localization' })
export class LocalizationPipe implements PipeTransform {
  transform(source: IQuestion | IAnswer, translation: string, localizations: Array<any> = []): any {
    if (translation) {
      const localizedQuestion = localizations.filter((localizationContent) => localizationContent.localizationId === source.localizationId).find((localizationContent) => localizationContent.locale === translation)
      if (localizedQuestion) {
        return localizedQuestion
      }
    }
    return source
  }
}
