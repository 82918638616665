<mat-form-field class="language-translation" appearance="outline" color="primary">
  <mat-label>Translation</mat-label>
  <mat-select (selectionChange)="setTranslation.emit($event)" [(value)]="selectedTranslation">
    <mat-option value="">None</mat-option>
    <mat-option *ngFor="let language of availableTranslations" value="{{ language.abbr }}">
      {{ language.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="language-translation__mobile">
  <div class="selected-language" (click)="openSelectDialog()">
    <img src="/assets/images/languages/short/{{ selectedTranslation }}.png" alt="English" />
  </div>
</div>
