import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'bestScore' })
export class BestScorePipe implements PipeTransform {
  transform(report: any, lessonId) {
    const reports = report.testReports.filter((r) => r.lessonId === lessonId)

    if (!reports.length) return

    if (reports.length === 1) {
      return reports[0].progress
    }

    const best = reports.reduce((prev, curr) => {
      return prev.progress > curr.progress ? prev : curr
    })

    return best.progress
  }
}

@Pipe({ name: 'latestResults' })
export class LatestResultsPipe implements PipeTransform {
  transform(report: any) {
    return report.testReports.filter((r) => {
      return r.lessonId === report.latestTestReport.lessonId
    })
  }
}
