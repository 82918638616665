<section class="footer">
  <img class="border top" src="assets/images/pictures/borderGray.png" alt="" />
  <article class="contentWrapper">
    <div class="navigation">
      <div class="navItem">
        <svg-icon src="assets/images/icons/method.svg"></svg-icon>
        <!-- ??? вопрос с приставкой /en в урлах страницы -->
        <a routerLink="{{ getURLLangPart() }}/how-it-works" class="navLink">{{ 'HOW_IT_WORK.TITLE' | translate }} </a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/reviews.svg"></svg-icon>
        <a routerLink="{{ getURLLangPart() }}/reviews" class="navLink">{{ 'REVIEWS.TITLE' | translate }}</a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/cources.svg"></svg-icon>
        <a routerLink="{{ getURLLangPart() }}/language-courses" class="navLink">{{ 'LANG_COURSES.TITLE_FOOTER' | translate }} </a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/curriculm.svg"></svg-icon>
        <a routerLink="{{ getURLLangPart() }}/curriculum" class="navLink">{{ 'CURRICULUM.TITLE' | translate }}</a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/p_guide.svg"></svg-icon>
        <a routerLink="{{ getURLLangPart() }}/parents" class="navLink">{{ 'PARENTS.TITLE_FOOTER' | translate }} </a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/schools.svg"></svg-icon>
        <a routerLink="{{ getURLLangPart() }}/schools-teachers" class="navLink">{{ 'SCHOOLS.TITLE_FOOTER' | translate }}</a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/homeschoolers.svg"></svg-icon>
        <a routerLink="{{ getURLLangPart() }}/homeschool" class="navLink">{{ 'HOMESCHOOLERS.TITLE' | translate }}</a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/privacy.svg"></svg-icon>
        <a href="https://help.dinolingo.com/article/493-privacy" target="_blank" class="navLink">{{ 'POLICY.TITLE_FOOTER' | translate }}</a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/terms.svg"></svg-icon>
        <a href="https://help.dinolingo.com/article/494-terms" target="_blank" class="navLink">{{ 'TERMS.TITLE_FOOTER' | translate }}</a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/contacts.svg"></svg-icon>
        <a target="_blank" href="https://help.dinolingo.com/" class="navLink">{{ 'FOOTER.CONTACT_US' | translate }} </a>
      </div>

      <div class="navItem">
        <svg-icon src="assets/images/icons/about2.svg"></svg-icon>
        <a routerLink="{{ getURLLangPart() }}/about-us" class="navLink">{{ 'FOOTER.ABOUT' | translate }} </a>
      </div>
      <div class="navItem">
        <svg-icon src="assets/images/icons/helpIcon.svg"></svg-icon>
        <a target="_blank" href="https://help.dinolingo.com/" class="navLink">{{ 'FOOTER.HELP' | translate }} </a>
      </div>
    </div>

    <div class="navigation">
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-spanish-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Spanish' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-french-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.French' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-english-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.English' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-italian-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Italian' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-german-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.German' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-japanese-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Japanese' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-euportuguese-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Portuguese_eu' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-portuguese-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Portuguese' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-russian-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Russian' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-chinese-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Chinese' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-greek-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Greek' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-swedish-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Swedish' | translate) }}</a>
      </div>
    </div>

    <div class="navigation">
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-dutch-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Dutch' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-polish-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Polish' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-arabic-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Arabic' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-hebrew-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Hebrew' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-ukrainian-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Ukrainian' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-latin-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Latin' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-korean-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Korean' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-hindi-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Hindi' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-persian-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Persian' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-croatian-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Croatian' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/learn-turkish-for-kids" class="navLink">{{ checkReplacement('FOOTER.FOR_KIDS' | translate, 'LANGUAGE_NAME.Turkish' | translate) }}</a>
      </div>
      <div class="navItem">
        <a routerLink="{{ getURLLangPart() }}/language-courses" class="navLink">{{ 'FOOTER.LANG_50' | translate }}</a>
      </div>
    </div>

    <div class="appsBlock">
      <div class="ourApps">
        <div class="app">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dinolingo.dinolearning">
            <img src="assets/images/store/gp.png" />
          </a>
          <a target="_blank" href="https://apps.apple.com/us/app/dinolingo-languages-for-kids/id1537076111">
            <img src="assets/images/store/ios.png" />
          </a>
        </div>
        <div class="gift">
          <a target="_blank" href="https://buy.stripe.com/00gfZB64o7CrfQY8xG">
            <img src="assets/images/dinosaurs/dinoGift.svg" />
            <div class="gift-link">
              {{ 'HOME.BUTTONS.BUY_GIFT' | translate }}
            </div>
          </a>
        </div>
      </div>
      <div class="social">
        <div class="socialBtn">
          <div class="faceBook">
            <a href="https://www.instagram.com/dinolingo" target="_blank" class="share">
              <div><img src="assets/images/social/dinostagrammer.png" /></div>
            </a>
          </div>
          <div class="faceBook">
            <a href="https://www.youtube.com/c/Dinolingo1" target="_blank" class="share">
              <div><img src="assets/images/social/dinotuber.png" /></div>
            </a>
          </div>
          <div class="faceBook">
            <a href="https://www.pinterest.com/dinolingo" target="_blank" class="share">
              <div><img src="assets/images/social/dinopinner.png" /></div>
            </a>
          </div>

          <div class="faceBook">
            <a href="https://blog.dinolingo.com/" target="_blank" class="share">
              <div><img src="assets/images/social/dinoreviever.png" /></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </article>
  <div class="loc-langs">
    <p>{{ 'LINKS.LANG_SITE' | translate }}</p>
    <div>
      <div *ngFor="let lang of translate.getLangs()">
        <span (click)="selectLocalization(lang)">{{ formatLangName(lang) }}</span>
      </div>
    </div>
  </div>
  <p class="copyright" [innerHTML]="'FOOTER.RIGHTS' | translate"></p>
</section>
