<div class="userInformation">
  <img *ngIf="!isLottie((currentSubuser$ | async)?.avatar.src)" src="{{ (currentSubuser$ | async)?.avatar.src }}" alt="user avatar" />
  <div class="container-actions-currentUser">
    <div class="actions">
      <div class="close" (click)="_dialog.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
      <div class="title">Who is learning?</div>
    </div>
    <div class="currentUser">
      <div class="subuser">
        <div appSounds (click)="selectSubuser(subuser)" class="parents" *ngFor="let subuser of user.subusers" tabindex="1">
          <img class="dino-img" *ngIf="subuser.avatar && !isLottie(subuser.avatar.src)" [src]="subuser.avatar.src" [alt]="subuser.avatar.name" />
          <ng-lottie *ngIf="subuser.avatar && isLottie(subuser.avatar.src)" class="dino-img" [options]="{ path: subuser.avatar.src }"> </ng-lottie>
          <span class="parent-text">{{ subuser.name }}</span>
        </div>

        <ng-container *ngIf="isMobile">
          <div
            [ngClass]="{
              'userAdd-max-width': user.subusers.length === 2 || user.subusers.length === 4,
              parents: user.subusers.length === 1 || user.subusers.length === 3
            }"
            *ngIf="(subuser$ | async).length < 4"
          >
            <div appSounds class="dino-img" (click)="openCheckParentPinDialog()">
              <span class="userAdd"><img src="/assets/images/icons/addSubuserPlus.svg" alt="" /></span>
            </div>
            <span class="parent-text">{{ 'CHILD.ADD_USER' | translate }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!isMobile">
          <div class="parents" *ngIf="(subuser$ | async).length < 4">
            <div appSounds class="dino-img" (click)="openCheckParentPinDialog()">
              <span class="userAdd"><img src="/assets/images/icons/addSubuserPlus.svg" alt="" /></span>
            </div>
            <span class="parent-text">{{ 'CHILD.ADD_USER' | translate }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="manageUserBtn">
      <div appSounds class="btn-text" (click)="openCheckParentPinDialog()" routerLinkActive="active">
        Manage Users <span><img src="/assets/images/icons/arrow-right-solid 1.svg" alt="" /></span>
      </div>
    </div>
    <div class="bottom-line" *ngIf="isMobile"></div>
  </div>
</div>
