import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LocalizationComponent } from './localization.component'

@NgModule({
  declarations: [LocalizationComponent],
  imports: [CommonModule],
  exports: [LocalizationComponent],
})
export class LocalizationModule {}
