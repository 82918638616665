import { Action } from '@ngrx/store'

export enum EProgressReportsActions {
  GetSubuserTestReports = '[ProgressReports] Get Students Test Reports',
  GetSubuserTestReportsSuccess = '[ProgressReports] Get Students Test Reports Success',
  GetSubuserTestReportsFailure = '[ProgressReports] Get Students Test Reports Failure',
  GetSubuserTestReport = '[ProgressReports] Get Student Test Reports',
  GetSubuserTestReportSuccess = '[ProgressReports] Get Student Test Reports Success',
  GetSubuserTestReportFailure = '[ProgressReports] Get Student Test Reports Failure',
  GetSubuserTimeLogs = '[ProgressReports] Get Students Time Logs',
  GetSubuserTimeLogsSuccess = '[ProgressReports] Get Students Time Logs Success',
  GetSubuserTimeLogsFailure = '[ProgressReports] Get Students Time Logs Failure',
  GetSubuserTimeLog = '[ProgressReports] Get Student Time Log',
  GetSubuserTimeLogSuccess = '[ProgressReports] Get Student Time Log Success',
  GetSubuserTimeLogFailure = '[ProgressReports] Get Student Time Log Failure',
  GetSubuserDetailedReports = '[ProgressReports] Get Students Detailed Reports',
  GetSubuserDetailedReportsSuccess = '[ProgressReports] Get Students Detailed Reports Success',
  GetSubuserDetailedReportsFailure = '[ProgressReports] Get Students Detailed Reports Failure',

  GetStudentsTestReports = '[Student] Get Students Test Reports',
  GetStudentsTestReportsSuccess = '[Student] Get Students Test Reports Success',
  GetStudentsTestReportsFailure = '[Student] Get Students Test Reports Failure',
  GetStudentTestReports = '[Student] Get Student Test Reports',
  GetStudentTestReportsSuccess = '[Student] Get Student Test Reports Success',
  GetStudentTestReportsFailure = '[Student] Get Student Test Reports Failure',
  GetStudentsTimeLogs = '[Student] Get Students Time Logs',
  GetStudentsTimeLogsSuccess = '[Student] Get Students Time Logs Success',
  GetStudentsTimeLogsFailure = '[Student] Get Students Time Logs Failure',
  GetStudentTimeLog = '[Student] Get Student Time Log',
  GetStudentTimeLogSuccess = '[Student] Get Student Time Log Success',
  GetStudentTimeLogFailure = '[Student] Get Student Time Log Failure',
  GetStudentsDetailedReports = '[Student] Get Students Detailed Reports',
  GetStudentsDetailedReportsSuccess = '[Student] Get Students Detailed Reports Success',
  GetStudentsDetailedReportsFailure = '[Student] Get Students Detailed Reports Failure',

  ResetReports = '[ProgressReports] Reset Reports',
}

export class GetSubuserTestReports implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTestReports
  constructor(public courseId: number) {}
}

export class GetSubuserTestReportsSuccess implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTestReportsSuccess
  constructor(public payload: any) {}
}

export class GetSubuserTestReportsFailure implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTestReportsFailure
  constructor(public payload: any) {}
}

export class GetSubuserTestReport implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTestReport
  constructor(public subuserId: number, public courseId: number) {}
}

export class GetSubuserTestReportSuccess implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTestReportSuccess
  constructor(public payload: any) {}
}

export class GetSubuserTestReportFailure implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTestReportFailure
  constructor(public payload: any) {}
}

export class GetSubuserTimeLogs implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTimeLogs
  constructor() {}
}

export class GetSubuserTimeLogsSuccess implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTimeLogsSuccess
  constructor(public payload: any) {}
}

export class GetSubuserTimeLogsFailure implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTimeLogsFailure
  constructor(public payload: any) {}
}

export class GetSubuserTimeLog implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTimeLog
  constructor(public subuserId: number) {}
}

export class GetSubuserTimeLogSuccess implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTimeLogSuccess
  constructor(public payload: any) {}
}

export class GetSubuserTimeLogFailure implements Action {
  public readonly type = EProgressReportsActions.GetSubuserTimeLogFailure
  constructor(public payload: any) {}
}

export class GetSubuserDetailedReports implements Action {
  public readonly type = EProgressReportsActions.GetSubuserDetailedReports
  constructor(public courseId: number) {}
}

export class GetSubuserDetailedReportsSuccess implements Action {
  public readonly type = EProgressReportsActions.GetSubuserDetailedReportsSuccess
  constructor(public payload: any) {}
}

export class GetSubuserDetailedReportsFailure implements Action {
  public readonly type = EProgressReportsActions.GetSubuserDetailedReportsFailure
  constructor(public payload: any) {}
}

export class GetStudentsTestReports implements Action {
  public readonly type = EProgressReportsActions.GetStudentsTestReports
  constructor(public schoolId: number, public classroomId: number, public courseId: number, public lessonId?: number) {}
}

export class GetStudentsTestReportsSuccess implements Action {
  public readonly type = EProgressReportsActions.GetStudentsTestReportsSuccess
  constructor(public payload: any) {}
}

export class GetStudentsTestReportsFailure implements Action {
  public readonly type = EProgressReportsActions.GetStudentsTestReportsFailure
  constructor(public payload: any) {}
}

export class GetStudentTestReports implements Action {
  public readonly type = EProgressReportsActions.GetStudentTestReports
  constructor(public schoolId: number, public classroomId: number, public studentId: number, public courseId: number) {}
}

export class GetStudentTestReportsSuccess implements Action {
  public readonly type = EProgressReportsActions.GetStudentTestReportsSuccess
  constructor(public payload: any) {}
}

export class GetStudentTestReportsFailure implements Action {
  public readonly type = EProgressReportsActions.GetStudentTestReportsFailure
  constructor(public payload: any) {}
}

export class GetStudentsTimeLogs implements Action {
  public readonly type = EProgressReportsActions.GetStudentsTimeLogs
  constructor(public schoolId: number, public classroomId: number) {}
}

export class GetStudentsTimeLogsSuccess implements Action {
  public readonly type = EProgressReportsActions.GetStudentsTimeLogsSuccess
  constructor(public payload: any) {}
}

export class GetStudentsTimeLogsFailure implements Action {
  public readonly type = EProgressReportsActions.GetStudentsTimeLogsFailure
  constructor(public payload: any) {}
}

export class GetStudentTimeLog implements Action {
  public readonly type = EProgressReportsActions.GetStudentTimeLog
  constructor(public schoolId: number, public classroomId: number, public studentId: number) {}
}

export class GetStudentTimeLogSuccess implements Action {
  public readonly type = EProgressReportsActions.GetStudentTimeLogSuccess
  constructor(public payload: any) {}
}

export class GetStudentTimeLogFailure implements Action {
  public readonly type = EProgressReportsActions.GetStudentTimeLogFailure
  constructor(public payload: any) {}
}

export class GetStudentsDetailedReports implements Action {
  public readonly type = EProgressReportsActions.GetStudentsDetailedReports
  constructor(public schoolId: number, public classroomId: number, public lessonId?: number) {}
}

export class GetStudentsDetailedReportsSuccess implements Action {
  public readonly type = EProgressReportsActions.GetStudentsDetailedReportsSuccess
  constructor(public payload: any) {}
}

export class GetStudentsDetailedReportsFailure implements Action {
  public readonly type = EProgressReportsActions.GetStudentsDetailedReportsFailure
  constructor(public payload: any) {}
}

export class ResetReports implements Action {
  public readonly type = EProgressReportsActions.ResetReports
  constructor() {}
}

export type ProgressReportsActions =
  | GetSubuserTestReports
  | GetSubuserTestReportsSuccess
  | GetSubuserTestReportsFailure
  | GetSubuserTestReport
  | GetSubuserTestReportSuccess
  | GetSubuserTestReportFailure
  | GetSubuserTimeLogs
  | GetSubuserTimeLogsSuccess
  | GetSubuserTimeLogsFailure
  | GetSubuserTimeLog
  | GetSubuserTimeLogSuccess
  | GetSubuserTimeLogFailure
  | GetSubuserDetailedReports
  | GetSubuserDetailedReportsSuccess
  | GetSubuserDetailedReportsFailure
  | GetStudentsTestReports
  | GetStudentsTestReportsSuccess
  | GetStudentsTestReportsFailure
  | GetStudentTestReports
  | GetStudentTestReportsSuccess
  | GetStudentTestReportsFailure
  | GetStudentsTimeLogs
  | GetStudentsTimeLogsSuccess
  | GetStudentsTimeLogsFailure
  | GetStudentTimeLog
  | GetStudentTimeLogSuccess
  | GetStudentTimeLogFailure
  | GetStudentsDetailedReports
  | GetStudentsDetailedReportsSuccess
  | GetStudentsDetailedReportsFailure
  | ResetReports
