<div class="stars">
  <div class="actions">
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
    <div class="title">{{ 'STARS.TITLE' | translate }}: {{ dialogData.totalPoints }}</div>
  </div>

  <div class="verticalContainer scroll-modern">
    <div class="stars-panel">
      <div class="activity-points header-color">
        <p>Activity</p>
        <p class="padding-left">Points</p>
      </div>
      <div class="activity-points activity-row">
        <p class="d-flex">
          <span><img src="/assets/images/icons/file-audio 1.svg" /></span>{{ 'STARS.AUDIOBOOK' | translate }}
        </p>
        <p class="d-flex padding-left">25</p>
      </div>
      <div class="activity-points activity-row">
        <p class="d-flex">
          <span><img src="/assets/images/icons/memo 1.svg" /></span>{{ 'STARS.LESSON' | translate }}
        </p>
        <p class="d-flex padding-left">50</p>
      </div>
      <div class="activity-points activity-row">
        <p class="d-flex">
          <span><img src="/assets/images/icons/music 1.svg" /></span>{{ 'STARS.SONG' | translate }}
        </p>
        <p class="d-flex padding-left">50</p>
      </div>
      <div class="activity-points activity-row">
        <p class="d-flex">
          <span><img src="/assets/images/icons/Vector.svg" /></span>{{ 'STARS.GAME' | translate }}
        </p>
        <p class="d-flex padding-left">75</p>
      </div>
      <div class="activity-points activity-row border-none">
        <p class="d-flex">
          <span><img src="/assets/images/icons/nfc-pen 1.svg" /></span>{{ 'STARS.TEST' | translate }}
        </p>
        <p class="d-flex padding-left">100</p>
      </div>
    </div>
    <div class="image-direction"><img src="/assets/images/icons/Group 2.svg" /></div>
    <p class="points-details"><span>*</span>{{ 'STARS.NOTE' | translate }}</p>
  </div>
</div>
