import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { IAssignmentRequest } from '../models/Assignment.model'

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  constructor(private http: HttpClient) {}

  public getSchool(schoolId: number) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}`)
  }

  public getStudentListPdf(schoolId: number, classroomId: number) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/printStudentList`)
  }

  public addAssignment(schoolId: number, classroomId: number, payload: IAssignmentRequest) {
    return this.http.post(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/assignments`, payload)
  }

  public editAssignment(schoolId: number, classroomId: number, assignmentId: number, payload: IAssignmentRequest) {
    return this.http.put(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/assignments/${assignmentId}`, payload)
  }

  public deleteAssignment(schoolId: number, classroomId: number, assignmentId: number) {
    return this.http.delete(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/assignments/${assignmentId}`, {
      observe: 'response',
    })
  }

  public getAssignments(schoolId: number, classroomId: number) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/assignments`)
  }

  public completeAssignment(schoolId: number, classroomId: number, payload: any) {
    return this.http.post(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/assignmentsCompleted`, payload)
  }

  public adjustStudentGrade(schoolId: number, classroomId: number, studentId: number, payload: any) {
    return this.http.put(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/students/${studentId}/grade`, payload)
  }

  public getStudentsGrades(schoolId: number, classroomId: number) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/grades`)
  }

  public getStudentGrades(schoolId: number, classroomId: number, studentId: number) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/students/${studentId}/grades`)
  }
}
