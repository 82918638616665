import { routerReducer } from '@ngrx/router-store'
import { ActionReducerMap } from '@ngrx/store'
import { IAppState } from '../state/app.state'
import { assignmentsReducers } from './assignments.reducers'
import { authReducer } from './auth.reducer'
import { booksReducer } from './books.reducers'
import { classroomsReducers } from './classrooms.reducers'
import { coursesReducer } from './courses.reducers'
import { headerReducer } from './header.reducers'
import { lessonsReducer } from './lessons.reducers'
import { notificationReducer } from './notification.reducers'
import { paymentReducer } from './payment.reducers'
import { progressReportsReducers } from './progress-reports.reducers'
import { schoolReducers } from './school.reducers'
import { statisticsReducer } from './statistics.reducers'
import { studentsReducers } from './students.reducers'
import { subuserReducer } from './subuser.reducers'
import { teachersReducers } from './teachers.reducers'
import { thumbnailsReducer } from './thumbnails.reducers'
import { userReducer } from './user.reducers'

export const appReducers: ActionReducerMap<IAppState, any> = {
  router: routerReducer,
  user: userReducer,
  auth: authReducer,
  subuser: subuserReducer,
  thumbnails: thumbnailsReducer,
  notification: notificationReducer,
  books: booksReducer,
  courses: coursesReducer,
  payment: paymentReducer,
  lessons: lessonsReducer,
  statistics: statisticsReducer,
  header: headerReducer,
  student: studentsReducers,
  classroom: classroomsReducers,
  progressReports: progressReportsReducers,
  teacher: teachersReducers,
  school: schoolReducers,
  assignments: assignmentsReducers,
}
