import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { BlogListComponent } from './blogList.component'
import { TranslateModule } from '@ngx-translate/core'
import { ReviewStarsModule } from '../../common/review-stars/review-stars.module'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, AngularSvgIconModule, TranslateModule, ReviewStarsModule],
  declarations: [BlogListComponent],
})
export class BlogListModule {}
