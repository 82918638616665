import { ELessonsActions, LessonsActions } from '../actions/lessons.actions'
import { ILessonsState, InitialLessonsState } from '../state/lessons.state'

export const lessonsReducer = (state = InitialLessonsState, action: LessonsActions): ILessonsState => {
  switch (action.type) {
    case ELessonsActions.HideHeaderLesson: {
      return {
        ...state,
        displayHeader: false,
      }
    }

    case ELessonsActions.DisplayHeaderLesson: {
      return {
        ...state,
        displayHeader: true,
      }
    }

    case ELessonsActions.GetLessonsSuccess: {
      return {
        ...state,
        unitsWithLessons: action.payload,
      }
    }

    case ELessonsActions.GetUnitWithLessonsSuccess: {
      return {
        ...state,
        lessons: action.payload,
      }
    }

    case ELessonsActions.GetPrintsSuccess: {
      return {
        ...state,
        prints: action.payload,
      }
    }

    case ELessonsActions.GetLessonSuccess: {
      return {
        ...state,
        currentLesson: action.payload,
      }
    }
    case ELessonsActions.GetLessonVideosSuccess: {
      return {
        ...state,
        currentLessonVideos: action.payload,
      }
    }
    case ELessonsActions.GetLessonGamesSuccess: {
      return {
        ...state,
        currentLessonGames: action.payload,
      }
    }
    case ELessonsActions.GetLessonTestSuccess: {
      return {
        ...state,
        currentLessonTest: action.payload,
      }
    }
    case ELessonsActions.AddAssignmentToLesson: {
      const assignment = action.assignment

      const units = state.unitsWithLessons.units.map((unit) => {
        const lessons = unit.lessons.map((lesson) => {
          if (lesson.id === assignment.lessonId) {
            return { ...lesson, assignment }
          }
          return lesson
        })

        return { ...unit, lessons }
      })

      return {
        ...state,
        unitsWithLessons: { ...state.unitsWithLessons, units },
      }
    }

    case ELessonsActions.RemoveAssignmentFromLesson: {
      const assignmentId = action.assignmentId

      const units = state.unitsWithLessons.units.map((unit) => {
        const lessons = unit.lessons.map((lesson) => {
          if (lesson.assignment?.id === assignmentId) {
            return { ...lesson, assignment: null }
          }
          return lesson
        })

        return { ...unit, lessons }
      })

      return {
        ...state,
        unitsWithLessons: { ...state.unitsWithLessons, units },
      }
    }

    case ELessonsActions.SavePreference: {
      return {
        ...state,
        currentPreference: { page: action.page, selectType: action.selectType },
      }
    }

    default:
      return state
  }
}
