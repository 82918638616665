import { CoursesActions, ECoursesActions } from '../actions/courses.actions'
import { ICourseState, InitialCoursesState } from '../state/courses.state'

export const coursesReducer = (state = InitialCoursesState, action: CoursesActions): ICourseState => {
  switch (action.type) {
    case ECoursesActions.SetCurrentCourse: {
      return {
        ...state,
        selectedCourse: action.payload,
      }
    }

    case ECoursesActions.GetAllCoursesSuccess: {
      return {
        ...state,
        courses: action.payload,
      }
    }

    default:
      return state
  }
}
