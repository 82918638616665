import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { HeaderComponent } from './header.component'
import { TranslateModule } from '@ngx-translate/core'
import { LocalizationModule } from "../../../../shared/components/localization/localization.module";

@NgModule({
    declarations: [HeaderComponent],
    exports: [HeaderComponent],
    imports: [CommonModule, RouterModule, TranslateModule, LocalizationModule]
})
export class HeaderModule {}
