import { Component, OnDestroy, OnInit, Inject } from '@angular/core'
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Store, select } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { selectCurrentTestReports } from '../../../../store/selectors/progress-reports.selectors'
import { TestPlanComponent } from '../../Lesson-Dialogs/test-plan/test-plan.component'
import { TestResultComponent } from '../../Lesson-Dialogs/test-result/test-result.component'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'

@Component({
  templateUrl: './all-test-reports.component.html',
  styleUrls: ['./all-test-reports.component.scss'],
})
export class AllTestReportsComponent implements OnInit, OnDestroy {
  constructor(private _dialog: MatDialog, public dialogRef: MatDialogRef<AllTestReportsComponent>, private _store: Store<IAppState>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public report) {}

  public reports
  public data

  private unsubscribe$ = new Subject()

  ngOnInit(): void {
    this.data = false
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentTestReports)).subscribe((res) => {
      this.reports = res
      this.data = true
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  openTestPlanPopup(lesson) {
    this._dialog.open(TestPlanComponent, {
      panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: lesson,
    })
  }

  openTestResultsPopup(reportData: any, lessonName, nickname = '') {
    this._dialog.open(TestResultComponent, {
      panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: { reportData, lessonName, nickname },
    })
  }
}
