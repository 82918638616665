<div class="addStudent">
  <div class="actions">
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
    <span class="title">{{ 'STUDENT.CREATE' | translate }}</span>
  </div>

  <div class="content">
    <div class="form">
      <div class="placeholder" (click)="openSelectAvatarPopup()" matTooltip=" {{ 'AVATAR.SELECT' | translate }}">
        <ng-container *ngIf="studentForm.get('avatar').value && isLottie(studentForm.get('avatar').value.src)">
          <ng-lottie *ngFor="let ava of avatars" class="ava-img" style="width: 100%; height: 100%" [options]="{ path: ava }"> </ng-lottie>
        </ng-container>

        <mat-icon *ngIf="!studentForm.get('avatar').value">add_photo_alternate</mat-icon>
      </div>
      <form [formGroup]="studentForm">
        <mat-error *ngIf="maxStudentsReached">{{ 'CREATE_STUDENT.FAILED_MAX_STUDENTS' | translate }}</mat-error>

        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="nickname">{{ 'PROFILE.NAME' | translate }}<span class="required">*</span></label>
            <input matInput placeholder="Enter name or nickname" required formControlName="nickname" />
            <div class="white" *ngIf="studentForm.controls.nickname.invalid && (studentForm.controls.nickname.dirty || studentForm.controls.nickname.touched)">{{ 'CREATE_STUDENT.INVALID_NAME' | translate }}<span class="required">*</span></div>
          </div>
        </div>

        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="code">{{ 'STUDENT.CODE' | translate }}<span class="required">*</span></label>
            <input matInput placeholder="Student Code" required formControlName="studentCode" [min]="4" [max]="10" />
          </div>
        </div>

        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="ageRange">Classroom<span class="required">*</span></label>
            <mat-select formControlName="classroomId" required [ngClass]="{ loader: !classrooms }">
              <ng-container *ngFor="let classroom of classrooms">
                <mat-option [value]="classroom.id">{{ classroom.classroomName }}</mat-option>
              </ng-container>
            </mat-select>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="subscribe">
    <button mat-raised-button color="accent" [disabled]="!studentForm.valid || maxStudentsReached" (click)="createStudent(studentForm)">
      {{ 'STUDENT.CREATE' | translate }}
    </button>
  </div>
</div>
