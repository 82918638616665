import { Action } from '@ngrx/store'
import { GetNotificationRequest } from 'src/app/models/Notification.model'

export enum ENotificationActions {
  GetNotification = '[Notification] Get Notification',
  GetNotificationSuccess = '[Notification] Get Notification Success',
}

export class GetNotification implements Action {
  public readonly type = ENotificationActions.GetNotification
  constructor(public payload: GetNotificationRequest) {}
}

export class GetNotificationSuccess implements Action {
  public readonly type = ENotificationActions.GetNotificationSuccess
  constructor(public payload: any) {}
}

export type NotificationActions = GetNotification | GetNotificationSuccess
