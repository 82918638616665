import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { ISchool } from '../../models/School.model'
import { NotificationService } from '../../services/notification.service'
import { SchoolService } from '../../services/school.service'
import { ESchoolActions, GetSchool, GetSchoolFailure, GetSchoolSuccess } from '../actions/school.actions'
import { IAppState } from '../state/app.state'

@Injectable()
export class SchoolEffects {
  constructor(private _actions$: Actions, private _schoolService: SchoolService, private _store: Store<IAppState>, private _notificationService: NotificationService) {}

  getSchool: Observable<GetSchoolSuccess | GetSchoolFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSchool>(ESchoolActions.GetSchool),
      switchMap((action) => {
        return this._schoolService.getSchool(action.schoolId).pipe(
          map((school: ISchool) => new GetSchoolSuccess(school)),
          catchError((error, stream) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return stream
          })
        )
      })
    )
  )
}
