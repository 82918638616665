import { IBook } from '../../models/Book.model'
import { IPaginator } from 'src/app/models/Paginator.model'

export interface IBooksState {
  books: Array<IBook>
  currentBook: IBook
  paginator: IPaginator
  totalBooks: any
}

export const InitialBooksState: IBooksState = {
  books: null,
  currentBook: null,
  paginator: null,
  totalBooks: null,
}
