import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { IStudent, IStudentRequest } from '../models/Student.model'

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {}

  public createStudent(request: IStudentRequest) {
    return this.http.post(`${environment.gatewayUrl}schools/${request.schoolId}/classrooms/${request.classroomId}/students`, request)
  }

  public updateStudent(student: IStudent, request: any) {
    return this.http.put(`${environment.gatewayUrl}schools/${student.classroom.schoolId}/classrooms/${student.classroomId}/students/${student.id}`, request)
  }

  public removeStudent(student: IStudent) {
    return this.http.delete(`${environment.gatewayUrl}schools/${student.classroom.schoolId}/classrooms/${student.classroomId}/students/${student.id}`)
  }

  public getStudentsForClassroom(schoolId, classroomId) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/students`)
  }

  public getStudents(schoolId) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/students`)
  }

  public bulkCreateStudents(schoolId, classroomId, students) {
    return this.http.post(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/students/bulk`, students)
  }

  public getStudentAssignments(schoolId, classroomId, studentId, all = false) {
    let params = {}
    if (all) {
      params = { all: true }
    }
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/students/${studentId}/assignments`, { params })
  }

  public getStudentsAssignments(schoolId, classroomId, offset = 0, limit = 0) {
    let params: any = { offset, limit }

    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/students/assignments`, { params })
  }

  public getCurrentStudent() {
    return this.http.get(`${environment.gatewayUrl}schools/currentStudent`)
  }

  public exportGradebook(schoolId: number, classroomId: number) {
    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/assignments/export`, {
      headers: { Accept: 'text/csv' },
      params: { format: 'csv' },
      responseType: 'text',
    })
  }

  public exportStudentGradebook(schoolId: number, classroomId: number, studentId: number, format: string) {
    const options: any = {
      params: { format },
    }

    if (format === 'csv') {
      options.headers = { Accept: 'text/csv' }
      options.responseType = 'text'
    }

    return this.http.get(`${environment.gatewayUrl}schools/${schoolId}/classrooms/${classroomId}/students/${studentId}/assignments/export`, options)
  }
}
