<div class="avatars dinosaur">
  <div class="actions">
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
    <div class="title">{{ 'AVATAR.TITLE' | translate }}</div>
  </div>
  <div class="container scroll-modern">
    <div class="inner-box">
      <div class="avatar dino" (click)="selectAvatar(thumbnail)" *ngFor="let thumbnail of thumbnails$ | async; let i = index">
        <ng-lottie style="width: 70px; height: 70px" (animationCreated)="animationCreated($event, i)" (mouseenter)="playAnim(i)" (mouseleave)="stopAnim(i)" [options]="{ path: thumbnail.src, autoplay: false }">
        </ng-lottie>
      </div>
    </div>
  </div>
</div>
