<section class="curriculum-popup">
  <app-curriculum [isDialog]="true" (loaded)="curriculumLoaded()" (close)="closeDialog()"></app-curriculum>
  <ng-container *ngIf="!loaded">
    <div class="loader-back">
      <div class="loaderBlock">
        <div class="{{ loaderClass }}"></div>
      </div>
    </div>
  </ng-container>
</section>
