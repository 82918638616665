<section class="testPlan">
  <article>
    <div class="user-name">
      <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
    </div>

    <div class="actions">
      <div>
        <span class="title">{{ data.name }}</span>
        <span class="download" (click)="downloadPdf()">{{ 'BUTTONS.DOWNLOAD' | translate }}</span>
        <span class="show" (click)="showPdf()">{{ 'BUTTONS.PRINT' | translate }}</span>
      </div>
    </div>
    <div class="content scroll-modern">
      <div class="common">
        {{ 'TEST_PLAN.CONTENT' | translate }} (<span>{{ lessonArray?.wordsCount }}</span
        >):
      </div>
      <div class="details">
        <div *ngFor="let words of lessonArray?.wordsList">{{ words }}</div>
      </div>
    </div>
  </article>
</section>
