import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NotificationsComponent } from './notifications.component'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [NotificationsComponent],
  imports: [MatDialogModule, MatButtonModule, MatIconModule, CommonModule, TranslateModule],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
