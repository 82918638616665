<div class="landing">
  <section class="mainComponent">
    <img src="assets/images/pictures/trees.png" class="trees" alt="trees" title="Trees" />
    <img src="assets/images/pictures/mountain.png" class="mountain" alt="mountain" title="Mountain" />
    <span class="rectRhombus orange"></span>
    <span class="rectRhombus small green"></span>
    <span class="rectCicrle red"></span>
    <span class="rectRhombus extraSmall orange"></span>
    <img src="assets/images/dinosaurs/oneDino.png" class="dino svgImage" />
    <article class="contentWrapper">
      <div class="text">
        <svg-icon [applyClass]="true" src="assets/images/dinosaurs/oneDino.svg"></svg-icon>
        <div class="titles">
          <h1 class="white baloo2">{{ 'PARENTS.TITLE' | translate }}</h1>
          <span [innerHTML]="'PARENTS.SUBTITLE' | translate"></span>
          <button class="primary orange">
            <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
          </button>
        </div>
      </div>
    </article>
  </section>

  <section class="gray">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" title="Border White" />
  </section>

  <section class="parents">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ 'PARENTS.BLOCK_TITLE_1.TITLE' | translate }}</h2>
      <div class="firstContentBlock">
        <div class="text">
          <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_1.HEAD_1' | translate }}</h4>
          <p>{{ 'PARENTS.BLOCK_TITLE_1.TEXT_1' | translate }}</p>

          <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_1.HEAD_2' | translate }}</h4>
          <p>{{ 'PARENTS.BLOCK_TITLE_1.TEXT_2' | translate }}</p>

          <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_1.HEAD_3' | translate }}</h4>
          <p>{{ 'PARENTS.BLOCK_TITLE_1.TEXT_3' | translate }}</p>

          <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_1.HEAD_4' | translate }}</h4>
          <p>{{ 'PARENTS.BLOCK_TITLE_1.TEXT_4' | translate }}</p>
        </div>
        <div class="image">
          <img src="assets/images/pictures/baloons.jpg" alt="Girl with child and baloons" title="Girl with child and baloons" />
        </div>
      </div>

      <div class="secondContentBlock">
        <div class="image">
          <img src="assets/images/pictures/dinoGirl.jpg" alt="dinoGirl" title="Dino and Girl" />
        </div>
        <button class="primary orange">
          <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
        </button>
        <div class="text">
          <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_1.HEAD_5' | translate }}</h4>
          <p>{{ 'PARENTS.BLOCK_TITLE_1.TEXT_5' | translate }}</p>

          <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_1.HEAD_6' | translate }}</h4>
          <p>{{ 'PARENTS.BLOCK_TITLE_1.TEXT_6' | translate }}</p>
          <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_1.HEAD_7' | translate }}</h4>
          <p>{{ 'PARENTS.BLOCK_TITLE_1.TEXT_7' | translate }}</p>
        </div>
      </div>
      <div class="underText">
        <p>
          {{ 'PARENTS.BLOCK_TITLE_1.UNDERTEXT' | translate }}
        </p>
      </div>
    </article>
  </section>

  <section class="gray" [ngClass]="{ hideBlock: currentLang !== 'en' }">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ 'PARENTS.BLOCK_TITLE_2.TITLE' | translate }}</h2>

      <div class="panel">
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_2.HEAD_1' | translate }}</h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>{{ 'PARENTS.BLOCK_TITLE_2.TEXT_1' | translate }}</p>
          </div>
        </details>

        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_2.HEAD_2' | translate }}</h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>{{ 'PARENTS.BLOCK_TITLE_2.TEXT_2' | translate }}</p>
          </div>
        </details>

        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_2.HEAD_3' | translate }}</h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>{{ 'PARENTS.BLOCK_TITLE_2.TEXT_3' | translate }}</p>
          </div>
        </details>

        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_2.HEAD_4' | translate }}</h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>{{ 'PARENTS.BLOCK_TITLE_2.TEXT_4' | translate }}</p>
          </div>
        </details>

        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">{{ 'PARENTS.BLOCK_TITLE_2.HEAD_5' | translate }}</h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>{{ 'PARENTS.BLOCK_TITLE_2.TEXT_5' | translate }}</p>
          </div>
        </details>
      </div>
    </article>
    <button class="primary orange">
      <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
    </button>
    <img src="assets/images/pictures/borderWhite.png" class="border top" alt="" title="Border White" />
  </section>

  <section class="gray"><review-stars [schemeTitle]="metaTitle"></review-stars></section>
</div>
