<div class="landing">
  <section class="mainComponent">
    <img src="assets/images/pictures/trees.png" class="trees" alt="trees" title="Trees" />
    <img src="assets/images/pictures/mountain.png" class="mountain" alt="mountain" title="Mountain" />
    <span class="rectRhombus orange"></span>
    <span class="rectRhombus small green"></span>
    <span class="rectCicrle red"></span>
    <span class="rectRhombus extraSmall orange"></span>
    <img src="assets/images/dinosaurs/oneDino.png" class="dino svgImage" />
    <article class="contentWrapper">
      <div class="text">
        <svg-icon [applyClass]="true" src="assets/images/dinosaurs/oneDino.svg"></svg-icon>
        <div class="titles">
          <h1 class="white baloo2">{{ 'SCHOOLS.TITLE' | translate }}</h1>
          <span [innerHTML]="'SCHOOLS.SUBTITLE' | translate"></span>
          <button class="primary orange">
            <a href="https://wp.dinolingo.com/school-quote/" target="blank" class="dinoLink">
              {{ 'HOME.BUTTONS.QUOTE_SCHOOL' | translate }}
            </a>
          </button>
        </div>
      </div>
    </article>
  </section>

  <section class="gray">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" title="Border White" />
  </section>

  <section class="schoolsSection">
    <article class="contentWrapper">
      <h2 class="baloo2 blue">{{ 'SCHOOLS.BLOCK_1.TITLE' | translate }}</h2>
    </article>
    <div class="programBlock">
      <div class="programInfo">
        <div class="textProgram">
          <p [innerHTML]="'SCHOOLS.BLOCK_1.STR_1' | translate"></p>

          <p [innerHTML]="'SCHOOLS.BLOCK_1.STR_2' | translate"></p>

          <p [innerHTML]="'SCHOOLS.BLOCK_1.STR_3' | translate"></p>

          <button class="primary orange signButton">
            <a href="https://wp.dinolingo.com/school-quote/" target="_balnk" class="dinoLink">
              {{ 'HOME.BUTTONS.QUOTE_SCHOOL' | translate }}
            </a>
          </button>
        </div>
      </div>
      <div class="programImg">
        <img src="assets/images/pictures/dinoSchools.svg" alt="dinoSchools" title="DinoLingo Schools" />
      </div>
    </div>
    <div class="dinoMap">
      <img src="assets/images/dinosaurs/dinoMap.jpg" alt="dinoMap" title="DinoLingo Map" />
    </div>
    <div class="infoBlock">
      <h2 class="baloo2 blue">{{ 'SCHOOLS.BLOCK_2.TITLE' | translate }}</h2>
      <div>
        <p [innerHTML]="'SCHOOLS.BLOCK_2.STR_1' | translate"></p>
        <p [ngClass]="{ hideBlock: currentLang !== 'en' }" [innerHTML]="'SCHOOLS.BLOCK_2.STR_2' | translate"></p>
        <p [ngClass]="{ hideBlock: currentLang !== 'en' }" [innerHTML]="'SCHOOLS.BLOCK_2.STR_3' | translate"></p>
        <br />
        <p [ngClass]="{ hideBlock: currentLang !== 'en' }" [innerHTML]="'SCHOOLS.BLOCK_2.STR_4' | translate"></p>
        <br />
        <p [ngClass]="{ hideBlock: currentLang !== 'en' }" [innerHTML]="'SCHOOLS.BLOCK_2.STR_5' | translate"></p>
        <p [innerHTML]="'SCHOOLS.BLOCK_2.STR_6' | translate"></p>
        <br />
        <p [innerHTML]="'SCHOOLS.BLOCK_2.STR_7' | translate"></p>
      </div>
    </div>
    <div class="btn">
      <button class="primary orange">
        <a href="https://wp.dinolingo.com/school-quote/" target="_blank" class="dinoLink">{{ 'SCHOOLS.BLOCK_2.BTN' | translate }}</a>
      </button>
    </div>
    <div class="customerBlock">
      <h2 class="baloo2 blue">{{ 'SCHOOLS.BLOCK_3.TITLE' | translate }}</h2>
      <div class="servicesBlock">
        <img src="assets/images/pictures/customers.jpg" alt="Our customers" title="Customers" />
        <img src="assets/images/pictures/schools_info2.png" alt="Dinolingo Schools" title="Dinolingo Schools" />
      </div>
    </div>
    <div class="orgBlock" [ngClass]="{ hideBlock: currentLang !== 'en' }">
      <article class="contentWrapper">
        <h2 class="blue baloo2">{{ 'SCHOOLS.BLOCK_4.TITLE' | translate }}</h2>
        <p [innerHTML]="'SCHOOLS.BLOCK_4.STR_1' | translate"></p>
        <p [innerHTML]="'SCHOOLS.BLOCK_4.STR_2' | translate"></p>
        <p [innerHTML]="'SCHOOLS.BLOCK_4.STR_3' | translate"></p>
        <p [innerHTML]="'SCHOOLS.BLOCK_4.STR_4' | translate"></p>
        <div class="exampleStudent">
          <p [innerHTML]="'SCHOOLS.BLOCK_4.STR_5' | translate"></p>
          <p [innerHTML]="'SCHOOLS.BLOCK_4.STR_6' | translate"></p>
          <p [innerHTML]="'SCHOOLS.BLOCK_4.STR_7' | translate"></p>
          <p [innerHTML]="'SCHOOLS.BLOCK_4.STR_8' | translate"></p>
        </div>
        <button class="primary orange">
          <a href="https://wp.dinolingo.com/school-quote/" target="_blank" class="dinoLink"> {{ 'SCHOOLS.BLOCK_4.BTN' | translate }}</a>
        </button>
      </article>
    </div>
  </section>
  <section class="gray">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ 'SCHOOLS.BLOCK_5.TITLE' | translate }}</h2>
      <div class="panel">
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">{{ 'SCHOOLS.BLOCK_5.HEAD_1' | translate }}</h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>{{ 'SCHOOLS.BLOCK_5.TEXT_1' | translate }}</p>
          </div>
        </details>
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">
                  {{ 'SCHOOLS.BLOCK_5.HEAD_2' | translate }}
                </h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>
              {{ 'SCHOOLS.BLOCK_5.TEXT_2' | translate }}
            </p>
          </div>
        </details>
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">
                  {{ 'SCHOOLS.BLOCK_5.HEAD_3' | translate }}
                </h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>
              {{ 'SCHOOLS.BLOCK_5.TEXT_3' | translate }}
            </p>
          </div>
        </details>
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">
                  {{ 'SCHOOLS.BLOCK_5.HEAD_4' | translate }}
                </h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>
              {{ 'SCHOOLS.BLOCK_5.TEXT_4' | translate }}
            </p>
          </div>
        </details>
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">
                  {{ 'SCHOOLS.BLOCK_5.HEAD_5' | translate }}
                </h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p [innerHTML]="'SCHOOLS.BLOCK_5.TEXT_5' | translate"><br /></p>
          </div>
        </details>
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">
                  {{ 'SCHOOLS.BLOCK_5.HEAD_6' | translate }}
                </h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>
              {{ 'SCHOOLS.BLOCK_5.TEXT_6' | translate }}
            </p>
          </div>
        </details>
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">
                  {{ 'SCHOOLS.BLOCK_5.HEAD_7' | translate }}
                </h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>
              {{ 'SCHOOLS.BLOCK_5.TEXT_7' | translate }}
            </p>
          </div>
        </details>
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">
                  {{ 'SCHOOLS.BLOCK_5.HEAD_8' | translate }}
                </h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p [innerHTML]="'SCHOOLS.BLOCK_5.TEXT_8' | translate"></p>
          </div>
        </details>
        <details>
          <summary>
            <ul>
              <li class="titleName">
                <img class="plus" src="assets/images/plus.png" alt="plus" title="Plus" />
                <img class="minus" src="assets/images/minus.png" alt="minus" title="Minus" />
              </li>
              <li class="titleValue">
                <h4 class="gray">
                  {{ 'SCHOOLS.BLOCK_5.HEAD_9' | translate }}
                </h4>
              </li>
            </ul>
          </summary>
          <div class="content">
            <p>
              {{ 'SCHOOLS.BLOCK_5.TEXT_9' | translate }}
            </p>
          </div>
        </details>
      </div>
    </article>

    <img src="assets/images/pictures/borderWhite.png" class="border top" alt="" title="Border White" />
  </section>

  <section class="gray"><review-stars [schemeTitle]="metaTitle"></review-stars></section>
</div>
