import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { LearnForKidsComponent } from './learnForKids.component'
import { TranslateModule } from '@ngx-translate/core'
import { TestimonialsModule } from 'src/app/views/landing/common/testimonials/testimonials.module'
import { ReviewStarsModule } from 'src/app/views/landing/common/review-stars/review-stars.module'
import { VideoPopupModule } from 'src/app/shared/popups/VideoPopup/videopopup.module'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, AngularSvgIconModule, TestimonialsModule, TranslateModule, ReviewStarsModule, VideoPopupModule],
  declarations: [LearnForKidsComponent],
})
export class LearnForKidsModule {}
