import { Action } from '@ngrx/store'
import { ICourse } from 'src/app/models/Course.model'
import { ISubuser } from 'src/app/models/Subuser.model'

export enum ECoursesActions {
  GetAllCourses = '[Course] Get All Courses',
  SetCurrentCourse = '[Course] Set Current Course',
  GetAllCoursesSuccess = '[Course] Get All Courses Success',
  AddCourseOpenDialog = '[Course]  Add Course Open Dialog',
  SetCurrentUnit = '[Course] Set Current Unit',
}

export class GetAllCourses implements Action {
  public readonly type = ECoursesActions.GetAllCourses
}

export class SetCurrentCourse implements Action {
  public readonly type = ECoursesActions.SetCurrentCourse
  constructor(public payload: ICourse) {}
}

export class AddCourseOpenDialog implements Action {
  public readonly type = ECoursesActions.AddCourseOpenDialog
  constructor(public payload: ISubuser) {}
}

export class GetAllCoursesSuccess implements Action {
  public readonly type = ECoursesActions.GetAllCoursesSuccess
  constructor(public payload: Array<ICourse>) {}
}

export type CoursesActions = GetAllCourses | SetCurrentCourse | GetAllCoursesSuccess | AddCourseOpenDialog
