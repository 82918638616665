import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class ParentsNotificationsComponent {
  constructor(public translate: TranslateService) {}
}
