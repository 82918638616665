<div class="editStudent">
  <div class="actions">
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
    <div class="title">{{ 'STUDENT.EDIT' | translate }}</div>
  </div>

  <div class="content">
    <div class="form">
      <div class="placeholder" (click)="openSelectAvatarPopup()" matTooltip=" {{ 'AVATAR.SELECT' | translate }}">
        <ng-container *ngIf="studentForm.get('avatar').value && isLottie(studentForm.get('avatar').value.src)">
          <ng-lottie *ngFor="let ava of avatars" class="ava-img" style="width: 100%; height: 100%" [options]="{ path: ava }"> </ng-lottie>
        </ng-container>
      </div>
      <form [formGroup]="studentForm">
        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="nickname">{{ 'PROFILE.NAME' | translate }}<span class="required">*</span></label>
            <input matInput placeholder="Enter name or nickname" required formControlName="nickname" />
            <div class="white" *ngIf="studentForm.controls.nickname.invalid && (studentForm.controls.nickname.dirty || studentForm.controls.nickname.touched)">{{ 'CREATE_STUDENT.INVALID_NAME' | translate }}<span class="required">*</span></div>
          </div>
        </div>

        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="code">{{ 'STUDENT.CODE' | translate }}<span class="required">*</span></label>
            <input matInput placeholder="Student Code" required formControlName="studentCode" [min]="4" [max]="10" />
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="buttons">
    <button mat-raised-button color="accent" [disabled]="!studentForm.valid" (click)="updateStudent(studentForm)">
      {{ 'STUDENT.EDIT' | translate }}
    </button>
  </div>
</div>
