import { AfterViewInit, Component, Inject } from '@angular/core'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { interval } from 'rxjs'
import { take } from 'rxjs/operators'
import { IAppState } from 'src/app/store/state/app.state'

@Component({
  templateUrl: 'confirmation.component.html',
  styleUrls: ['confirmation.component.scss'],
})
export class ConfirmationPopupComponent implements AfterViewInit {
  public question

  public extraConfirmation

  public secondsLeft = 5

  private countdown$

  public customYes
  public customNo

  constructor(public _dialog: MatDialogRef<ConfirmationPopupComponent>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data, private _store: Store<IAppState>) {
    if (typeof data === 'object') {
      this.question = data?.question || 'Confirm your action'
      this.extraConfirmation = data?.extraConfirmation || false
      this.customYes = data?.customYes || false
      this.customNo = data?.customNo || false
    } else {
      this.question = data
    }
  }

  ngAfterViewInit() {
    if (this.extraConfirmation === true) {
      this.countdown$ = interval(1000)
      this.countdown$.pipe(take(5)).subscribe(() => this.secondsLeft--)
    }
  }

  selectAnswer(answer) {
    this._dialog.close(answer)
  }
}
