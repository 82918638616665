import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { take } from 'rxjs/operators'
import { AdjustStudentGrade, EAssignmentActions } from '../../../../store/actions/assignment.actions'

@Component({
  templateUrl: './adjust-grade.component.html',
  styleUrls: ['./adjust-grade.component.scss'],
})
export class AdjustGradeDialogComponent implements OnInit {
  public isCompleted = false

  public showScore = false

  public score = 0

  public form: UntypedFormGroup

  constructor(private _store: Store, private _actions$: Actions, private _dialog: MatDialog, public dialogRef: MatDialogRef<AdjustGradeDialogComponent>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public $data: any) {}

  ngOnInit(): void {
    this.isCompleted = this.$data.assignmentCompleted.complete
    this.showScore = this.isTest()
    this.score = this.$data.assignmentCompleted.progress || 0

    this.form = new UntypedFormGroup({
      isCompleted: new UntypedFormControl(this.isCompleted),
      score: new UntypedFormControl(this.score),
    })
  }

  adjustGrade(form: UntypedFormGroup): void {
    const { score } = form.value
    this.doAdjustment(score)
  }

  markAsComplete(complete: boolean): void {
    this.doAdjustment(complete === true ? 100 : 0)
  }

  private doAdjustment(score: number): void {
    const schoolId = this.$data.student.classroom.schoolId
    const classroomId = this.$data.student.classroomId
    const studentId = this.$data.student.id
    const lessonId = this.$data.assignmentCompleted.lesson.id

    this._store.dispatch(new AdjustStudentGrade(schoolId, classroomId, studentId, { progress: score, lessonId }))

    this._actions$.pipe(ofType(EAssignmentActions.AdjustStudentGradeSuccess), take(1)).subscribe((res) => {
      this.dialogRef.close()
    })
  }

  isTest(): boolean {
    return [3, 4, 5].includes(this.$data.assignmentCompleted.lesson.typeId)
  }
}
