import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ProgressReportsService {
  constructor(private http: HttpClient) {}

  public getSubuserTestReports(courseId) {
    return this.http.get(`${environment.gatewayUrl}log/parents/subuser/latestTestReports?courseId=${courseId}`)
  }

  public getSubuserTestReport(subuserId, courseId) {
    return this.http.get(`${environment.gatewayUrl}log/parents/subuser/${subuserId}/testReports?courseId=${courseId}`)
  }

  public getSubuserTimeLogs() {
    return this.http.get(`${environment.gatewayUrl}log/parents/subuser/timeLogs`)
  }

  public getSubuserTimeLog(subuserId) {
    return this.http.get(`${environment.gatewayUrl}log/parents/subuser/${subuserId}/timeLogs`)
  }

  public getSubuserDetailedReports(courseId) {
    return this.http.get(`${environment.gatewayUrl}log/parents/subuser/detailedReports?courseId=${courseId}`)
  }

  public getStudentsTestReports(schoolId, classroomId, courseId, lessonId = null) {
    return this.http.get(`${environment.gatewayUrl}log/schools/${schoolId}/classrooms/${classroomId}/latestTestReports?courseId=${courseId}&lessonId=${lessonId}`)
  }

  public getStudentTestReports(schoolId, classroomId, studentId, courseId) {
    return this.http.get(`${environment.gatewayUrl}log/schools/${schoolId}/classrooms/${classroomId}/students/${studentId}/testReports?courseId=${courseId}`)
  }

  public getStudentsTimeLogs(schoolId, classroomId) {
    return this.http.get(`${environment.gatewayUrl}log/schools/${schoolId}/classrooms/${classroomId}/timeLogs`)
  }

  public getStudentTimeLog(schoolId, classroomId, studentId) {
    return this.http.get(`${environment.gatewayUrl}log/schools/${schoolId}/classrooms/${classroomId}/students/${studentId}/timeLogs`)
  }

  public getStudentsDetailedReports(schoolId, classroomId, lessonId = null) {
    return this.http.get(`${environment.gatewayUrl}log/schools/${schoolId}/classrooms/${classroomId}/detailedReports?lessonId=${lessonId}`)
  }

  public exportStudentsReports(schoolId: number, classroomId: number, payload: any) {
    const options: any = {
      params: { ...payload },
    }

    if (payload.format === 'csv') {
      options.headers = { Accept: 'text/csv' }
      options.responseType = 'text'
    }

    return this.http.get(`${environment.gatewayUrl}log/schools/${schoolId}/classrooms/${classroomId}/export`, options)
  }
}
