import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class SiteStatusService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = `${environment.gatewayUrl}admin/options/main/status`
  }

  public handelStatusSite() {
    const domain = location.href.includes('stage-learn.dinolearning.com') ? 'stage-learn.dinolearning.com' : 'dinolingo.com'
    return this.http.get(`${this.gatewayUrl}`, {
      params: { domain },
    })
  }
}
