<div class="editSubuser">
  <div class="actions">
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
    <span class="title">{{ 'CHILD.EDIT' | translate }} </span>
  </div>

  <div class="content">
    <form [formGroup]="editSubuserForm">
      <div class="form-fields-container full-width">
        <div class="form-field-container">
          <label for="nickname">{{ 'PROFILE.NAME' | translate }}</label>
          <input type="text" id="nickname" placeholder="Enter name or nickname" maxlength="10" formControlName="name" />
          <div class="white" *ngIf="editSubuserForm.controls.name.invalid">{{ 'CREATE_CHILD.INVALID_NAME' | translate }}<span class="required">*</span></div>
        </div>
      </div>

      <div class="form-fields-container full-width">
        <div class="form-field-container">
          <label for="ageRange">{{ 'PROFILE.AGE' | translate }}<span class="required">*</span></label>
          <mat-select formControlName="age" required placeholder="{{ 'PLACEHOLDERS.AGE' | translate }}">
            <mat-option value="3"><span>2-5 years </span><span class="small-tip">videos and books only</span></mat-option>
            <mat-option value="6"><span>6-10 years </span><span class="small-tip">full experience</span></mat-option>
            <mat-option value="12"><span>11+ years </span><span class="small-tip">books/games/test only</span></mat-option>
          </mat-select>
        </div>
      </div>

    </form>
    <div class="remove" (click)="removeSubuser()" *ngIf="(subuser$ | async).length > 1">
      <img src="assets/images/icons/redClose.svg" alt="remove subuser" />
      <p>{{ 'CHILD.REMOVE' | translate }}</p>
    </div>
  </div>

  <div class="buttons">
    <button mat-raised-button color="accent" [disabled]="editSubuserForm.invalid" (click)="updateSubuser()">
      {{ 'BUTTONS.SAVE' | translate }}
    </button>
  </div>
</div>
