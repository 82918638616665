import { AuthActions, EAuthActions } from '../actions/auth.actions'
import { IAuthState, InitialAuthState } from '../state/auth.state'

export const authReducer = (state = InitialAuthState, action: AuthActions): IAuthState => {
  switch (action.type) {
    case EAuthActions.RefreshToken: {
      return {
        ...state,
        latestRequest: action.payload,
      }
    }

    case EAuthActions.SignInSuccess: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
      }
    }

    default:
      return state
  }
}
