import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { NotFoundComponent } from './not-found.component'

@NgModule({
  imports: [CommonModule, RouterModule, AngularSvgIconModule],
  declarations: [NotFoundComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotFoundModule {}
