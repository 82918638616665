import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, HostListener } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { Store } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { TestResultComponent } from '../../../../shared/popups/Lesson-Dialogs/test-result/test-result.component'
import { rangomSpinner } from '../../../../helpers/utils/spiner.util'
import { filter, takeUntil } from 'rxjs/operators'
import { GetSubuserTestReports, GetSubuserTestReport, GetStudentsTestReports, GetStudentTestReports, ResetReports } from '../../../../store/actions/progress-reports.actions'
import { selectLatestTestReports } from '../../../../store/selectors/progress-reports.selectors'
import { AllTestReportsComponent } from '../../../../shared/popups/ProgressReports-Dialogs/all-test-reports/all-test-reports.component'
import { Subject } from 'rxjs'
import { SortProgressReportsUtil } from '../../../../helpers/utils/sortProgressReports.util.js'
import { IAssignment } from '../../../../models/Assignment.model'
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
  selector: 'app-test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.scss'],
})
export class TestResultsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() type
  @Input() course
  @Input() classroom
  @Input() sortMethod
  @Input() assignments: Array<IAssignment> = []

  public loaded = false
  public loaderClass: string = rangomSpinner()
  public testReports
  public selectedAssignment

  private unsubscribe$ = new Subject<void>()

  constructor(private _dialog: MatDialog, private _store: Store<IAppState>, public translate: TranslateService, private deviceService: DeviceDetectorService) {}

  isMobileScreen: boolean = false

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.deviceService.isMobile()) {
      this.checkScreenSize()
    }
  }

  private checkScreenSize(): void {
    this.isMobileScreen = window.outerHeight < 500 || window.outerWidth < 500
  }

  ngOnInit() {
    if (this.deviceService.isMobile()) {
      this.checkScreenSize()
    }

    this._store
      .select(selectLatestTestReports)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((reports) => reports.length !== 0)
      )
      .subscribe((res) => {
        this.testReports = SortProgressReportsUtil.sort(this.sortMethod, res)
        this.loaded = true
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()

    // reset state
    this._store.dispatch(new ResetReports())
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadReports()
  }

  loadReports() {
    this.loaded = false

    if (this.type === 'parent') {
      // load subuser reports
      this._store.dispatch(new GetSubuserTestReports(this.course.id))
    } else if (this.type === 'school') {
      const lessonFilter = this.selectedAssignment ? this.selectedAssignment.lesson.id : null

      // load student reports
      this._store.dispatch(new GetStudentsTestReports(this.classroom.schoolId, this.classroom.id, this.course.id, lessonFilter))
    }
  }

  get assignmentOptions() {
    return this.assignments.filter((assignment) => ['minitest', 'unittest', 'test'].includes(assignment.lesson.type.slug))
  }

  selectAssignment(assignment) {
    this.selectedAssignment = assignment
    this.loadReports()
  }

  openAllTestReportsPopup(report) {
    if (this.type === 'school') {
      this._store.dispatch(new GetStudentTestReports(report.classroom.schoolId, report.classroom.id, report.id, this.course.id))
    } else if (this.type === 'parent') {
      this._store.dispatch(new GetSubuserTestReport(report.subuser.id, this.course.id))
    }

    this._dialog.open(AllTestReportsComponent, {
      panelClass: 'all-test-reports-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: report,
    })
  }

  openTestResultsPopup(reportData: any, lessonName, nickname = '') {
    this._dialog.open(TestResultComponent, {
      // panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: { reportData, lessonName, nickname },
    })
  }
}
