import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { ITeacher, ITeacherRequest } from '../../models/Teacher.model'
import { NotificationService } from '../../services/notification.service'
import { TeacherService } from '../../services/teacher.service'
import {
  AddTeacher,
  AddTeacherFailure,
  AddTeacherSuccess,
  ETeachersActions,
  GetTeachers,
  GetTeachersFailure,
  GetTeachersSuccess,
  RemoveTeacher,
  RemoveTeacherFailure,
  RemoveTeacherSuccess,
  UpdateTeacher,
  UpdateTeacherFailure,
  UpdateTeacherSuccess,
} from '../actions/teachers.actions'
import { IAppState } from '../state/app.state'

@Injectable()
export class TeachersEffects {
  constructor(private _actions$: Actions, private _teacherService: TeacherService, private _store: Store<IAppState>, private _notificationService: NotificationService) {}

  addTeacher: Observable<AddTeacherSuccess | AddTeacherFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddTeacher>(ETeachersActions.AddTeacher),
      map((action: AddTeacher) => action.payload),
      switchMap((payload: ITeacherRequest) => {
        return this._teacherService.createTeacher(payload).pipe(
          map((teacher: ITeacher) => new AddTeacherSuccess(teacher)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.error.clientMessage, 5)
            return of(new AddTeacherFailure(error))
          })
        )
      })
    )
  )

  updateTeacher: Observable<UpdateTeacherSuccess | UpdateTeacherFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateTeacher>(ETeachersActions.UpdateTeacher),
      switchMap((action: UpdateTeacher) => {
        return this._teacherService.updateTeacher(action.teacher, action.payload).pipe(
          map((teacher: ITeacher) => new UpdateTeacherSuccess(teacher)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.error.clientMessage, 5)
            return of(new UpdateTeacherFailure(error))
          })
        )
      })
    )
  )

  removeTeacher: Observable<RemoveTeacherSuccess | RemoveTeacherFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveTeacher>(ETeachersActions.RemoveTeacher),
      switchMap((payload: RemoveTeacher) => {
        return this._teacherService.deleteTeacher(payload.schoolId, payload.teacherId)
      }),
      switchMap((removedTeacher: any) => {
        return of(new RemoveTeacherSuccess(removedTeacher))
      }),
      catchError((error) => {
        this._notificationService.showNotificationError(error.error.clientMessage, 5)
        return of(new RemoveTeacherFailure(error))
      })
    )
  )

  getTeachers$: Observable<GetTeachersSuccess | GetTeachersFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetTeachers>(ETeachersActions.GetTeachers),
      switchMap((payload: GetTeachers) => {
        return this._teacherService.getTeachers(payload.schoolId).pipe(
          map((teachers: Array<ITeacher>) => new GetTeachersSuccess(teachers)),
          catchError((error, stream) => {
            this._notificationService.showNotificationError(error.clientMessage, 5)
            return stream
          })
        )
      })
    )
  )
}
