import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { Location } from '@angular/common'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { ManageBooksAutoplay } from 'src/app/store/actions/user.actions'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { Subject } from 'rxjs'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { ISubuser } from 'src/app/models/Subuser.model'
import { selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { getHeaderValue } from 'src/app/store/selectors/header.selectors'
import { TranslateService } from '@ngx-translate/core'
import { takeUntil } from 'rxjs/operators'
import { ReportProblemPopupComponent } from 'src/app/shared/popups/Report-Dialogs/report-problem/report-problem.component'
import { ICourse } from '../../../models/Course.model'
import { AudioService } from 'src/app/services/audio.service'

@Component({
  selector: 'book-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
})
export class BookPlayerComponent implements OnInit, OnDestroy {
  @Input() type: string
  @Input() course: ICourse
  @Input() set media(media: any) {
    if (media) {
      this.book = media
    }
  }
  @Output() ended = new EventEmitter<boolean>()

  @Output() newPageOpened = new EventEmitter<number>()

  public pageRead = []

  public currentPage = 0
  public currentPart: string
  public book: any
  public subuser: ISubuser
  public autoplayStatus = false
  public timer: any
  public initTimer: any
  public unsubscribe$ = new Subject()
  public autoplayValue = 0
  public currentAudioDuration = 0

  public dir = 'ltr'

  constructor(public location: Location, private _store: Store<IAppState>, private _dialog: MatDialog, private audioService: AudioService, public translate: TranslateService) {}

  ngOnInit() {
    this.book.pages.forEach((element) => {
      if (element.audio) {
        this.audioService.preloadAudio(element.audio, element.audio)
      }
    })

    if (this.course.slug === 'arabic' || this.course.slug === 'persian' || this.course.slug === 'hebrew') {
      this.dir = 'rtl'
    }

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      if (user) {
        if (user.user.userOption && user.user.userOption.booksAutoplay) {
          this.autoplayValue = user.user.userOption.booksAutoplay === true ? 1 : user.user.userOption.booksAutoplay
          this.autoplayStatus = !!this.autoplayValue
        }
      }
    })

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)).subscribe((subuser) => {
      if (subuser) this.subuser = subuser
    })

    if (this.book.pages[0].audio) {
      this.initTimer = setTimeout(() => {
        this.currentAudioDuration = isNaN(this.audioPlay(this.book.pages[0].audio)) ? 1 : this.audioPlay(this.book.pages[0].audio)
        if (this.autoplayStatus) {
          this.timer = setTimeout(() => {
            this.goToPage(this.currentPage + 1)
          }, this.autoplayValue * 1000 + ~~(this.currentAudioDuration * 1000))
        }
      }, 1000)
    } else {
      if (this.autoplayStatus) {
        this.timer = setTimeout(() => {
          this.goToPage(this.currentPage + 1)
        }, this.autoplayValue * 1000)
      }
    }

    this._store.pipe(takeUntil(this.unsubscribe$), select(getHeaderValue)).subscribe((currentPart) => {
      if (currentPart) {
        this.currentPart = currentPart
      }
    })
  }

  public audioPlay(src) {
    return this.audioService.playAudio(src, src, true, true, 'turnpage')
  }

  ngOnDestroy() {
    clearTimeout(this.timer)
    clearTimeout(this.initTimer)
    this.audioService.stopAllAudio()
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  public manageAutoplay() {
    this._store.dispatch(new ManageBooksAutoplay(this.autoplayValue))
    if (!this.autoplayValue) {
      clearTimeout(this.timer)
    }

    if (!this.autoplayStatus && this.autoplayValue) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.goToPage(this.currentPage + 1)
      }, this.autoplayValue * 1000 + ~~(this.currentAudioDuration * 1000) - 500)
    }

    this.autoplayStatus = !!this.autoplayValue
  }

  public goToPage(newPage: number) {
    if (newPage < 1) return

    if (!this.pageRead.includes(newPage) && newPage < this.book.pages.length) {
      this.newPageOpened.emit(newPage)
      this.pageRead.push(newPage)
    }

    if (newPage > this.book.pages.length - 1 && this.currentPart !== 'books') {
      this.ended.emit(true)
      this.pageRead = []
      clearTimeout(this.timer)
      return (this.currentPage = 0)
    }

    this.currentPage = newPage
    if (this.book.pages[newPage].audio) {
      this.currentAudioDuration = isNaN(this.audioPlay(this.book.pages[newPage].audio)) ? 1 : this.audioPlay(this.book.pages[newPage].audio)
    }

    if (this.autoplayStatus) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.goToPage(this.currentPage + 1)
      }, this.autoplayValue * 1000 + ~~(this.currentAudioDuration * 1000) - 500)
    }
  }

  public openReportPopup(book) {
    const data = {
      bookId: book.bookId,
      pageNumber: book.pageNumber,
      bookName: book.text,
    }
    this._dialog.open(ReportProblemPopupComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      data: data,
      panelClass: 'medium-adaptive-popup',
    })
  }
}
