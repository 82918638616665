import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { SelectCourseDialogComponent } from './selectCourse.component'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { TranslateModule } from '@ngx-translate/core'
import { AvatarSelectPopupModule } from '../avatar-select/avatar-select.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    AvatarSelectPopupModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    TranslateModule,
    SoundsModule,
  ],
  declarations: [SelectCourseDialogComponent],
  providers: [],
})
export class SelectCourseDialogModule {}
