import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { ProgressBarComponent } from './progress.component'

@NgModule({
  declarations: [ProgressBarComponent],

  imports: [CommonModule, MatButtonModule, MatIconModule],

  exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
