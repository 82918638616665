import { Action } from '@ngrx/store'
import { IGetUnitWithLessonsRequest, ILesson } from 'src/app/models/Lesson.model'
import { IAssignment } from '../../models/Assignment.model'

export enum ELessonsActions {
  HideHeaderLesson = '[Lessons] Hide Header',
  DisplayHeaderLesson = '[Lessons] Display Header',
  CompleteLesson = '[Lessons] Complete Lesson',
  CompleteLessonSuccess = '[Lessons] Complete Lesson Success',
  CompleteLessonFailure = '[Lessons] Complete Lesson Failure',
  CompleteUnit = '[Lesson] Complete Unit',
  CompleteUnitSuccess = '[Lesson] Complete Unit Success',
  CompleteUnitFailure = '[Lesson] Complete Unit Failure',
  GetLessons = '[Lessons] Get Course Lessons',
  GetLessonsSuccess = '[Lessons] Get All Course Lessons Success',
  GetLessonsFailure = '[Lessons] Get All Course Lessons Failure',
  GetPrints = '[Lessons] Get Prints',
  GetPrintsSuccess = '[Lessons] Get  Prints Success',
  GetPrintsFailure = '[Lessons] Get  Prints Failure',
  GetLesson = '[Lessons] Get Course Lesson',
  GetLessonSuccess = '[Lessons] Get Lesson Success',
  GetLessonFailure = '[Lessons] Get Lessons Failure',
  GetLessonWordsList = '[Lessons] Get Lesson Words List',
  GetLessonWordsListSuccess = '[Lessons] Get Lesson Words List Success',
  GetLessonVideos = '[Lessons] Get Lesson Videos',
  GetLessonVideosSuccess = '[Lessons] Get Lesson Videos Success',
  GetLessonVideosFailure = '[Lessons] Get Lesson Videos Failure',
  GetLessonGames = '[Lessons] Get Lesson Videos',
  GetLessonGamesSuccess = '[Lessons] Get Lesson Games Success',
  GetLessonGamesFailure = '[Lessons] Get Lesson Games Failure',
  GetLessonTest = '[Lessons] Get Lesson Test',
  GetLessonTestSuccess = '[Lessons] Get Lesson Test Success',
  GetLessonTestFailure = '[Lessons] Get Lesson Test Failure',
  // GetLessonWord = '[Lesson] Get Lesson Word',
  // GetLessonWordSuccess = '[Lesson] Get Lesson Word Success',
  GetUnitWithLessons = '[Lesson] Get Unit With Lessons',
  GetUnitWithLessonsSuccess = '[Lesson] Get Unit With Lessons Success',
  AddAssignmentToLesson = '[Lesson] Add Assignment To Lesson',
  RemoveAssignmentFromLesson = '[Lesson] Remove Assignment From Lesson',
  GetLocalizations = '[Lesson] Get Localizations',
  GetLocalizationsSuccess = '[Lesson] Get Localizations Success',
  GetAvailableLocales = '[Lesson] Get Available Locales',
  GetAvailableLocalesSuccess = '[Lesson] Get Available Locales Success',
  SavePreference = '[Lesson] Save preference'
}

export class HideHeaderLesson implements Action {
  public readonly type = ELessonsActions.HideHeaderLesson
}

export class DisplayHeaderLesson implements Action {
  public readonly type = ELessonsActions.DisplayHeaderLesson
}

export class CompleteLesson implements Action {
  public readonly type = ELessonsActions.CompleteLesson
  constructor(public payload: any) {}
}

export class CompleteLessonSuccess implements Action {
  public readonly type = ELessonsActions.CompleteLessonSuccess
  constructor(public payload: any) {}
}

export class CompleteLessonFailure implements Action {
  public readonly type = ELessonsActions.CompleteLessonFailure
  constructor(public payload: any) {}
}

export class CompleteUnit implements Action {
  public readonly type = ELessonsActions.CompleteUnit
  constructor(public payload: any) {}
}

export class CompleteUnitSuccess implements Action {
  public readonly type = ELessonsActions.CompleteUnitSuccess
  constructor(public payload: any) {}
}

export class CompleteUnitFailure implements Action {
  public readonly type = ELessonsActions.CompleteUnitFailure
  constructor(public payload: any) {}
}

export class GetPrints implements Action {
  public readonly type = ELessonsActions.GetPrints
  constructor(public payload: any) {}
}

export class GetPrintsSuccess implements Action {
  public readonly type = ELessonsActions.GetPrintsSuccess
  constructor(public payload: any) {}
}

export class GetPrintsFailure implements Action {
  public readonly type = ELessonsActions.GetPrintsFailure
  constructor(public payload: any) {}
}

export class GetUnitWithLessons implements Action {
  public readonly type = ELessonsActions.GetUnitWithLessons
  constructor(public payload: IGetUnitWithLessonsRequest) {}
}

export class GetUnitWithLessonsSuccess implements Action {
  public readonly type = ELessonsActions.GetUnitWithLessonsSuccess
  constructor(public payload: any) {}
}

export class GetLessons implements Action {
  public readonly type = ELessonsActions.GetLessons
  constructor(public payload: any) {}
}

export class GetLessonsSuccess implements Action {
  public readonly type = ELessonsActions.GetLessonsSuccess
  constructor(public payload: Array<any>) {}
}

export class GetLessonsFailure implements Action {
  public readonly type = ELessonsActions.GetLessonsFailure
  constructor(public payload: any) {}
}

export class GetLesson implements Action {
  public readonly type = ELessonsActions.GetLesson
  constructor(public payload: any) {}
}

export class GetLessonSuccess implements Action {
  public readonly type = ELessonsActions.GetLessonSuccess
  constructor(public payload: any) {}
}

export class GetLessonFailure implements Action {
  public readonly type = ELessonsActions.GetLessonFailure
  constructor(public payload: any) {}
}

export class GetLessonVideos implements Action {
  public readonly type = ELessonsActions.GetLessonVideos
  constructor(public payload: number | string) {}
}

export class GetLessonVideosSuccess implements Action {
  public readonly type = ELessonsActions.GetLessonVideosSuccess
  constructor(public payload: any) {}
}

export class GetLessonVideosFailure implements Action {
  public readonly type = ELessonsActions.GetLessonVideosFailure
  constructor(public payload: any) {}
}

export class GetLessonGames implements Action {
  public readonly type = ELessonsActions.GetLessonGames
  constructor(public payload: number | string) {}
}

export class GetLessonGamesSuccess implements Action {
  public readonly type = ELessonsActions.GetLessonGamesSuccess
  constructor(public payload: any) {}
}

export class GetLessonGamesFailure implements Action {
  public readonly type = ELessonsActions.GetLessonGamesFailure
  constructor(public payload: any) {}
}

export class GetLessonTest implements Action {
  public readonly type = ELessonsActions.GetLessonTest
  constructor(public payload: number | string) {}
}

export class GetLessonTestSuccess implements Action {
  public readonly type = ELessonsActions.GetLessonTestSuccess
  constructor(public payload: any) {}
}

export class GetLessonTestFailure implements Action {
  public readonly type = ELessonsActions.GetLessonTestFailure
  constructor(public payload: any) {}
}

export class AddAssignmentToLesson implements Action {
  public readonly type = ELessonsActions.AddAssignmentToLesson
  constructor(public assignment: IAssignment) {}
}

export class RemoveAssignmentFromLesson implements Action {
  public readonly type = ELessonsActions.RemoveAssignmentFromLesson
  constructor(public assignmentId: number) {}
}

export class GetLocalizations implements Action {
  public readonly type = ELessonsActions.GetLocalizations
  constructor(public lessonId: number, public locale: string) {}
}

export class GetLocalizationsSuccess implements Action {
  public readonly type = ELessonsActions.GetLocalizationsSuccess
  constructor(public localizations: Array<object>) {}
}

export class GetAvailableLocales implements Action {
  public readonly type = ELessonsActions.GetAvailableLocales
  constructor(public lessonId: number) {}
}

export class GetAvailableLocalesSuccess implements Action {
  public readonly type = ELessonsActions.GetAvailableLocalesSuccess
  constructor(public locales: Array<string>) {}
}

export class SavePreference implements Action {
  public readonly type = ELessonsActions.SavePreference
  constructor(public selectType: string, public page: number) {}
}

export type LessonsActions =
  | GetLessons
  | GetLessonsSuccess
  | GetLessonsFailure
  | GetLesson
  | GetLessonSuccess
  | GetLessonFailure
  | GetLessonVideos
  | GetLessonVideosSuccess
  | GetLessonVideosFailure
  | GetLessonGames
  | GetLessonGamesSuccess
  | GetLessonGamesFailure
  | GetLessonTest
  | GetLessonTestSuccess
  | GetLessonTestFailure
  | CompleteLesson
  | CompleteLessonSuccess
  | CompleteLessonFailure
  | CompleteUnit
  | CompleteUnitSuccess
  | CompleteUnitFailure
  | GetPrints
  | GetPrintsFailure
  | GetPrintsSuccess
  | DisplayHeaderLesson
  | HideHeaderLesson
  // | GetLessonWordsList
  // | GetLessonWordsListSuccess
  // | GetLessonWord
  // | GetLessonWordSuccess
  | GetUnitWithLessons
  | GetUnitWithLessonsSuccess
  | AddAssignmentToLesson
  | RemoveAssignmentFromLesson
  | GetLocalizations
  | GetLocalizationsSuccess
  | GetAvailableLocales
  | GetAvailableLocalesSuccess
  | SavePreference
