import { Component, Inject } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'

@Component({
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss'],
})
export class NotificationsComponent {
  constructor(public dialogRef: MatDialogRef<NotificationsComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  close() {
    this.dialogRef.close()
  }
}
