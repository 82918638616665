import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { StudentService } from '../../../../services/student.service'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import * as Papa from 'papaparse'

@Component({
  templateUrl: './bulk-create.component.html',
  styleUrls: ['./bulk-create.component.scss'],
})
export class BulkCreateComponent implements AfterViewInit {
  @ViewChild('csvUpload') csvUpload: ElementRef
  // TODO: ADD TRANSLATION
  constructor(private _route: ActivatedRoute, private _router: Router, private _formBuilder: UntypedFormBuilder, private _studentService: StudentService, private _snackBar: MatSnackBar, private cd: ChangeDetectorRef) {}

  public loaded

  public classroom

  public bulkStudentForm: UntypedFormGroup

  ngAfterViewInit(): void {
    this._route.queryParams.subscribe(
      (classroom) => {
        if (!classroom) {
          this._router.navigate(['/profile/teachers/students'])
          return
        }

        this.classroom = classroom

        this.bulkStudentForm = this._formBuilder.group({
          students: this._formBuilder.array([this.studentGroup()]),
        })

        this.bulkStudentForm.updateValueAndValidity()

        this.loaded = true

        this.cd.detectChanges()
      },
      () => this._router.navigate(['/profile/teachers/students'])
    )
  }

  studentGroup(): UntypedFormGroup {
    return this._formBuilder.group({
      studentName: '',
      studentCode: '',
    })
  }

  get studentNames(): UntypedFormArray {
    return this.bulkStudentForm.get('studentNames') as UntypedFormArray
  }

  get studentCodes(): UntypedFormArray {
    return this.bulkStudentForm.get('studentCodes') as UntypedFormArray
  }

  get students(): UntypedFormArray {
    return this.bulkStudentForm.get('students') as UntypedFormArray
  }

  get availableStudents(): number {
    return Math.max(0, this.classroom.availableStudents - this.students.length)
  }

  getStudentNameControl(index): UntypedFormControl {
    const students: any = this.students
    return students.controls[index].controls.studentName as UntypedFormControl
  }

  getStudentCodeControl(index): UntypedFormControl {
    const students: any = this.students
    return students.controls[index].controls.studentCode as UntypedFormControl
  }

  addRow() {
    this.students.push(this.studentGroup())
  }

  removeRow(index) {
    this.students.removeAt(index)
  }

  onBlur(index: number) {
    const numRows = this.students.length

    // don't add a new row if we will exceed the available students
    if (numRows === parseInt(this.classroom.availableStudents)) {
      return
    }

    const row = this.students.value[index]

    // add a new form row if we're on the bottom-most input
    if (index + 1 === numRows) {
      // only add a new row if the input was not empty
      if (row.studentName !== '') {
        this.addRow()
      }
    }
  }

  checkFormIsValid() {
    this.students.value.forEach((student, index) => {
      // if both fields are empty we can skip
      if (student.studentName === '' && student.studentCode === '') {
        return
      }

      // if one field is filled, the other must be too
      if (student.studentName !== '' && student.studentCode === '') {
        this.getStudentCodeControl(index).setErrors({ empty: true })
        return
      } else if (student.studentName === '' && student.studentCode !== '') {
        this.getStudentNameControl(index).setErrors({ empty: true })
        return
      }

      // make sure there are no duplicate student codes
      const studentCodes = this.students.value.filter((f) => f.studentCode !== '').map((m) => m.studentCode)
      if (new Set(studentCodes).size !== studentCodes.length) {
        this.students.setErrors({ duplicateStudentCodes: true })
      }
    })
  }

  save() {
    this.checkFormIsValid()

    if (!this.students.length) return
    if (!this.bulkStudentForm.valid) return
    if (this.students.length > this.classroom.availableStudents) return

    this._studentService.bulkCreateStudents(this.classroom.schoolId, this.classroom.id, { students: this.students.value }).subscribe(
      (res: any) => {
        if (res.success === true) {
          this._snackBar.open('Students successfully created', 'cancel', {
            duration: 2000,
          })

          this._router.navigate(['/profile/teachers/students'])
        }
      },
      (error) => {
        this._snackBar.open('ERROR: ' + error.error.clientMessage, 'cancel', {
          duration: 2000,
        })
      }
    )
  }

  openFileDialog() {
    // open file dialog
    this.csvUpload.nativeElement.click()
  }

  async processCsv($event) {
    if ($event.target.files.length) {
      // reset form fields
      while (this.students.length !== 0) {
        this.students.removeAt(0)
      }

      const file = $event.target.files[0]

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.data.length) {
            results.data.forEach((d) => {
              const group = this.studentGroup()
              const studentName = group.get('studentName')
              const studentCode = group.get('studentCode')
              studentName.setValue(d.studentName)
              studentName.markAsTouched()
              studentName.markAsDirty()
              studentCode.setValue(d.studentCode)
              studentCode.markAsTouched()
              studentCode.markAsDirty()

              this.students.push(group)
            })
            if (this.availableStudents !== 0) {
              this.addRow()
            }
          }
        },
      })
    }
  }
}
