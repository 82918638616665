<section class="subuser" (click)="closeToolTip()">
  <div class="back-user" routerLink="/profile/parents/subusers"><img src="/assets/images/icons/chevron-down-outline 2.svg" alt="" />Users</div>
  <article class="contentWrapper">
    <div class="info">
      <div class="avatar-section-container">
        <div class="ava">
          <ng-lottie *ngFor="let ava of avatars" class="ava-img" (click)="openUpdateAvatarPopup()" [options]="{ path: ava }"> </ng-lottie>
          <div (click)="openUpdateAvatarPopup()" class="pencil"></div>
        </div>

        <div class="lock-switch-section">
          <div class="lock-switch-container">
            <span [class.active]="!isChecked" (click)="unlock(false)">Lock</span>
            <span [class.active]="isChecked" (click)="unlock(true)">Unlock</span>
          </div>
          <div class="lock-info">
            <span class="icon" (click)="changeToolTip($event)"><img src="/assets/images/icons/circle-info-2.svg" alt="" /></span>
            <span>Lock/Unlock Lessons</span>
            <div class="tooltip-triangle" *ngIf="displayTooltip">
              <img src="/assets/images/icons/tooltip-triangle.svg" alt="" />
            </div>
            <div class="tooltip-2" *ngIf="displayTooltip">{{ 'CHILD.LOCK' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="text">
        <form>
          <div class="form-fields-container full-width">
            <div class="form-field-container">
              <label>Name or Nickname<span class="required">*</span></label>
              <input type="text" disabled value="{{ (subuser$ | async)?.name }}" />
            </div>
          </div>

          <div class="form-fields-container full-width">
            <div class="form-field-container">
              <label>{{ 'PROFILE.AGE' | translate }} <span class="required">*</span></label>
              <input type="text" disabled value="{{ ageRange }} {{ 'CHILD.AGE' | translate }}" />
            </div>
          </div>
        </form>

        <div class="update-button-container">
          <button class="blue-btn" (click)="openEditSubuserDialog(subuser)">Edit</button>
        </div>
      </div>
    </div>

    <div class="languages-section">
      <div class="language-heading">{{ 'CHILD.LANGUAGES' | translate }}</div>
      <div class="languages">
        <div class="language-container" *ngFor="let course of (subuser$ | async)?.courses">
          <span class="flag"><img src="/assets/images/languages/{{ course.slug }}.png" alt="" /></span>
          <span class="name">{{ formatLangName(course) }}</span>
          <span class="close" (click)="removeCourse(course.id)"><img src="/assets/images/icons/xmark-solid-2.svg" alt="" /></span>
        </div>
      </div>
    </div>

    <div class="subscriptions-section">
      <div class="subscriptions-form-container" *ngIf="(subuser$ | async)?.courses.length < 6">
        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label>Manage Language Courses<span class="required">*</span></label>

            <mat-select [(value)]="selectedCourse" placeholder="{{ 'PLACEHOLDERS.CLASSROOM_COURSE' | translate }}">
              <mat-option>None</mat-option>
              <ng-container *ngFor="let course of courses$ | async">
                <mat-option [value]="course.id" *ngIf="course.active">
                  {{ formatLangName(course) }}
                  <img class="langSelectImg" src="/assets/images/languages/{{ course.slug }}.png" alt="select {{ course.slug }} language" />
                </mat-option>
              </ng-container>
            </mat-select>
          </div>
        </div>

        <div class="submit-button">
          <button class="grey-btn" [disabled]="selectedCourse == undefined" (click)="addCourse(selectedCourse)">ADD</button>
        </div>
      </div>
      <div class="buy-new-language">
        <p class="bold">You can add up to 6 language courses</p>
      </div>
    </div>
  </article>
</section>
