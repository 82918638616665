import { state } from '@angular/animations'
import { createSelector } from '@ngrx/store'
import { IAppState } from '../state/app.state'
import { IPaymentState } from '../state/payment.state'

const paymentState = (state: IAppState) => state.payment

export const selectPlan = createSelector(paymentState, (state: IPaymentState) => state.plan)

export const selectCustomer = createSelector(paymentState, (state: IPaymentState) => state.customer)

export const selectPlans = createSelector(paymentState, (state: IPaymentState) => state.plans)

export const selectPaymentMethod = createSelector(paymentState, (state: IPaymentState) => state.paymentMethod)

// export const selectInvoices = createSelector(
//   paymentState,
//   (state: IPaymentState) => state.invoicesPdf,
// );
