import { NgModule } from '@angular/core'
import { RestorePasswordComponent } from './restore-password.component'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatStepperModule } from '@angular/material/stepper'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LocalizationModule } from "../../../shared/components/localization/localization.module";

@NgModule({
    declarations: [RestorePasswordComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, MatStepperModule, MatButtonModule, RouterModule, TranslateModule, SoundsModule, LocalizationModule]
})
export class RestorePasswordModule {}
