import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { IBook, IBookCompleteRequest, IBookCompleteResponse, IBooksRequest, IBooksResponse } from 'src/app/models/Book.model'
import { BooksService } from 'src/app/services/books.service'
import { NotificationService } from 'src/app/services/notification.service'
import { CompleteBook, CompleteBookSuccess, EBooksActions, GetBook, GetBooks, GetBooksSuccess, GetBookSuccess } from '../actions/books.actions'
import { selectCurrentSubuser } from '../selectors/subuser.selectors'
import { IAppState } from '../state/app.state'

@Injectable()
export class BooksEffects {
  getBooks$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetBooks>(EBooksActions.GetBooks),
      withLatestFrom(this._store$.pipe(select(selectCurrentSubuser))),
      map(([action, subuser]) => {
        const { payload } = action
        const subuserId = subuser.id
        return { ...payload, subuserId }
      }),
      mergeMap((request: IBooksRequest) => this._booksService.getBooks(request)),
      mergeMap((books: IBooksResponse) => of(new GetBooksSuccess(books))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getBook$: Observable<GetBookSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetBook>(EBooksActions.GetBook),
      map((action: GetBook) => action.payload),
      mergeMap((request: number) => this._booksService.getBook(request)),
      mergeMap((book: IBook) => of(new GetBookSuccess(book))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  completeBook$: Observable<CompleteBookSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<CompleteBook>(EBooksActions.CompleteBook),
      map((action: CompleteBook) => action.payload),
      mergeMap((request: IBookCompleteRequest) => this._booksService.completeBook(request)),
      mergeMap((response: IBookCompleteResponse) => of(new CompleteBookSuccess(response))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  constructor(private _actions$: Actions, private _store$: Store<IAppState>, private _booksService: BooksService, private _notificationService: NotificationService) {}
}
