<section class="loginWrapper">
  <div class="header">
    <div class="titleWrapper">
      <a routerLink="{{ getURLLangPart() }}/">
        <img src="assets/images/logos/logo-wy.svg" class="logo" alt="DinoLingo Logo" />
      </a>
    </div>
    <localization></localization>
  </div>
  <div class="content">
    <div class="details">
      <form [formGroup]="loginFormGroup" class="loginForm">
        <h1 class="baloo">{{ 'PROFILE.LOGIN_PT' | translate }}</h1>

        <mat-progress-bar mode="query" [value]="100" class="mat-progress-bar-buffer" *ngIf="loading"></mat-progress-bar>

        <div class="inputGroup">
          <input matInput type="email" required placeholder="{{ 'PROFILE.EMAIL' | translate }}" formControlName="email" />
          <ng-container *ngIf="(email.touched || email.dirty) && email.errors">
            <mat-error *ngIf="email.errors.required">
              {{ 'PROFILE.EMAIL_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="email.errors.email">
              {{ 'PROFILE.EMAIL_ERROR' | translate }}
            </mat-error>
          </ng-container>
        </div>

        <div class="inputGroup">
          <input matInput type="password" [type]="hidePassword ? 'password' : 'text'" required placeholder="{{ 'PROFILE.PASSWORD' | translate }}" formControlName="password" />
          <div class="hide">
            <mat-icon (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </div>
          <ng-container *ngIf="(password.touched || password.dirty) && password.errors">
            <mat-error *ngIf="password.errors.required">
              {{ 'PROFILE.PASSWORD_REQUIRED' | translate }}
            </mat-error>
          </ng-container>
        </div>

        <div class="inputGroup nolabel">
          <button appSounds mat-raised-button class="login-btn" type="submit" (click)="login(loginFormGroup)" [disabled]="loginFormGroup.invalid" color="accent">
            {{ 'BUTTONS.LOGIN' | translate }}
          </button>
        </div>

        <div class="inputGroup nolabel">
          <button appSounds mat-raised-button class="login-btn uppercase" type="submit" (click)="studentLogin()" color="accent">
            {{ 'BUTTONS.LOGIN_STUDENT' | translate }}
          </button>
        </div>

        <div class="inputGroup nolabel">
          <a appSounds mat-raised-button class="signup-btn" routerLink="{{ getURLLangPart() }}/auth/registration">
            {{ 'PROFILE.ACCOUNT_CREATE_FREE' | translate }}
          </a>
        </div>

        <p>
          <a routerLink="{{ getURLLangPart() }}/auth/restore-password">{{ 'PROFILE.PASSWORD_ERROR' | translate }}</a>
        </p>
        <p>{{ 'PROFILE.CANT_LOGIN' | translate }}</p>
        <p>
          <a target="_blank" href="https://wp.dinolingo.com/login/">{{ 'PROFILE.LOGIN_TO_OLD_SITE' | translate }}</a>
        </p>
      </form>
    </div>
    <div class="dino-help">
      <img src="assets/images/pictures/payment/dino-full.svg" />
      <a target="_blank" href="https://help.dinolingo.com/">
        {{ 'PROFILE.HELP' | translate }}
      </a>
    </div>
  </div>
</section>
