import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GradebookStudentComponent } from './gradebook.component'
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { MatSortModule } from '@angular/material/sort'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { ExportGradebookDialogModule } from '../../../../../shared/popups/Export-Dialogs/export-gradebook/export-gradebook.module'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [GradebookStudentComponent],
  imports: [CommonModule, MatTableModule, MatSortModule, MatIconModule, MatButtonModule, ExportGradebookDialogModule, TranslateModule],
  exports: [GradebookStudentComponent],
})
export class GradebookModule {}
