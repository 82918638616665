import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SidebarComponent } from './sidebar.component'
import { TranslateModule } from '@ngx-translate/core'
import { RouterModule } from '@angular/router'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { FormsModule } from '@angular/forms'
import { SoundsModule } from '../../../../helpers/directives/sounds-directive/sounds.module'
import { MatExpansionModule } from '@angular/material/expansion'

@NgModule({
  declarations: [SidebarComponent],
  imports: [CommonModule, TranslateModule, RouterModule, MatSidenavModule, MatIconModule, MatButtonModule, FormsModule, SoundsModule, MatExpansionModule],
  exports: [SidebarComponent],
})
export class SidebarModule {}
