import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReviewStarsComponent } from './review-stars.component'
import { NgxJsonLdModule } from '@ngx-lite/json-ld'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [ReviewStarsComponent],
  exports: [ReviewStarsComponent],
  imports: [CommonModule, NgxJsonLdModule, TranslateModule],
})
export class ReviewStarsModule {}
