import { Action } from '@ngrx/store'
import { ISubuser, ISubuserRequest, ISubuserCourseRequest } from 'src/app/models/Subuser.model'

export enum ESubuserActions {
  SelectSubuserOpenDialog = '[Subuser] Select Subuser Open Dialog',
  SelectSubuser = '[Subuser] Select subuser',
  SelectSubuserSuccess = '[Subuser] Select subuser success',
  SelectSubuserFailure = '[Subuser] Select subuser failure',
  AddSubuserOpenDialog = '[Subuser] Add Subuser Open Dialog',
  AddSubuser = '[Subuser] Add Subuser',
  AddSubuserSuccess = '[Subuser] Add Subuser Success',
  AddSubuserFailure = '[Subuser] Add Subuser Failure ',
  GetAllSubuser = '[Subuser] Get All Subuser',
  GetAllSubuserSuccess = '[Subuser] Get All Subuser Success',
  GetSubuser = '[Subuser] Get Subuser',
  GetSubuserSuccess = '[Subuser] Get Subuser Success',
  DeleteCourseFromSubuser = '[Subuser] Delete Course From Subuser',
  DeleteCourseFromSubuserSuccess = '[Subuser] Delete Course From Subuser Success',
  DeleteCourseFromSubuserFailure = '[Subuser] Delete Course From Subuser Failure',
  AddCourseToSubuser = '[Subuser] Add Course To Subuser',
  AddCourseToSubuserSuccess = '[Subuser] Add Course To Subuser Success',
  UpdateSubuser = '[Subuser] Update Subuser',
  UpdateSubuserSuccess = '[Subuser] Update Subuser Success',
  RemoveSubuser = '[Subuser] Remove Subuser',
  RemoveSubuserSuccess = '[Subuser] Remove Subuser Success',
  GetSubuserReport = '[Subuser] Get Subuser Report',
  GetSubuserReportSuccess = '[Subuser] Get Subuser Report Success',
  UpdateSubuserAvatar = '[Subuser] Update Subuser Avatar',
  UpdateSubuserAvatarSuccess = '[Subuser] Update Subuser Avatar Success',
  RemoveSubuserStatistic = '[Subuser] Remove Subuser Statistic',
  RemoveSubuserStatisticSuccess = '[Subuser] Remove Subuser Statistic Success',
  SetCurrentUnit = '[Subuser] Set Current Unit',
  SetCurrentUnitSuccess = '[Subuser] Set Current Unit Success',
  SetCurrentSubuserDetailedReports = '[Subuser] Set Current Subuser Detailed Reports',
  SetSubuserUnlockLessons = '[Subuser] Set Subuser Unlock Lessons',
  SetSubuserUnlockLessonsSuccess = '[Subuser] Set Subuser Unlock Lessons Success',
  GetAllSubuserCourses = '[Subuser] Get All Subuser Courses',
  GetAllSubuserCoursesSuccess = '[Subuser] Get All Subuser Courses Success',
  GetAllSubuserCoursesFailure = '[Subuser] Get All Subuser Courses Failure',
}
export class GetSubuserReport implements Action {
  public readonly type = ESubuserActions.GetSubuserReport
  constructor(public payload: any) {}
}

export class GetSubuserReportSuccess implements Action {
  public readonly type = ESubuserActions.GetSubuserReportSuccess
  constructor(public payload: any) {}
}

export class RemoveSubuserStatistic implements Action {
  public readonly type = ESubuserActions.RemoveSubuserStatistic
  constructor(public payload: any) {}
}

export class RemoveSubuserStatisticSuccess implements Action {
  public readonly type = ESubuserActions.RemoveSubuserStatisticSuccess
  constructor(public payload: any) {}
}

export class RemoveSubuser implements Action {
  public readonly type = ESubuserActions.RemoveSubuser
  constructor(public payload: string | number) {}
}

export class RemoveSubuserSuccess implements Action {
  public readonly type = ESubuserActions.RemoveSubuserSuccess
  constructor(public payload: any) {}
}

export class UpdateSubuser implements Action {
  public readonly type = ESubuserActions.UpdateSubuser
  constructor(public payload: ISubuser) {}
}

export class UpdateSubuserSuccess implements Action {
  public readonly type = ESubuserActions.UpdateSubuserSuccess
  constructor(public payload: any) {}
}

export class SetCurrentUnit implements Action {
  public readonly type = ESubuserActions.SetCurrentUnit
  constructor(public payload: any) {}
}

export class SetCurrentUnitSuccess implements Action {
  public readonly type = ESubuserActions.SetCurrentUnitSuccess
  constructor(public payload: any) {}
}

export class AddCourseToSubuser implements Action {
  public readonly type = ESubuserActions.AddCourseToSubuser
  constructor(public payload: ISubuserCourseRequest) {}
}

export class AddCourseToSubuserSuccess implements Action {
  public readonly type = ESubuserActions.AddCourseToSubuserSuccess
  constructor(public payload: any) {}
}

export class DeleteCourseFromSubuser implements Action {
  public readonly type = ESubuserActions.DeleteCourseFromSubuser
  constructor(public payload: ISubuserCourseRequest) {}
}

export class DeleteCourseFromSubuserSuccess implements Action {
  public readonly type = ESubuserActions.DeleteCourseFromSubuserSuccess
  constructor(public payload: any) {}
}

export class DeleteCourseFromSubuserFailure implements Action {
  public readonly type = ESubuserActions.DeleteCourseFromSubuserFailure
  constructor(public payload: any) {}
}

export class GetSubuser implements Action {
  public readonly type = ESubuserActions.GetSubuser
  constructor(public payload: number | string) {}
}

export class GetSubuserSuccess implements Action {
  public readonly type = ESubuserActions.GetSubuserSuccess
  constructor(public payload: ISubuser) {}
}
/**
 * We need to check if we have use this
 */
// export class AddSubuserOpenDialog implements Action {
//   public readonly type = ESubuserActions.AddSubuserOpenDialog;
// }

export class AddSubuser implements Action {
  public readonly type = ESubuserActions.AddSubuser
  constructor(public payload: ISubuserRequest) {}
}

export class AddSubuserSuccess implements Action {
  public readonly type = ESubuserActions.AddSubuserSuccess
  constructor(public payload: any) {}
}

export class AddSubuserFailure implements Action {
  public readonly type = ESubuserActions.AddSubuserFailure
  constructor(public payload: any) {}
}

export class SelectSubuser implements Action {
  public readonly type = ESubuserActions.SelectSubuser
  constructor(public payload: any) {}
}

export class SelectSubuserSuccess implements Action {
  public readonly type = ESubuserActions.SelectSubuserSuccess
  constructor(public payload: any) {}
}

export class SelectSubuserFailure implements Action {
  public readonly type = ESubuserActions.SelectSubuserFailure
  constructor(public payload: any) {}
}

export class GetAllSubuser implements Action {
  public readonly type = ESubuserActions.GetAllSubuser
}

export class GetAllSubuserSuccess implements Action {
  public readonly type = ESubuserActions.GetAllSubuserSuccess
  constructor(public payload: Array<ISubuser>) {}
}

export class UpdateSubuserAvatar implements Action {
  public readonly type = ESubuserActions.UpdateSubuserAvatar
  constructor(public payload: any) {}
}

export class UpdateSubuserAvatarSuccess implements Action {
  public readonly type = ESubuserActions.UpdateSubuserAvatarSuccess
  constructor(public payload: any) {}
}

export class SetCurrentSubuserDetailedReport implements Action {
  public readonly type = ESubuserActions.SetCurrentSubuserDetailedReports
  constructor(public payload: any) {}
}

export class SetSubuserUnlockLessons implements Action {
  public readonly type = ESubuserActions.SetSubuserUnlockLessons
  constructor(public payload: any) {}
}

export class SetSubuserUnlockLessonsSuccess implements Action {
  public readonly type = ESubuserActions.SetSubuserUnlockLessonsSuccess
  constructor(public payload: any) {}
}

export class GetAllSubuserCourses implements Action {
  public readonly type = ESubuserActions.GetAllSubuserCourses
  constructor() {}
}

export class GetAllSubuserCoursesSuccess implements Action {
  public readonly type = ESubuserActions.GetAllSubuserCoursesSuccess
  constructor(public payload: any) {}
}

export class GetAllSubuserCoursesFailure implements Action {
  public readonly type = ESubuserActions.GetAllSubuserCoursesFailure
  constructor(public payload: any) {}
}

export type SubuserActions =
  | SelectSubuser
  | SelectSubuserSuccess
  | SelectSubuserFailure
  | AddSubuser
  | AddSubuserSuccess
  | AddSubuserFailure
  | GetAllSubuser
  | GetAllSubuserSuccess
  | GetSubuser
  | GetSubuserSuccess
  | AddCourseToSubuser
  | AddCourseToSubuserSuccess
  | UpdateSubuser
  | UpdateSubuserSuccess
  | RemoveSubuser
  | RemoveSubuserSuccess
  | DeleteCourseFromSubuserFailure
  | GetSubuserReport
  | GetSubuserReportSuccess
  | UpdateSubuserAvatar
  | RemoveSubuserStatistic
  | RemoveSubuserStatisticSuccess
  | SetCurrentUnit
  | SetCurrentUnitSuccess
  | SetCurrentSubuserDetailedReport
  | SetSubuserUnlockLessons
  | SetSubuserUnlockLessonsSuccess
  | GetAllSubuserCourses
  | GetAllSubuserCoursesSuccess
  | GetAllSubuserCoursesFailure
