import { ICourse } from '../../models/Course.model'

export interface ICourseState {
  courses: Array<ICourse>
  selectedCourse: ICourse
  // currentUnit: any;
}

export const InitialCoursesState: ICourseState = {
  courses: null,
  selectedCourse: null,
  // currentUnit: null
}
