import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog'

export class DefaultPopupSettings extends MatDialogConfig {
  closeOnNavigation = true
  hasBackdrop: true
  width: '50rem'
  height: '32rem'

  constructor(data?: any) {
    super()
    this.data = data
  }

  noClose() {
    this.disableClose = true
    return this
  }
}
