<div class="adjust-grade">
  <div class="actions">
    <span class="title">{{ 'GRADEBOOK.ADJUSTMENT.TITLE' | translate }} </span>
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>
  <div class="content">
    <div class="form" [formGroup]="form">
      <div class="completed" *ngIf="!showScore">
        <button mat-raised-button color="primary" (click)="markAsComplete(true)">{{ 'GRADEBOOK.ADJUSTMENT.MARK_COMPLETE' | translate }}</button>
        <button mat-raised-button color="accent" (click)="markAsComplete(false)">{{ 'GRADEBOOK.ADJUSTMENT.MARK_INCOMPLETE' | translate }}</button>
      </div>
      <div class="score" *ngIf="showScore">
        <mat-form-field>
          <mat-label>{{ 'GRADEBOOK.ADJUSTMENT.SCORE' | translate }}</mat-label>
          <input matInput formControlName="score" type="text" [value]="score.toString()" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="save" *ngIf="showScore">
    <button mat-raised-button color="accent" (click)="adjustGrade(form)">
      {{ 'GRADEBOOK.ADJUSTMENT.CONFIRM' | translate }}
    </button>
  </div>
</div>
