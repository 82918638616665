import { NgModule } from '@angular/core'
import { SoundsDirective } from './sounds.directive'
/**
 *  The module allows to use the directive
 *  for the whole project
 */
@NgModule({
  declarations: [SoundsDirective],
  exports: [SoundsDirective],
})
export class SoundsModule {}
