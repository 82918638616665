import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators'
import { NotificationService } from 'src/app/services/notification.service'
import { IStudent, IStudentRequest } from '../../models/Student.model'
import { StudentService } from '../../services/student.service'
import {
  AddStudent,
  AddStudentFailure,
  AddStudentSuccess,
  EStudentActions,
  GetCurrentStudent,
  GetCurrentStudentFailure,
  GetCurrentStudentSuccess,
  GetStudents,
  GetStudentsFailure,
  GetStudentsForClassroom,
  GetStudentsForClassroomFailure,
  GetStudentsForClassroomSuccess,
  GetStudentsSuccess,
  RemoveStudent,
  RemoveStudentFailure,
  RemoveStudentSuccess,
  UpdateStudent,
  UpdateStudentFailure,
  UpdateStudentSuccess,
} from '../actions/students.actions'
import { IAppState } from '../state/app.state'

@Injectable()
export class StudentsEffects {
  constructor(private _actions$: Actions, private _studentService: StudentService, private _store: Store<IAppState>, private _notificationService: NotificationService) {}

  addStudent$: Observable<AddStudentSuccess | AddStudentFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddStudent>(EStudentActions.AddStudent),
      map((action: AddStudent) => action.payload),
      switchMap((payload: IStudentRequest) => {
        return this._studentService.createStudent(payload)
      }),
      exhaustMap((student: IStudent) => of(new AddStudentSuccess(student))),
      catchError((error) => {
        this._notificationService.showNotificationError(error.error.clientMessage, 2)
        return of(new AddStudentFailure(error))
      })
    )
  )

  updateStudent$: Observable<UpdateStudentSuccess | UpdateStudentFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateStudent>(EStudentActions.UpdateStudent),
      switchMap((action: UpdateStudent) => {
        return this._studentService.updateStudent(action.student, action.payload)
      }),
      exhaustMap((student: IStudent) => of(new UpdateStudentSuccess(student))),
      catchError((error) => {
        this._notificationService.showNotificationError(error.error.clientMessage, 2)
        return of(new UpdateStudentFailure(error))
      })
    )
  )

  removeStudent$: Observable<RemoveStudentSuccess | RemoveStudentFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveStudent>(EStudentActions.RemoveStudent),
      switchMap((action: RemoveStudent) => this._studentService.removeStudent(action.payload)),
      switchMap((removedStudent: any) => {
        return [new RemoveStudentSuccess(removedStudent)]
      }),
      catchError((error) => {
        this._notificationService.showNotificationError(error.error.clientMessage, 2)
        return of(new RemoveStudentFailure(error))
      })
    )
  )

  getStudentsForClassroom$: Observable<GetStudentsForClassroomSuccess | GetStudentsForClassroomFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentsForClassroom>(EStudentActions.GetStudentsForClassroom),
      mergeMap((action) => {
        return this._studentService.getStudentsForClassroom(action.schoolId, action.classroomId).pipe(
          map((students: Array<IStudent>) => new GetStudentsForClassroomSuccess(students)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetStudentsForClassroomFailure(error))
          })
        )
      })
    )
  )

  getCurrentStudent: Observable<GetCurrentStudentSuccess | GetCurrentStudentFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetCurrentStudent>(EStudentActions.GetCurrentStudent),
      switchMap(() => {
        return this._studentService.getCurrentStudent().pipe(
          map((student: IStudent) => new GetCurrentStudentSuccess(student)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetCurrentStudentFailure(error))
          })
        )
      })
    )
  )

  getStudents: Observable<GetStudentsSuccess | GetStudentsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudents>(EStudentActions.GetStudents),
      mergeMap((action) => {
        return this._studentService.getStudents(action.schoolId).pipe(
          map((students: Array<IStudent>) => new GetStudentsSuccess(students)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetStudentsFailure(error))
          })
        )
      })
    )
  )
}
