import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { TutorialsComponent } from './tutorials.component'
import { VideoPopupModule } from 'src/app/shared/popups/VideoPopup/videopopup.module'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'

@NgModule({
  imports: [CommonModule, TranslateModule, VideoPopupModule, MatButtonModule, MatInputModule, MatFormFieldModule, MatIconModule],
  declarations: [TutorialsComponent],

  providers: [],
})
export class TutorialsModule {}
