<div class="achieve">
  <div class="actions">
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
    <div class="title">{{ 'ACHIVEMENTS.TITLE' | translate }}</div>
  </div>

  <div class="verticalContainer scroll-modern">
    <div class="inner-box">
      <div class="item" *ngFor="let achievement of achievements$ | async">
        <img *ngIf="achievement.subuserAchievements.length" [src]="achievement.src" alt="{{ achievement.src }}" />
        <img *ngIf="achievement.length" [src]="achievement.src" alt="{{ achievement.src }}" />
        <img *ngIf="achievement.userAchievements?.length" [src]="achievement.src" alt="{{ achievement.src }}" />
        <img *ngIf="!achievement.subuserAchievements.length && !achievement.userAchievements?.length" [src]="achievement.ghostSrc" alt="{{ achievement.ghostSrc }}" />
        <div>
          <span class="title">{{ achievement.name }}</span>
          <span class="title-description">{{ achievement.description }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="image-direction"><img src="/assets/images/icons/Group 2.svg" /></div>
</div>
