import { Action } from '@ngrx/store'

export enum ESchoolActions {
  GetSchool = '[School] Get School',
  GetSchoolSuccess = '[School] Get School Success',
  GetSchoolFailure = '[School] Get School Failure',
  UpdateNumberAllocableStudents = '[School] Update Number Allocable Students',
  AddAllocableStudents = '[School] Add Allocable Students',
  SubtractAllocableStudents = '[School] Subtract Allocable Students',
}

export class GetSchool implements Action {
  public readonly type = ESchoolActions.GetSchool
  constructor(public schoolId: number) {}
}

export class GetSchoolSuccess implements Action {
  public readonly type = ESchoolActions.GetSchoolSuccess
  constructor(public payload: any) {}
}

export class GetSchoolFailure implements Action {
  public readonly type = ESchoolActions.GetSchoolFailure
  constructor(public payload: any) {}
}

export class UpdateNumberAllocableStudents implements Action {
  public readonly type = ESchoolActions.UpdateNumberAllocableStudents
  constructor(public newNumber: number) {}
}

export class AddAllocableStudents implements Action {
  public readonly type = ESchoolActions.AddAllocableStudents
  constructor(public number: number) {}
}

export class SubtractAllocableStudents implements Action {
  public readonly type = ESchoolActions.SubtractAllocableStudents
  constructor(public number: number) {}
}

export type SchoolActions = GetSchool | GetSchoolSuccess | GetSchoolFailure | UpdateNumberAllocableStudents | AddAllocableStudents | SubtractAllocableStudents
