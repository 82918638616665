<div class="container pin">
  <div class="closeBlock">
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>
  <div class="checkParentPin">
    <div class="userInfo">
      <img class="avatar" *ngIf="(user$ | async)?.thumbnail" [src]="(user$ | async)?.thumbnail.src" [alt]="(user$ | async)?.thumbnail.name" />
      <span>{{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}</span>
    </div>
  
    <mat-form-field *ngIf="user.pin" color="accent" autocomplete="off">
      <mat-label>{{ 'PARENT_PIN.TITLE' | translate }}</mat-label>
      <input [(ngModel)]="pin" maxlength="4" type="password" matInput placeholder="1234" autocomplete="new-password" name="nonono" />
      <button mat-button *ngIf="pin" matSuffix mat-icon-button aria-label="Clear" (click)="pin = ''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  
    <div class="pin" *ngIf="user.pin">
      <button class="numberButtons" *ngFor="let button of buttons" (click)="addValue(button)" color="accent" mat-icon-button>
        {{ button }}
      </button>
    </div>
  </div>
  
  <div class="goToDashboard">
    <button (click)="checkPin()" mat-raised-button color="accent">
      {{ buttonText | translate }}
    </button>
  </div>
</div>

