import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { TranslateModule } from '@ngx-translate/core'
import { ExportReportsDialogComponent } from './export-reports.component'

@NgModule({
  declarations: [ExportReportsDialogComponent],
  imports: [CommonModule, TranslateModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatDatepickerModule],
})
export class ExportReportsDialogModule {}
