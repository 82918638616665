import { NgModule } from '@angular/core'
import { MenuPopupComponent } from './menu.component'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [MenuPopupComponent],
  imports: [RouterModule, CommonModule, MatButtonModule, TranslateModule],
})
export class MenuPopupModule {}
