import { Injectable } from '@angular/core'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _snackBar: MatSnackBar) {}

  public showNotification(text: string, duration: number) {
    this._snackBar.open(text, null, {
      duration: duration * 1000,
      horizontalPosition: 'center',
      panelClass: 'successNotificationMessage',
    })
  }

  public showNotificationError(errorText: string, duration: number) {
    this._snackBar.open(errorText, null, {
      duration: duration * 1000,
      horizontalPosition: 'center',
      panelClass: 'errorNotificationMessage',
    })
  }
}
