import { Component, Inject } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { TranslateService } from '@ngx-translate/core'

@Component({
  templateUrl: 'lesson-unavailable.component.html',
  styleUrls: ['lesson-unavailable.component.scss'],
})
export class LessonUnavailablePopupComponent {
  constructor(public dialogRef: MatDialogRef<LessonUnavailablePopupComponent>, public translate: TranslateService) {}

  close() {
    this.dialogRef.close()
  }
}
