import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { IAppState } from 'src/app/store/state/app.state'
import { Store, select } from '@ngrx/store'
import { GetThumbnails } from 'src/app/store/actions/thumbnails.actions'
import { selectAllThumbnails } from 'src/app/store/selectors/thumbnails.selectors'
import { TranslateService } from '@ngx-translate/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { AnimationItem } from 'lottie-web'

@Component({
  templateUrl: 'avatar-select.component.html',
  styleUrls: ['avatar-select.component.scss'],
})
export class AvatarSelectPopupComponent implements OnInit, OnDestroy {
  constructor(public dialogRef: MatDialogRef<AvatarSelectPopupComponent>, private store: Store<IAppState>, public translate: TranslateService, private ngZone: NgZone) {}

  public animationItem: AnimationItem[] = []

  public unsubscribe$ = new Subject()
  public thumbnails$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectAllThumbnails))

  ngOnInit() {
    this.store.dispatch(new GetThumbnails())
  }

  selectAvatar(avatar) {
    this.dialogRef.close(avatar)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  public playAnim(index) {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem[index].play()
    })
  }

  public stopAnim(index) {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem[index].pause()
    })
  }

  public animationCreated(animationItem: AnimationItem, index): void {
    this.animationItem[index] = animationItem
  }

  public isLottie(url) {
    if (url.includes('.json')) {
      return true
    }
    return false
  }
}
