import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core'
import { ISubuser } from 'src/app/models/Subuser.model'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { selectStatistics, selectStars } from 'src/app/store/selectors/statistics.selectors'
import { selectCurrentSubuser, selectAllSubuser } from 'src/app/store/selectors/subuser.selectors'
import { selectCurrentCourse } from 'src/app/store/selectors/courses.selectors'
import { IUser } from 'src/app/models/User.model'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { SelectSubuser } from 'src/app/store/actions/subuser.actions'
import { GetStatistics } from 'src/app/store/actions/statistics.actions'
import { NavigationEnd, Router } from '@angular/router'
import { selectCourseLessons, selectDisplayHeader } from 'src/app/store/selectors/lessons.selectors'
import { getHeaderValue, getDropdownOpen } from 'src/app/store/selectors/header.selectors'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs'
import { filter, map, switchMap, takeUntil } from 'rxjs/operators'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { MenuPopupComponent } from '../../popups/Control-Dialogs/menu/menu.component'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { CheckParentPinComponent } from '../../popups/Parent-Dialogs/checkParentPin/checkParentPin.component'
import { ConfirmationPopupComponent } from '../../popups/Control-Dialogs/confirmation/confirmation.component'
import { AuthService } from 'src/app/services/auth.service'
import { SetDropdownOpen } from 'src/app/store/actions/header.actions'
import { EStudentActions, GetCurrentStudent, GetCurrentStudentSuccess } from '../../../store/actions/students.actions'
import { Actions, ofType } from '@ngrx/effects'
import { IStudent } from '../../../models/Student.model'
import { StudentAssignmentsDialogComponent } from '../../popups/Assignment-Dialogs/student-assignments/student-assignments.component'
import { FormatTextService } from '../../../services/formatText.service'
import { environment } from 'src/environments/environment'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  switch = true
  subuserDropdown = false
  starsPanel = false
  profileMenu = false
  public hidden = false
  public units$: Observable<any>
  @Input() user: IUser
  @Output() changeSubuser = new EventEmitter()
  @Input() subuser: ISubuser
  @Output() achievements = new EventEmitter()
  @Output() stars = new EventEmitter()
  @Output() progress = new EventEmitter()
  @Output() dinosaurs = new EventEmitter()
  @Output() courses = new EventEmitter()
  public hclass: String = ''
  public unsubscribe$ = new Subject()
  public _user: any
  public userType: String
  public timerId
  public currentCourse: any
  public mobileMenuOpened = false
  public type
  public student: IStudent
  public firstView = true
  public courseName = ''
  private subscription: Subscription

  constructor(
    private _store: Store<IAppState>,
    private _actions$: Actions,
    public _dialog: MatDialog,
    public router: Router,
    public translate: TranslateService,
    public _authService: AuthService,
    private formatTextService: FormatTextService,
    public subUserStoreService: SubUserStoreService,
    private _cdRef: ChangeDetectorRef
  ) {

    this.subscription = router.events.subscribe((val) => {
      if (!this.student) return

      if (val instanceof NavigationEnd) {
        if (val.url === '/profile/lessons') {
          this.loadStudentAssignments()
        }
      }
    })
  }

  public statistics$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectStatistics))
  public headerValue$ = this._store.pipe(takeUntil(this.unsubscribe$), select(getHeaderValue))
  public course$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentCourse))
  public subuser$: ISubuser
  public avatars = []
  public subusers$
  public subuserStars$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectStars))
  public displayHeader$

  /* Display header only if subuser exist */
  public subuser2 = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)).subscribe((subuser: ISubuser) => {
    if (subuser) {
      this.timerId = setTimeout(() => {
        this.hclass = 'display'
      }, 1000)
    }
  })

  public dropdownOpen: any

  ngOnInit() {
    document.addEventListener('click', () => {
      this._store.dispatch(new SetDropdownOpen({ starsPanel: false }))
      this._store.dispatch(new SetDropdownOpen({ profileMenu: false }))
    })

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectAllSubuser)).subscribe((subuser) => {
      this.subusers$ = subuser
    })

    combineLatest([this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)), this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser))])
      .pipe(
        filter(([user, subuser]) => !!user && !!subuser),
        switchMap(([user, subuser]) => {
          if (subuser.studentId !== null) {
            if (!this.student) {
              this.loadStudentAssignments()
            }

            return this._actions$.pipe(
              takeUntil(this.unsubscribe$),
              ofType<GetCurrentStudentSuccess>(EStudentActions.GetCurrentStudentSuccess),
              map((payload: GetCurrentStudentSuccess) => {
                return {
                  user,
                  subuser,
                  student: payload.student,
                  type: 'student',
                }
              })
            )
          }

          return of({
            user,
            subuser,
            student: null,
            type: 'subuser',
          })
        })
      )
      .subscribe(({ user, subuser, student, type }) => {
        this.student = student
        this.type = type

        if (user) {
          this._user = user.user
          this.userType = user.userType
        }

        if (this.student) {
          if (this.firstView && this.student.incompleteAssignments > 0) {
            this.firstView = false
            if (this.router.url !== '/profile/parents') {
              this.openStudentAssignmentsDialog()
            }
          }
        }

        this.subuser$ = subuser

        if (!['teacherAdmin', 'teacher'].includes(user.userType)) {
          this.subusers$.forEach((element) => {
            if (element.id == this.subuser$.id) {
              this.avatars.unshift(element.avatar.src)
            }
          })
        }
      })

    if (this.student) {
      if (this.student.incompleteAssignments > 0) {
        if (this.router.url !== '/profile/parents') {
          this.openStudentAssignmentsDialog()
        }
      }
    }

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectDisplayHeader)).subscribe((result) => {
      this.displayHeader$ = result

      this._cdRef.detectChanges()
    })

    if (this.subUserStoreService.currentLanguage) {
      this.translate.use(this.subUserStoreService.currentLanguage)
    }

    combineLatest([this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)), this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentCourse))])
      .pipe(filter(([subuser, course]) => !!subuser && !!course))
      .subscribe(([subuser, course]) => {
        this._store.dispatch(
          new GetStatistics({
            subuserId: subuser.id,
            courseId: course.id,
          })
        )
      })

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCourseLessons)).subscribe((courseWithUnits) => {
      if (courseWithUnits) {
        this.currentCourse = courseWithUnits.courseWithUnit
        this.courseName = this.formatCourseName(this.currentCourse.units[0]?.name)
      }
    })

    this._store.pipe(takeUntil(this.unsubscribe$), select(getDropdownOpen)).subscribe((dropdownOpen) => {
      this.starsPanel = dropdownOpen.starsPanel
      this.profileMenu = dropdownOpen.profileMenu
    })
  }

  ngOnDestroy(): void {
    clearTimeout(this.timerId)

    this.unsubscribe$.next()

    this.unsubscribe$.complete()

    this.subscription.unsubscribe()
  }

  loadStudentAssignments() {
    this._store.dispatch(new GetCurrentStudent())
  }

  public formatLangName(course) {
    return this.formatTextService.formatLangName(course)
  }

  public formatCourseName(name) {
    return this.formatTextService.formatMultiName(name)
  }

  openDialogMenu() {
    this._dialog.open(MenuPopupComponent, {
      width: '22rem',
      height: '46rem',
      panelClass: 'menuDialog',
    })
  }

  setMunuStatus($event) {
    this.mobileMenuOpened = !!$event.target.checked
  }

  mobileMenu() {
    this.switch = !this.switch
  }

  selectSubuser(subuser) {
    this.subuserDropdown = false
    this.subUserStoreService.currentSubuser = JSON.stringify(subuser)

    const currentSubuser = JSON.parse(this.subUserStoreService.currentSubuser)
    return this._store.dispatch(new SelectSubuser(currentSubuser))
  }

  openSelectUserPopup() {
    this.checkParentPin(() => this.changeSubuser.emit(this.user))
  }

  openSelectCoursePopup() {
    this.checkParentPin(() => this.courses.emit(true))
  }

  openSubuserDropdown(event: Event) {
    let action = () => {
      this.subuserDropdown = !this.subuserDropdown
      event.stopPropagation()
    }

    if (!this.subuserDropdown) {
      return this.checkParentPin(action)
    }

    action()
  }

  checkParentPin(callback = null) {
    if (this._user.pin) {
      const dialog = this._dialog.open(CheckParentPinComponent, {
        closeOnNavigation: true,
        disableClose: true,
        hasBackdrop: true,
        data: 'BUTTONS.CONFIRM',
      })

      dialog.afterClosed().subscribe((result) => {
        if (result && callback) {
          callback()
        }
      })
    } else if (callback) {
      callback()
    }
  }

  public openReportPopup() {
    this._dialog.open(ReportProblemPopupComponent, {
      panelClass: 'medium-adaptive-popup',
    })
  }

  public isLottie(url) {
    if (url.includes('.json')) {
      return true
    }

    return false
  }

  openStarsPanel(event: Event) {
    this._store.dispatch(new SetDropdownOpen({ starsPanel: true }))
    event.stopPropagation()
  }

  openProfileMenu(event: Event) {
    this._store.dispatch(new SetDropdownOpen({ profileMenu: true }))
    event.stopPropagation()
  }

  openCurriculumDialog() {
    this.checkParentPin(() => this.router.navigate(['/profile/lesson-plan']))
  }

  openStudentAssignmentsDialog() {
    if (!this.student) return

    this._dialog.open(StudentAssignmentsDialogComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      data: {
        schoolId: this.student.classroom.schoolId,
        classroomId: this.student.classroom.id,
        student: this.student,
      },
    })
  }

  profileLink(route: string) {
    this.checkParentPin(() => {
      this.router.navigate([route])
    })
  }

  logout() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.LOG_OUT',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      this._authService.logout()
      window.location.href = environment.logoutRedirectUrl
    })
  }
}
