import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { AddClassroom, AddClassroomSuccess, EClassroomActions } from '../../../../store/actions/classrooms.actions'
import { GetAllCourses } from '../../../../store/actions/courses.actions'
import { GetTeachers } from '../../../../store/actions/teachers.actions'
import { selectAllCourses } from '../../../../store/selectors/courses.selectors'
import { selectTeachers } from '../../../../store/selectors/teachers.selectors'
import { selectCurrentUser } from '../../../../store/selectors/user.selectors'
import { IAppState } from '../../../../store/state/app.state'

@Component({
  templateUrl: './add-classroom.component.html',
  styleUrls: ['./add-classroom.component.scss'],
})
export class AddClassroomDialogComponent implements OnInit, OnDestroy {
  public classroomForm: UntypedFormGroup
  public courses
  public teachers
  public loaded = false
  private destroyed$ = new Subject<void>()
  public displayTooltip = false

  constructor(private updates$: Actions, private _dialog: MatDialog, public dialogRef: MatDialogRef<AddClassroomDialogComponent>, @Inject(MAT_DIALOG_DATA) public school: any, private _store: Store<IAppState>, public translate: TranslateService) {
    this.updates$.pipe(ofType<AddClassroomSuccess>(EClassroomActions.AddClassroomSuccess), takeUntil(this.destroyed$)).subscribe((res: AddClassroomSuccess) => {
      this.dialogRef.close(res)
    })
  }

  ngOnInit() {
    this._store.dispatch(new GetAllCourses())
    this._store.dispatch(new GetTeachers(this.school.id))

    combineLatest([this._store.pipe(takeUntil(this.destroyed$), select(selectCurrentUser)), this._store.pipe(takeUntil(this.destroyed$), select(selectAllCourses)), this._store.pipe(takeUntil(this.destroyed$), select(selectTeachers))])
      .pipe(filter(([user, courses, teachers]) => user !== null && courses !== null && teachers !== null))
      .subscribe(([user, courses, teachers]) => {
        this.loaded = true
        this.courses = courses
        this.teachers = teachers
        this.classroomForm = new UntypedFormGroup({
          teacher: new UntypedFormControl('', [Validators.required]),
          schoolId: new UntypedFormControl('', [Validators.required]),
          classroomName: new UntypedFormControl('', [Validators.required]),
          classroomCode: new UntypedFormControl({ value: '', disabled: true }),
          courseId: new UntypedFormControl('', [Validators.required]),
          allocatedStudents: new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.max(this.school.allocableStudents)]),
          lessonMode: new UntypedFormControl(''),
        })

        this.classroomForm.patchValue({
          schoolId: user.user.schoolId,
        })
      })
  }

  ngOnDestroy() {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  createClassroom(form: UntypedFormGroup) {
    if (form.invalid) {
      return
    }
    const request = form.value
    this._store.dispatch(new AddClassroom(request))
  }

  get allocableStudentRange() {
    const max = Math.max(0, this.school.allocableStudents)
    return `1 - ${max}`
  }

  public changeToolTip(event: Event) {
    this.displayTooltip = !this.displayTooltip
    event.stopPropagation()
  }
}
