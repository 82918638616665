import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ParentsComponent } from './parents.component'
import { ParentsSubusersModule } from './subusers/subusers.module'
import { ParentsNotificationsModule } from './notifications/notifications.module'
import { ParentsProfileModule } from './profile/profile.module'
import { SubuserModule } from './subuser/subuser.module'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { ProgressReportsModule } from '../progress-reports/progress-reports.module'
import { SidebarModule } from './sidebar/sidebar.module'
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { MatSortModule } from '@angular/material/sort'
import { GradebookModule } from './student/gradebook/gradebook.module'
import { LocalizationModule } from "../../../shared/components/localization/localization.module";

@NgModule({
    declarations: [ParentsComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ParentsSubusersModule,
        ParentsNotificationsModule,
        ParentsProfileModule,
        MatSidenavModule,
        MatIconModule,
        MatButtonModule,
        SubuserModule,
        TranslateModule,
        SoundsModule,
        ProgressReportsModule,
        SidebarModule,
        GradebookModule,
        MatTableModule,
        MatSortModule,
        LocalizationModule
    ]
})
export class ParentsModule {}
