import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GradebookComponent } from './gradebook.component'
import { TranslateModule } from '@ngx-translate/core'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { ExportGradebookDialogModule } from '../../../../shared/popups/Export-Dialogs/export-gradebook/export-gradebook.module'
import { AdjustGradeDialogModule } from '../../../../shared/popups/Assignment-Dialogs/adjust-grade/adjust-grade.module'

@NgModule({
  declarations: [GradebookComponent],
  imports: [CommonModule, TranslateModule, MatFormFieldModule, MatSelectModule, MatButtonModule, ExportGradebookDialogModule, AdjustGradeDialogModule],
  exports: [GradebookComponent],
})
export class GradebookModule {}
