import { ISchool } from '../../models/School.model'

export interface ISchoolState extends ISchool {}

export const InitialSchoolState: ISchoolState = {
  id: null,
  schoolName: null,
  allocableStudents: null,
  availableStudents: null,
  numStudents: null,
}
