import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { AboutUSComponent } from './about-us.component'
import { TranslateModule } from '@ngx-translate/core'
import { ReviewStarsModule } from '../common/review-stars/review-stars.module'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, AngularSvgIconModule, TranslateModule, ReactiveFormsModule, ReviewStarsModule],
  declarations: [AboutUSComponent],
})
export class AboutUsModule {}
