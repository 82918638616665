<section class="subuser">
  <article class="contentWrapper">
    <div class="headings">
      <div class="title">{{ 'LINKS.CHILDREN' | translate }}</div>
    </div>
    <div class="users-container">
      <div class="user" matRipple *ngFor="let subuser of subuser$ | async">
        <div class="icon-text">
          <div class="icon">
            <img class="avatar" *ngIf="subuser.avatar && !isLottie(subuser.avatar.src)" [src]="subuser.avatar.src" [alt]="subuser.avatar.name" />
            <ng-lottie *ngIf="subuser.avatar && isLottie(subuser.avatar.src)" class="avatar" [options]="{ path: subuser.avatar.src }"> </ng-lottie>
          </div>
          <div class="text">
            <span>{{ subuser.name }}</span>
          </div>
        </div>
        <div class="edit-remove-btn">
          <div class="btn" [routerLink]="['/profile/parents/subuser', subuser.id]">
            <span>{{ 'BUTTONS.EDIT' | translate }}</span>
          </div>
          <div class="btn" (click)="removeSubuser(subuser)">
            <span>{{ 'BUTTONS.REMOVE' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="add" *ngIf="(subuser$ | async)?.length < 6" (click)="openAddSubuserDialog()">
        <img src="assets/images/icons/circle-plus 1.svg" alt="add subuser" />
        <span>{{ 'CHILD.ADD_NEW_USER' | translate }}</span>
      </div>
      <p class="bold">*In order to add a new language, click edit user</p>
    </div>
  </article>
</section>
