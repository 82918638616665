import { Injectable } from '@angular/core'
import { IAnswer } from '../models/Answer.model'
import { IQuestion } from '../models/Question.model'

@Injectable({
  providedIn: 'root',
})
export class RandomizeService {
  public shuffle(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }
    return array
  }

  public getRandomQuestionImage(question: IQuestion) {
    let images = []
    images.push(question.pictureSrc)
    if (question.altImage1) {
      images.push(question.altImage1)
    }
    if (question.altImage2) {
      images.push(question.altImage2)
    }
    if (question.altImage3) {
      images.push(question.altImage3)
    }
    if (question.altImage4) {
      images.push(question.altImage4)
    }
    if (question.altImage5) {
      images.push(question.altImage5)
    }

    let random = Math.floor(Math.random() * images.length)
    return images[random]
  }

  public getRandomAnswerImage(answer: IAnswer) {
    let images = []
    images.push(answer.pictureSrc)
    if (answer.altImage1) {
      images.push(answer.altImage1)
    }
    if (answer.altImage2) {
      images.push(answer.altImage2)
    }
    if (answer.altImage3) {
      images.push(answer.altImage3)
    }
    if (answer.altImage4) {
      images.push(answer.altImage4)
    }
    if (answer.altImage5) {
      images.push(answer.altImage5)
    }

    let random = Math.floor(Math.random() * images.length)
    return images[random]
  }

  public getRandom12Colors() {
    let colors = ['#bfe0dd', '#bfe0c8', '#cfe0bf', '#e0e0bf', '#e0d6bf', '#e0c8bf', '#e0bfca', '#d3bfe0', '#bfc2e0', '#bfd1e0', '#d3d9dc', '#bfe6b8']
    return this.shuffle(colors)
  }
}
