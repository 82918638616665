export const mouseWheelScroll = ({ nativeElement }) => {
  const item = nativeElement
  item.addEventListener('wheel', function (e) {
    if (e.deltaX == -0) {
      e.deltaY > 0 ? (item.scrollLeft += 80) : (item.scrollLeft -= 80)
    }
    return
  })
}

export const mouseMoveScroll = ({ nativeElement }): void => {
  let isDown = false
  let startX
  let scrollLeft

  nativeElement.addEventListener('mousedown', (event) => {
    isDown = true
    nativeElement.classList.add('activeScroll')
    startX = event.pageX - nativeElement.offsetLeft
    scrollLeft = nativeElement.scrollLeft
  })
  nativeElement.addEventListener('mouseleave', () => {
    isDown = false
    nativeElement.classList.remove('activeScroll')
  })
  nativeElement.addEventListener('mouseup', () => {
    isDown = false
    nativeElement.classList.remove('activeScroll')
  })
  nativeElement.addEventListener('mousemove', (event) => {
    if (!isDown) return
    event.preventDefault()
    const x = event.pageX - nativeElement.offsetLeft
    const walk = x - startX
    nativeElement.scrollLeft = scrollLeft - walk
  })
}
