import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = environment.gatewayUrl
  }

  public getAllCourses(defaultCourses = 'active') {
    return this.http.get(`${this.gatewayUrl}course/get/${defaultCourses}`)
  }

  public getDetailedReport(request) {
    return this.http.get(`${this.gatewayUrl}log/get/report`, {
      params: { subuserId: request.subuserId, courseId: request.courseId },
    })
  }

  public getDetailedReportByDate(request) {
    return this.http.get(`${this.gatewayUrl}log/get/report`, {
      params: {
        subuserId: request.subuserId,
        courseId: request.courseId,
        firstDay: request.firstDay,
        lastDay: request.lastDay,
      },
    })
  }
}
