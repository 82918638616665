<section class="subuser">
  <article class="contentWrapper">
    <h2>{{ 'NOTIFICATIONS.TITLE' | translate }}</h2>

    <div class="toggle">
      <span class="title">{{ 'NOTIFICATIONS.LESSON' | translate }}</span>
      <mat-slide-toggle> </mat-slide-toggle>
    </div>

    <div class="toggle">
      <span class="title">{{ 'NOTIFICATIONS.UNIT' | translate }}</span>
      <mat-slide-toggle> </mat-slide-toggle>
    </div>

    <div class="toggle">
      <span class="title">{{ 'NOTIFICATIONS.WEEK' | translate }}</span>
      <mat-slide-toggle> </mat-slide-toggle>
    </div>

    <div class="toggle">
      <span class="title">{{ 'NOTIFICATIONS.MONTH' | translate }}</span>
      <mat-slide-toggle> </mat-slide-toggle>
    </div>

    <div class="toggle">
      <span class="title">{{ 'NOTIFICATIONS.ACCOUNT' | translate }}</span>
      <mat-slide-toggle> </mat-slide-toggle>
    </div>

    <div class="toggle">
      <span class="title">{{ 'NOTIFICATIONS.MESSAGE' | translate }}</span>
      <mat-slide-toggle> </mat-slide-toggle>
    </div>
    <div class="toggle">
      <span class="title">{{ 'NOTIFICATIONS.REMIND' | translate }}</span>
      <mat-slide-toggle> </mat-slide-toggle>
    </div>
  </article>
</section>
