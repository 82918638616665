export const isAccessLesson = ({ lesson, accessibleCourses, subuser, id }) => {
  if (lesson.isFree) {
    if (!subuser.lessonMode && !lesson.completed && !lesson.current) {
      return false
    }
    return true
  }

  if (typeof accessibleCourses != 'undefined' && (accessibleCourses.indexOf(null) != -1 || accessibleCourses.indexOf(id) != -1)) {
    if (!subuser.lessonMode && !lesson.completed && !lesson.current) {
      return false
    }
    return true
  }
  return null
}

export const setCoins = (lessonType) => {
  let subuserStars = 0

  switch (lessonType) {
    case 'video':
      return (subuserStars = 50)

    case 'video-song':
      return (subuserStars = 50)

    case 'game':
      return (subuserStars = 75)

    case 'minitest':
      return (subuserStars = 100)

    case 'unittest':
      return (subuserStars = 100)

    case 'book':
      return (subuserStars = 25)

    default:
      return
  }
}

/**
 * Function Check does it have user access to lesson on current course
 * @param accessibleCourses
 */
export const isAccessleCourses = (accessibleCourses, course) => {
  if (typeof accessibleCourses != 'undefined' && (accessibleCourses.indexOf(null) != -1 || accessibleCourses.indexOf(course.id) != -1)) {
    return true
  }

  return false
}
