import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'testimonials-section',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements AfterViewInit {
  constructor(public translate: TranslateService) {}

  @ViewChild('customersWrapper', { static: false }) customers: ElementRef
  @ViewChild('reviewTriangle', { static: false }) reviewTriangle: ElementRef
  @ViewChild('reviewBlock', { static: false }) reviewBlock: ElementRef

  ngAfterViewInit() {
    this.showSlides(1)
  }

  checkReview(event: Event | any, reviewNumber: number) {
    const reviewAvatarCoords = event.target.getBoundingClientRect()
    const reviewsWrapperCoords = this.customers.nativeElement.getBoundingClientRect()

    const relativePosition = {
      top: reviewAvatarCoords.top - reviewsWrapperCoords.top,
      left: reviewAvatarCoords.left - reviewsWrapperCoords.left,
      right: reviewAvatarCoords.right - reviewsWrapperCoords.right,
      bottom: reviewAvatarCoords.bottom - reviewsWrapperCoords.bottom,
    }

    this.reviewTriangle.nativeElement.style.left = `${relativePosition.left}px`

    this.showSlides(reviewNumber)
  }

  showSlides(n: number): void {
    const reviews = this.reviewBlock.nativeElement.getElementsByClassName('review')

    if (n > reviews.length) this.showSlides(1)

    if (n < 1) this.showSlides(reviews.length)

    for (let i = 0; i < reviews.length; i++) {
      reviews[i].style.display = 'none'
    }

    reviews[n - 1].style.display = 'block'
    reviews[n - 1].classList.add('active')
  }
}
