import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { IPlan } from 'src/app/models/Payment.model'
import { NotificationService } from 'src/app/services/notification.service'
import { PaymentService } from 'src/app/services/payment.service'
import { SignInFailure } from '../actions/auth.actions'
import {
  ActivateStoppedSubscription,
  ActivateStoppedSubscriptionSuccess,
  EPaymentActions,
  GetCustomer,
  GetCustomerSuccess,
  GetPaymentMethod,
  GetPaymentMethodSuccess,
  GetPlan,
  GetPlans,
  GetPlansSuccess,
  GetPlanSuccess,
  UpdatePaymentMethod,
  UpdatePaymentMethodSuccess,
  UpdatePlan,
  UpdatePlanSuccess,
} from '../actions/payment.actions'
import { IAppState } from '../state/app.state'

@Injectable()
export class PaymentEffects {
  getPlan$: Observable<GetPlanSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetPlan>(EPaymentActions.GetPlan),
      map((action: GetPlan) => action.payload),
      mergeMap((payload: any) => this._paymentService.getPlanById(payload)),
      mergeMap((plan: IPlan) => of(new GetPlanSuccess(plan))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getPlans$: Observable<GetPlansSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetPlans>(EPaymentActions.GetPlans),
      mergeMap(() => {
        return this._paymentService.getPlans()
      }),
      mergeMap((plans: any) => of(new GetPlansSuccess(plans))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  updatePlan$: Observable<UpdatePlanSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdatePlan>(EPaymentActions.UpdatePlan),
      map((action: UpdatePlan) => action.payload),
      mergeMap((payload: any) => {
        return this._paymentService.updatePlan(payload)
      }),
      mergeMap((customer: any) => {
        return of(new UpdatePlanSuccess(customer))
      }),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getCustomer$: Observable<GetCustomerSuccess | GetPaymentMethod> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetCustomer>(EPaymentActions.GetCustomer),
      map((action: GetCustomer) => action.payload),
      mergeMap((payload: any) => this._paymentService.getCustomer(payload)),
      mergeMap((customer: any) => [new GetCustomerSuccess(customer)]),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getPaymentMethod$: Observable<GetPaymentMethodSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetPaymentMethod>(EPaymentActions.GetPaymentMethod),
      map((action: GetPaymentMethod) => action.payload),
      mergeMap((payload: any) => {
        return this._paymentService.getPaymentMethod(payload)
      }),
      mergeMap((paymentMethod: any) => of(new GetPaymentMethodSuccess(paymentMethod))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  updatePaymentMethod$: Observable<UpdatePaymentMethodSuccess | SignInFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdatePaymentMethod>(EPaymentActions.UpdatePaymentMethod),
      map((action: UpdatePaymentMethod) => action.payload),
      mergeMap((payload: any) => {
        return this._paymentService.updatePaymentMethod(payload)
      }),
      mergeMap((paymentMethod: any) => of(new UpdatePaymentMethodSuccess(paymentMethod))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        this._store.dispatch(new SignInFailure(error.clientMessage))
        return caught
      })
    )
  )

  activateStoppedSubscription$: Observable<ActivateStoppedSubscriptionSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<ActivateStoppedSubscription>(EPaymentActions.ActivateStoppedSubscription),
      map((action: ActivateStoppedSubscription) => action.payload),
      mergeMap((payload: any) => {
        return this._paymentService.activateStoppedSubscription(payload)
      }),
      mergeMap((request: any) => [new ActivateStoppedSubscriptionSuccess(request)]),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.error, 2)
        this._store.dispatch(new SignInFailure(error.error))
        return caught
      })
    )
  )

  constructor(private _actions$: Actions, private _store: Store<IAppState>, private _paymentService: PaymentService, private _notificationService: NotificationService) {}
}
