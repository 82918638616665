import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { ConfirmationPopupComponent } from './confirmation.component'

@NgModule({
  declarations: [ConfirmationPopupComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatIconModule, MatCardModule, MatInputModule, TranslateModule, SoundsModule],
})
export class ConfirmationPopupModule {}
