<section [ngClass]="{ test: test }" class="game clickAndTell">
  <div class="game-help-text" (click)="audiohelpPlay()">
    <button mat-icon-button color="primary">
      <audio #audiohelp>
        <source src="/assets/images/sounds/instructions/{{ currentlang }}/CAT.mp3" type="audio/mp3" />
      </audio>
      <img src="/assets/images/games/audio4x.png" alt="audio icon" />
    </button>
    {{ 'GAME.CLICK_AND_TELL.HELP_TEXT' | translate }}
  </div>
  <ng-container *ngIf="game">
    <img class="trees left" src="/assets/images/games/click-and-tell/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/click-and-tell/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/click-and-tell/gameBackground3.svg" alt=" " />
  </ng-container>
  <ng-container *ngIf="test">
    <img class="trees left" src="/assets/images/tests/testBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/tests/testBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/tests/testBackground3.svg" alt=" " />
  </ng-container>
  <article class="contentWrapper">
    <div class="cards">
      <div *ngFor="let card of altAnswers; let index = index" class="card" (click)="clickAndTell(card, $event)" [ngClass]="{ 'helpActive': audiohelpIsPlaying }">
        <div class="mark">
          <img src="/assets/images/games/audio4x.png" alt="audio icon" *ngIf="!listened.includes(card)" />
          <img src="/assets/images/games/green_checked_icon.svg" alt="checked icon" *ngIf="listened.includes(card)" />
        </div>
        <div class="image" [ngStyle]="{ 'background-color': cardColors[index] }">
          <img [src]="card.pictureSrc" alt="{{ card.text }}" *ngIf="card.imgType != 'json'" />
          <lottie-player *ngIf="card.imgType == 'json' && comparison" class="lottie_p" autoplay="true" mode="normal" (frame)="lottieFrameChanged($event)" [src]="card.pictureSrc" style="width: 100%; height: 100%"> </lottie-player>
          <ng-lottie
            *ngIf="card.imgType == 'json' && !comparison"
            (animationCreated)="animationCreated($event, index)"
            class="lottie_p"
            style="width: 100%; height: 100%"
            (mouseenter)="playAnim(index)"
            (mouseleave)="stopAnim(index)"
            [options]="{ path: card.pictureSrc, autoplay: false }"
          >
          </ng-lottie>
        </div>
        <span class="title">{{ card.text }}</span>
      </div>
    </div>
  </article>
  <div class="balance-row"></div>
  <div appSounds class="continue" [ngClass]="{ 'continue-visible': checkAllListened }" (click)="setAnswer(question)" mat-button>
    <img src="/assets/images/games/continue_cta.svg" alt="continue icon" />
    <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
  </div>
</section>
