import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class SiteBlockService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = environment.gatewayUrl
  }

  public getBlock(code) {
    return this.http.get(`${this.gatewayUrl}siteblock-ext/get`, { params: { code } })
  }

  public getPage(slug) {
    return this.http.get(`${this.gatewayUrl}page/get`, { params: { slug } })
  }

  public getPages() {
    return this.http.get(`${this.gatewayUrl}page/all`)
  }
}
