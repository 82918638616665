import { ISubuser, ISubuserOptions } from 'src/app/models/Subuser.model'

export interface ISubuserState {
  subusers: Array<ISubuser>
  subuser: ISubuser
  currentSubuser: ISubuser
  subuserDetailedReports: any
  subuserReport: ISubuser
  allCourses: []
}

export const InitialSubuserState: ISubuserState = {
  subusers: null,
  subuser: null,
  currentSubuser: null,
  subuserDetailedReports: null,
  subuserReport: null,
  allCourses: [],
}
