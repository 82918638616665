import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(@Inject(DOCUMENT) private dom, private titleSvc: Title, private metaSvc: Meta) {}

  updateTitle(title: string) {
    this.titleSvc.setTitle(title)
  }

  updateDescription(content: string) {
    this.metaSvc.updateTag({ name: 'description', content })
  }

  createCanonicalLink(url: string) {
    this.metaSvc.updateTag({ name: 'robots', content: 'index,follow' })

    const el = this.dom.querySelector("[rel='canonical']")
    if (el) {
      el.remove()
    }

    const link: HTMLLinkElement = this.dom.createElement('link')

    link.setAttribute('rel', 'canonical')
    link.setAttribute('href', url)
    // link.id = 'canonical';
    this.dom.head.appendChild(link)
  }
}
