import { IAppState } from '../state/app.state'
import { IAssignmentsState } from '../state/assignments.state'
import { createSelector } from '@ngrx/store'

const assignmentState = (state: IAppState) => state.assignments

export const selectAssignments = createSelector(assignmentState, (state: IAssignmentsState) => state.assignments)

export const selectStudentAssignments = createSelector(assignmentState, (state: IAssignmentsState) => state.studentAssignments)

export const selectStudentsAssignments = createSelector(assignmentState, (state: IAssignmentsState) => state.studentsAssignments)

export const selectStudentsGrades = createSelector(assignmentState, (state: IAssignmentsState) => state.studentsGrades)

export const selectStudentGrades = createSelector(assignmentState, (state: IAssignmentsState) => state.studentGrades)
