import { IAssignment, IStudentGrades, IStudentsAssignments, IStudentsGrades } from '../../models/Assignment.model'

export interface IAssignmentsState {
  assignments: Array<IAssignment>
  studentAssignments: Array<IAssignment>
  studentsAssignments: IStudentsAssignments
  studentsGrades: Array<IStudentsGrades>
  studentGrades: IStudentGrades
}

export const InitialAssignmentsState: IAssignmentsState = {
  assignments: [],
  studentAssignments: [],
  studentsAssignments: null,
  studentsGrades: [],
  studentGrades: null,
}
