import { NgModule } from '@angular/core'
import { BookEndPopupComponent } from './book-end.component'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [BookEndPopupComponent],
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule, MatCardModule, MatDialogModule, TranslateModule],
})
export class BookEndPopupModule {}
