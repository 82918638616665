<div class="mobile-dashboard-container">
  <div class="logo">
    <img src="/assets/images/logos/dinolingo-brand-logo-1.svg" alt="" />
  </div>
  <div class="container">
    <div class="menu-container">

      <div class="menu">
        <!-- Lesson Plan -->
        <ng-container *ngIf="user?.userType !== 'student'">
          <div class="icon-text" routerLink="/profile/lesson-plan">
            <div class="icon">
              <img src="/assets/images/icons/menuLessonGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.LESSON_PLAN' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <div class="icon-text" routerLink="/profile/parents/progress-reports">
          <div class="icon">
            <img src="/assets/images/icons/menuProgressReportGold.svg" alt="" />
          </div>
          <div class="text">
            <span>{{ 'LINKS.PROGRESS' | translate }}</span>
          </div>
        </div>
        <div class="icon-text" routerLink="/profile/parents/subusers">
          <div class="icon">
            <img src="/assets/images/icons/menuUsersGold.svg" alt="" />
          </div>
          <div class="text">
            <span>{{ 'LINKS.CHILDREN' | translate }}</span>
          </div>
        </div>
        <div class="icon-text" routerLink="/profile/parents/profile">
          <div class="icon">
            <img src="/assets/images/icons/menuProfileGold.svg" alt="" />
          </div>
          <div class="text"><span>My Profile</span></div>
        </div>
        <ng-container *ngIf="user.userType === 'parent'">
          <div class="icon-text bordered" routerLink="/profile/{{ teacherOrParent }}/subscriptions/stripe">
            <div class="icon">
              <img src="/assets/images/icons/Group 723.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.SUBSCRIPTIONS' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="teacherOrParent">
          <div class="icon-text" routerLink="/profile/{{ teacherOrParent }}/tutorials" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuTutorialGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.TUTORIALS' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <div class="icon-text" (click)="goToHelp()">
          <div class="icon">
            <img src="/assets/images/icons/menuHelpGold.svg" alt="" />
          </div>
          <div class="text">
            <span>{{ 'LINKS.HELP' | translate }}</span>
          </div>
        </div>

        <ng-container *ngIf="user.userType === 'student'">
          <div class="icon-text" routerLink="/profile/parents/studentTutorials" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuTutorialGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.TUTORIALS' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <div class="icon-text" (click)="openMobileAppMessage()">
          <div class="icon">
            <img src="/assets/images/icons/menuDownloadAppGold.svg" alt="" />
          </div>
          <div class="text"><span>Download App</span></div>
        </div>
        <div class="icon-text" color="primary" (click)="logout()">
          <div class="icon">
            <img src="/assets/images/icons/menuLogoutGold.svg" alt="logout image" />
          </div>
          <div class="text">
            <span class="logout-color">{{ 'BUTTONS.LOGOUT' | translate }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
