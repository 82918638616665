<section [ngClass]="{ test: test }" class="game correctOrIncorrect">
  <div class="game-help-text" (click)="audiohelpPlay()">
    <button mat-icon-button color="primary">
      <audio #audiohelp>
        <source src="/assets/images/sounds/instructions/{{ currentlang }}/TOF.mp3" type="audio/mp3" />
      </audio>
      <img src="/assets/images/games/audio4x.png" alt="audio icon" />
    </button>
    {{ 'GAME.CORRECT_OR_INCORRECT.HELP_TEXT' | translate }}
  </div>
  <ng-container *ngIf="game">
    <img class="trees left" src="/assets/images/games/correct-or-incorrect/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/correct-or-incorrect/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/correct-or-incorrect/gameBackground3.svg" alt=" " />
  </ng-container>
  <ng-container *ngIf="test">
    <img class="trees left" src="/assets/images/tests/testBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/tests/testBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/tests/testBackground3.svg" alt=" " />
  </ng-container>
  <article class="contentWrapper">
    <div class="card" (click)="playAudio(question.answers[0].audioSrc, $event)" [ngClass]="{ 'helpActive': audiohelpIsPlaying }">
      <div class="mark">
        <img src="/assets/images/games/audio4x.png" alt="audio icon" />
      </div>
      <div class="image" [ngStyle]="{ 'background-color': cardColors[0] }">
        <img [src]="altImage[0]" alt="{{ question.text }}" *ngIf="imgType != 'json'" />
        <ng-container *ngIf="imgType == 'json'">
          <ng-lottie *ngFor="let altm of altImage" class="lottie_p" style="width: 100%; height: 100%" [options]="{ path: altm }"> </ng-lottie>
        </ng-container>
      </div>
      <span *ngIf="game" class="title">{{ question.text }}</span>
    </div>
    <div class="answers fixed">
      <div class="answer" (click)="selectAnswer(true)" [ngClass]="{ 'helpActivePlus': audiohelpIsPlaying }">
        <img src="/assets/images/games/correct_inact.svg" alt="button yes" [ngClass]="{ 'active': !checked && selectedAnswer !== true }" />
        <img src="/assets/images/games/correct_act.svg" alt="button yes selected" [ngClass]="{ 'active': !checked && selectedAnswer === true }" />
        <img src="/assets/images/games/correct_one.svg" alt="button yes was correct" [ngClass]="{ 'active': checked && selectedAnswer === false && !checkRightAnswer }" />
        <img src="/assets/images/games/correct_inact.svg" alt="button yes" [ngClass]="{ 'active': checked && selectedAnswer === false && checkRightAnswer }" />
        <img src="/assets/images/games/green_correct_checked.svg" alt="button yes is correct" [ngClass]="{ 'active': checked && selectedAnswer === true && checkRightAnswer }" />
        <img src="/assets/images/games/red_correct_checked.svg" alt="button yes is incorrect" [ngClass]="{ 'active': checked && selectedAnswer === true && !checkRightAnswer }" />
      </div>
      <div class="answer" (click)="selectAnswer(false)" [ngClass]="{ 'helpActivePlus': audiohelpIsPlaying }">
        <img src="/assets/images/games/incorrect_inact.svg" alt="button no" [ngClass]="{ 'active': !checked && selectedAnswer !== false }" />
        <img src="/assets/images/games/incorrect_act.svg" alt="button no selected" [ngClass]="{ 'active': !checked && selectedAnswer === false }" />
        <img src="/assets/images/games/incorrect_one.svg" alt="button no was correct" [ngClass]="{ 'active': checked && selectedAnswer === true && !checkRightAnswer }"  />
        <img src="/assets/images/games/incorrect_inact.svg" alt="button no" [ngClass]="{ 'active': checked && selectedAnswer === true && checkRightAnswer }" />
        <img src="/assets/images/games/green_incorrect_checked.svg" alt="button no is correct" [ngClass]="{ 'active': checked && selectedAnswer === false && checkRightAnswer }" />
        <img src="/assets/images/games/red_incorrect_checked.svg" alt="button no is incorrect" [ngClass]="{ 'active': checked && selectedAnswer === false && !checkRightAnswer }" />
      </div>
    </div>
  </article>
  <div class="balance-row"></div>
  <ng-container>
    <div *ngIf="checked && checkRightAnswer" class="anim stars">
      <img src="/assets/images/anim/check_stars.png" alt="check" />
    </div>
    <div class="continue" [ngClass]="{ 'continue-visible': selectedAnswer != null && !checked }" *ngIf="!checked" (click)="checkAnswer()" mat-button>
      <img src="/assets/images/games/check_cta.svg" alt="check icon" />
      <span>{{ 'BUTTONS.CHECK' | translate }}</span>
    </div>
    <div appSounds class="continue" [ngClass]="{ 'continue-visible': checked }" *ngIf="checked" (click)="setAnswer(selectedAnswer)" mat-button>
      <img src="/assets/images/games/continue_cta.svg" alt="continue icon" />
      <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
    </div>
  </ng-container>
</section>
