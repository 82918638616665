import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { TopBannerComponent } from './topbanner.component'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule],
  declarations: [TopBannerComponent],
  exports: [TopBannerComponent],
})
export class TopBannerModule {}
