import { createSelector } from '@ngrx/store'
import { IAppState } from '../state/app.state'
import { IStatisticsState } from '../state/statistics.state'

const statisticsState = (state: IAppState) => state.statistics

export const selectStatistics = createSelector(statisticsState, (state: IStatisticsState) => state)

export const selectDinosaurs = createSelector(statisticsState, (state: IStatisticsState) => state.dinosaurs)

export const selectOpenDinosaurs = createSelector(statisticsState, (state: IStatisticsState) => state.openDinosaurs)

export const selectAchievements = createSelector(statisticsState, (state: IStatisticsState) => state.achievements)

export const selectStars = createSelector(statisticsState, (state: IStatisticsState) => state.stars)

export const selectTestReport = createSelector(statisticsState, (state: IStatisticsState) => state.testReport)
