<section class="student--all-test-reports" *ngIf="data">
  <article class="contentWrapper">
    <div class="actions">
      <div class="close"><span (click)="dialogRef.close()">+</span></div>
      <div class="name-title">
        <div class="name">{{ this.report.subuser.name }}</div>
        <div class="title">{{ 'LINKS.TEST_RESULT' | translate }}</div>
      </div>
    </div>
    <!-- Cycle A Start -->
    <div class="level">
      <!-- Cycle Aa Start -->
      <div class="unit" *ngFor="let unit of reports">
        <h2 class="headLevel">{{ unit.name }}</h2>
        <!-- Cycle Aaa Start -->
        <div class="tests" *ngFor="let lesson of unit?.lessons">
          <!-- NEXT IF test has results -->
          <mat-accordion *ngIf="lesson.testReports.length">
            <mat-expansion-panel hideToggle [expanded]="false">
              <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'48px'">
                <mat-panel-title>
                  <div class="test-row">
                    <div class="left">
                      <h4>{{ lesson.name }}</h4>
                    </div>
                    <div class="center">
                      <div>
                        <span class="result-title exist">{{ 'LEARNING_REPORT.RESULTS' | translate }} </span>
                        <!-- Add "exist" class if has results -->
                        <mat-icon color="489600">arrow_drop_down</mat-icon>
                      </div>
                      <span>({{ lesson | bestScore : lesson.id }}% {{ 'LEARNING_REPORT.RESULTS_TEXT' | translate }}) </span>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="test-results">
                <!-- Cycle Aaab Start -->
                <div class="result-row" *ngFor="let report of lesson.testReports">
                  <span class="date">{{ report.createdAt | date : 'MMM dd, yyyy' }}</span>
                  <span
                    class="success"
                    [ngClass]="{
                      bronze: report.progress < 70,
                      silver: report.progress >= 70 && report.progress < 90,
                      gold: report.progress >= 90
                    }"
                  >
                    {{ report.progress }}% ({{ report.correctAnswers }}
                    {{ 'LEARNING_REPORT._FROM' | translate }}
                    {{ report.allAnswers }})</span
                  >
                  <div class="goto-details" (click)="openTestResultsPopup(report, lesson.name, report.subuser)">
                    <span>{{ 'LEARNING_REPORT.DETAILS' | translate }} </span>
                    <mat-icon class="arrow right" color="primary">arrow_right</mat-icon>
                  </div>
                </div>
                <!-- Cycle Aaab End -->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <!-- END IF test has results -->
          <!-- NEXT IF test NO results -->
          <div class="test-row" *ngIf="!lesson.testReports.length">
            <div class="left">
              <h4>{{ lesson.name }}</h4>
            </div>
            <div class="center">
              <div>
                <span class="result-title">{{ 'LEARNING_REPORT.NO_RESULTS' | translate }} </span>
              </div>
            </div>
          </div>
          <!-- END IF test NO results -->
        </div>

        <!-- Cycle Aaa End -->
      </div>
      <!-- Cycle Aa End -->
    </div>
    <!-- Cycle A End -->
  </article>
</section>
