export const rangomSpinner = () => {
  const loaderNum = Math.floor(Math.random() * Math.floor(4))

  switch (loaderNum) {
    case 0:
      return 'circleDotLoader'
    case 1:
      return 'rectangularLoader'
    case 2:
      return 'spinnerLoader'
    case 3:
      return 'slowSpinner'

    default:
      break
  }
}
