<div class="dino-view">
  <div class="actions">
    <span class="title">{{ dinosaur.name }}</span>
    <span appSounds class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></span>
  </div>

  <div class="dinosaur test">
    <ng-lottie class="lottie_p" [options]="options"></ng-lottie>
  </div>
  <div class="description">{{ dinosaur.description }}</div>
</div>
