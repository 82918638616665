import { IStudent } from 'src/app/models/Student.model'

export interface IStudentState {
  students: Array<IStudent>
  currentStudent: IStudent
}

export const InitialStudentState: IStudentState = {
  students: [],
  currentStudent: null,
}
