<section class="studentLogin">
  <article class="">
    <h4>Student Login</h4>
    <form [formGroup]="studentLoginForm" class="studentLoginForm">
      <div class="inputGroup">
        <input matInput type="text" required placeholder="{{ 'PROFILE.CLASSROOM_CODE' | translate }}" formControlName="classroomCode" />
        <ng-container *ngIf="(classroomCode.touched || classroomCode.dirty) && classroomCode.errors">
          <mat-error *ngIf="classroomCode.errors.required">
            {{ 'PROFILE.CLASSROOM_CODE_REQUIRED' | translate }}
          </mat-error>
        </ng-container>
      </div>

      <div class="inputGroup">
        <input matInput type="text" required placeholder="{{ 'PROFILE.STUDENT_CODE' | translate }}" formControlName="studentCode" />
        <ng-container *ngIf="(studentCode.touched || studentCode.dirty) && studentCode.errors">
          <mat-error *ngIf="studentCode.errors.required">
            {{ 'PROFILE.STUDENT_CODE_REQUIRED' | translate }}
          </mat-error>
        </ng-container>
      </div>

      <div class="inputGroup nolabel">
        <button appSounds mat-raised-button class="login-btn" type="submit" (click)="studentLogin()" [disabled]="studentLoginForm.invalid" color="accent">
          {{ 'BUTTONS.LOGIN' | translate }}
        </button>
      </div>
    </form>
  </article>
</section>
