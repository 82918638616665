import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TeachersComponent } from './teachers.component'
import { TranslateModule } from '@ngx-translate/core'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar'
import { ConfirmationPopupModule } from '../../../shared/popups/Control-Dialogs/confirmation/confirmation.module'
import { AddTeacherDialogModule } from '../../../shared/popups/Teacher-Dialogs/add-teacher/add-teacher.module'
import { EditTeacherDialogModule } from '../../../shared/popups/Teacher-Dialogs/edit-teacher/edit-teacher.module'

@NgModule({
  declarations: [TeachersComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    ConfirmationPopupModule,
    AddTeacherDialogModule,
    EditTeacherDialogModule,
  ],
})
export class TeachersModule {}
